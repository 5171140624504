/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, AfterViewInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Action, select } from '@ngrx/store';

/**Services **/
// import {  OCCPageState, OccTextState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';
import html2pdf from 'html2pdf.js';
import { takeWhile } from 'rxjs/operators';
import { CareersJr_Buttons_Reducer_Text_State, CareersJr_EmpOutlook_Wages_State, CareersJr_Settings_Tab_Text_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
@Component({
  selector: 'wages-careersJr',
  templateUrl: './wages-careersJr.layout.html',
})

export class WagesCareersJrComponent implements OnInit, OnDestroy, AfterViewInit {
  loggedInState = StorageService.staticSessionStorageGet('stateAbr'); /** Contains the state while logging in*/
  selectedStates; /** Contains user selected state, by default US. If the default is not need then we have to consider "''" not '' or "" */
  duplicateState; /** clone variable for selected states, used when displaying states  */
  displayValue = 2; /** Based on this we will apply disable property to the list */
  wagestabs = { 'annual': 'Annual Wages', 'monthly': 'Monthly Wages', 'hourly': 'Hourly Wages' };
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  showWages = true;
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  langVal; /** To get the value of language */
  settingsTextTab;
  compAlive = true;
  occName;
  occId;
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;

  loc_btn_color = ['#a22903', '#005787', '#1b621e', '#014941', '#770c84'];
  @ViewChild('atWagesPrintPage', { static: true }) atWagesPrintPage: ElementRef;
  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  NotIE = navigator.userAgent.match(/Trident.*rv\:11\./);
  occPdfData: any;
  constructor(private router: Router, private occstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private utils: Utilities,
    private occSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private activatedRoute: ActivatedRoute,
    private dispatchStore: Store<Action>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private Frameworkstore: Store<FrameworkConfigState>,
    private occDispatch: CareersJrDispatchService,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string
     ) {
      this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
      this.restrictedAccountConstant = restrictedAccount;
      if (this.accountTypeJwt !== this.restrictedAccountConstant) {
        this.restrictedAccountType = true;
    } else {
        this.restrictedAccountType = false;
    }
      this.selectedStates = ['US', this.loggedInState]; /** Contains user selected state, by default US. If the default is not need then we have to consider "''" not '' or "" */
      this.duplicateState = ['US', this.loggedInState]; /** clone variable for selected states, used when displaying states  */

     this.occDispatch.careerWagesListen().pipe(takeWhile(() => this.compAlive)).subscribe(() => {
      // console.log('AGA called ---');
      this.utils.showLoading();
      this.careersPrint();
    });
    this.loggedInState = StorageService.staticSessionStorageGet('stateAbr');
    /** Get user selected language */
    this.langVal = StorageService.staticSessionStorageGet('langset');
    /** Check whether user selected states while reloading,
    * if he selected place that states in selected variables,
    * if not default values are inserted
    */
    if (StorageService.staticSessionStorageGet('wagesStates')) {
      const arr = StorageService.staticSessionStorageGet('wagesStates').split(',');
      this.duplicateState = arr;
      this.selectedStates = arr;
    } else {
      this.selectedStates = ['US', this.loggedInState] ;
      this.duplicateState = ['US', this.loggedInState];
    }
    // Get data from reducer to display buttons text
     this.occstore.pipe(select('CareersJr_Buttons_Reducer_Text'), takeWhile(() => this.compAlive)).subscribe((v) => {
      this.occCareerText = v;
    });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
     this.occSetstore.pipe(select('CareersJr_Settings_Tab_Text'), takeWhile(() => this.compAlive)).subscribe((v) => {
      this.settingsText = v;
       if (this.settingsText.CareersJr_Settings_Tab_Text.tabs) {
         this.settingsTextTab = this.settingsText.CareersJr_Settings_Tab_Text.tabs.find((obj) => (obj['tabId'] == 2));
       }
    });
    // Get data from reducer to display in cards

    this.OCCPageStateStore.pipe(select('CareersJr_EmpOutlook_Wages'), takeWhile(() => this.compAlive)).subscribe((v) => {
      this.occCareerStore = v;
      if (this.occCareerStore && this.occCareerStore.WageLevels && this.occCareerStore.WageLevels.periods) {
        setTimeout(() => {
          this.getPdfData();
        }, 2000);
      }

    });
  }
  getPdfData() {
    let count = 0;
    if (this.occCareerStore && this.occCareerStore.WageLevels && this.occCareerStore.WageLevels.periods) {
    this.occCareerStore.WageLevels.periods =  this.occCareerStore.WageLevels.periods.map((val: any) => {
         val.wages.map((val1: any) => {
           val1.locations.map((val2: any) => {
             val2.inx = count = count + 1;
             return val2;
           });
           return val1;
         });
         return val;
       });
     }
  }
  // Making sure that the The 'stateAbbr' and 'US' at the time of login is default when diplaying the wages in the wages page
  ngAfterViewInit() {
    let states;
    this.selectedStates = this.duplicateState;
    /* Below we are comparing to an empty string not to undefined so we have used combination of "''"*/
    if (this.duplicateState[0] != '\'\'') {
      //Inorder to make By default the US state and state specific login details to be displayed we have assigned duplicateState to states
       states = this.duplicateState;
       //In case of 'IC' login, we know 'IC' is same as 'US' so,
       //there is an issue while selecting five states. Inorder to overcome
       //that issue we have deleted the 'IC' so that the duplicate array can take four more selections in the
       //SelectState list when we login using 'IC' credentials i:e TestDynamic/Crinkle7 if we donot delete IC
       //then we can select only four States
       states[1] === 'IC' ? ( this.duplicateState.splice(1, 1), (states = ['US'])) : (states = this.duplicateState);
    } else {
      /*Here below we have used this else, if there arises a case
        where we donot want by default 'US' to load its details.
        Then the else case will be executed. Here the expected value
        is a string so, if we use '' or "" within the array then we get error,
        as the expected is a string format if we send undefined(''or"")
        the app crashes to avoid such a crash we have considered "''"   */
      states = ['\'\''];
    }

    this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_WAGES', payload: {
        methodVal: 'GET', module_Name: 'OccJr/v1/',
        path_params: ['OccJr', parseInt(this.occId), 'accountID'], query_params: { 'sections': 'WageLevels', 'states': states, 'lang': StorageService.staticSessionStorageGet('langset') },
        body_Params: {}, endUrlVal: 'pages'
      }
    });
    this.showWages = (this.displayValue === 0) ? false : true;
  }
  ngOnInit() {
    const val = StorageService.staticSessionStorageGet('CareersJr_Reducer_OccText') ? JSON.parse(StorageService.staticSessionStorageGet('CareersJr_Reducer_OccText')) : undefined;
    if (val && val.careersJr_Buttons_Reducer_Text && val.careersJr_Buttons_Reducer_Text.common) {
      this.occCareerText = val;
    }

    this.activatedRoute.queryParams.pipe(takeWhile(() => this.compAlive)).subscribe(params => {
      this.occName = params['occname'];
      this.occId = params['occid'];
    });
  }
  async careersPrint() {
    const element = this.atWagesPrintPage.nativeElement;
    const opt = {
      filename: 'Career Jr - Wages.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      margin: [0.1, 0, 0.2, 0],
      jsPDF: { compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.compAlive = false;
  }
  //To keep href dynamically
  value(checkVal) {
    return '#' + checkVal;
  }

  checkopen() {
    //Works when we click on selectStates button
    this.duplicateState = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.duplicateState.push(this.selectedStates[i]);
    }
    // console.log('duplicateState : ' + this.duplicateState);
    this.displayValue = this.selectedStates.length;
  }
  showStates() {
    //Works when we click on Apply button
    this.utils.showLoading();
    let states;
    this.selectedStates = this.duplicateState;
    if (this.duplicateState[0] != '\'\'') {
      states = this.duplicateState;
    } else {
      states = ['\'\''];
    }
    StorageService.staticSessionStorageSet('wagesStates', states);
    const val = StorageService.staticSessionStorageGet('CareersJr_Reducer_OccText') ? JSON.parse(StorageService.staticSessionStorageGet('CareersJr_Reducer_OccText')) : undefined;
    if (val && val.careersJr_Buttons_Reducer_Text && val.careersJr_Buttons_Reducer_Text.common) {
      this.occCareerText = val;
    }
    const asmtEndurl = (this.restrictedAccountType) ? 'OccJr/standard' : 'OccJr/standard/default';

    this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_SETTING_TABS_TEXT', payload: {
        methodVal: 'GET', module_Name: 'Settings/v1/',
        path_params:  (this.restrictedAccountType) ? ['accountID'] : [],
        query_params: { 'lang': this.langVal },
        body_Params: {}, endUrlVal: asmtEndurl, name: 'careers'
      }
    });
    this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_WAGES', payload: {
        methodVal: 'GET', module_Name: 'OccJr/v1/',
        path_params: ['OccJr', parseInt(this.occId), 'accountID'], query_params: { 'sections': 'WageLevels', 'states': states, 'lang': StorageService.staticSessionStorageGet('langset') },
        body_Params: {}, endUrlVal: 'pages'
      }
    });
    this.showWages = (this.displayValue === 0) ? false : true;
  }

  count(val, e) {
    //Works when we click on check box
    if (this.duplicateState[0] == '\'\'') {
      this.duplicateState = [];
    }
    if (e.which == 13 || e == 'click') {
      if (this.duplicateState.indexOf(val) == -1) {
        this.duplicateState.push(val);
      } else if (this.duplicateState.indexOf(val) != -1) {
        this.duplicateState.splice(this.duplicateState.indexOf(val), 1);
      }
      this.displayValue = this.duplicateState.length;
      if (this.displayValue == 0) {
        this.duplicateState = ['\'\''];
      }

    }

  }
  cancelStates() {
    //Works when we click on cancel button
    this.duplicateState = this.selectedStates;
    this.displayValue = this.selectedStates.length;
  }
  resetStates() {
    //Works when we click on reset button
    this.displayValue = 1;
    //here we have taken 5 in splice because we want to restrict selection of states upto 5
    this.loggedInState === 'IC' ? this.duplicateState.splice(0, 5, 'US') : this.duplicateState.splice(0, 5, 'US', this.loggedInState);
  }

}
