/**Angular imports.*/
import {
  ANALYZE_FOR_ENTRY_COMPONENTS,
  NgModule,
  ModuleWithProviders,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

/**Third party Libraries.*/
// import { SlimScrollModule } from 'ng2-slimscroll';

/**Custome imports.*/
import { DynamicLayout } from './dynamicLayout.component';
import { ListDynamicComponent } from './layouts/list.component';
import { TileDesignComponent } from './layouts/tiles-design/tiles-design.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, ChartsModule],
    declarations: [
        DynamicLayout,
        ListDynamicComponent,
        //  PlansHeaderComponent,
        // TilesDynamicComponent,
        TileDesignComponent,
        // WidgetDynamicComponent,
        // PlansDynamicComponent,
    ],
    exports: [
        DynamicLayout,
        // PlansHeaderComponent,
        TileDesignComponent,
    ],
    providers: []
})
export class GridModule {
  static withComponents(components: any[]): ModuleWithProviders<GridModule> {
    return {
      ngModule: GridModule,
      providers: [
        {
          provide: ANALYZE_FOR_ENTRY_COMPONENTS,
          useValue: components,
          multi: true,
        },
      ],
    };
  }
}

