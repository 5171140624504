import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
// import { Observable } from "rxjs/Rx";
import { Observable } from 'rxjs';




// import 'rxjs/add/operator/empty';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ServerApi } from '../../shared/outer-services/app.apicall.service';
import { ApiCallClass } from '../../shared/modal/apicall.modal';
import { Utilities } from '../../shared/outer-services/utilities.service';
import { StoreSharedService } from '../../shared/outer-services/store-shared.service';
import { StoreService } from '../services/store-service';
import { DashBoardActionTypes, GetDashboardDataAction, SetDashboardDataAction, SetDashboardAssessmentResultAction, SetDashboardAssessmentPageTextAction, SetDashboardPersonalInfoAction, AccountSettingActionTypes, SetAccountSettingPageTextAction, SetAccountSettingImageAction, SetPortfolioSettingsAction, SetDashboardBestCareerAction } from '../actions/dashboard.action';
import { DashboardApiCallService } from '../../shared/outer-services/dashBoard-api-calls.service';
import { of } from 'rxjs/internal/observable/of';
import { DashBoardAllListState, AssessmentResultState, AssessmentPageTextState, dashboardPersonalInfoState, accountSettingPageTextState, accountSettingImageState, portFolioSettingsState, dashboardBestCareersState } from '../state/dashboard.state';
export function toPayload(action: Action): any {
  return (action as any).payload;
}
@Injectable()
export class MainEffects {
  constructor(private action$: Actions,
    private utils: Utilities,
    private storeService: StoreSharedService,
    private server: ServerApi, private apiJson: ApiCallClass,
    public dashboardServer: DashboardApiCallService) {
  }


  @Effect() getFrameworkConfigHeaderEffect$ = this.action$
    .pipe(ofType('GET_HEADERFOOTER_TEXT'),
      map(toPayload),
      switchMap(payload => {

        // console.log('in effects the payload effect was: ' + JSON.stringify(payload));
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        // console.log('before call');

        // console.log('pages--->')
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            // console.log('result of header footer:' + JSON.stringify(result[0]));
            return of({ type: 'SET_HEADERFOOTER_TEXT', payload: result[0].Result });
          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getFrameworkConfigHeaderEffect$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  @Effect() getFrameworkConfigTabsEffect$ = this.action$
    .pipe(ofType('GET_TABITEMS_TEXT')
      , map(toPayload)
      , switchMap(payload => {

        // console.log('in effects the payload effect was: ' + JSON.stringify(payload));
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        // console.log('before call');

        // console.log('pages--->')
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_TABITEMS_TEXT', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getFrameworkConfigTabsEffect$: ${e.message}`);
            return Observable.create();
          }));
      })
    );


  @Effect() getPayloadExample$ = this.action$
    .pipe(ofType('GET_ASMNT_COMMON_TEXT')
      , map(toPayload)
      , switchMap(payload => {

        // console.log('in effects the payload effect was: ' + JSON.stringify(payload));
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        //console.log('in GET_ASMNT_COMMON_TEXT effect');
        if (payload.endUrlVal == 'pageText/common') {
          // console.log('pageText/common--->')
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'SET_COMMON_TEXT', payload: result[0].Result })
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts getPayloadExample$ pageText/common: ${e.message}`);
              return Observable.create();
            }));
        } else if (payload.endUrlVal == 'pages') {
          // console.log('pages--->')
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'OCC_EFFECT', payload: this.storeService.convertJson(result[0].Result, 'sectionName', 'sectionResults', 'common') })

            ), catchError(e => {
              this.utils.handleError(`main-effects.ts getPayloadExample$ pages: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );

  @Effect() empTextVar$ = this.action$
    .pipe(ofType('EMP_OUTLOOK_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        // console.log('in EMP_OUTLOOK_TEXT effect');

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result => {
              this.utils.hideLoading();
              return of({ type: 'EMP_OUTLOOK_EFFECT', payload: this.storeService.convertJson(result[0].Result, 'sectionName', 'sectionResults', 'outlook') });
            }
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts empTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );

  @Effect() wagesTextVar$ = this.action$
    .pipe(ofType('WAGES_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        //console.log('in WAGES_TEXT effect');

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result => {
              this.utils.hideLoading();
              return of({ type: 'WAGES_EFFECT', payload: this.storeService.convertJson(result[0].Result, 'sectionName', 'sectionResults', 'wages') });
            }
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts wagesTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );
  @Effect() occTextVar$ = this.action$
    .pipe(ofType('OCC_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'OCC_TEXT_EFFECT', payload: this.storeService.convertTextJson(result[0].Result) })
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts occTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );
  /** OccIndex store text */

  @Effect() occIndexStoreTextVar$ = this.action$
    .pipe(ofType('OCC_INDEX_STORE_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        // console.log('OCC_INDEX_STORE_TEXT ')
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            // console.log('inside OCC_INDEX_TEXT:');
            return of({ type: 'OCC_INDEX_STORE_EFFECT', payload: this.storeService.convertOCCIndexTextJson(result[0].Result) });
          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts occIndexStoreTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  @Effect() occFilterStoreTextVar$ = this.action$
    .pipe(ofType('OCC_FILTER_STORE_TEXT')
      , map(toPayload)
      , switchMap(payload => {

        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            // console.log('inside OCC_FILTER_TEXT:');
            return of({ type: 'OCC_FILTER_STORE_EFFECT', payload: this.storeService.convertOCCFilterTextJson(result[0].Result) });
          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts occFilterStoreTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  /** Occ settings text */
  @Effect() occSettingsTextVar$ = this.action$
    .pipe(ofType('OCC_SETTING_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            // console.log('inside OCC_SETTING_TEXT:' + JSON.stringify(result[0]));
            return of({ type: 'OCC_SETTING_EFFECT', payload: this.storeService.convertSettingsTextJson(result[0].Result) });
          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts occSettingsTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  /* starts assessments effect calls */

  @Effect() getIntroText$ = this.action$
    .pipe(ofType('GET_INTRO_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_INTRO_TEXT', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getIntroText$: ${e.message}`);
            return Observable.create();
          }));
      })
    );


  @Effect() getQuestionsResponses$ = this.action$
    .pipe(ofType('GET_QUESTION_RESPONSES')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_QUESTION_RESPONSES', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getQuestionsResponses$: ${e.message}`);
            return Observable.create();
          }));
      })
    );

  @Effect() getQuestionsText$ = this.action$
    .pipe(ofType('GET_QUESTION_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.sessionID = payload.sessionId;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_QUESTION_TEXT', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getQuestionsText$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  @Effect() getRestoreText$ = this.action$
    .pipe(ofType('GET_RESTORE_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        //console.log('in GET_RESTORE_TEXT effect');

        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.sessionID = payload.sessionId;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_RESTORE_TEXT', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getRestoreText$: ${e.message}`);
            return Observable.create();
          }));
      })
    );

  @Effect() getAreaText$ = this.action$
    .pipe(ofType('GET_AREA_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_AREA_TEXT', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getAreaText$: ${e.message}`);
            return Observable.create();
          }));
      })
    );

  @Effect() getParticularAreaText$ = this.action$
    .pipe(ofType('GET_PARTICULAR_AREA_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_PARTICULAR_AREA_TEXT', payload: result[0].Result.areas })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getParticularAreaText$: ${e.message}`);
            return Observable.create();
          }));
      })
    );


  @Effect() getSectionStateValues$ = this.action$
    .pipe(ofType('GET_SECTIONS_STATES')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        // console.log('GET_SECTIONS_STATES payload.path_params:' + payload.path_params);
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': { 'accountID': this.utils.getAccountId() }
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_SCTIONS_STATES', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getSectionStateValues$: ${e.message}`);
            return Observable.create();
          }));
      })
    );


  /** ProgIndex store text */

  @Effect() progIndexStoreTextVar$ = this.action$
    .pipe(ofType('PROGS_INDEX_STORE_TEXT'),
      map(toPayload)
      , switchMap(payload => {
        // console.log('OCC_INDEX_STORE_TEXT ')
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            return of({ type: 'PROGS_INDEX_STORE_EFFECT', payload: this.storeService.convertProgIndexTextJson(result[0].Result) });

          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts progIndexStoreTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );




  @Effect() progTextVar$ = this.action$
    .pipe(ofType('PROGS_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'PROG_TEXT_EFFECT', payload: this.storeService.convertProgTextJson(result[0].Result) })
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts progTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );
  /** AidIndex store text */
  @Effect() aidIndexStoreTextVar$ = this.action$
    .pipe(ofType('AID_INDEX_STORE_TEXT'),
      map(toPayload)
      , switchMap(payload => {
        // console.log('OCC_INDEX_STORE_TEXT ')
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            return of({ type: 'AID_INDEX_STORE_EFFECT', payload: this.storeService.convertAidIndexTextJson(result[0].Result) });

          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts aidIndexStoreTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  @Effect() aidTextVar$ = this.action$
    .pipe(ofType('AID_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'AID_TEXT_EFFECT', payload: this.storeService.convertAidTextJson(result[0].Result) })
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts aidTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );
  /** SchoolIndex store text */
  @Effect() schoolIndexStoreTextVar$ = this.action$
    .pipe(ofType('SCHOOL_INDEX_STORE_TEXT'),
      map(toPayload)
      , switchMap(payload => {
        // console.log('OCC_INDEX_STORE_TEXT ')
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            return of({ type: 'SCHOOL_INDEX_STORE_EFFECT', payload: this.storeService.convertSchoolIndexTextJson(result[0].Result) });

          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts schoolIndexStoreTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  @Effect() schoolTextVar$ = this.action$
    .pipe(ofType('SCHOOL_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'SCHOOL_TEXT_EFFECT', payload: this.storeService.convertSchoolTextJson(result[0].Result) })
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts schoolTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );
  /** MilitaryIndex store text */
  @Effect() militaryIndexStoreTextVar$ = this.action$
    .pipe(ofType('MILITARY_INDEX_STORE_TEXT'),
      map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result => {
            return of({ type: 'MILITARY_INDEX_STORE_EFFECT', payload: this.storeService.convertMilitaryIndexTextJson(result[0].Result) });
          }
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts militaryIndexStoreTextVar$: ${e.message}`);
            return Observable.create();
          }));
      })
    );
  @Effect() militaryTextVar$ = this.action$
    .pipe(ofType('MILITARY_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.data = user;

        if (payload.endUrlVal == 'pages') {
          return this.server.callApi([this.apiJson])
            .pipe(switchMap(result =>
              of({ type: 'MILITARY_TEXT_EFFECT', payload: this.storeService.convertMilitaryTextJson(result[0].Result) })
            ), catchError(e => {
              this.utils.handleError(`main-effects.ts militaryTextVar$: ${e.message}`);
              return Observable.create();
            }));
        }
      })
    );
  @Effect() getLocationText$ = this.action$
    .pipe(ofType('GET_LOCATION_TEXT')
      , map(toPayload)
      , switchMap(payload => {
        this.apiJson.method = payload.methodVal;
        this.apiJson.moduleName = payload.module_Name;
        const asess_Variable = {
          input_data: [
            {
              'param_type': 'path',
              'params': payload.path_params
            },
            {
              'param_type': 'query',
              'params': payload.query_params
            },
            {
              'param_type': 'body',
              'params': payload.body_Params
            }
          ]
        };
        //  console.log('in location effect');

        const user = JSON.stringify(asess_Variable);
        this.apiJson.endUrl = payload.endUrlVal;
        this.apiJson.sessionID = payload.sessionId;
        this.apiJson.data = user;
        return this.server.callApi([this.apiJson])
          .pipe(switchMap(result =>
            of({ type: 'SET_LOCATION_TEXT', payload: result[0].Result })
          ), catchError(e => {
            this.utils.handleError(`main-effects.ts getLocationText$: ${e.message}`);
            return Observable.create();
          }));
      })
    );


  /* This is effect for occs rating and occs list*/
  @Effect() GetDashboardDataActionEffect$ = this.action$
    .pipe(ofType(DashBoardActionTypes.GetDashboardData),
      switchMap(() =>
        this.dashboardServer.getFavourites().pipe(map((result: DashBoardAllListState['dashBoardData']) => {
          return new SetDashboardDataAction(result);
        }))
      ));

  /* This is effect for dashboard assessment*/
  @Effect() GetDashboardAssessmentResultEffect$ = this.action$
    .pipe(ofType(DashBoardActionTypes.GetDBAssessmentResult),
      switchMap(() =>
        this.dashboardServer.getDashboardAssementResult().pipe(map((result: AssessmentResultState['dashBoardAsmntData']) => {
          return new SetDashboardAssessmentResultAction(result);
        }))
      ));
  /* This is effect for dashboard assessment*/
  @Effect() GetDashboardAssessmentPageTextEffect$ = this.action$
    .pipe(ofType(DashBoardActionTypes.GetDBAssessmentPageText),
      switchMap(() =>
        this.dashboardServer.dashboardAssessmentPageText().pipe(map((result: AssessmentPageTextState['dashBoardAsmntPageTextData']) => {
          return new SetDashboardAssessmentPageTextAction(result);
        }))
      ));
  @Effect() GetDashboardPersonalInfoEffect$ = this.action$
    .pipe(ofType(DashBoardActionTypes.GetDBPersonalInfoData),
      switchMap(() =>
        this.dashboardServer.dashboardPersonalInfo().pipe(map((result: dashboardPersonalInfoState['dashBoardPersonalInfoData']) => {
          return new SetDashboardPersonalInfoAction(result);
        }))
      ));

  @Effect() GetAccountSettingPageTextEffect$ = this.action$
    .pipe(ofType(AccountSettingActionTypes.GetAccountSettingPageTextData),
      switchMap(() =>
        this.dashboardServer.getAccountSettingPageText().pipe(map((result: accountSettingPageTextState['accountSettingPageTextData']) => {
          return new SetAccountSettingPageTextAction(result);
        }))
      ));
  @Effect() GetAccountSettingImageEffect$ = this.action$
    .pipe(ofType(AccountSettingActionTypes.GetAccountSettingImageData),
      switchMap(() =>
        this.dashboardServer.getAccountSettingImage().pipe(map((result: accountSettingImageState['accountSettingImageData']) => {
          return new SetAccountSettingImageAction(result);
        }))
      ));
  @Effect() GetPortfolioSettingsEffect$ = this.action$
    .pipe(ofType(AccountSettingActionTypes.GetPortfolioSettingsData),
      switchMap(() =>
        this.dashboardServer.getPortfolioSettings().pipe(map((result: portFolioSettingsState['portFolioSettingsData']) => {
          return new SetPortfolioSettingsAction(result);
        }))
      ));
  @Effect() GetDashboardBestCareersEffect$ = this.action$
    .pipe(ofType(DashBoardActionTypes.GetDBBestCareersData),
      switchMap(() =>
        this.dashboardServer.dashboardBestCareers().pipe(map((result: dashboardBestCareersState['dashBoardBestCareersData']) => {
          return new SetDashboardBestCareerAction(result);
        }))
      ));
}
