/**import angular core packages */
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import html2pdf from 'html2pdf.js';

/**custom imports */
// import { OCCPageState, OccTextState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Occ_Settings_Tab_Text_State, Occ_Buttons_Reducer_Text_State, Occ_EmpOutlook_Wages_State } from '../../occ-state-management/occ-state/occ-state';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

@Component({
  selector: 'edu-and-training-emerg',
  templateUrl: './edu-and-training-layout.html',
})

export class EduTrainingEmergComponent implements OnInit, OnDestroy {
  @Input() educationTraining: any = [];
  @Input('mainlogo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  @ViewChild('educationPrint', {static: true}) educationPrint: ElementRef;
  printview = false;
  filter = 999; //Declare for assigning the value of valfil variable
  eduandtraining; //Declare for assigning the value of OccPageText state
  eduText; //Declare for assigning the value of Occ_Buttons_Reducer_Text state
  settingsText; //Declare for assigning the subscribe value of Occ_Settings_Tab_Text state
  settingsTextTab; //Declare for assigning the obj of settingsText
  occTextSettings = new Subscription;
  eventSub = new Subscription;

  constructor(
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private occstore: Store<Occ_Buttons_Reducer_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occDispatch: OccDispatchService,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private storageService: StorageService,
    private router: Router,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    //get the data from reducer states.
    this.eventSub = occDispatch.listen().subscribe((e) => {
      if (e.type == 'Education and Training') {
        this.filter = 999;

      } if (e.type === 'emergingEvent1') {
        this.emergingAboutPrint();
      }
    });
    this.eduandtraining = this.OCCPageStateStore.select('Occ_EmpOutlook_Wages');
    this.eduText = this.occstore.select('Occ_Buttons_Reducer_Text');
    this.occTextSettings = this.occSetstore.select('Occ_Settings_Tab_Text').subscribe((v) => {
      this.settingsText = v;
      if (this.settingsText.Occ_Settings_Tab_Text.tabs != null && this.settingsText.Occ_Settings_Tab_Text.tabs != undefined) {
        const ref = this;
        this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(function (obj, inx) {
          if (obj['tabId'] == 1) {
            ref.settingsTextTab = obj;
          }
        }.bind(this));

      }

    });
  }

  /**ngOnInit method is called when initializing the component */
  ngOnInit() {
    const val = JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'));
    if (val && val.Occ_Buttons_Reducer_Text && val.Occ_Buttons_Reducer_Text.common != undefined) {
      this.eduText = val;
    }
  }

  /**ngOnDestroy method is called when destroying the component */
  ngOnDestroy() {
    this.occTextSettings.unsubscribe();
    this.eventSub.unsubscribe();
  }

  /**methodfilter is for expandable and collapsible of divs */
  methodfilter(valfil) {
    this.filter = valfil;

  }
  callProgsDetails(id, name) {
    this.router.navigate(['../../progsdetails/progHeader'], { relativeTo: this.activatedRoute, queryParams: { progid: id, occname: name } });
  }
  async emergingAboutPrint() {
    const data = this.educationPrint.nativeElement;
    const opt = {
      filename: 'Emerging Career - Education and Training.pdf',
      margin: [0, 0, 0.2, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }
}
