/** Angular imports */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/** import shared Components */
import { Store, Action } from '@ngrx/store';
import { OccTextState, OccSettingsTextState } from '../../../../state-management/state/main-state';
import { Subscription } from 'rxjs';
import { Renderer2 } from '@angular/core';
import { Utilities } from '../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../shared/outer-services/storage.service';
import { OccApiCallClass } from '../occ-constants/model';
import { OccApiCallService } from '../occ-services/occ-apicalls.service';
import { Occ_Settings_Tab_Text_State, Occ_Buttons_Reducer_Text_State } from '../occ-state-management/occ-state/occ-state';
import { EventDispatchService } from '../../../../shared/outer-services/event-dispatch.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-compare-occ',
    templateUrl: './occ-compare.component.html',
})

export class OccCompareComponent implements OnInit, OnDestroy {

    enterEventListener; /**listener for footer height */
    Firstresult = []; /**Declare for store section results */
    SecondResult = []; /**Declare for store section results */
    comOccName = []; /**arrray of occupations that are need to be sorted */
    IndexkeyValue = []; /**array for store the section name of result */
    iconClass = []; /**array for store the class name  */
    colorClass = []; /**array for storing the color class */
    wageTextVal: any = {}; /**to store the wages text values  */
    CompWageLabel = []; /**array to store wageLabels to check condition for wages are available or not*/
    CompWageLabel1 = []; /**array to store wageLabels to check condition for wages are available or not*/
    filter = [999, 999, 999, 999, 999, 999]; /** used to expanding accordians*/
    finalCompArray = []; /**array to store all the values in our template for first result */
    reducerSub1 = new Subscription; /**for reducer subscription */
    reducerSub2 = new Subscription; /**for reducer subscription */
    routeSub = new Subscription; /** declare for route subscription */
    subscription1 = new Subscription; /**declare for api call subscription */
    outereventSubscribe = new Subscription;
    finalCompArray2 = []; /**array to store final comparision results*/
    occID = ''; /**to store query param */
    compareNames = []; /**array to store session are available inthis compare screen*/
    settingsTextTab = []; /** array to store tab names and user rating */
    occName = ''; /**declare to store occName came from the route Subscription */
    settingsText; /**declare for store occupationSetting text came from the reducer */
    accountId = ''; /**declare to store account id */
    occIndexListText; /**declare for store indexListText came from the reducer */
    backAssessmentValue = true; /**declare for toggle the back assesment button */
    lang; /**declare for language */
    compareClusVal = ''; /**used to store the indexValue when navigating from compare to index page */
    restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
    accountTypeJwt; /**Variable used to store accounttype from JWT */
    restrictedAccountType: boolean;
    @ViewChild('occcompareFocus', {static: true}) occcompareFocusElement: ElementRef;
    constructor(
        private router: Router,
        private occSetstore: Store<Occ_Settings_Tab_Text_State>,
        private utils: Utilities,
        private storageService: StorageService,
        private occstore: Store<Occ_Buttons_Reducer_Text_State>,
        public browserDom: Renderer2,
        private dispatchStore: Store<Action>,
        private apiJson: OccApiCallClass,
        private apiJson1: OccApiCallClass,
        private serverApi: OccApiCallService,
        private activatedRoute: ActivatedRoute,
        private outereventDispatcher: EventDispatchService,
        @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string,
        @Inject('CAREER_IMAGE_URL') public CareersImages: string,
         private titleService: Title
    ) {
        this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
        this.restrictedAccountConstant = restrictedAccount;
        if (this.accountTypeJwt !== this.restrictedAccountConstant) {
            this.restrictedAccountType = true;
        } else {
            this.restrictedAccountType = false;
        }
        this.lang = this.storageService.sessionStorageGet('langset');

        /** To display back assessment button on header when coming from CCI Quick Pic OR CCI Assessments */
        if (this.storageService.sessionStorageGet('prevRoute') === 'cciJr' || this.storageService.sessionStorageGet('prevRoute') === 'cciQuick') {
            this.backAssessmentValue = true;
        } else {
            this.backAssessmentValue = false;
        }
        this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.occID = params['occId'];
            this.occName = params['occName'];
            this.compareClusVal = params['indexValue'];
        });
        this.accountId = 'accountID';
        this.reducerSub1 = this.occstore.select('Occ_Buttons_Reducer_Text').subscribe((v) => {
            this.occIndexListText = v;
        });
        this.reducerSub2 = this.occSetstore.select('Occ_Settings_Tab_Text').subscribe((v) => {
            this.settingsText = v;
            if (this.settingsText.Occ_Settings_Tab_Text.tabs != null && this.settingsText.Occ_Settings_Tab_Text.tabs !== undefined) {
                const ref = this;
                ref.settingsTextTab = [];
                this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(function (obj, inx) {
                    obj.sections.forEach(function (obj1, inx1) {
                        ref.settingsTextTab.push(obj1);
                    });
                }.bind(this));
                this.callApiFunction();
            }
        });
    }

    ngOnInit() {
        this.dynamicStyle();
        this.utils.showLoading();
        const val = JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'));
        if (val && val.Occ_Buttons_Reducer_Text && val.Occ_Buttons_Reducer_Text.common  !== undefined) {
            this.occIndexListText = val;
        }
     const asmtEndurl = (this.restrictedAccountType) ? 'occ/standard' : 'occ/standard/default';
        this.dispatchStore.dispatch({
            type: 'GET_OCC_SETTING_TABS_TEXT', payload: {
                methodVal: 'GET', module_Name: 'Settings/v1/',
                path_params:  (this.restrictedAccountType) ? ['accountID'] : [],
                query_params: { 'lang': this.storageService.sessionStorageGet('langset') },
                body_Params: {}, endUrlVal: asmtEndurl, name: 'careers'
            }
        });
        this.outereventSubscribe = this.outereventDispatcher.listenStaticContent().subscribe((e) => {
            if (this.occcompareFocusElement) {
               this.occcompareFocusElement.nativeElement.setAttribute('tabindex', '0');
               this.occcompareFocusElement.nativeElement.focus();
           }
       });
        this.occcompareFocusElement.nativeElement.setAttribute('tabindex', '0');
        this.occcompareFocusElement.nativeElement.focus();
        this.storageService.sessionStorageSet('savedValId', '');
        this.setDocTitle('Compare Careers');
    }
    callApiFunction() {
        /** Here we are calling two api calls to get the result of the
         */
        let compareTwoOcc = [];
        let OccIds = [];
        this.comOccName = [];
        /** Getv the occupation id and name that is selected by user in occ list */

        OccIds = this.occID.split('&');
        compareTwoOcc = this.occName.split('&');

        for (let i = 0; i < OccIds.length; i++) {
            this.comOccName.push(compareTwoOcc[i]);
        }
        this.apiJson = new OccApiCallClass();
        this.apiJson.method = 'GET';
        this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
        this.apiJson.moduleName = 'Occ/v1/';
        this.apiJson.endUrl = 'pages';
        const compareInfo = {
            input_data: [
                {
                    'param_type': 'path',
                    'params': ['occ', OccIds[0], this.accountId]
                },
                {
                    'param_type': 'query',
                    'params': {
                        'sections': 'Overview,TaskList,WageLevels,OutlookRatings,WorkingConditions,Preparation',
                        'states': 'US', 'lang': this.lang
                    }
                },
                {
                    'param_type': 'body',
                    'params': {

                    }
                }
            ]
        };
        const user = JSON.stringify(compareInfo);
        this.apiJson.data = user;
        compareTwoOcc[0] = this.apiJson;
        this.apiJson1 = new OccApiCallClass();
        const compareInfo1 = {
            input_data: [
                {
                    'param_type': 'path',
                    'params': ['occ', OccIds[1], this.accountId]
                },
                {
                    'param_type': 'query',
                    'params': {
                        'sections': 'Overview,TaskList,WageLevels,OutlookRatings,WorkingConditions,Preparation',
                        'states': 'US', 'lang': this.lang
                    }
                },
                {
                    'param_type': 'body',
                    'params': {

                    }
                }
            ]
        };
        this.apiJson1.method = 'GET';
        this.apiJson1.sessionID = this.storageService.sessionStorageGet('auth_key');
        this.apiJson1.moduleName = 'Occ/v1/';
        this.apiJson1.endUrl = 'pages';
        const user1 = JSON.stringify(compareInfo1);
        this.apiJson1.data = user1;
        compareTwoOcc[1] = this.apiJson1;
        /** compareTwoOcc array contain data of two api call */
        this.subscription1 = this.serverApi.callApi(compareTwoOcc).subscribe((res) => {
            /** After hitting the api we get the result of
             * frst and second occupations
             */
            this.Firstresult = [];
            this.SecondResult = [];
            this.iconClass = [];
            this.colorClass = [];
            for (let i = 0; i < res[0].Result.length; i++) {

                /** Since we get the same same result length for both first and second
                 * occupations take the length of any result
                 */
                if (res[0].Result[i].sectionName === 'Overview' && (this.settingsTextTab.indexOf('Overview') !== -1)) {
                    this.IndexkeyValue.push(res[0].Result[i].sectionName);
                    this.iconClass.push('icon-overview');
                    this.colorClass.push('cardcolor-5');
                    this.Firstresult.push(res[0].Result[i].sectionResults);
                    this.SecondResult.push(res[1].Result[i].sectionResults);
                } else if (res[0].Result[i].sectionName === 'TaskList' && (this.settingsTextTab.indexOf('TaskList') !== -1)) {
                    this.IndexkeyValue.push(res[0].Result[i].sectionName);
                    this.colorClass.push('cardcolor-12');
                    this.iconClass.push('icon-job-list');
                    this.Firstresult.push(res[0].Result[i].sectionResults);
                    this.SecondResult.push(res[1].Result[i].sectionResults);
                } else if (res[0].Result[i].sectionName === 'WageLevels' && (this.settingsTextTab.indexOf('WageLevels') !== -1)) {
                    this.IndexkeyValue.push(res[0].Result[i].sectionName);
                    this.colorClass.push('cardcolor-1');
                    this.iconClass.push('icon-clu_finance');
                    this.Firstresult.push([res[0].Result[i].sectionResults]);
                    this.SecondResult.push([res[1].Result[i].sectionResults]);
                    res[0].Result[i].sectionResults.headers.forEach((ele, inx) => {
                        this.wageTextVal[ele.type] = ele.header;
                    });
                    this.firstWageValue(res[0].Result[i].sectionResults);
                    this.secondWageValue(res[1].Result[i].sectionResults);
                } else if (res[0].Result[i].sectionName === 'OutlookRatings' && (this.settingsTextTab.indexOf('OutlookRatings') !== -1)) {
                    this.IndexkeyValue.push(res[0].Result[i].sectionName);
                    this.colorClass.push('cardcolor-3');
                    this.iconClass.push('icon-employment-outlook');
                    this.Firstresult.push([res[0].Result[i].sectionResults]);
                    this.SecondResult.push([res[1].Result[i].sectionResults]);
                } else if (res[0].Result[i].sectionName === 'WorkingConditions'
                    && (this.settingsTextTab.indexOf('WorkingConditions') !== -1)) {
                    this.IndexkeyValue.push(res[0].Result[i].sectionName);
                    this.colorClass.push('cardcolor-11');
                    this.iconClass.push('icon-work-setting');
                    this.Firstresult.push(res[0].Result[i].sectionResults);
                    this.SecondResult.push(res[1].Result[i].sectionResults);
                } else if (res[0].Result[i].sectionName === 'Preparation' && (this.settingsTextTab.indexOf('Preparation') !== -1)) {
                    this.IndexkeyValue.push(res[0].Result[i].sectionName);
                    this.colorClass.push('cardcolor-10');
                    this.iconClass.push('icon-preparation');
                    this.Firstresult.push(res[0].Result[i].sectionResults);
                    this.SecondResult.push(res[1].Result[i].sectionResults);
                }
            }
            this.utils.hideLoading();
            this.getCompareText();
        });
    }

    dynamicStyle() {

        const ref = this;
        this.enterEventListener = function (event) {
            try {
                const elmnt = document.getElementById('main-body');
                const sticky = document.getElementById('cmpr-header');
                const sticky1 = sticky.offsetTop;
                if (window.innerWidth >= 767) {
                  if (
                    elmnt.scrollTop + 1 < sticky1 ||
                    elmnt.scrollTop + 1 < this.actStricky1
                  ) {
                    this.actStricky1 = sticky1;
                    ref.browserDom.removeStyle(
                      document.querySelector('.compHeader'),
                      'position'
                    );
                    ref.browserDom.removeStyle(
                      document.querySelector('.compHeader'),
                      'top'
                    );
                    ref.browserDom.removeStyle(
                      document.querySelector('.compHeader'),
                      'z-index'
                    );
                  } else {
                    ref.browserDom.setStyle(
                      document.querySelector('.compHeader'),
                      'top',
                      '5px'
                    );
                    ref.browserDom.setStyle(
                      document.querySelector('.compHeader'),
                      'position',
                      'sticky'
                    );
                    ref.browserDom.setStyle(
                      document.querySelector('.compHeader'),
                      'z-index',
                      '99'
                    );
                  }
                }
            } catch (e) {
                console.log('error in factor tru block--->' + e.message);
            }
        };
        document.addEventListener('scroll', this.enterEventListener, true);

    }

    /** Below code is to get the text of section names dynamically form api
     * we use occ text api call and retrive the result in that we will
     * show what are the session are available inthis compare screen
     */
    getCompareText() {
        this.compareNames = [];
        for (let i = 0; i < this.IndexkeyValue.length; i++) {
            this.compareNames.push(this.settingsText.Occ_Settings_Tab_Text.allSections[this.IndexkeyValue[i]]);
        }
    }

    /** firstWageValue method is to push all the values of first result
     *  according to their label names and the state Name
     * so that the label contain states and ites payperiod and
     * respective median wages of first occupation that is selected
     */
    firstWageValue(firstRes) {
        let LabelAvailable = false;
        const jsonValues = [];

        /** Get first wage result and push it to an json array */
        for (let i = 0; i < firstRes.periods.length; i++) {
            const allValues = [];
            for (let j = 0; j < firstRes.periods[i].wages.length; j++) {
                for (let loc = 0; loc < firstRes.periods[i].wages[j].locations.length; loc++) {
                    allValues.push({
                        label: firstRes.periods[i].wages[j].label,
                        location: firstRes.periods[i].wages[j].locations[loc].location,
                        period: firstRes.periods[i].period,
                        median: firstRes.periods[i].wages[j].locations[loc].median
                    });
                }
            }
            jsonValues.push(allValues);

            /** Get all labels in first result and store it in an array */

            for (let j = 0; j < firstRes.periods[i].wages.length; j++) {
                for (let k = 0; k < this.CompWageLabel.length; k++) {
                    if (firstRes.periods[i].wages[j].label === this.CompWageLabel[k][0]) {
                        LabelAvailable = true;
                        break;
                    } else {
                        LabelAvailable = false;
                    }
                }
                if (LabelAvailable === false) {
                    this.CompWageLabel.push([firstRes.periods[i].wages[j].label]);
                }

            }
        }
        /** converting jsonValues so that the json array contain label name and its child values  */
        const jsonArray1 = [];
        for (let variable1 = 0; variable1 < this.CompWageLabel.length; variable1++) {
            for (let variable2 = 0; variable2 < jsonValues.length; variable2++) {
                const localArray = [];
                for (let variable3 = 0; variable3 < jsonValues[variable2].length; variable3++) {
                    if (jsonValues[variable2][variable3].label === this.CompWageLabel[variable1][0]) {
                        localArray.push({
                            loc: jsonValues[variable2][variable3].location,
                            prd: jsonValues[variable2][variable3].period,
                            mdn: jsonValues[variable2][variable3].median
                        });
                    }
                }
                jsonArray1.push({
                    lab: this.CompWageLabel[variable1],
                    val: localArray
                });
            }
        }

        /** convert jsonArray1 based on label so that we can have
         * all the values of same label in one array andget the all unique
         * names of same label in one array
        */
        const CompWageUniName = [];
        const jsonArray2 = [];
        for (let variable1 = 0; variable1 < this.CompWageLabel.length; variable1++) {
            const localjson = [];
            const uniqueName = [];
            let isValAvail = false;
            for (let variable2 = 0; variable2 < jsonArray1.length; variable2++) {
                if (jsonArray1[variable2].lab === this.CompWageLabel[variable1]) {
                    for (let variable3 = 0; variable3 < jsonArray1[variable2].val.length; variable3++) {
                        localjson.push(jsonArray1[variable2].val[variable3]);
                        for (let k = 0; k < uniqueName.length; k++) {
                            if (jsonArray1[variable2].val[variable3].loc === uniqueName[k]) {
                                isValAvail = true;
                                break;
                            } else { isValAvail = false; }
                        }
                        if (isValAvail === false) {
                            uniqueName.push(jsonArray1[variable2].val[variable3].loc);
                        }
                    }
                }
            }
            CompWageUniName.push(uniqueName);
            jsonArray2.push(localjson);
        }

        /** now in final json array we have the state and ite values according to their
         * label name so that we can display all the values in our template for first result
         */
        for (let variable1 = 0; variable1 < jsonArray2.length; variable1++) {
            const finalRes = [];
            for (let variable2 = 0; variable2 < CompWageUniName[variable1].length; variable2++) {
                const parares = [];
                for (let j = 0; j < jsonArray2[variable1].length; j++) {
                    if (jsonArray2[variable1][j].loc === CompWageUniName[variable1][variable2]) {
                        parares.push([jsonArray2[variable1][j].prd, jsonArray2[variable1][j].mdn]);
                    }
                }
                finalRes.push({
                    plocV: CompWageUniName[variable1][variable2],
                    pVal: parares
                });
            }
            this.finalCompArray.push(finalRes);

        }

    }

    /** secondWageValue method is to push all the values of second result
     *  according to their label names and the state Name
     * so that the label contain states and ites payperiod and
     * respective median wages of second occupation that is selected
     */
    secondWageValue(secondRes) {
        let LabelAvailable1 = false;
        const jsonValues = [];

        /** Get second wage result and push it to an json array */

        for (let variable1 = 0; variable1 < secondRes.periods.length; variable1++) {
            const allValues = [];
            for (let j = 0; j < secondRes.periods[variable1].wages.length; j++) {
                for (let loc = 0; loc < secondRes.periods[variable1].wages[j].locations.length; loc++) {
                    allValues.push({
                        label: secondRes.periods[variable1].wages[j].label,
                        location: secondRes.periods[variable1].wages[j].locations[loc].location,
                        period: secondRes.periods[variable1].period,
                        median: secondRes.periods[variable1].wages[j].locations[loc].median
                    });
                }
            }
            jsonValues.push(allValues);
            /** Get all labels in second result and store it in an array */
            for (let j = 0; j < secondRes.periods[variable1].wages.length; j++) {
                for (let k = 0; k < this.CompWageLabel1.length; k++) {
                    if (secondRes.periods[variable1].wages[j].label == this.CompWageLabel1[k]) {
                        LabelAvailable1 = true;
                        break;
                    } else { LabelAvailable1 = false; }
                }
                if (LabelAvailable1 === false) {
                    this.CompWageLabel1.push([secondRes.periods[variable1].wages[j].label]);
                }
            }
        }

        /** converting jsonValues so that the json array contain label name and its child values  */
        const jsonArray1 = [];
        for (let variable1 = 0; variable1 < this.CompWageLabel1.length; variable1++) {
            for (let variable2 = 0; variable2 < jsonValues.length; variable2++) {
                const localArray = [];
                for (let variable3 = 0; variable3 < jsonValues[variable2].length; variable3++) {
                    if (jsonValues[variable2][variable3].label == this.CompWageLabel1[variable1]) {
                        localArray.push({
                            loc: jsonValues[variable2][variable3].location,
                            prd: jsonValues[variable2][variable3].period,
                            mdn: jsonValues[variable2][variable3].median
                        });
                    }
                }
                jsonArray1.push({
                    lab: this.CompWageLabel1[variable1],
                    val: localArray
                });
            }
        }

        /** convert jsonArray1 based on label so that we can have
         * all the values of same label in one array andget the all unique
         * names of same label in one array
        */
        const CompWageUniName = [];
        const jsonArray2 = [];
        for (let variable1 = 0; variable1 < this.CompWageLabel1.length; variable1++) {
            const localjson = [];
            const uniqueName = [];
            let isValAvail = false;
            for (let variable2 = 0; variable2 < jsonArray1.length; variable2++) {
                if (jsonArray1[variable2].lab == this.CompWageLabel1[variable1]) {
                    for (let variable3 = 0; variable3 < jsonArray1[variable2].val.length; variable3++) {
                        localjson.push(jsonArray1[variable2].val[variable3]);
                        for (let k = 0; k < uniqueName.length; k++) {
                            if (jsonArray1[variable2].val[variable3].loc == uniqueName[k]) {
                                isValAvail = true;
                                break;
                            } else { isValAvail = false; }
                        }
                        if (isValAvail === false) {
                            uniqueName.push(jsonArray1[variable2].val[variable3].loc);
                        }
                    }
                }
            }
            CompWageUniName.push(uniqueName);
            jsonArray2.push(localjson);
        }

        /** now in final json array we have the state and ite values according to their
         * label name so that we can display all the values in our template for first result
         */
        for (let variable1 = 0; variable1 < jsonArray2.length; variable1++) {
            const finalRes = [];
            for (let variable2 = 0; variable2 < CompWageUniName[variable1].length; variable2++) {
                const parares = [];
                for (let j = 0; j < jsonArray2[variable1].length; j++) {
                    if (jsonArray2[variable1][j].loc == CompWageUniName[variable1][variable2]) {
                        parares.push([jsonArray2[variable1][j].prd, jsonArray2[variable1][j].mdn]);
                    }
                }
                finalRes.push({
                    plocV: CompWageUniName[variable1][variable2],
                    pVal: parares
                });
            }
            this.finalCompArray2.push(finalRes);

        }
    }

    /** This method is used to check on which list user clicked, so that
    * the remaining panels are closed and we show the only user clicked panel.
    * simply it is for collapsing the panel
    */
    methodfilter(valfil, index) {
        this.filter = [999, 999, 999, 999, 999, 999];
        this.filter[index] = valfil;
    }

    /** The below method is used to return the key value according to the given check value */
    valueOccIndexCheck(key, checkVal) {
        if (checkVal === 'ref') {
            return '#collapsed' + key;
        } else if (checkVal === 'controls') {
            return 'collapsed' + key;
        }
    }

    /** Navigate to career list */
   CareerList(check) {
        if (this.compareClusVal === 'title') {
            this.storageService.sessionStorageSet('occShow', '1');
        } else {
            this.storageService.sessionStorageSet('occShow', '0');
        }
        this.router.navigate(['../occIndex'], { relativeTo: this.activatedRoute, queryParams: { chk: check, occclusinx: this.compareClusVal } });
    }

    /** Navigates to occlist page */
    backAssessment() {
        if (this.storageService.sessionStorageGet('module') === 'ip') {
            this.router.navigate(['../occlist'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../../' + this.storageService.sessionStorageGet('prevRoute') + '/result'], { relativeTo: this.activatedRoute });
        }
    }
    /**ngOnDestroy method called when destroying the component */
    ngOnDestroy() {
        document.removeEventListener('scroll', this.enterEventListener, true);
        this.reducerSub1.unsubscribe();
        this.reducerSub2.unsubscribe();
        this.routeSub.unsubscribe();
        this.subscription1.unsubscribe();
        this.outereventSubscribe.unsubscribe();
    }

     public setDocTitle(title: string) {
            this.titleService.setTitle(title + ' - CIS360 Careers');
    }
}
