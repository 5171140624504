import { Action } from '@ngrx/store';

export enum DashBoardActionTypes {
    GetDashboardData = '[DashBoardAllListState] GET_DASHBOARD_TITLES',
    SetDashboardData = '[DashBoardAllListState] SET_DASHBOARD_TITLES',
    ResetDashboard = '[DashBoardAllListState] RESET_DASHBOARD',
    SetDBAssessmentResult = '[AssessmentResultState] SET_DASHBOARD_ASSMT_RESULT',
    GetDBAssessmentResult = '[AssessmentResultState] GET_DASHBOARD_ASSMT_RESULT',
    SetDBAssessmentPageText = '[AssessmentPageTextState] SET_DASHBOARD_PAGE_TEXT',
    GetDBAssessmentPageText = '[AssessmentPageTextState] GET_DASHBOARD_PAGE_TEXT',
    SetDBPersonalInfoData = '[dashboardPersonalInfoState] SET_DASHBOARD_PERSONAL_INFO',
    GetDBPersonalInfoData = '[dashboardPersonalInfoState] GET_DASHBOARD_PERSONAL_INFO',
    SetDBBestCareersData = '[dashboardBestCareersState] SET_DASHBOARD_BEST_CAREERS',
    GetDBBestCareersData = '[dashboardBestCareersState] GET_DASHBOARD_BEST_CAREERS'

}
export enum AccountSettingActionTypes {
    SetAccountSettingPageTextData = '[accountSettingPageTextState] SET_ACCOUNT_SETTING_PAGETEXT',
    GetAccountSettingPageTextData = '[accountSettingPageTextState] GET_ACCOUNT_SETTING_PAGETEXT',
    SetAccountSettingImageData = '[accountSettingImageState] SET_ACCOUNT_SETTING_IMAGE',
    GetAccountSettingImageData = '[accountSettingImageState] GET_ACCOUNT_SETTING_IMAGE',
    SetPortfolioSettingsData = '[portFolioSettingsState] SET_PORTFOLIO_SETTINGS_DATA',
    GetPortfolioSettingsData = '[portFolioSettingsState] GET_PORTFOLIO_SETTINGS_DATA'
}
export class GetDashboardDataAction implements Action {
    readonly type = DashBoardActionTypes.GetDashboardData;
}
export class SetDashboardDataAction implements Action {
    readonly type = DashBoardActionTypes.SetDashboardData;
    constructor(public payload: any) { }
}
export class ResetDashboardAction implements Action {
    readonly type = DashBoardActionTypes.ResetDashboard;
}
export class GetDashboardAssessmentResultAction implements Action {
    readonly type = DashBoardActionTypes.GetDBAssessmentResult;
}
export class SetDashboardAssessmentResultAction implements Action {
    readonly type = DashBoardActionTypes.SetDBAssessmentResult;
    constructor(public payload: any) { }
}
export class GetDashboardAssessmentPageTextAction implements Action {
    readonly type = DashBoardActionTypes.GetDBAssessmentPageText;
}
export class SetDashboardAssessmentPageTextAction implements Action {
    readonly type = DashBoardActionTypes.SetDBAssessmentPageText;
    constructor(public payload: any) { }
}
export class SetDashboardPersonalInfoAction implements Action {
    readonly type = DashBoardActionTypes.SetDBPersonalInfoData;
    constructor(public payload: any) { }
}
export class GetDashboardPersonalInfoAction implements Action {
    readonly type = DashBoardActionTypes.GetDBPersonalInfoData;
}
export class SetAccountSettingPageTextAction implements Action {
    readonly type = AccountSettingActionTypes.SetAccountSettingPageTextData;
    constructor(public payload: any) { }
}
export class GetAccountSettingPageTextAction implements Action {
    readonly type = AccountSettingActionTypes.GetAccountSettingPageTextData;
}
export class SetAccountSettingImageAction implements Action {
    readonly type = AccountSettingActionTypes.SetAccountSettingImageData;
    constructor(public payload: any) { }
}
export class GetAccountSettingImageAction implements Action {

    readonly type = AccountSettingActionTypes.GetAccountSettingImageData;
}
export class SetPortfolioSettingsAction implements Action {
    readonly type = AccountSettingActionTypes.SetPortfolioSettingsData;
    constructor(public payload: any) { }
}
export class GetPortfolioSettingsAction implements Action {
    readonly type = AccountSettingActionTypes.GetPortfolioSettingsData;
}
export class SetDashboardBestCareerAction implements Action {
    readonly type = DashBoardActionTypes.SetDBBestCareersData;
    constructor(public payload: any) { }
}
export class GetDashboardBestCareerAction implements Action {
    readonly type = DashBoardActionTypes.GetDBBestCareersData;
}
export type DashboardActions =
    GetDashboardDataAction
    | SetDashboardDataAction
    | ResetDashboardAction
    | SetDashboardAssessmentResultAction
    | GetDashboardAssessmentResultAction
    | SetDashboardAssessmentPageTextAction
    | GetDashboardAssessmentPageTextAction
    | SetDashboardPersonalInfoAction
    | GetDashboardPersonalInfoAction
    | SetDashboardBestCareerAction
    | GetDashboardBestCareerAction;

export type AccountSetttingActions =
    GetAccountSettingImageAction
    | SetAccountSettingImageAction
    | GetAccountSettingPageTextAction
    | SetAccountSettingPageTextAction
    | SetPortfolioSettingsAction
    | GetPortfolioSettingsAction;
