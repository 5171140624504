export const environment = {
  production: false,
  // URL for API endpoint
  ApiUrl: '/',
  resumeAPIUrl: '/ResumeBuilder/',
  // Proxy for API calls
  proxy: '',
  // URL for JWT/AUTH endpoints
  AuthUrl: 'http://',
  DevRouteValues: '/stateAbbr/accountID',
  Auth_Key: 'auth_key',
  CP_PROXY: '',
  AssetsUrl: 'https://images.intocareers.org/resume/',
  LandingImages: 'https://images.intocareers.org/landing/',
  CareersImages: 'https://images.intocareers.org/occ/',
  SpanishIcon: 'https://images.intocareers.org/careertrek/',
  AssessmentIcons: 'https://images.intocareers.org/assessment/',
  fontIcons: 'https://images.intocareers.org/fonticons/',
  dependableStrengthsLink: 'https://cistemp.intocareers.org/ds',
  IC_VideoUrl: 'https://videos.intocareers.org/',
  GoogleAnalytics: false,
};
