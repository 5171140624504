
/** Angular Imports */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 |  exponentialStrength:10}}
 *   formats to: 1024
 */
@Pipe({ name: 'customDate' })
// export class CustomDate extends DatePipe implements PipeTransform {
export class CustomDate implements PipeTransform {
  transform(value: Date | string | number, args?: any): string {

    const dateformat: string = 'MM/dd/yyyy';
    if (value) {
      const datePipe = new DatePipe('en-US'); // Use your desired locale
      return datePipe.transform(value, dateformat);

    }

    return '';
  }
}
@Pipe({ name: 'customDateSafariSafe' })
export class customDateSafariSafe extends DatePipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return moment(date).format(format);
    }
  }
}

@Pipe({ name: 'pagesFilter' })
export class pagesFilter {
  transform(pages, [key]) {
    if (pages[key]) {
      return pages.filter((page) => {
        return page[key] === true;
      });
    }
  }
}
@Pipe({
  name: 'BoldPipe',
})
export class BoldPipe implements PipeTransform {
  transform(val: any): any {
    const dataText = ([] = val.split('**'));
    const finalText = '<b>' + dataText[1] + '</b>' + dataText[2];
    return finalText;
  }
}

@Pipe({
  name: 'EQPipe',
})
export class EQPipe implements PipeTransform {
  transform(text: string): string {
    const first = text.split(':')[0];
    let second = text.split(':')[1];
    second = '<span class=\'d-block\'>' + second + '</span>';
    return first + second;
  }
}
@Pipe({
  name: 'BestCareerCluster',
})
export class BestCareerCluster implements PipeTransform {
  transform(text: string): string {
    const first = text.split(':')[0];
    // trim() is used to remove empty character before cluster career links
    const second = text.split(':')[1].trim();
    const finalText =
      '<b>' +
      first +
      ':&nbsp' +
      '</b>' +
      '<a target=:"_blank" href="' +
      second +
      '">' +
      second +
      '</a>';
    return finalText;
  }
}

@Pipe({
  name: 'MarkdownToLink',
})
export class TransformMarkdownPipe implements PipeTransform {
  transform(text: string): string {
    /**handles http:// https:// mailto: cases */
    if (text && text.length > 0 && text.includes(':')) {
      return text.replace(
        /\[([^\]]+)\]\(([^\)]+)\)/g,
        "<a href='$2' class='anchorclass' target='_blank'>$1</a>"
      );
    }
    //special case for internal redirect in CareerJr module
    else if (text && text.length > 0 && text.includes('/redirect/fileOccJr/')) {
      return text.replace(
        /\[([^\]]+)\]\(([^\)]+)\)/g,
        "<span class='anchorclass pointer'>$1</span>"
      );
    }
    return text;
  }
}

@Pipe({
  name: 'MarkdownToHiper',
})
export class TransformMarkdownHiperPipe implements PipeTransform {
  transform(text: string): string {
    /**handles http:// https:// mailto: cases */
    if (text && text.length > 0 && text.includes(':')) {
      return text.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '$2');
    }
    return text;
  }
}