import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { Quill } from './quill';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})

export class TextareaComponent implements OnInit, AfterContentInit {

  @Input() id: string;
  @Input() name: string;
  @Input() value: string;
  @Input() index: string;
  @Input() required: boolean;
  @Input() placeholder = '';
  private editorConfig: any;

  constructor() { }

  ngAfterContentInit() {
    window.setTimeout(() => {
      let toolbarOptions = [
        //  ['bold', 'italic', 'underline', 'strike'],
        //  ['blockquote', 'code-block'],

        // [{ 'header': 1 }, { 'header': 2 }],
        [ /*{ 'list': 'ordered'},*/ { 'list': 'bullet' }],
        //  [{ 'script': 'sub'}, { 'script': 'super' }],
        //  [{ 'indent': '-1'}, { 'indent': '+1' }],
        //  [{ 'direction': 'rtl' }],

        //  [{ 'size': ['small', false, 'large', 'huge'] }],
        //  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //  [{ 'color': [] }, { 'background': [] }],
        //  [{ 'font': [] }],
        //  [{ 'align': [] }],

        //  ['clean']
      ];

      let quill = new Quill('#' + this.id + 'Editor', {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: 'snow'
      });

      quill.clipboard.dangerouslyPasteHTML(0, this.value);
      quill.root.dataset.placeholder = this.placeholder ? this.placeholder : '';
    });
  }

  ngOnInit() {

    this.editorConfig = {
      'editable': true,
      'spellcheck': true,
      'height': 'auto',
      'width': 'auto',
      'translate': 'yes',
      'enableToolbar': true,
      'showToolbar': true,
      'placeholder': this.placeholder,
      'imageEndPoint': '',
      'toolbar': [
          //["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
          //["fontName", "fontSize", "color"],
          //["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
          //["cut", "copy", "delete", "removeFormat", "undo", "redo"],
          //["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
          //["link", "unlink", "image", "video"]
          ['paragraph', 'unorderedList']
      ]
    };

  }
}
