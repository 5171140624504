
/** Angular imports */
import { Injectable } from '@angular/core';

/** Rxjs imports */
import { Subject ,  Observable } from 'rxjs';

@Injectable()
/** This service is used to track all the popup events and dispatch click events */
export class CareersJrDispatchService {

        constructor() { }

        trackEvnt: Subject<Event> = new Subject<Event>();
        clusAtGlanceEvnt: Subject<Event> = new Subject<Event>();
        clusRighFormeEvnt: Subject<Event> = new Subject<Event>();
        clusEducationEvnt: Subject<Event> = new Subject<Event>();
        clusPathwayEvnt: Subject<Event> = new Subject<Event>();
        clusRelatedCareerEvnt: Subject<Event> = new Subject<Event>();

        careerAtGlanceEvnt: Subject<Event> = new Subject<Event>();
        careerJobDescriptionEvnt: Subject<Event> = new Subject<Event>();
        careerWagesEvnt: Subject<Event> = new Subject<Event>();
        careerOutlookEvnt: Subject<Event> = new Subject<Event>();
        careerEduTrainingEvnt: Subject<Event> = new Subject<Event>();
        careerPersonalQualitiesEvnt: Subject<Event> = new Subject<Event>();
        careerRelatedEvnt: Subject<Event> = new Subject<Event>();
        // Below method used to dispatch events.
        dispatch(data: Event) {
                this.trackEvnt.next(data);
        }
        clusterAtGlanceDispatch(data: Event) {
                this.clusAtGlanceEvnt.next(data);
        }
        clusterThingsToKnowDispatch(data: Event) {
                this.clusRighFormeEvnt.next(data);
        }
        clusterEducationDispatch(data: Event) {
                this.clusEducationEvnt.next(data);
        }
        clusterReatedCareerDispatch(data: Event) {
                this.clusRelatedCareerEvnt.next(data);
        }
        careerAtglanceDispatch(data: Event) {
                this.careerAtGlanceEvnt.next(data);
        }
        careerJobDescriptionDispatch(data: Event) {
                this.careerJobDescriptionEvnt.next(data);
        }
        careerWagesDispatch(data: Event) {
                this.careerWagesEvnt.next(data);
        }
        careerOutLookDispatch(data: Event) {
                this.careerOutlookEvnt.next(data);
        }
        careerEducationTrainingDispatch(data: Event) {
                this.careerEduTrainingEvnt.next(data);
        }
        careerPersonalQualityDispatch(data: Event) {
                this.careerPersonalQualitiesEvnt.next(data);
        }
        careerRelatedDispatch(data: Event) {
                this.careerRelatedEvnt.next(data);
        }
        // Below method is used to listen to the dispatched events.
        // We have to use .subscribe() to track the method success callback in calledcall.
        listen(): Observable<Event> {
                return this.trackEvnt.asObservable();
        }
        clusterAtGlanceListen(): Observable<Event> {
                return this.clusAtGlanceEvnt.asObservable();
        }
        clusterThingsToKnowListen() {
                return this.clusRighFormeEvnt.asObservable();
        }
        clusterEducationListen() {
                return this.clusEducationEvnt.asObservable();
        }
        clusterReatedCareerListen() {
               return this.clusRelatedCareerEvnt.asObservable();
        }

        careerAtglanceListen(): Observable<Event> {
                return this.careerAtGlanceEvnt.asObservable();
        }
        careerJobDescriptionListen(): Observable<Event> {
                return this.careerJobDescriptionEvnt.asObservable();
        }
        careerWagesListen(): Observable<Event> {
                return this.careerWagesEvnt.asObservable();
        }
        careerOutLookListen(): Observable<Event> {
                return this.careerOutlookEvnt.asObservable();
        }
        careerEducationTrainingListen(): Observable<Event> {
                return this.careerEduTrainingEvnt.asObservable();
        }
        careerPersonalQualityListen(): Observable<Event> {
                return this.careerPersonalQualitiesEvnt.asObservable();
        }
        careerRelatedListen(): Observable<Event> {
                return this.careerRelatedEvnt.asObservable();
        }
}


