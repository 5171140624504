import { Component, OnInit, HostListener } from '@angular/core';
import { DashboardApiCallService } from '../../../shared/outer-services/dashBoard-api-calls.service';
import { PracticeTestType } from '../models/practiceTestType.model';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { PracticeTestAccessUrlResponse } from '../models/practiceTestAccessUrlResponse';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../practice-test.module.css'],
})
export class HomeComponent implements OnInit {
  practiceTestType$: PracticeTestType;
  userAccessRoute$: PracticeTestAccessUrlResponse;
  testType: number;
  typeSelected: string;

  customError: string = '';

  /**
   * get mouse position to display error message if
   * unsuccessful response from api
   */
  mouseX: string;
  mouseY: string;

  //display error message if true
  routeLoadError: boolean = false;

  linkElement: Element;
  isMobile: boolean = false;
  isTablet: boolean = false;
  iOS: string = '';

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    this.routeLoadError = false;
  }

  constructor(
    public dashService: DashboardApiCallService,
    private activeRouter: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private deviceService: DeviceDetectorService
  ) {
    this.typeSelected = 'ball-fussion';

    /**create a anchor object for testprep link if
     * it does not exist
     */
    if (!document.getElementById('testPrepLink')) {
      this.linkElement = document.createElement('a');
      this.linkElement.id = 'testPrepLink';
      window.document.body.appendChild(this.linkElement);
    }
  }

  ngOnInit() {
    //get testType from url
    this.testType = +this.activeRouter.snapshot.data.testType;
    if (this.testType < 0 || this.testType > 3) {
      //set default if typeType is out of range
      this.testType = 0;
    }

    this.dashService.getPracticeTestCateogry(this.testType).subscribe(
      (tests) => {
        this.spinnerService.show();
        this.practiceTestType$ = tests;
      },
      (err) => {
        console.error(err);
        this.spinnerService.hide();
      },
      () => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 200);
      }
    );

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.iOS = this.deviceService.os;
  }

  /**get peterson access Url */
  getPetersonUserRoute(testID: number, e: MouseEvent) {
    this.mouseX = e.pageX + 'px';
    this.mouseY = e.pageY + 10 + 'px';

    this.spinnerService.show();
    const link = document.getElementById('testPrepLink');
    if (this.isMobile && this.iOS === 'iOS') {
      link.setAttribute('target', '_top');
    } else if (this.isTablet && this.iOS === 'Mac') {
      link.setAttribute('target', '_top');
    } else {
      link.setAttribute('target', '_blank');
    }

    this.dashService.getPracticeTestAccessUrl(testID).subscribe(
      (userRoute) => {
        this.userAccessRoute$ = userRoute;
        //redirect to Peterson's site in a new tab
        if (this.userAccessRoute$.isSuccessful) {
          /**Avoid using windows.open() because of iphone/ipad
           * security restrictions
           */

          if (this.userAccessRoute$.myItem) {
            setTimeout(() => {
              link.setAttribute('href', this.userAccessRoute$.myItem);
              link.click();
              link.remove();
            });
          } else {
            this.routeLoadError = true;
            this.customError = 'Loading course failed, please try again.';
          }
        } else {
          this.routeLoadError = true;
          this.customError = 'Course not available.';
        }
      },
      (err) => {
        this.routeLoadError = true;
        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  closeErrorDiv(e: MouseEvent) {
    if (e.pageX + 'px' === this.mouseX && e.pageY + 10 + 'px' === this.mouseY) {
      return;
    } else {
      this.routeLoadError = false;
    }
  }
}
