export interface Occ_EmpOutlook_Wages_State {
  Occ_EmpOutlook_Wages: any;
}
export const defaultOcc_EmpOutlook_Wages_State: Occ_EmpOutlook_Wages_State = {
  Occ_EmpOutlook_Wages: {},
};
export interface Occ_Settings_Tab_Text_State {
  Occ_Settings_Tab_Text: any;
}
export const defaultOcc_Settings_Tab_Text_State: Occ_Settings_Tab_Text_State = {
  Occ_Settings_Tab_Text: {},
};
export interface Occ_Index_Reducer_Text_State {
  Occ_Index_Reducer_Text: any;
}
export const defaultOcc_Index_Reducer_Text_State: Occ_Index_Reducer_Text_State =
  {
    Occ_Index_Reducer_Text: {},
  };
export interface Occ_Buttons_Reducer_Text_State {
  Occ_Buttons_Reducer_Text: any;
}
export const defaultOcc_Buttons_Reducer_Text_State: Occ_Buttons_Reducer_Text_State =
  {
    Occ_Buttons_Reducer_Text: {},
  };
export interface Occ_Assessment_Filter_State {
  Occ_Assessment_Filter_Text: any;
}
export const defaultOcc_Assessment_Filter_State: Occ_Assessment_Filter_State = {
  Occ_Assessment_Filter_Text: {},
};

/**we will not modify current state(s) settings because other modules are using
 * those State Store as well
 *
 * The state stores added are for career details module
 */
//at a glance tab state store
export interface Occ_Details_AtGlance_State {
  Occ_Details_At_Glance: any;
}
export const defaultOcc_Details_AtGlance_State: Occ_Details_AtGlance_State = {
  Occ_Details_At_Glance: {},
};

//job description tab state store
export interface Occ_Detail_JobDescription_State {
  Occ_Detail_Job_Description: any;
}
export const defaultOcc_Detail_JobDescription_State: Occ_Detail_JobDescription_State =
  {
    Occ_Detail_Job_Description: {},
  };

//wages tab state store
export interface Occ_Detail_Wages_State {
  Occ_Detail_Wages: any;
}
export const defaultOcc_Detail_Wages_State: Occ_Detail_Wages_State = {
  Occ_Detail_Wages: {},
};

//Employment & Outlook tab state store
export interface Occ_Detail_Employment_Outlook_State {
  Occ_Detail_Employment_Outlook: any;
}
export const defaultOcc_Detail_Employment_Outlook_State: Occ_Detail_Employment_Outlook_State =
  {
    Occ_Detail_Employment_Outlook: {},
  };

//Education & Training tab state store
export interface Occ_Detail_Education_Training_State {
  Occ_Detail_Education_Training: any;
}
export const initOcc_Detail_Education_Training_State: Occ_Detail_Education_Training_State =
  {
    Occ_Detail_Education_Training: {},
  };

//Personal & Qualities tab state store
export interface Career_Detail_Personal_Qualities_State {
  Career_Detail_Personal_Qualities: any;
}
export const initCareer_Details_Personal_Qualities_State: Career_Detail_Personal_Qualities_State =
  {
    Career_Detail_Personal_Qualities: {},
  };

//related Career tab state stroe
export interface Career_Detail_Related_Careers_State {
  Career_Detail_Related_Careers: any;
}
export const initCareer_Detail_Related_Careers_State: Career_Detail_Related_Careers_State =
  {
    Career_Detail_Related_Careers: {},
  };

export interface Career_Detail_Header_State {
  Career_Detail_Header: any;
}
export const initCareer_Detail_Header_State: Career_Detail_Header_State = {
  Career_Detail_Header: {},
};
