/**Angular imports */
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import html2pdf from 'html2pdf.js';
import { takeWhile } from 'rxjs/operators';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { AssessmentsService } from '../../../../assessments/shared/services/assessments.service';
/**Services */
// import { OCCPageState, OccSettingsTextState } from '../../../../../state-management/state/main-state';
import { Occ_EmpOutlook_Wages_State, Occ_Settings_Tab_Text_State } from '../../occ-state-management/occ-state/occ-state';
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';
import { CCIAsmntConst } from '../../occ-constants/cluster-icons';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'at-a-glance-cluster',
  templateUrl: './at-a-glance-layout.html',
})

export class AtAGlanceClusterComponent implements OnInit, OnDestroy {
  clusterID = ''; /**Declare for storing ClusterID.*/
  clusterName; /**Declare for storing text from the store.*/
  // nameTxt; /**Declare for storing the lang text*/
  occClusterStore; /**Declare for storing the cluster text from store.*/
  settingsText; /**Declare for storing the section names from store.*/
  settingsTextTab; /**Declare for storing the tab values from store.*/
  SortCCI = -1;
  CCIcolor = {};
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  printview = false;
  compActive = true;
  frameWorkObj;


  @ViewChild('clusAtaGlance', { static: true }) clusAtaGlance: ElementRef;

  @Input() atAGlance: any = [];
  @Input('logo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameworkObj;
  isSortCCIJrExists = false;
  isSortCCIAdultExists = false;
  occClusterText: any;
  occCCIScoreAll: any;
  asmntObj: any;
  showClusterBox = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occDispatch: OccDispatchService,
    private utils: Utilities,
    private storageService: StorageService,
    private assessmentServices: AssessmentsService,
    private Frameworkstore: Store<FrameworkConfigState>,
    @Inject('LANDING_IMAGE_URL') public LandingImages: string,
    private titleService: Title) {

      this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
        /**
         * Defaults to 0 if no query param provided.
         * */
        this.clusterID = params['clusId'];
        this.clusterIcon = params['clusIcon'];
        // this.nameTxt = 'cls_' + this.clusterID;
        this.storageService.sessionStorageSet('cciClus_ID', JSON.stringify(params['clusId']));
        this.storageService.sessionStorageSet('cciClusIcon', JSON.stringify(params['clusIcon']));
        this.storageService.sessionStorageSet('cciclusColor', JSON.stringify(params['clusColor']));
      });

      this.Frameworkstore.select('config').pipe(takeWhile(() => this.compActive)).subscribe((res) => {
        if (res.config) {
          const tabId = parseInt(this.storageService.sessionStorageGet('tabId'));
          this.asmntObj = res.config.Result.tabItems[tabId].compList.map(obj => obj.compId);
          this.frameWorkObj = res.config;
        }
      });

    this.OCCPageStateStore.pipe(select('Occ_EmpOutlook_Wages'), takeWhile(() => this.compActive)).subscribe((v) => {
      this.occClusterStore = v;
      // console.log('occClusterStore----->',(this.occClusterStore));
      if (!this.occClusterStore.CCIScoreAll && this.occClusterStore.CCIScore && this.occClusterStore.CCIScore.level !== -1) {
        if (this.occClusterStore.CCIScore.level == 0) {
          this.CCIcolor['low'] = '#b0e0e6';
          this.CCIcolor['medium'] = '#bcb3b3';
          this.CCIcolor['high'] = '#cdcdcd';
        }
        if (this.occClusterStore.CCIScore.level == 1) {
          this.CCIcolor['low'] = '#bcb3b3';
          this.CCIcolor['medium'] = '#b0e0e6';
          this.CCIcolor['high'] = '#cdcdcd';
        }
        if (this.occClusterStore.CCIScore.level == 2) {
          this.CCIcolor['low'] = '#bcb3b3';
          this.CCIcolor['medium'] = '#cdcdcd';
          this.CCIcolor['high'] = ' #b0e0e6';
        }
      }
      if (this.occClusterStore && this.occClusterStore.CCIScoreAll) {
        this.occClusterStore.CCIScoreAll = this.occClusterStore.CCIScoreAll.filter(val => {
          return this.asmntObj.includes('sort' + val.sortId);
      });
      }
      if ( this.occClusterStore && this.occClusterStore.CCIScoreAll && this.occClusterStore.CCIScoreAll.length >= 1) {
        const finalList = this.occClusterStore.CCIScoreAll.filter((val => (val.taken === true)));
        const arr = CCIAsmntConst;
        if (finalList.length === 1) {
            this.occCCIScoreAll = finalList[0];
        } else if (finalList.length > 1) {
          arr.forEach(x => {
            if (!this.occCCIScoreAll) {
              this.occCCIScoreAll = finalList.find(y => {
                if (x == y.sortId) {
                  return y;
                }
              });
            }
          });

        } else if (finalList.length === 0) {
          const falseList = this.occClusterStore.CCIScoreAll.filter((val => (val.taken === false)));
          arr.forEach(x => {
            if (!this.occCCIScoreAll) {
              this.occCCIScoreAll = falseList.find(y => {
                if (x == y.sortId) {
                  return y;
                }
              });
            }
          });
        }
      } else {
        // cluster definition
        this.showClusterBox = true;
      }
    });
    this.occDispatch.clusterAtGlanceListen().pipe(takeWhile(() => this.compActive)).subscribe(() => {
      this.utils.showLoading();
      this.clusterPrint();
    });
    this.occSetstore.pipe(select('Occ_Settings_Tab_Text'), takeWhile(() => this.compActive)).subscribe((v) => {
      this.settingsText = v;
      if (this.settingsText.Occ_Settings_Tab_Text.tabs) {
        this.settingsTextTab = this.settingsText.Occ_Settings_Tab_Text.tabs.find((obj) => (obj['tabId'] === 0));

        const val = JSON.parse(this.storageService.sessionStorageGet('OccIndexReducerText'));
        //As now we are using clusterName uncommented the code.
          const value = true;
          if (val && val.Occ_Index_Reducer_Text !== undefined) {
            val.Occ_Index_Reducer_Text.clusList.forEach((v) => {
              if ((v.clusterID == this.clusterID) && value == true) {
                this.clusterName = (v.title);
              }
            });
          }
      }
    });
    const red_OccText = this.storageService.sessionStorageGet('Reducer_OccText');
    if (red_OccText && red_OccText !== 'undefined') {
      let red_OccText_parsed = JSON.parse(red_OccText);
      if (red_OccText_parsed.Occ_Buttons_Reducer_Text &&
        red_OccText_parsed.Occ_Buttons_Reducer_Text.common &&
        red_OccText_parsed.Occ_Buttons_Reducer_Text.common.careerCluster) {
          this.occClusterText = red_OccText_parsed.Occ_Buttons_Reducer_Text.common.careerCluster;
        }
    }
  }

  /**
  * This method is used to get into At a glance component.
  */
  ngOnInit() {

    let id;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration')).config.Result.tabItems;
    for (let i = 0; i < vl.length; i++) {
      const list = vl[i].compList;
      for (let j = 0; j < list.length; j++) {
        id = list[j].compId;
        if (id == 'sortCCIJr') {
          this.SortCCI = i;
          break;
        } else if (id == 'sortCCIAdult') {
          this.SortCCI = i;
          break;
        }
      }
    }
    this.setDocTitle();
  }

  /**
   * This method is for navigating from cluster to the assessment.
   * @param taken is to check wheather the assessment is taken or not.
   */
  goToCCI(taken, sortId) {
    let navTo , setTo;
        if (sortId == 'CCIQuick') {
          this.storageService.sessionStorageSet('CCIassessment', 'CCIQuick');
          this.storageService.sessionStorageSet('activeSortId', sortId);
          setTo = 'CCIQuickResult';
          navTo = 'cciQuick';
        } else if (sortId == 'CCIQuickAdult') {
          this.storageService.sessionStorageSet('CCIassessment', 'CCIQuickAdult');
          this.storageService.sessionStorageSet('activeSortId', sortId);
          setTo = 'CCIQuickResult';
          navTo = 'CCIQuickAdult';
        } else if (sortId == 'CCIJr') {
          this.storageService.sessionStorageSet('CCIassessment', 'CCIJr');
          this.storageService.sessionStorageSet('activeSortId', sortId);
          setTo = 'CCIjrResult';
          navTo = 'cciJr';
        } else if (sortId == 'CCIAdult') {
          this.storageService.sessionStorageSet('CCIassessment', 'CCIAdult');
          this.storageService.sessionStorageSet('activeSortId', sortId);
          setTo = 'CCIjrResult';
          navTo = 'cciAdult';
        }


    if (taken == true) {
      this.assessmentServices.setBackValue = 'careersHeader';
     // this.storageService.sessionStorageSet('inTab', 'cciCareer');
      this.storageService.sessionStorageSet(setTo , JSON.stringify(this.occCCIScoreAll.clusters));
      const navigate = '../../' + navTo + '/result';
      this.router.navigate([navigate], { relativeTo: this.activatedRoute });
    } else if (taken == false) {
      const navigate = '../../' + navTo + '/intro';
      this.router.navigate([navigate], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * This method is for navigating from cluster to career.
   * @param id for storing the occID.
   * @param name for storing the occ name.
   * @param clusterIcon for storing the clusterIcon.
   * @param clusterColor for storing the clusterColor.
   */
  CallOccDetailCareer(id, name, clusterIcon, clusterColor) {
    const twoDigit = id.toString().substr(0, 2);
    if (twoDigit == 14) {
      this.router.navigate(['../occEmergCareer'], {
        relativeTo: this.activatedRoute,
        queryParams: { occid: id, occname: name, clusIcon: clusterIcon, clusColor: clusterColor }
      });
    } else {
      this.router.navigate(['../occCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    }
  }
  //  This function is for displaying the pop-up when we ckick on print button /
  async clusterPrint() {
    const data = this.clusAtaGlance.nativeElement;
    const opt = {
      filename: 'Career Cluster - At a Glance.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      margin: [0.1, 0, 0.2, 0],
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
    this.utils.hideLoading();
  }
  public setDocTitle() {
    this.titleService.setTitle('Career Details - CIS360 Careers');
  }
  // This ngOnDestroy() function is call after Component destory.
  ngOnDestroy() {
    this.compActive = false;
  }
}
