import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DatetimeComponent } from './components/form/datetime/datetime.component';
import { InputComponent } from './components/form/input/input.component';
import { RadioComponent } from './components/form/radio/radio.component';
import { SelectComponent } from './components/form/select/select.component';
import { TextareaComponent } from './components/form/textarea/textarea.component';
import { ValidationComponent } from './components/form/validation/validation.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  imports: [
    FormsModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TooltipModule.forRoot(),
  ],
  declarations: [
    DatetimeComponent,
    InputComponent,
    RadioComponent,
    SelectComponent,
    TextareaComponent,
    ValidationComponent
  ],
  exports: [
    DatetimeComponent,
    InputComponent,
    RadioComponent,
    SelectComponent,
    TextareaComponent,
    ValidationComponent
  ]
})
export class SharedModule { }
