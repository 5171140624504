/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, AfterViewInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Action, select } from '@ngrx/store';
import { of } from 'rxjs';

/**Services **/
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';
import html2pdf from 'html2pdf.js';
import { delay, takeWhile } from 'rxjs/operators';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import {
  CareersJr_Buttons_Reducer_Text_State,
  CareersJr_EmpOutlook_Wages_State,
  CareersJr_Settings_Tab_Text_State,
} from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';

@Component({
  selector: 'employment-careersJr',
  templateUrl: './employment-careersJr.layout.html',
})
export class EmploymentCareersJrComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  @ViewChild('empOutLookPrintPage', { static: true })
  empOutLookPrintPage: ElementRef;
  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  displayValue = 1; /** Based on this we will apply disable property to the list */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  loggedInState;
  selectedStates; /** Contains user selected state, by default US */
  duplicateState; /** clone variable for selected states, used when displaying states  */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  langVal; /** To get the value of language */
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  loc_btn_color = ['#a22903', '#005787', '#1b621e', '#014941', '#770c84'];
  compAlive = true;
  occname;
  occId;
  printPage = false;
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;

  constructor(
    private router: Router,
    private utils: Utilities,
    private storageService: StorageService,
    private occstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private occDispatch: CareersJrDispatchService,
    private dispatchStore: Store<Action>,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private occSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private Frameworkstore: Store<FrameworkConfigState>,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
    this.loggedInState = this.storageService.sessionStorageGet('stateAbr');
    this.selectedStates = ['US', this.loggedInState];
    this.duplicateState = ['US', this.loggedInState];
    this.occDispatch
      .careerOutLookListen()
      .pipe(takeWhile(() => this.compAlive))
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
    /** Get user selected languge */
    this.langVal = this.storageService.sessionStorageGet('langset');
    /** Check whether user selected states while reloading,
     * if he selected place that states in selected variables,
     * if not default values are inserted
     */
    if (this.storageService.sessionStorageGet('outlookStates')) {
      const arr = this.storageService.sessionStorageGet('outlookStates').split(',');
      this.duplicateState = arr;
      this.selectedStates = arr;
    } else {
      this.selectedStates = ['US', this.loggedInState];
      this.duplicateState = ['US', this.loggedInState];
    }
    // Get data from reducer to display buttons text
    this.occstore
      .pipe(
        select('CareersJr_Buttons_Reducer_Text'),
        takeWhile(() => this.compAlive)
      )
      .subscribe((v) => {
        this.occCareerText = v;
      });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSetstore
      .pipe(
        select('CareersJr_Settings_Tab_Text'),
        takeWhile(() => this.compAlive)
      )
      .subscribe((v) => {
        this.settingsText = v;
        if (this.settingsText.CareersJr_Settings_Tab_Text.tabs) {
          this.settingsTextTab =
            this.settingsText.CareersJr_Settings_Tab_Text.tabs.find(
              (obj) => obj['tabId'] === 3
            );
        }
      });
    // Get data from reducer to display in cards
    this.OCCPageStateStore.pipe(
      select('CareersJr_Detail_Employment_Selector'),
      takeWhile(() => this.compAlive)
    ).subscribe((v) => {
      if (v.OutlookRatings) {
        this.occCareerStore = v;

        if (
          this.occCareerStore &&
          this.occCareerStore.OutlookRatings &&
          this.occCareerStore.OutlookRatings.states
        ) {
          of('delay Observable time')
            .pipe(delay(1600))
            .subscribe(() => {
              this.getPdfData();
            });
        }
      }
    });
  }
  getPdfData() {
    //sort states alphabetically
    this.occCareerStore.OutlookRatings.states.sort(function (a, b) {
      return a.state.toLowerCase().localeCompare(b.state.toLowerCase());
    });
    //United States needs to always display at bottom of Employment and Outlook tables
    const UsState = 'United States'.toLowerCase();
    const USData = this.occCareerStore.OutlookRatings.states.find(
      (state) => state.state.toLowerCase() === UsState
    );
    const USIndex = this.occCareerStore.OutlookRatings.states.findIndex(
      (state) => state.state.toLowerCase() === UsState
    );
    if (this.occCareerStore.OutlookRatings.states.length > 0 && USIndex >= 0) {
      this.occCareerStore.OutlookRatings.states.splice(USIndex, 1);
      this.occCareerStore.OutlookRatings.states.splice(
        this.occCareerStore.OutlookRatings.states.length,
        0,
        USData
      );
    }
  }
  ngAfterViewInit() {
    let states;
    this.selectedStates = this.duplicateState;
    if (this.duplicateState[0] !== "''") {
      states = this.duplicateState;
      //making sure that 5 states are selected max.
      states[1] === 'IC'
        ? this.duplicateState.splice(1, 1) && (states = ['US'])
        : (states = this.duplicateState);
    } else {
      /*Here below we have used this else, if there arises a case
        where we donot want by default 'US' to load its details.
        Then the else case will be executed. Here the expected value
        is a string so, if we use '' or "" within the array then we get error,
        as the expected is a string format if we send undefined(''or"")
        the app crashes to avoid such a crash we have considered "''"   */
      states = ["''"];
    }
    if (!this.occId || this.occId.length === 0) {
      this.utils.log('employment-careersJr.component.ts ngAfterViewInit GET_CAREERSJR_EMPLOMENT', 'debug').subscribe();
    }
    this.storageService.sessionStorageSet('outlookStates', states);
    this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_EMPLOMENT',
      payload: {
        methodVal: 'GET',
        module_Name: 'OccJr/v1/',
        path_params: ['OccJr', parseInt(this.occId), 'accountID'],
        query_params: {
          sections:
            'MajorEmployers,OutlookInfo,OutlookRatings,TopOpeningLocations',
          states: states,
          lang: this.storageService.sessionStorageGet('langset'),
        },
        body_Params: {},
        endUrlVal: 'pages',
      },
    });

    try {
      this.dispatchStore.dispatch({
        type: 'GET_CAREERJR_EMPLOYMENT',
        payload: {
          methodVal: 'GET',
          module_Name: 'OccJr/v1/',
          path_params: ['OccJr', Number(this.occId), 'accountID'],
          query_params: {
            sections: 'OutlookRatings,MajorEmployers,Opportunities',
            states: states,
            lang: this.langVal,
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    } catch (e) {
      console.log('error------>' + e.message);
    }
  }
  ngOnInit() {
    const val = this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')
      ? JSON.parse(this.storageService.sessionStorageGet('CareersJr_Reducer_OccText'))
      : undefined;
    if (
      val &&
      val.careersJr_Buttons_Reducer_Text &&
      val.careersJr_Buttons_Reducer_Text.common
    ) {
      this.occCareerText = val;
    }
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compAlive))
      .subscribe((params) => {
        this.occname = params['occname'];
        this.occId = params['occid'];
      });
  }
  async careerPrint() {
    const element = this.empOutLookPrintPage.nativeElement;
    const opt = {
      filename: 'Career Jr - Employment.pdf',
      margin: [2, 0, 2.5, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** this ngOnDestroy() function is call after Component destory */

  ngOnDestroy() {
    // unsubscribe all the subscritions
    this.compAlive = false;
  }
  checkopen() {
    // Works when we click on selectStates button
    this.duplicateState = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.duplicateState.push(this.selectedStates[i]);
    }
    this.displayValue = this.selectedStates.length;
  }
  showStates() {
    // Works when we click on Apply button
    this.utils.showLoading();
    let states;
    this.selectedStates = this.duplicateState;
    if (this.duplicateState[0] != "''") {
      states = this.duplicateState;
    } else {
      /*Here the expected value
      is a string so, if there is no default state and we select no state
      then we are going to pass undefined or null so if we use '' or ""
      within the array then we get error,
      as the expected is a string format if we send undefined(''or"")
      the app crashes to avoid such a crash we have considered "''"   */
      states = ["''"];
    }
    this.storageService.sessionStorageSet('outlookStates', states);
    const val = this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')
      ? JSON.parse(this.storageService.sessionStorageGet('CareersJr_Reducer_OccText'))
      : undefined;
    if (
      val &&
      val.careersJr_Buttons_Reducer_Text &&
      val.careersJr_Buttons_Reducer_Text.common
    ) {
      this.occCareerText = val;
    }
    const asmtEndurl = this.restrictedAccountType
      ? 'OccJr/standard'
      : 'OccJr/standard/default';

    this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_SETTING_TABS_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'Settings/v1/',
        path_params: this.restrictedAccountType ? ['accountID'] : [],
        query_params: { lang: this.langVal },
        body_Params: {},
        endUrlVal: asmtEndurl,
        name: 'careers',
      },
    });
    if (!this.occId || this.occId.length === 0) {
      this.utils.log('employment-careersJr.component.ts showStates GET_CAREERSJR_EMP_OUTLOOK', 'debug').subscribe();
    }
        this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_EMP_OUTLOOK',
      payload: {
        methodVal: 'GET',
        module_Name: 'OccJr/v1/',
        path_params: ['OccJr', parseInt(this.occId), 'accountID'],
        query_params: {
          sections:
            'MajorEmployers,OutlookInfo,OutlookRatings,TopOpeningLocations',
          states: states,
          lang: this.storageService.sessionStorageGet('langset'),
        },
        body_Params: {},
        endUrlVal: 'pages',
      },
    });
  }
  count(val, e) {
    //Works when we click on check box
    if (this.duplicateState[0] == "''") {
      this.duplicateState = [];
    }
    if (e.which == 13 || e == 'click') {
      if (this.duplicateState.indexOf(val) == -1) {
        this.duplicateState.push(val);
      } else if (this.duplicateState.indexOf(val) != -1) {
        this.duplicateState.splice(this.duplicateState.indexOf(val), 1);
      }
      this.displayValue = this.duplicateState.length;
      if (this.displayValue == 0) {
        this.duplicateState = ["''"];
      }
    }
  }
  cancelStates() {
    //Works when we click on cancel button
    this.duplicateState = this.selectedStates;
    this.displayValue = this.selectedStates.length;
  }
  resetStates() {
    //Works when we click on reset button
    this.displayValue = 1;
    //here we have taken 5 in splice because we want to restrict selection of states upto 5
    this.loggedInState === 'IC'
      ? this.duplicateState.splice(0, 5, 'US')
      : this.duplicateState.splice(0, 5, 'US', this.loggedInState);
  }
}
