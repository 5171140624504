
/** Angular imports */
import { Component, Input, OnInit, ViewChild, Pipe, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
// import { BrowserDomAdapter } from '@angular/platform-browser/src/browser/browser_adapter';
import { Store } from '@ngrx/store';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';

/** Third party library import */
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Custom imports */
import { ApiCallClass } from '../modal/apicall.modal';
import { ServerApi } from '../outer-services/app.apicall.service';
import { StorageService } from '../outer-services/storage.service';
import { RouteService } from '../outer-services/route.service';
import { EventDispatchService } from '../outer-services/event-dispatch.service';



@Component({
  selector: 'ngbd-modal-content',
  template: `<div class="modal-header" data-keyboard="false">
      <h4 class="modal-title"> {{headerText}}{{((modalCommonText)?.commonText[headsection])}}{{trans_error}}</h4>
      </div>
      <div class="modal-body"  >
     {{session_exp_txt }} {{err_occ }}{{((modalCommonText)?.commonText[headingText])}}</div>
      <div class="modal-footer"  [hidden]="closevalue">
      <button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="btn-common btn btn-success" (click)="deleteTestScore()" >{{(modalCommonText)?.commonText[yesbtn]}}</button>
      <button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn-primary" (click)="activeModal.close('Close click')">{{(modalCommonText)?.commonText[nobtn]}}</button>
      </div>
     <div class="modal-footer" [hidden]="!closevalue">

    <button id="reload" type="button" class="btn-common btn btn-warning" (click)="reloadmodal()" >Reload</button>
     <button id="logout" type="button" class="btn-common btn btn-warning" (click)="logoutmodal()" >Sign out</button>
     </div>
    `
})
export class NgbdModalContent implements OnInit {
  headsection;
  yesbtn;
  nobtn;
  @Input() deletevalue;
  @Input() arrayValue;

  @Input() trans_error;
  // @Input() closebtn;
  @Input() err_occ;

  @Input() session_exp_txt;
  @Input() sessionName;

  @Input() Closebtnvalue;

  unsavedTxt;
  @Input() commonButtons;
  @Input() valueofunchange;

  // browserDom: BrowserDomAdapter;
  closevalue = false;
  testScoreArr = [];
  headerText;
  modalCommonText;
  headingText;
  constructor(private http: HttpClient, public browserDom: Renderer2, private router: Router, private store: Store<any>,
    public activeModal: NgbActiveModal, private apiJson: ApiCallClass, private storageService: StorageService,
    private eventService: EventDispatchService, private routeService: RouteService) {
    // this.browserDom = new BrowserDomAdapter();
    this.modalCommonText = JSON.parse(this.storageService.sessionStorageGet('AsmntCommonTextStorage'));
    // this.modalCommonText = store.select('AsmntCommonText');
    // this.unsavedTxt = this.commonButtons['unsavedTxt'];
  }

  ngOnInit() {

    // console.log("close vlaue is  :in shared");
    try {
      setTimeout(function () {
        // try {
        if (this.commonButtons != undefined) {
          // console.log('this.commonButtons : ' + this.commonButtons)
          this.headerText = this.commonButtons.headerText;
          this.headingText = this.commonButtons.unsavedTxt;
          this.headsection = this.commonButtons.headsection;
          this.nobtn = this.commonButtons.nobtn;
          this.yesbtn = this.commonButtons.yesbtn;
        }
        // console.log('headtext--' + this.headingText)
        // } catch (e) {
        //   console.log('in model component---' + e.message);
        // }
        this.browserDom.setStyle(document.querySelector('.modal-dialog'), 'width', '90%');
      }.bind(this), 0);
    } catch (e) {
      console.log('error--->' + e.message);
    }
    if (this.Closebtnvalue == 1) {
      this.closevalue = true;
    }

  }

  deleteTestScore() {
    // console.log(this.deletevalue);
    if (this.deletevalue >= 0) {
      for (let i = 0; i < this.arrayValue.length; i++) {
        if (this.deletevalue == i) {
          this.arrayValue.splice(i, 1);
          for (let j = 0; j < 2; j++) {
            this.browserDom.setStyle(document.querySelectorAll('.SaveBtn' + i)[j], 'display', 'none');
            this.browserDom.setStyle(document.querySelectorAll('.edtBtn' + i)[j], 'display', 'block');
            this.browserDom.setStyle(document.querySelectorAll('.cancelBtn' + i)[j], 'display', 'none');
          }
        }
      }
    }


    if (this.valueofunchange == 'unsavedcheck') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('unsaved', true, true);

      this.eventService.dispatch(evnt);
    }
    this.activeModal.close(true);
  }

  closemodal() {
    // console.log("called close()");

    this.activeModal.close(true);

  }
  reloadmodal() {
    window.location.reload();
  }
  logoutmodal() {
    this.routeService.mainLogOut();
    // window.location.href = 'login/loginForm';
  }
}
@Component({
  selector: 'ngbd-modal-content',
  template: `<div class="rc-assessment-popup modal-content modal-color">
  <div class="modal-header text-white py-2 px-3" >
  <h4 class="modal-title">{{header}}</h4>
  <button type="button" class="modal-close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="font-weight-bold my-1">{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark text-white" (click)="activeModal.close('Close click')">Close</button>
</div>
</div>
    `
})
export class NgbdModalRC {
  @Input() header;
  @Input() message;

  constructor(public activeModal: NgbActiveModal) {}


}
@Injectable()
export class NgbdModalLoaderContent implements OnInit {
  shwHideVal;
  // browserDom: BrowserDomAdapter;
  constructor(private spinnerService: NgxSpinnerService) {
    // this.browserDom = new BrowserDomAdapter();
  }

  ngOnInit() {

  }

  showLoading() {
    // console.log('coming on show');
    this.spinnerService.show();
  }
  hideLoading() {
    // console.log('coming on hide');
    this.spinnerService.hide();
  }

}


@Component({
  selector: 'ngbd-modal-content',
  template: `

  <div class="modal-header" style="color: #0b8cbc;" data-keyboard="false">
      <h5 class="modal-title" [hidden]="!deleteValueTxt"> Delete Answer Set {{answerSet_txt}}  </h5>
      <h5 class="modal-title" [hidden]="deleteValueTxt"> {{(modalCommonText)?.commonText[headsection]}} {{answerSet_txt}}</h5>

      </div>
      <div class="modal-body"><h6 id="thought">{{((modalCommonText)?.commonText[enter_thought_txt])}}</h6><p  [hidden]="showcomponent">
      <textarea class="form-control popup-textarea-plp2" rows="5" aria-label="save-text" name="save-text" id="comment" placeholder="" [(ngModel)]="str"></textarea></p>
     <p [hidden]="!showcomponent" >{{delete_sure_txt}}</p>
     <p style="color: #FF0000; font-size:14px;" [hidden]="!showcomponent">{{action_undone_txt}}</p>
     </div>
      <div class="modal-footer">
      <button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="{{classProperty}}"  (click)=" assessmentCommonAction()" >{{(modalCommonText)?.commonText[yesbtn]}}</button>
      <button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn btn-warning" (keydown)="keydownInput($event)" (click)="activeModal.close('Close click')">{{(modalCommonText)?.commonText[nobtn]}}</button>
      </div>
    `
})
export class AssessmentModalPopups implements OnInit {
  //saveModal parameters
  headsection;
  enter_thought_txt;
  @Input() textarea_txt;
  yesbtn;
  nobtn;
  @Input() classProperty;
  // @Input() jsonObjTxt;
  str: string;

  //deleteModal parameters

  @Input() showvalue;
  @Input() delete_sure_txt;
  @Input() answerSet_txt;
  @Input() action_undone_txt;
  @Input() commonButtons;
  @Input() Deletebtnvalue;

  showcomponent = false;
  modalCommonText;
  deleteValueTxt = false;
  constructor(private http: HttpClient, public browserDom: Renderer2,
    public activeModal: NgbActiveModal, private apiJson: ApiCallClass, private store: Store<any>,
    private eventService: EventDispatchService, private storageService: StorageService) {
    // this.browserDom = new BrowserDomAdapter();
    this.modalCommonText = JSON.parse(this.storageService.sessionStorageGet('AsmntCommonTextStorage'));
  }
  ngOnInit() {
    setTimeout(function () {
      this.nobtn = this.commonButtons.nobtn;
      this.yesbtn = this.commonButtons.yesbtn;
      this.enter_thought_txt = this.commonButtons.enter_thought_txt;
      this.headsection = this.commonButtons.headsection;
      this.browserDom.setStyle(document.querySelector('.modal-dialog'), 'width', '90%');
      if (this.showvalue == 1) {
        this.showcomponent = true;
        this.classProperty = 'btn-common btn-danger';
      } else {
        this.classProperty = 'btn-common btn btn-success';
      }
      this.str = this.textarea_txt;
    }.bind(this), 0);
    if (this.Deletebtnvalue == 1) {
      this.deleteValueTxt = true;
    }
  }


  assessmentCommonAction() {

    if (this.showvalue == 1) {
      // console.log("delete Ok !...");
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('DeleteButtonAction', true, true);
      this.eventService.dispatch(evnt);

    } else {
      if (this.showvalue == 2) {
        this.storageService.sessionStorageSet('textareaValue', this.str);
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent('SaveButtonAction', true, true);
        this.eventService.dispatch(evnt);

      }

    }
    this.activeModal.close();
  }
  keydownInput(event) {
    event.preventDefault();
    this.activeModal.close();
  }
  ngOnDestroy() {
    // console.log("comming to distroy !...");
    this.showvalue = '';
  }
}




@Component({
  selector: 'ngbd-modal-snackbar',
  template: `<div id="snackbar">
  {{snackbarname}}
  </div>    `
})
export class SnackBar implements OnInit {
  snackbarname;
  constructor() {
  }

  ngOnInit() {

  }
  myFunction(funcName) {
    this.snackbarname = funcName;
    const x = document.getElementById('snackbar');
    x.className = 'show';
    x.innerHTML = ' <i class="fa fa-check" aria-hidden="true"></i>&nbsp;' + funcName;
   /*Here the usecase is for showing the snackbar in 2 seconds of time  */
    setTimeout(function () { x.className = x.className.replace('show', ''); }, 2000);
  }
}

@Component({
  selector: 'ngbd-modal-snackbar-red',
  template: `<div id="snackbarred">
  {{snackbarname}}
  </div>    `
})
export class SnackBarRed implements OnInit {
  snackbarname;
  constructor() {
  }

  ngOnInit() {

  }
  myFunction(funcName) {
    this.snackbarname = funcName;
    const x = document.getElementById('snackbarred');
    x.className = 'show';
    x.innerHTML = ' <i class="fa fa-check" aria-hidden="true"></i>&nbsp;' + funcName;
    setTimeout(function () { x.className = x.className.replace('show', ''); }, 3000);
  }
}
