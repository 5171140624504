/**Angular imports.*/
import { Component, Input, OnDestroy, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, NavigationExtras, RouterModule, Router } from '@angular/router';
import { Action, select, Store } from '@ngrx/store';

/**Third party Libraries.*/
import { Subscription } from 'rxjs';
import {
    debounceTime, take
} from 'rxjs/operators';
import { NgbdModalLoaderContent } from '../../../../shared/modal/shared-modal-component';
/**Custom imports.*/
import { EventDispatchService } from '../../../../shared/outer-services/event-dispatch.service';
import { StorageService } from '../../../../shared/outer-services/storage.service';
import { Utilities } from '../../../../shared/outer-services/utilities.service';
import * as LtiActionType from '../../../../state-management/actions/lti-module.action';
import { getltilocationselector } from '../../../../state-management/selectors/lti-location.selector';
import { LtiLocationModel } from '../../../../state-management/state/lti-location.state';
import { FrameworkConfigState } from '../../../../state-management/state/main-state';
import { HideRestrictedComponents } from '../../../../shared/constants/app.constants';


@Component({
    selector: 'tiles-dynamic-component',
    templateUrl: `./tiles.layout.html`,
})
export class TilesDynamicComponent implements OnDestroy {
    @ViewChild('assessment_header', { static: true }) main: ElementRef;
    @Input('FrameworkObj') FrameworkObj;
    subscription = new Subscription; /** Declare to listen if any change occured.*/
    subscription1 = new Subscription; /** Declare to listen if any change occured.*/
    tileView = false; /**Declare for storing the boolean values. */
    ItemName = ''; /**Declare for storing the itemnames. */
    urlArr; /**Declare for splitting the urls. */
    frameSub: any;
    languageKey;
    compList: any;
    hideRestrictedComponents: [];
    accountType: string;
    constructor(private store: Store<any>,
        private util: Utilities,
        private FrameworkStore: Store<FrameworkConfigState>,
        private loaderContent: NgbdModalLoaderContent,
        private renderer: Renderer2, private dispatchStore: Store<Action>, private route: Router,
        private storageService: StorageService, private activeRoute: ActivatedRoute,
        private eventDispatcher: EventDispatchService, private locatonStore: Store<LtiLocationModel>,
        @Inject('SPANISH_ICON') public spanishIcon: string,
        private titleService: Title) {
        
        this.hideRestrictedComponents = HideRestrictedComponents;
        const acct = JSON.parse(this.storageService.sessionStorageGet('accountType'));
        if (acct && acct.accountType) {
            this.accountType = acct.accountType;
        }
        this.tilesViewMethod();
        this.subscription = this.route.events.pipe(debounceTime(300))
            .subscribe((event) => {
                this.tilesViewMethod();
            });
        this.subscription1 = eventDispatcher.listenStaticContent().subscribe((e) => {
            /** After event listen it will check whether user want to save partially or completely */
            if (this.main) {
                this.main.nativeElement.setAttribute('tabindex', '0');
                this.main.nativeElement.focus();
            }
        });

    }
    ngAfterViewInit() {
        this.urlArr = window.location.pathname.split('/');
        if (this.urlArr[this.urlArr.length - 1] === 'tiles' || this.urlArr.length === 3) {
               const careerJr = this.FrameworkObj.compList.filter((f) => f.url == './careersJr/careerJrIndex').find((x) => x.url);
            if (this.FrameworkObj && this.FrameworkObj.compList && this.FrameworkObj.compList.length == 1 && !careerJr) {
                this.tileClicked(this.FrameworkObj.compList[0]);
                this.tileView = false;
            }


        }
        // this.compList=this.FrameworkObj.compList
    }
    // alphabetized(){
    //     this.FrameworkObj.compList.sort(function (a1: any, b1: any) {
    //         return a1.compName.toLowerCase().localeCompare(b1.compName.toLowerCase());
    //      });
    //      return this.FrameworkObj.compList
    // }
    tilesViewMethod() {
        this.frameSub = this.FrameworkStore.select('config').subscribe((v) => {
            this.FrameworkObj = this.util.getFrameObjbyId();
            if (v && v.config && v.config.Result && v.config.Result.lang && v.config.Result.lang === this.storageService.sessionStorageGet('langset')) {
                this.languageKey = v.config.Result.lang;
                this.util.hideLoading();
            }
        });

        let indvlocationCompId;
        this.locatonStore.pipe(select(getltilocationselector), take(1)).subscribe((locresponse) => {
            if (locresponse) {
                indvlocationCompId = locresponse.split('|')[0];
            }
        });
        this.urlArr = window.location.pathname.split('/');
        if (this.urlArr[this.urlArr.length - 1] === 'tiles' || this.urlArr.length === 3) {
            this.storageService.sessionStorageSet('inTab', '');

            if (this.FrameworkObj && this.FrameworkObj.compList) {
                let individualModule;
                if (indvlocationCompId) {

                    individualModule = this.FrameworkObj.compList.find(val => val.compId == indvlocationCompId);
                }
                if (individualModule) {
                    this.tileClicked(individualModule);
                    this.tileView = false;
                    /*Here removed the locationModule if the component is not fileOcc,fileMils and education components
                    because those components need details view tab redirection based on this locationModule*/
                    if (!indvlocationCompId.includes('file')) {
                        this.storageService.sessionStorageRemove('locationModule');
                    }
                    this.locatonStore.dispatch(new LtiActionType.RemoveLtiLocationAction());

                } else if (this.FrameworkObj.compList.length == 1) {
                    this.tileClicked(this.FrameworkObj.compList[0]);
                    this.tileView = false;
                } else {
                    this.tileView = true;
                }
            } else {
                this.tileView = true;
            }
            //    console.log('tileView in if ---> ' + this.tileView);
            this.loaderContent.hideLoading();
        } else {
            //    console.log('tileView in else ---> ' + this.tileView);
            this.tileView = false;
            if (indvlocationCompId && !indvlocationCompId.includes('file')) {
                this.storageService.sessionStorageRemove('locationModule');
            }
        }
        // console.log('out of route change');
    }
    /**
      * This method gets execute when we click on the tile
      * @param item declare for storing the data on which tile is clicked
      */
    tileClicked(item) {

        this.storageService.sessionStorageSet('careerShow', '0');
        this.storageService.sessionStorageSet('activeSortId', item.compId);
        this.storageService.sessionStorageSet('progschool', 'false');
        this.storageService.sessionStorageSet('showHeaderText', '0');
        this.storageService.sessionStorageRemove('filterSettings');
        this.storageService.sessionStorageRemove('prevRoute');
        this.storageService.sessionStorageSet('savedValId', '');
        this.loaderContent.showLoading();
        this.dispatchStore.dispatch({ type: 'RESET_STORE' });
        // document title has been changed to assessment specific hence commented the below global setter.
        // document.title = item.compName + " - CIS360 Home";
        const Arr = this.route.url.split('./');
        this.ItemName = 'plpTile&' + item.name;
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent(this.ItemName, true, true);
        this.eventDispatcher.dispatch(evnt);
        if (item.compId == 'sortCCIJr') {
            this.storageService.sessionStorageSet('CCIassessment', 'CCIJr');
        } else {
            this.storageService.sessionStorageSet('CCIassessment', 'CCIAdult');
        }
        if (item.compId.startsWith('url')) {
            window.open(item.url, '_blank');

        } else {
            setTimeout(function () {
                this.route.navigate([item.url], { relativeTo: this.activeRoute });
            }.bind(this), 50);
        }
        this.loaderContent.hideLoading();

    }

    /**
    * This method is used for unsubscribing the event.
    */

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscription1.unsubscribe();
    }
     /**title service */
  public setDocTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
