import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LtiLocationModel } from '../../state-management/state/lti-location.state';
import * as LtiActionType from '../../state-management/actions/lti-module.action';
import { Location } from '@angular/common';
import { EventDispatchService } from '../../shared/outer-services/event-dispatch.service';
import { StorageService } from '../../shared/outer-services/storage.service';
import { FrameworkConfigState } from '../../state-management/state/main-state';
import { take } from 'rxjs/operators';
import { routeObj } from '../../shared/constants/app.constants';
@Component({
  selector: 'app-redirect',
  template: 'Loading....',
})
export class RedirectComponent {
  initAssessmentContent = 'tiles';
  initOccContent = 'occIndex';
  menuItems = [];
  accountId = ''; /**Declare for storing the account id.*/
  appTitle = ''; /**Declare for storing the app title. */
  hideFilter = true; /**Declare for storing the boolean value.*/
  clickBtn = false; /**Declare for storing the boolean value.*/
  changedLang = '';
  userName = ''; /**Declare for storing the username.*/
  userObj; /**Declare for storing the language list. */
  headerFooterObj;
  logo;
  stateName;
  compActive = true;
  plansData;
  personalData;
  accountTypeStore;
  indvlocationmodule;
  FrameworkObj: any;
  urlArr: Array<string>;
  frameConfigDetails: any;
  frameworkTabItems;
  combineAllcomp = [];
  redirectActive = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private locatonStore: Store<LtiLocationModel>,
    private eventService: EventDispatchService,
    private Frameworkstore: Store<FrameworkConfigState>,
    private router: Router,
    private _location: Location,
    private storageService: StorageService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      // console.log(params)
      const compId = params['compId'];
      const mainId = params['mainId'];
      const subId = params['subId'];
      // console.log(params)

      let finalStartLocation = compId;
      if (mainId) {
        finalStartLocation = finalStartLocation + '|' + mainId;
      }
      if (subId) {
        finalStartLocation = finalStartLocation + '|' + subId;
      }
      this.locatonStore.dispatch(
        new LtiActionType.SetLtiLocationAction(finalStartLocation)
      );
      this.indvlocationmodule = finalStartLocation;
      let first = '';
      this.Frameworkstore.select('config')
        .pipe(take(1))
        .subscribe((v) => {
          const frameConfig = v['config']['Result'];
          if (frameConfig !== undefined && frameConfig !== null) {
            const res = frameConfig;
            this.menuItems = [];
            this.headerFooterObj = res.headerFooter;
            document.title = this.headerFooterObj.appName;
            this.appTitle = this.headerFooterObj.appName;
            this.userObj = res.user;
            this.logo = res.user.logo;
            let langArr = [];
            langArr = JSON.parse(
              this.storageService.sessionStorageGet('langAssArr')
            );
            const langSet = this.storageService.sessionStorageGet('langset');
            if (langSet == null || langSet + '' == 'undefined') {
              const tmpObj = this.userObj.langList.find((val, inx) => {
                return val.lang == res.user.prefLang;
              });
              {
                this.changedLang = tmpObj.name;
                this.storageService.sessionStorageSet('langset', tmpObj.lang);
              }
            } else {
              const ref = this;
              this.userObj.langList.forEach(function (index, val) {
                if (index.lang == langSet) {
                  ref.changedLang = index.name;
                }
              });
            }
            let moduleLoadObj;
            /**
             * LTI navigation Either plan|2|1, fileMil|200100|3, fileProg
             */
            let resumeSubroute;
            res.tabItems
              .filter((obj, inx) => {
                let individualModule, startLoc;
                if (this.indvlocationmodule) {
                  startLoc = this.indvlocationmodule.split('|');

                  if (parseInt(startLoc[0]) === inx + 1) {
                    individualModule = obj;
                  } else if (startLoc[0] === 'plan' && obj.layout === 'plan') {
                    individualModule = obj;
                  } else if (
                    startLoc[0] === 'dashboard' &&
                    obj.layout === 'dashboard'
                  ) {
                    individualModule = obj;
                  } else {
                    const foundObj = obj.compList.find(
                      (compObj) => compObj.compId === startLoc[0]
                    );
                    if (foundObj && obj.layout === 'tiles') {
                      individualModule = obj;
                    }
                  }
                }

                return individualModule;
              })
              .forEach((obj, inx) => {
                if (obj.tabId != undefined) {
                  const tmpobj = {};
                  const individualModule = obj;

                  const startLoc = this.indvlocationmodule.split('|');
                  // console.log('tabitems:',obj);
                  // if (this.indvlocationmodule) {
                  // console.log('startLOc-->', startLoc)
                  // if (parseInt(startLoc[0]) === inx + 1) {
                  //     individualModule = obj;
                  // } else if ((startLoc[0] === 'plan') && obj.layout === 'plan') {
                  //     individualModule = obj;

                  // } else if ((startLoc[0] === 'dashboard') && obj.layout === 'dashboard') {
                  //     individualModule = obj;

                  if (startLoc[0] === 'resumeResume') {
                    resumeSubroute = startLoc
                      .filter((obj, inx) => inx != 0)
                      .join('/');
                  }
                  // else if (obj.layout !== 'dashboard') {
                  //     individualModule = obj.compList.find(val => val.compId == startLoc[0]);
                  // }
                  // console.log('resumesubroute--->', resumeSubroute)
                  // }
                  tmpobj['title'] = this.headerFooterObj.tabs[inx].tabName;
                  if (resumeSubroute) {
                    tmpobj['subroute'] =
                      'tiles/' +
                      routeObj[startLoc[0]].itemConfig.startUrl +
                      resumeSubroute;
                  } else if (
                    obj.layout == 'tiles' &&
                    obj.defaultComp == 'landing'
                  ) {
                    tmpobj['subroute'] = 'tiles';
                  } else if (
                    obj.layout == 'plan' &&
                    obj.defaultComp == 'landing'
                  ) {
                    tmpobj['subroute'] = 'plan';
                  } else if (
                    obj.layout == 'dashboard' &&
                    obj.defaultComp == 'landing'
                  ) {
                    tmpobj['subroute'] = 'dashboard';
                  } else if (obj.defaultComp != '') {
                    tmpobj['subroute'] =
                      routeObj[obj.defaultComp].itemConfig.url;
                  } else {
                    tmpobj['subroute'] =
                      routeObj[obj.compList[0].moduleId].itemConfig.url;
                  }

                  tmpobj['root'] = obj.tabId;
                  this.menuItems.push(tmpobj);
                  /*If the accountType is not 'Guest' then, all the modules will be loaded onto the Browser,
                             which means that the user is already registered and has privelege to access different
                             modules in the application except the GuestAccount module*/
                  if (individualModule) {
                    first = 'done';
                    // this.loadModules(tmpobj);
                    moduleLoadObj = tmpobj;
                    // this.router.navigate(['./' + obj.tabId + '/' + obj.layout], { relativeTo: this.activatedRoute });
                    this.storageService.sessionStorageSet('tabId', obj.tabId);
                  } else if (
                    !this.storageService.sessionStorageGet('tabId') &&
                    this.userObj.accountType !== 'Guest'
                  ) {
                    if (
                      (!first && tmpobj['subroute'] !== 'plan') ||
                      (!first &&
                        (obj.compList.length > 0 ||
                          (this.plansData && this.plansData.length > 0)) &&
                        tmpobj['subroute'] === 'plan')
                    ) {
                      first = 'done';
                      // this.loadModules(tmpobj);
                      moduleLoadObj = tmpobj;
                      // this.router.navigate(['./' + obj.tabId + '/' + obj.layout], { relativeTo: this.activatedRoute });
                      this.storageService.sessionStorageSet('tabId', obj.tabId);
                    }
                  }
                }
              });
            if (moduleLoadObj) {
              this.loadModules(moduleLoadObj);
            }
            // this.utils.appendDynamicColors(res.colors);
          }
          if (this.plansData && this.plansData.length === 0) {
            const removeIndex = this.menuItems
              .map(function (item) {
                return item.subroute;
              })
              .indexOf('plan');
            //Without this logic the education tab will not display for restricted users.
            if (removeIndex >= 0) {
              this.menuItems.splice(removeIndex, 1);
            }
          }
        });
    });
  }
  loadModules(item) {
    // const evnt = document.createEvent('CustomEvent');
    // evnt.initCustomEvent('FooterDispatch', true, true, '');
    /*Due to this code, the background page is navigating to plp homepage without any user responce.
          In order to avoid this error, I have removed below code.
          Now, the navigation will be done as per user responce for warning model popup.*/
    /*if (item.subroute == 'plan') {
            this.store.dispatch(new AddItem(0));
            this.store.dispatch(new ChangePlanView(true))
        }*/
    this.storageService.sessionStorageRemove('planIndex');
    this.storageService.sessionStorageRemove('IsPlanIsPlanViewMode');
    this.storageService.sessionStorageRemove('planId');
    this.storageService.sessionStorageRemove('itemId');
    this.storageService.sessionStorageRemove('CustomAttr');
    const evnt = document.createEvent('CustomEvent');
    if (
      item.subroute == 'plan' &&
      !this.storageService.sessionStorageGet('loginPlansConfiguration')
    ) {
      // console.log('plan dispatched');
      this.storageService.sessionStorageSet('tabId', item.root);
      // document.title = this.appTitle;
      const url = '../app/' + item.root + '/' + item.subroute;
      this.storageService.sessionStorageSet('planPath', url);
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('PlanLoad', true, true);
      this.eventService.dispatch(evnt);
    } else {
      // document.title = this.appTitle;
      this.router
        .navigate(['app/' + item.root + '/' + item.subroute])
        .then((val) => {
          // if user clicked yes for Unsaved changes will be lost(modal pop up) then we are setting manu tab id in session storage
          if (val) {
            this.storageService.sessionStorageSet('tabId', item.root);
          }
        });
    }
  }
}
