import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
// import { Observable } from "rxjs/Rx";
import { Observable } from 'rxjs';




// import 'rxjs/add/operator/empty';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ServerApi } from '../../../../../../shared/outer-services/app.apicall.service';
import { ApiCallClass } from '../../../../../../shared/modal/apicall.modal';
import { Utilities } from '../../../../../../shared/outer-services/utilities.service';
import { PlanSummarayActionTypes, SetPlanSummaryDataAction } from '../action/plan.action';
import { PlanUserSummaryState } from '../state/plan-state';
export function toPayload(action: Action): any {
    return (action as any).payload;
}
@Injectable()
export class PlansMainEffects {
    constructor(private action$: Actions,
        private utils: Utilities,
        private server: ServerApi, private apiJson: ApiCallClass,
    ) {
    }
    @Effect() GetPlanSEffect$ = this.action$
        .pipe(ofType(PlanSummarayActionTypes.GetPlanSummaryData),
            switchMap(() => {
                this.apiJson.method = 'GET';
                this.apiJson.endUrl = '/Dynamicicap/UserSummary/' +
                    this.utils.getAccountId() + '?isForCis360=true&stateAbbr=stateAbbr&lang=en';
                this.apiJson.moduleName = 'dynamicicap';
                return this.server.callDynamicPlpApi([this.apiJson]).pipe(map((result: PlanUserSummaryState['planSummary']) => {
                    return new SetPlanSummaryDataAction(result);
                }));
            }));
}
