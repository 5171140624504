/**Angular core packages */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';

/* dialog is reference of loading model template.*/
@Injectable()
export class RouteService {
  ratingValueFlag = false;
  osFromDashboard = false;
  options: NgbModalOptions = {
    backdrop: 'static',
  }; // options variable passing as a parameter to the open method of modalService
  timeoutVariable; // Variable for assigning the setTimeOut method
  modalRef1; // Variable for assigning the modalService open method
  commonModelText; // Variable for assigning the AsmntCommonText
  frameworkConfig; // Variable for assigning the subscribe value of FrameworkConfigReducer
  constructor(
    private http: HttpClient,
  ) {  }
  exitApp() {
    try {
      return this.http
        .post('/logout/log', {})
        .pipe(
          // catch error is required incase a 401 is returned from trying to use an expired JWT
          catchError((err) => (window.location.href = '/logout'))
        )
        .subscribe(
          (response: any) => (window.location.href = response.logoutURL)
        );
    } catch (e) {
      console.log('exitApp exception:' + e.message);
    }
  }
  /**This function call for logout the application */
  mainLogOut() {
    this.exitApp();
  }
  /** */
  goToLogin() {
    this.goToLoginCall();
  }
  goToLoginCall() {
    try {
      return this.http
        .get('/logout/login', {})
        .pipe(
          // catch error is required incase a 401 is returned from trying to use an expired JWT
          catchError((err) => (window.location.href = '/logout'))
        )
        .subscribe((response: any) => {
          window.location.href = response.logoutURL;
        });
    } catch (e) {
      console.log('goToLoginCall exception:' + e.message);
    }
  }
  continueWithoutAccount(city: string, zip: string, type: string) {
    this.continueZipLoginCall(city, zip, type);
  }
  continueZipLoginCall(city: string, zip: string, type: string) {
    try {
      return this.http
        .get('/logout/continueBrowsing', {})
        .pipe(
          // catch error is required incase a 401 is returned from trying to use an expired JWT
          catchError((err) => (window.location.href = '/logout'))
        )
        .subscribe((response: any) => {
          window.location.href = `${response.continueUrl}?city=${city}&zipCode=${zip}&siteType=${type}`;
        });
    } catch (e) {
      console.log(
        'Continue browsing without creating an account exception:' + e.message
      );
    }
  }
  //Set osFromDashboard value
  set setOsDashboardValue(val) {
    this.osFromDashboard = val;
  }
  // Get osFromDashboard value
  get getOsDashboardValue() {
    return this.osFromDashboard;
  }
    //Set Rating value
    set setRatingValue(val) {
      this.ratingValueFlag = val;
  }
  // Get Rating value
  get getRatingValue() {
      return this.ratingValueFlag;
  }
}
