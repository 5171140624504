import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { Utilities } from '../../../../shared/outer-services/utilities.service';
import { FrameworkConfigState} from '../state-management/state/main-state';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { EventDispatchService } from './outer-services/event-dispatch.service';
@Component({
    selector: 'static-footer',
    template: `<footer id="fhid" class="common-footer w-100"  *ngIf="showFooter">
              <section class="copyright">
                <div class="container w-100">
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="footer-text-desktop desktop-design-display" [ngStyle]="(defaultFooter1)?{'justify-content': 'inherit'}:{'justify-content':'center'}" >
                        <div>{{defaultFooter}}</div>
                        <div *ngIf="defaultFooter2">{{defaultFooter2}}</div>
                        <div class="pt-2" *ngIf="defaultFooter1">{{defaultFooter1}}</div>
                      </div>
                    <div class="mobile-design-display">
                 <p>
                  <span>{{defaultFooter}}</span>
                  <span class="tooltip-footer">
                    <span class="footer-mobile-text" *ngIf="defaultFooter1">Read more...</span>
                    <span class="tooltiptext">{{defaultFooter}}<br><span *ngIf="defaultFooter2">{{defaultFooter2}}</span><br>{{defaultFooter1}}</span>
                  </span>
                </p>
            </div>
          </div>
        </div>
    </div>
    </section>
    <section class="footer-bottom"> </section>
  </footer>`,

})
export class OutsideStaticFooterComponent implements OnInit {
    footervaluetext = '';
    defaultFooter = '';
    footervalposition = '';
    viewval = 0;
    footervaliptext = '';
    asmntname = '';
    subscription = new Subscription;
    subscription1 = new Subscription;
    endurl = '';
    showFooter = true;
    defaultFooter1 = '';
    defaultFooter2 = '';
    constructor( private Frameworkstore: Store<FrameworkConfigState>, private route: Router, private eventService: EventDispatchService) {

    }
    ngOnInit() {
        try {
            this.subscription = this.Frameworkstore.select('config').subscribe((res) => {
                if (res['config'].Result) {
                    const frameConfig = res['config'];
                    const foorJson = (frameConfig);
                    this.defaultFooter = foorJson.Result.headerFooter.copyright;
                    this.defaultFooter2 = foorJson.Result.headerFooter.stateCopyright;
                }
            });
            this.subscription1 = this.eventService.listen().subscribe((eventResponse) => {
              if (eventResponse.type === 'FooterDispatch') {
                const payload: any = eventResponse;
                if (payload.detail !== '') {
                   this.defaultFooter1 = payload.detail.copyrightExtra;
                } else {
                  this.defaultFooter1 = '';
                }
              }});

            // this.subscription1 = this.asmntIntrostore.select('AsmntIntroText').subscribe((res) => {
            //     const footertxt = res['AsmntIntroText'].pageText;
            //     if (footertxt != undefined && footertxt !== null && footertxt !== '') {
            //         if (footertxt.copyrightExtra == "") {
            //             this.defaultFooter = this.defaultFooter
            //             this.defaultFooter1 = "";
            //         }
            //         else if (footertxt.copyrightExtra != "") {
            //             this.defaultFooter1 = footertxt.copyrightExtra
            //         }

            //         // this.footervaluetext = footertxt.copyrightExtra;
            //         //  console.log("footer--->" + JSON.stringify(footertxt.copyrightExtra));

            //     }

            // });

        } catch (e) {
            console.log('footer exception:' + e.message);
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscription1.unsubscribe();
    }
}
