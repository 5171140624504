
/** Angular imports */
import { Injectable } from '@angular/core';

/** Rxjs imports */
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
/** This service is used to track all the popup events and dispatch click events */
export class EventDispatchService {
        trackEvnt: Subject<any> = new Subject<any>();
        staticTrackEvnt: Subject<any> = new Subject<any>();
        menuCloseEvnt: BehaviorSubject<Event>;
        constructor() {
                const evnt = document.createEvent('CustomEvent');
                // evnt.initCustomEvent('test', true, true, {});
                this.trackEvnt = new BehaviorSubject<Event>(evnt);
                this.staticTrackEvnt = new BehaviorSubject<Event>(evnt);
                this.menuCloseEvnt = new BehaviorSubject<Event>(evnt);

        }

        // Below method used to dispatch events.
        dispatch(data: any) {
                this.trackEvnt.next(data);
        }
        dispatchStaticContent(data: any) {
                this.staticTrackEvnt.next(data);
        }
        menucloseDispatch(data) {
                this.menuCloseEvnt.next(data);
        }
        menucloselisten() {
                return this.menuCloseEvnt.asObservable();
        }
        // Below method is used to listen to the dispatched events.
        // We have to use .subscribe() to track the method success callback in calledcall.
        listen(): Observable<Event> {
                return this.trackEvnt.asObservable();
        }
        listenStaticContent(): Observable<Event> {
                return this.staticTrackEvnt.asObservable();
        }
}
