import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';


@Component({
    selector: 'page-not-found',
    template: '<div>{{message}}</div>',
})

export class PageNotFoundComponent implements OnInit {
    message = 'Loading...';
    constructor(private router: Router, ) {
    }

    ngOnInit() {
    }


}

