import { DashBoardAllListState, AssessmentResultState, AssessmentPageTextState, dashboardPersonalInfoState, accountSettingPageTextState, accountSettingImageState, portFolioSettingsState, dashboardBestCareersState } from '../state/dashboard.state';
import { DashboardActions, DashBoardActionTypes, AccountSetttingActions, AccountSettingActionTypes } from '../actions/dashboard.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export function DashboardReducer(state: DashBoardAllListState, action: DashboardActions) {
    switch (action.type) {
        case DashBoardActionTypes.SetDashboardData: {
            return { ...state, dashBoardData: action.payload };
        }
        case DashBoardActionTypes.ResetDashboard: {
            // state = undefined;
            return undefined;
        }
        default: {
            return state;
        }
    }
}
export function DashboardAssessmentResultReducer(state: AssessmentResultState, action: DashboardActions) {
    switch (action.type) {
        case DashBoardActionTypes.SetDBAssessmentResult: {
            return { ...state, dashBoardAsmntData: action.payload };
        }
        // case DashBoardActionTypes.ResetDashboard: {
        //     // state = undefined;
        //     return undefined;
        // }
        default: {
            return state;
        }
    }
}
export function DashboardAssessmentPageTextReducer(state: AssessmentPageTextState, action: DashboardActions) {
    switch (action.type) {
        case DashBoardActionTypes.SetDBAssessmentPageText: {
            return { ...state, dashBoardAsmntPageTextData: action.payload };
        }
        default: {
            return state;
        }
    }
}
export function DashboardPersonalInfoReducer(state: dashboardPersonalInfoState, action: DashboardActions) {
    switch (action.type) {
        case DashBoardActionTypes.SetDBPersonalInfoData: {
            return { ...state, dashBoardPersonalInfoData: action.payload };
        }
        default: {
            return state;
        }
    }
}
export function DashboardBestCareersReducer(state: dashboardBestCareersState, action: DashboardActions) {
    switch (action.type) {
        case DashBoardActionTypes.SetDBBestCareersData: {
            return { ...state, dashBoardBestCareersData: action.payload };
        }
        default: {
            return state;
        }
    }
}
export function AccountSettingPageTextReducer(state: accountSettingPageTextState, action: AccountSetttingActions) {
    switch (action.type) {
        case AccountSettingActionTypes.SetAccountSettingPageTextData: {
            return { ...state, accountSettingPageTextData: action.payload };
        }
        default: {
            return state;
        }
    }
}
export function AccountSettingImageReducer(state: accountSettingImageState, action: AccountSetttingActions) {
    switch (action.type) {
        case AccountSettingActionTypes.SetAccountSettingImageData: {
            return { ...state, accountSettingImageData: action.payload };
        }
        default: {
            return state;
        }
    }
}
export function PortFolioSettingsReducer(state: portFolioSettingsState, action: AccountSetttingActions) {
    switch (action.type) {
        case AccountSettingActionTypes.SetPortfolioSettingsData: {

            return { ...state, portFolioSettingsData: action.payload };
        }
        default: {
            return state;
        }
    }
}
const getdashboardAllList = createFeatureSelector<DashBoardAllListState>('dashBoardData');
export const dashboardAllListSelector = createSelector(
    getdashboardAllList,
    state => state
);
const getdashboardAssessmentResultList = createFeatureSelector<AssessmentResultState>('dashBoardAsmntData');
export const dashboardAssessmentResultListSelector = createSelector(
    getdashboardAssessmentResultList,
    state => state
);
const getdashboardAssessmentPageText = createFeatureSelector<AssessmentPageTextState>('dashBoardAsmntPageTextData');
export const dashboardAssessmentPageTextSelector = createSelector(
    getdashboardAssessmentPageText,
    state => state
);
const getdashboardPesonalInfo = createFeatureSelector<dashboardPersonalInfoState>('dashBoardPersonalInfoData');
export const dashboardPersonalInfoSelector = createSelector(
    getdashboardPesonalInfo,
    state => state
);
const getAccountsettingPagetext = createFeatureSelector<accountSettingPageTextState>('accountSettingPageTextData');
export const accountSettingPagetextSelector = createSelector(
    getAccountsettingPagetext,
    state => state
);
const getAccountsettingImage = createFeatureSelector<accountSettingImageState>('accountSettingImageData');
export const getAccountsettingImageSelector = createSelector(
    getAccountsettingImage,
    state => state
);
const getPortfolioSettings = createFeatureSelector<portFolioSettingsState>('portFolioSettingsData');
export const portFolioSettingsSelector = createSelector(
    getPortfolioSettings,
    state => state
);
const getdashboardBestCareer = createFeatureSelector<dashboardBestCareersState>('dashBoardBestCareersData');
export const dashboardBestCareerSelector = createSelector(
    getdashboardBestCareer,
    state => state
);
