import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'customText' })
export class TextIteratorPipeJr implements PipeTransform {
  transform(text: string): string {
    if (text.includes('http') || text.includes('mailto')) {
      text = this.constructText(text);
    }
    return text;
  }

  constructText(hreftext) {
    let textaray = [];
    let finalString = '';
    try {
      const hypertxt1 = [] = hreftext.split('[');
      const hypertxt2 = [] = hypertxt1[1].split(']');
      let text1;
      let hypertxt3;
      text1 = hypertxt1[0] + hypertxt2[1];
      if (hypertxt1[2]) {
        hypertxt3 = [] =  hypertxt1[2].split( ']');
         text1 = hypertxt1[0] + hypertxt2[1] + hypertxt3[1];
      }
      textaray = text1.split('(');
      const sendarry = [] = textaray[1].split(')');
      let sendarry2;
      if (textaray[2]) {
        sendarry2 = [] = textaray[2].split(')');
      }
      // console.log('outside if hreftext, --->',hreftext);
      if (sendarry[0].includes('http://') || sendarry[0].includes('https://') || sendarry[0].includes('mailto')) {

        finalString = textaray[0] + ' <a class="anchorclass" target=:"_blank" href="' + sendarry[0] + '">' +
          hypertxt2[0] + '</a>' + sendarry[1];
        // console.log('if finalString--->'+finalString);
      }
      if (textaray[2] && (sendarry2[0].includes('http://') || sendarry2[0].includes('https://') || sendarry2[0].includes('mailto'))) {

        finalString = finalString + ' <a class="anchorclass" target="_blank" href="' + sendarry2[0] + '">' +
          hypertxt3[0] + '</a>' + sendarry2[1];
        // console.log('else if finalString--->'+finalString);
      }
      if (textaray[2] && !(sendarry2[0].includes('http://') || sendarry2[0].includes('https://') || sendarry2[0].includes('mailto'))) {
        finalString = finalString + ' (' + sendarry2[0] + ') ' + sendarry2[1];
      }
    } catch (e) {
      console.log('text custom pipe exception---->' + e.message);
    }
    return finalString;

  }

}
@Pipe({ name: 'PrintWeblinkPipe' })
export class PrintWeblinkPipeJr implements PipeTransform {
  transform(text: string): string {
    if (text.includes('http') || text.includes('mailto')) {
      text = this.constructText(text);
    }
    return text;
  }

  constructText(hreftext) {
    let finalString = '';
    if (hreftext.includes('http://') ||
      hreftext.includes('https://') ||
      hreftext.includes('mailto')) {
      finalString = hreftext.split('[')[0].replace(':', '');
      for (let i = 0; i < hreftext.split('[').length; i++) {
        if (i != 0) {
          if (hreftext.split('[')[i].includes('http://') ||
            hreftext.split('[')[i].includes('https://') ||
            hreftext.split('[')[i].includes('mailto')) {
            for (let j = 0; j < hreftext.split('[')[i].split(']').length; j++) {
              if (j != 0 && (hreftext.split('[')[i].split(']')[j].includes('http://') ||
                hreftext.split('[')[i].split(']')[j].includes('https://')
              )) {
                hreftext.split('[')[i].split(']')[j].split('(').map((obj, inx) => {
                  if (inx != 0) {
                    if (obj.includes('https://') || obj.includes('http://')) {
                      finalString += ':' + ' <a class=\'anchorclass\' target=\'_blank\' href=\'' + obj.split(')')[0] + '\'>' + obj.split(')')[0] + '</a>' + obj.split(')')[1];
                    } else {
                      finalString += '(' + obj.split(')').join(')');
                    }
                  } else {
                    finalString += obj;
                  }
                });
              } else if (hreftext.split('[')[i].split(']')[j].includes('mailto')) {
                finalString += ':' + ' <a class=\'anchorclass\' target=\'_blank\' href=\'' + hreftext.split('[')[i].split(']')[j].split('(')[1].split(')').join(' ') + '\'>' + hreftext.split('[')[i].split(']')[j].split('(mailto:')[1].split(')').join(' ') + '</a>';
              } else if (!(hreftext.split('[')[i].split(']')[j].includes('http://') ||
                hreftext.split('[')[i].split(']')[j].includes('https://'))) {
                finalString += hreftext.split('[')[i].split(']')[j];
              }
            }
          }
        }
      }
    } else {
      finalString = hreftext;
    }
    // console.log('finalsting', finalString.trim());
    return finalString.trim();
  }
}
@Pipe({name: 'constructText'})
export class ConstructText implements PipeTransform {
  transform(text: string): string {
    const extractString = [] = text.split(']');
    const finalString = extractString[0].slice(1);
    return finalString;
}
}

@Pipe({ name: 'PrintCustomlinkPipe' })
export class PrintCustomlinkPipe implements PipeTransform {
  transform(text: string): string {
    if (text.includes('http') || text.includes('mailto')) {
      text = this.constructText(text);
    }
    return text;
  }

  constructText(hreftext) {
    let finalString = '';
    if (hreftext.includes('http://') ||
      hreftext.includes('https://') ||
      hreftext.includes('mailto')) {
      finalString = hreftext.split('[')[0].replace(':', '');
      for (let i = 0; i < hreftext.split('[').length; i++) {
        if (i != 0) {
          if (hreftext.split('[')[i].includes('http://') ||
            hreftext.split('[')[i].includes('https://') ||
            hreftext.split('[')[i].includes('mailto')) {
            for (let j = 0; j < hreftext.split('[')[i].split(']').length; j++) {
              if (j != 0 && (hreftext.split('[')[i].split(']')[j].includes('http://') ||
                hreftext.split('[')[i].split(']')[j].includes('https://')
              )) {
                hreftext.split('[')[i].split(']')[j].split('(').map((obj, inx) => {
                  if (inx != 0) {
                    if (obj.includes('https://') || obj.includes('http://')) {
                      finalString += ' (' + ' <a class=\'anchorclass\' target=\'_blank\' href=\'' + obj.split(')')[0] + '\'>' + obj.split(')')[0] + '</a>' + obj.split(')')[1] + ')' ;
                    } else {
                      finalString += '(' + obj.split(')').join(')');
                    }
                  } else {
                    finalString += obj;
                  }
                });
              } else if (hreftext.split('[')[i].split(']')[j].includes('mailto')) {
                finalString += ':' + ' <a class=\'anchorclass\' target=\'_blank\' href=\'' + hreftext.split('[')[i].split(']')[j].split('(')[1].split(')').join(' ') + '\'>' + hreftext.split('[')[i].split(']')[j].split('(mailto:')[1].split(')').join(' ') + '</a>';
              } else if (!(hreftext.split('[')[i].split(']')[j].includes('http://') ||
                hreftext.split('[')[i].split(']')[j].includes('https://'))) {
                finalString += hreftext.split('[')[i].split(']')[j];
              }
            }
          }
        }
      }
    } else {
      finalString = hreftext;
    }
    // console.log('finalsting', finalString.trim());
    return finalString.trim();
  }
}
