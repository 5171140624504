
/** Angular imports */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonNgbdModalContent } from './common-modal';
import { CommonExtendTime } from './extend-time.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Custom imports

@NgModule({
    imports: [CommonModule, NgbModule],
    declarations: [CommonNgbdModalContent],
    exports: []
})
export class CommonUtilitiesModule {
  static forRoot(): ModuleWithProviders<CommonUtilitiesModule> {
    return {
      ngModule: CommonUtilitiesModule,
      providers: [CommonExtendTime],
    };
  }
}
