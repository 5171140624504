/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

/**Services **/
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import {
  CareersJr_EmpOutlook_Wages_State, CareersJr_Settings_Tab_Text_State,
  CareersJr_Buttons_Reducer_Text_State
} from '../../careersJr-state-management/careersJr-state/careersJr-state';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'at-a-glance-emerging-careersJr',
  templateUrl: './at-a-glance-emerging-careersJr.layout.html',
})
export class AtAGlanceEmergingCareersJrComponent implements OnInit, OnDestroy {
  CareerJrCareerStore; /** Is a variable that is used to store data coming from reducer */
  occSettingsSub = new Subscription();
  showClusterBox = true; // Checks whether to show cluster box or not
  occPageSub = new Subscription();
  eduIcon = [
    'icon-education-level-1',
    'icon-education-level-2',
    'icon-education-level-3',
    'icon-education-level-4',
    'icon-education-level-5',
  ];
  settingsText; /** Is a variable that is used to store data coming from reducer */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  occName;
  atAGlancePrintSub: Subscription;
  printHidden = false;
  dataView = false;

  // printpage= true;
  @ViewChild('atAGlancePrintPage', { static: true })
  atAGlancePrintPage: ElementRef;

  @Input('mainlogo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  public doughnutChartLabels = ['Completed', 'Started', 'Incomplete'];
  public doughnutChartData: number[] = [8, 52, 22, 8];
  public doughnutChartType = 'doughnut';
  optionsChart = {
    tooltips: { enabled: false },
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    // maintainAspectRatio: false
    // aspectRatio: 2.5
  };
  public doughnutDataset: Array<any> = [
    {
      // grey
      backgroundColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      borderColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];
  onLoadFirst = true;
  eventSub: Subscription;
  copyright: any;
  moreDataDailog: boolean;
  occId: any;
  constructor(
    private router: Router,
    private careerJrSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private occDispatch: CareersJrDispatchService,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private utils: Utilities,
    private storageService: StorageService,
    private careerJrstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private CareerJrPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    this.atAGlancePrintSub = this.occDispatch
      .careerAtglanceListen()
      .subscribe(() => {
        this.printHidden = true;
      });
    this.eventSub = occDispatch.listen().subscribe((e) => {
      if (e.type === 'emergingEventPrint0') {
        this.careerPrint();
      }
    });
    // Get data from reducer to display buttons text
    this.occCareerText = this.careerJrstore.select(
      'CareersJr_Buttons_Reducer_Text'
    );
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = this.careerJrSetstore
      .select('CareersJr_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 0) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
          this.onLoadFirst = false;
        }
      });
    // Get data from reducer to display in

    this.occPageSub = this.CareerJrPageStateStore.select(
      'CareersJr_EmpOutlook_Wages'
    ).subscribe((v) => {
      this.CareerJrCareerStore = v;
      //console.log("this.CareerJrCareerStore ",this.CareerJrCareerStore);

      if (this.CareerJrCareerStore.TopPreparation) {
        this.moreDataDailog = this.CareerJrCareerStore.TopPreparation.filter(
          (f) => f.type === 'ul'
        ).some((s) => s.text[0] === '[More]');
      }

      // Setting up the text alignment in the box.// at a glance.
      if (this.CareerJrCareerStore.TopPreparation) {
        this.CareerJrCareerStore.TopPreparation.forEach((element) => {
          if (
            element.type == 'ul' &&
            JSON.stringify(element.text) !== '["[More]"]'
          ) {
            const textLength = JSON.stringify(element.text);
            if (textLength.length > 250) {
              //element.text.splice(2,element.text.length-2);
              this.dataView = true;
            } else {
              this.dataView = false;
            }
          }
        });
      }

      //console.log("after",this.CareerJrCareerStore);

      if (
        this.CareerJrCareerStore.HotJob !== undefined &&
        this.CareerJrCareerStore.STEM !== undefined
      ) {
        if (
          this.CareerJrCareerStore.HotJob.rating ||
          this.CareerJrCareerStore.STEM.rating
        ) {
          this.showClusterBox = false;
        } else {
          this.showClusterBox = true;
        }
      }
    });
  }
  ngOnInit() {
    this.copyright = this.frameWorkObj.Result.headerFooter.copyright;
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJr_Reducer_OccText'));
    if (
      val &&
      val.careersJr_Buttons_Reducer_Text &&
      val.careersJr_Buttons_Reducer_Text.common != undefined
    ) {
      this.occCareerText = val;
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
      this.occId = params['occid'];
    });
  }
  printchange(event) {
    this.printHidden = false;
  }
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.atAGlancePrintSub.unsubscribe();
    this.eventSub.unsubscribe();
  }
  //When user click on card respective events are emmited
  callPage(page) {
    if (page == 'wage') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('callWagePage', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'outlook') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('callOutlookPage', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'edu') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('calleduPage', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'job_Setting') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('job_Setting', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'job_Task') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('job_Task', true, true);
      this.occDispatch.dispatch(evnt);
    }
  }
  //Redirect to cluster page
  workplaceRedirect(id, name, icon, color) {
    this.router.navigate(['../careersJrClusterHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clusId: id,
        clusName: name,
        clusIcon: icon,
        clusColor: color,
      },
    });
  }
  async careerPrint() {
    const element = this.atAGlancePrintPage.nativeElement;
    const opt = {
      filename: 'Emerging Career Jr - At a Glance.pdf',
      margin: [3, 0, 0, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
    // this.printHidden.emit(false);
  }
}
