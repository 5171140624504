import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { WidgetDynamicComponent } from './modules/framework/layouts/widget/widget.component';
import { Subscription } from 'rxjs';
import { Utilities } from './shared/outer-services/utilities.service';
import { routeObj } from './shared/constants/app.constants';

import { FrameworkConfigState } from './state-management/state/main-state';
import { TilesDynamicComponent } from './modules/framework/layouts/tiles/tiles.component';
import { ListDynamicComponent } from './modules/framework/layouts/list.component';
import { PlansDynamicComponent } from './modules/framework/layouts/plans/personalized_plans';
@Component({
    selector: 'layout-config-component',
    template: `
              <div id='wrapper-body'>
                <div id='main-wrapper-body'>
                    <div id='main-body' tabindex='-1'>
                        <dynamic-layout-tag [componentTypes]='componentType' [FrameworkObj]='frameworkObj'>
                        </dynamic-layout-tag>

                        <!--<static-footer class='footerheightlength' ></static-footer>-->
                    </div>
                </div>
            </div>
            `
})
export class LayoutConfigComponent implements OnInit, OnDestroy {
    componentType = WidgetDynamicComponent;

    moduleName = '';
    frameworkObj;

    subscription = new Subscription;
    frameworkConfig: any;
    constructor(private Frameworkstore: Store<FrameworkConfigState>, private utils: Utilities,
        private actRoute: ActivatedRoute, private router: Router) {
        const path = (window.location.pathname).split('/');
        this.moduleName = path[2];
    }

    ngOnInit() {
        let frame: any;
        const test = {};
        // this.utils.showLoading();
        try {
            this.subscription = this.Frameworkstore.select('config').subscribe((frameworkResult) => {
                // console.log('layout Frameworkstore: '+ JSON.stringify(frameworkResult));
                this.frameworkConfig = frameworkResult;
                frame = this.getFrameworkComponent(this.moduleName);
                this.componentType = frame.component;
                const path = (window.location.pathname).split('/');
                const moduleName = path[2];
                const framework = frameworkResult['config'].Result.tabItems.find((obj, inx) => {
                    return (moduleName == obj.tabId);
                });
                try {
                    const list = [], result = {};
                    if (framework + '' != 'undefined') {

                        // console.log('moduleName-->' + moduleName);
                        framework.compList.forEach(function (obj, inx) {
                            if (routeObj[obj.compId] !== undefined) {
                                const result1 = Object.assign({}, routeObj[obj.compId].itemConfig, obj);
                                framework.compList[inx] = result1;
                            } else {
                                framework.compList.splice(inx, 1);
                                // console.log('framework---',obj.compId);
                            }
                        }.bind(this));
                        // console.log('framework---->' + JSON.stringify(framework.compList));
                        framework['menuHighlightStatus'] = false;
                        // if (framework.tabId + '' == '0') {
                        //     framework.image = '../assets/images/assessments-landing.jpg';

                        // } else if (framework.tabId + '' == '1') {
                        //     framework.image = '../assets/images/career-landing.jpg';
                        // } else {
                        //     framework.image = '../assets/images/education-landing.jpg';
                        // }

                        this.frameworkObj = framework;

                    }
                } catch (e) {
                    console.log('assessment entry exception:' + e.message);
                }
            });
        } catch (e) {
            console.log('assessment entry exception:' + e.message);
        }

    }
    // In this function checking the conditions for widget,tiles and dashboard layouts
    getFrameworkComponent(moduleName) {
        let frame;
        this.frameworkConfig.config.Result.tabItems.forEach(function (obj, inx) {
            if (obj.tabId + '' === moduleName + '') {
                frame = obj;
                if (obj.layout === 'widget') {
                    // frame['component'] = WidgetDynamicComponent;
                } else if (obj.layout === 'tiles') {
                    // frame['component'] = TilesDynamicComponent;
                } else if (obj.layout === 'dashboard') {
                    // frame['component'] = ListDynamicComponent;
                } else if (obj.layout === 'plan') {
                    // frame['component'] = PlansDynamicComponent;
                }
            }
        });
        return frame;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
