import {
  Injectable,
  EventEmitter,
  Output,
  Pipe,
  Directive,
} from '@angular/core';

// @Directive()
@Pipe({ name: 'AudioService' })
@Injectable()
export class AudioService {
  @Output('closeEvent') closeEvent = new EventEmitter();

  close() {
    this.closeEvent.emit();
  }
}
