import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {  OCCPageState, OccSettingsTextState } from '../../../../../state-management/state/main-state';
import { Occ_Settings_Tab_Text_State, Occ_EmpOutlook_Wages_State } from '../../occ-state-management/occ-state/occ-state';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import html2pdf from 'html2pdf.js';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'right-for-me',
  templateUrl: './right-for-me-layout.html',
})

export class RightForMeComponent implements OnInit, OnDestroy {
  occClusterStore; /**Declare for storing the cluster text from store.*/
  settingsText; /**Declare for storing the section names from store.*/
  settingsTextTab; /**Declare for storing the tab values from store.*/
  printview = false;
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  compActive = true;
  @ViewChild('clusRightForMe', {static: true}) clusRightForMe: ElementRef;

  @Input() rightForMeData: any = {};
  @Input('logo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  constructor(private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occDispatch: OccDispatchService,
    private utils: Utilities, private activatedRoute: ActivatedRoute) {

      this.OCCPageStateStore.pipe(select('Occ_EmpOutlook_Wages'), takeWhile(() => this.compActive)).subscribe((resp) => {
        this.occClusterStore = resp;
      });
      this.occSetstore.pipe(select('Occ_Settings_Tab_Text'), takeWhile(() => this.compActive)).subscribe((v) => {
        this.settingsText = v;

       if (this.settingsText.Occ_Settings_Tab_Text.tabs) {
         this.settingsTextTab = this.settingsText.Occ_Settings_Tab_Text.tabs.find((obj) => (obj['tabId'] === 1));
       }
     });
    this.occDispatch.clusterRighforMeListen().pipe(takeWhile(() => this.compActive)).subscribe(() => {
        this.utils.showLoading();
        this.clusterPrint();
    });
  }
  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
      /**
       * Defaults to 0 if no query param provided.
       * */
      this.clusterIcon = params['clusIcon'];
    });
  }
   //  This function is for displaying the pop-up when we ckick on print button /
   async clusterPrint() {
    const data = this.clusRightForMe.nativeElement;
    const opt = {
      filename: 'Career Cluster - Things to Know.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      margin : [0.1, 0, 0.2, 0],
      jsPDF: { unit: 'mm', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }
  /**
    * This ngOnDestroy() function is call after Component destory.
    */
  ngOnDestroy() {
    this.compActive = false;
  }
}
