import {
  defaultCareersJr_EmpOutlook_Wages_State,
  defaultCareersJr_Buttons_Reducer_Text_State,
  defaultOcc_Jr_Assessment_Filter_State,
  defaultCareersJr_Detail_AtGlance_State,
  defaultCareersJr_Detail_ThingsToKnow_State,
  defaultCareersJr_Detail_Employment_State,
  defaultCareersJr_Detail_Education_State,
  defaultCareersJr_Detail_RelatedCareers_State,
} from '../careersJr-state/careersJr-state';
import { defaultCareersJr_Settings_Tab_Text_State } from '../careersJr-state/careersJr-state';
import { defaultCareersJr_Index_Reducer_Text_State } from '../careersJr-state/careersJr-state';
import { Action } from '../careersJr-action/careersJr-action';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

export function CareersJr_EmpOutlook_Wages_Reducer(
  state = defaultCareersJr_EmpOutlook_Wages_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_SECTIONS_TEXT': {
      state = action.payload;
      return state;
    }
    case 'SET_CAREERSJR_EMP_OUTLOOK': {
      state['MajorEmployers'] = action.payload.MajorEmployers;
      state['OutlookInfo'] = action.payload.OutlookInfo;
      state['OutlookRatings'] = action.payload.OutlookRatings;
      return state;
    }
    case 'SET_CAREERSJR_WAGES': {
      state['WageLevels'] = action.payload.WageLevels;
      return state;
    }
    case 'RESET_OCC_PAGE': {
      state = { CareersJr_EmpOutlook_Wages: '' };
    }
    default: {
      return state;
    }
  }
}
export function CareersJr_Settings_Tab_Text_Reducer(
  state = defaultCareersJr_Settings_Tab_Text_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERSJR_SETTING_TABS_TEXT': {
      state = { CareersJr_Settings_Tab_Text: action.payload };
      return state;
    }
    case 'RESET_CAREERSJR_SETTING_TABS_TEXT': {
      state = { CareersJr_Settings_Tab_Text: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
export function CareersJr_Index_Reducer_Text_Reducer(
  state = defaultCareersJr_Index_Reducer_Text_State,
  action: Action
) {
  try {
    const checknull = StorageService.staticSessionStorageGet(
      'CareersJrIndexReducerText'
    );
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('CareersJr_Index_Reducer_Text reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_CAREERJR_INDEX_STORE_TEXT': {
      state = { CareersJr_Index_Reducer_Text: action.payload };
      StorageService.staticSessionStorageSet(
        'CareersJrIndexReducerText',
        JSON.stringify(state)
      );
      return state;
    }
    default: {
      return state;
    }
  }
}
export function CareersJr_Buttons_Reducer_Text(
  state = defaultCareersJr_Buttons_Reducer_Text_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERJR_BUTTONS_TEXT': {
      state = { careersJr_Buttons_Reducer_Text: action.payload };
      StorageService.staticSessionStorageSet(
        'CareersJr_Reducer_OccText',
        JSON.stringify(state)
      );
      return state;
    }
    default: {
      return state;
    }
  }
}
export function Occ_Jr_Assessment_Filter_Reducer(
  state = defaultOcc_Jr_Assessment_Filter_State,
  action: Action
) {
  try {
    const checknull = StorageService.staticSessionStorageGet(
      'OccJrAssessmentReducerText'
    );
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('Occ_Jr_Assessment_Filter_Reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_OCC_JR_ASSESSMENT_FILTER_TEXT': {
      state = { Occ_Jr_Assessment_Filter_Text: action.payload };
      StorageService.staticSessionStorageSet(
        'OccJrAssessmentReducerText',
        JSON.stringify(state)
      );
      return state;
    }
    default: {
      return state;
    }
  }
}
//CareersJr At glance tab
export function CareersJr_AtGlance_Reducer(
  state = defaultCareersJr_Detail_AtGlance_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERJR_DETAIL_ATGLANCE': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREERJR_DETAIL_ATGLANCE': {
      state = { CareersJr_Detail_AtGlance: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//thing to know reducer
export function CareersJr_ThingsToknow_Reducer(
  state = defaultCareersJr_Detail_ThingsToKnow_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERJR_DETAIL_THINGSTOKNOW': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREERJR_DETAIL_THINGSTOKNOW': {
      state = { CareersJr_Detail_ThingsToKnow: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Employment
export function CareersJr_Employment_Reducer(
  state = defaultCareersJr_Detail_Employment_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERJR_DETAIL_EMPLOYMENT': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREERJR_DETAIL_EMPLOYMENT': {
      state = { CareersJr_Detail_Employment: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Education
export function CareersJr_Education_Reducer(
  state = defaultCareersJr_Detail_Education_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERJR_DETAIL_EDUCATION': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREERJR_DETAIL_EDUCATION': {
      state = { CareersJr_Detail_Education: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Related Careers
export function CareersJr_RelatedCareers_Reducer(
  state = defaultCareersJr_Detail_RelatedCareers_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERJR_DETAIL_RELATEDCAREERS': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREERJR_DETAIL_RELATEDCAREERS': {
      state = { CareersJr_Detail_RelatedCareers: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
