import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareersJrIndexComponent } from './careersJr-shared/careersJr-index/careersJr-index-component';
import { CareersJrCompareComponent } from './careersJr-compare/careersJr-compare.component';
import { CareersJrHeaderComponent } from './careersJr-shared/careersJr-common-header/careersJr-header-component';
import { CareersJrEmergHeaderComponent } from './careersJr-shared/careersJr-common-header/emergCareersJr-header-component';
import { CareersJrClusterHeaderComponent } from './careersJr-shared/careersJr-common-header/careersJr-cluster-header-component';

export const routes: Routes = [
    { path: 'careerJrIndex', component: CareersJrIndexComponent },
    { path: 'careerJrcompare', component: CareersJrCompareComponent },
    { path: 'careersJrHeader', component: CareersJrHeaderComponent },
    { path: 'careersJrEmergHeader', component: CareersJrEmergHeaderComponent },
    { path: 'careersJrClusterHeader', component: CareersJrClusterHeaderComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class CareersJrDetailsRoutingModule { }
