/**Angular Libraries **/
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  Input,
  Inject,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

/**Services **/
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import {
  Occ_EmpOutlook_Wages_State,
  Occ_Settings_Tab_Text_State,
  Occ_Buttons_Reducer_Text_State,
} from '../../occ-state-management/occ-state/occ-state';
import html2pdf from 'html2pdf.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'at-a-glance',
  templateUrl: './at-a-glance-layout.html',
})
export class AtAGlanceComponent implements OnInit, OnDestroy {
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  occSettingsSub = new Subscription();
  showClusterBox = true; // Checks whether to show cluster box or not
  occPageSub = new Subscription();
  eduIcon = [
    'icon-education-level-1',
    'icon-education-level-2',
    'icon-education-level-3',
    'icon-education-level-4',
    'icon-education-level-5',
  ];
  settingsText; /** Is a variable that is used to store data coming from reducer */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  occName;
  atAGlancePrintSub: Subscription;
  printHidden = false;

  // printpage= true;
  @ViewChild('atAGlancePrintPage', { static: true })
  atAGlancePrintPage: ElementRef;

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  public doughnutChartLabels = ['Completed', 'Started', 'Incomplete'];
  public doughnutChartData: number[] = [8, 52, 22, 8];
  public doughnutChartType = 'doughnut';
  optionsChart = {
    tooltips: { enabled: false },
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    // maintainAspectRatio: false
    // aspectRatio: 2.5
  };
  public doughnutDataset: Array<any> = [
    {
      // grey
      backgroundColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      borderColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];
  onLoadFirst = true;
  classIndexVal = 3;
  constructor(
    private router: Router,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private occDispatch: OccDispatchService,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private occstore: Store<Occ_Buttons_Reducer_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    private titleService: Title,
    private storageService: StorageService
  ) {
    this.atAGlancePrintSub = this.occDispatch
      .careerAtglanceListen()
      .subscribe(() => {
        this.printHidden = true;
      });

    // Get data from reducer to display buttons text
    this.occCareerText = this.occstore.select('Occ_Buttons_Reducer_Text');
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = this.occSetstore
      .select('Occ_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;

        if (
          this.settingsText.Occ_Settings_Tab_Text.tabs != null &&
          this.settingsText.Occ_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 0) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
          this.onLoadFirst = false;
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = this.OCCPageStateStore.select(
      'Career_Details_At_Glance'
    ).subscribe((v) => {
      this.occCareerStore = v;
      if (
        this.occCareerStore.HotJob !== undefined &&
        this.occCareerStore.STEM !== undefined &&
        this.occCareerStore.SelfEmployment !== undefined
      ) {
        if (
          this.occCareerStore.HotJob.rating ||
          this.occCareerStore.STEM.rating ||
          this.occCareerStore.SelfEmployment.rating
        ) {
          this.showClusterBox = false;
        } else {
          this.showClusterBox = true;
        }
        if (
          this.occCareerStore.HotJob.rating &&
          this.occCareerStore.STEM.rating &&
          this.occCareerStore.SelfEmployment.rating
        ) {
          this.classIndexVal = 2;
        } else if (
          (this.occCareerStore.HotJob.rating &&
            this.occCareerStore.STEM.rating) ||
          (this.occCareerStore.HotJob.rating &&
            this.occCareerStore.SelfEmployment.rating) ||
          (this.occCareerStore.STEM.rating &&
            this.occCareerStore.SelfEmployment.rating)
        ) {
          this.classIndexVal = 1;
        } else {
          this.classIndexVal = 3;
        }
      }
    });
  }
  ngOnInit() {
    const val = JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'));
    if (
      val &&
      val.Occ_Buttons_Reducer_Text &&
      val.Occ_Buttons_Reducer_Text.common != undefined
    ) {
      this.occCareerText = val;
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
    });
    this.setDocTitle();
  }
  printchange(event) {
    this.printHidden = false;
  }
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.atAGlancePrintSub.unsubscribe();
  }
  //When user click on card respective events are emmited
  callPage(page: string) {
    if (page == 'wage') {
      const evt = new Event('callWagePage', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'outlook') {
      const evt = new Event('callOutlookPage', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'edu') {
      const evt = new Event('calleduPage', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'job_Setting') {
      const evt = new Event('job_Setting', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'job_Task') {
      const evt = new Event('job_Task', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    }
  }
  //Redirect to cluster page
  workplaceRedirect(id, name, icon, color) {
    this.router.navigate(['../occCluster'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clusId: id,
        clusName: name,
        clusIcon: icon,
        clusColor: color,
      },
    });
  }
  careerRedirect() {
    this.router.navigate(['../occIndex'], {
      relativeTo: this.activatedRoute,
    });
  }
  public setDocTitle() {
    this.titleService.setTitle('Career Details - CIS360 Careers');
  }
}
