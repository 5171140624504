import { ActivatedRoute, Params, Router } from '@angular/router';
// import {  OCCPageState, OccSettingsTextState } from '../../../../../state-management/state/main-state';
import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Occ_Settings_Tab_Text_State, Occ_EmpOutlook_Wages_State } from '../../occ-state-management/occ-state/occ-state';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import html2pdf from 'html2pdf.js';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'education',
  templateUrl: './education-layout.html'
})

export class EducationComponent implements OnInit, OnDestroy {
  occClusterStore; /**Declare for storing the cluster text from store.*/
  settingsText; /**Declare for storing the section names from store.*/
  settingsTextTab; /**Declare for storing the tab values from store.*/
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  clusterColor = ''; /**Declare for storing the cluster color.*/
  printview = false;
  compActive = true;
  @ViewChild('clusEducation', { static: true }) clusEducation: ElementRef;

  @Input('educationData') educationData: any = {};
  @Input('logo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private occDispatch: OccDispatchService,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private utils: Utilities) {

    this.educationData['RelatedPrograms'] = { 'lists': [] };
    this.OCCPageStateStore.pipe(select('Occ_EmpOutlook_Wages'), takeWhile(() => this.compActive)).subscribe((resp) => {
      this.occClusterStore = resp;
    });
    this.occSetstore.pipe(select('Occ_Settings_Tab_Text'), takeWhile(() => this.compActive)).subscribe((v) => {
       this.settingsText = v;

      if (this.settingsText.Occ_Settings_Tab_Text.tabs) {
        this.settingsTextTab = this.settingsText.Occ_Settings_Tab_Text.tabs.find((obj) => (obj['tabId'] === 2));
      }
    });
     this.occDispatch.clusterEducationListen().pipe(takeWhile(() => this.compActive)).subscribe(() => {
      this.utils.showLoading();
      this.clusterPrint();
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
      /**
       * Defaults to 0 if no query param provided.
       * */
      this.clusterIcon = params['clusIcon'];
    });

  }
  callProgramsCareer(id, name) {
    this.router.navigate(['../../progsdetails/progHeader'], { relativeTo: this.activatedRoute, queryParams: { progid: id, occname: name } });
    // this..showLoading();
    setTimeout(function () {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('relatedProgamDispatch', true, true);
      this.occDispatch.dispatch(evnt);
    }.bind(this), 100);
  }
  /**
   * This method is for navigating from cluster to career.
   * @param id is for storing occid.
   * @param name is for storing occcareername.
   */
  CallOccDetailCareer(id, name) {
    const twoDigit = id.toString().substr(0, 2);
    if (twoDigit == 14) {
      this.router.navigate(['../occEmergCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    } else {
      this.router.navigate(['../occCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    }
  }
  //  This function is for displaying the pop-up when we ckick on print button /
  async clusterPrint() {
    const data = this.clusEducation.nativeElement;
    const opt = {
      filename: 'Career Cluster - Education.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      margin: [0.1, 0, 0.2, 0],
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }

  /**
     * This ngOnDestroy() function is call after Component destory.
     */
  ngOnDestroy() {
    this.compActive = false;
  }
}
