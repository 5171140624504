import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { LtiLocationModel } from '../state/lti-location.state';


const getLtiLocationState = createFeatureSelector<LtiLocationModel>('componentId');

export const getltilocationselector: MemoizedSelector<LtiLocationModel, string> = createSelector(
    getLtiLocationState,
    state => {
        return state.componentId;
    }
);

