/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

/**Services **/
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJr_Settings_Tab_Text_State, CareersJr_EmpOutlook_Wages_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'things-to-know-cluster-careersJr',
  templateUrl: './things-to-know-cluster.layout.html',
})
export class ThingsToKnowClusterCareersJrComponent
  implements OnInit, OnDestroy
{
  expandCard = 0; /** A variable to tell which card to open first */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  careerJrCareerStore; /** Is a variable that is used to store data coming from reducer */
  eventSub = new Subscription();
  occSettingsSub = new Subscription();
  settingsTextTab;
  descriptionExpand = 999; //T show/hide the MoreDescription accordion cards
  occName;
  jobDescPrintSub: Subscription;
  printPage = false;
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  clusterID = ''; /**Declare for storing ClusterID.*/

  @ViewChild('atJobdescPrintPage', { static: true })
  atJobdescPrintPage: ElementRef;

  @Input('mainlogo') mainlogo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  occPageSub = new Subscription();
  compAlive = true;

  constructor(
    private router: Router,
    private careerJrDispatch: CareersJrDispatchService,
    private activatedRoute: ActivatedRoute,
    private careerJrSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private utils: Utilities,
    private storageService: StorageService,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string
  ) {
    // Subsribing an event, when job task or setting was clicked in at-a-glance
    this.jobDescPrintSub = this.careerJrDispatch
      .clusterThingsToKnowListen()
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
    this.eventSub = careerJrDispatch.listen().subscribe((e) => {
      if (e.type == 'job_Setting') {
        this.expandCard = 2;
      } else if (e.type == 'Job Description') {
        this.expandCard = 0;
      }
    });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = careerJrSetstore
      .select('CareersJr_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 1) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = this.OCCPageStateStore.select(
      'CareersJr_EmpOutlook_Wages'
    ).subscribe((v) => {
      this.careerJrCareerStore = v;
    });
  }
  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compAlive))
      .subscribe((params) => {
        /**
         * Defaults to 0 if no query param provided.
         * */
        this.clusterID = params['clusId'];
        this.clusterIcon = params['clusIcon'];
        this.occName = params['occname'];
        // this.nameTxt = 'cls_' + this.clusterID;
        this.storageService.sessionStorageSet('cciClus_ID', JSON.stringify(params['clusId']));
        this.storageService.sessionStorageSet(
          'cciClusIcon',
          JSON.stringify(params['clusIcon'])
        );
        this.storageService.sessionStorageSet(
          'cciclusColor',
          JSON.stringify(params['clusColor'])
        );
      });
  }
  async careerPrint() {
    const element = this.atJobdescPrintPage.nativeElement;
    const opt = {
      filename: 'Career Jr Cluster - Things to Know.pdf',
      image: { type: 'svg', quality: 0.98 },
      margin: [1.2, 0, 0, 0],
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'cm', format: 'A4', compress: true },
      pagebreak: {
        avoid: '.break-avoid',
        mode: ['auto', 'css', 'legacy', 'whiteline'],
      },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** Called when a card is clicked to open or close */
  methodfilter(valfil) {
    if (this.expandCard != valfil) {
      this.expandCard = valfil;
      this.descriptionExpand = valfil; //We are getting the description accordion cards in a loop. So based on the index we are showing the open accordion card and remaining all of the cards will be closed and we are assigning the variable to 999(to close).
    } else {
      this.descriptionExpand = 999;
      this.expandCard = 999;
    }
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.eventSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.jobDescPrintSub.unsubscribe();
    this.compAlive = false;
  }

  navigateCluster(val: string) {
    const occId: string = this.customSplit(val);
    //no action taken
    if (!occId || occId == '') return;

    this.router.navigate(['../careersJrHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: { occid: occId },
    });
  }

  customSplit(str: string) {
    //empty string, hyper link, mailto link
    if (
      !str ||
      str.trim().length == 0 ||
      str.includes('http') ||
      str.includes('mailto')
    )
      return '';

    //get internal link
    const internalLink: string = str.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '$2');

    //internal link format does not match '/redirect/fileCareerJr/xxxxxx/1'
    //xxxxx is occId
    if ((internalLink.match(/\//g) || []).length <= 3) return '';

    const splittedStr: Array<string> = internalLink.split(/\//);

    return splittedStr[3];
  }
}
