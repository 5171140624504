import { StorageService } from '../../shared/outer-services/storage.service';
import * as AccountTypeAllActions from '../actions/account-Type.action';
import { AccountTypeModel } from '../state/account-Type.state';
export type AccountTypeAction = AccountTypeAllActions.AccountTypeActions;


const defaultAccountType: AccountTypeModel = {
    accountType: ''
};

export function accountTypeReducer(state: AccountTypeModel = defaultAccountType, action: AccountTypeAction): AccountTypeModel {
    switch (action.type) {
        case AccountTypeAllActions.AccountTypes.SetAccountType: {
            const result = { ...state, accountType: action.payload };
            StorageService.staticSessionStorageSet('accountType', JSON.stringify(result));
            return result;
        }
        case AccountTypeAllActions.AccountTypes.AccountTypeInit: {
            const result = { ...state, ...JSON.parse(StorageService.staticSessionStorageGet('accountType')) || '{}' };
            return result;
        }
        default: {
            return state;
        }
    }
}
