import { Action } from '@ngrx/store';

export const ADD_ITEM_ID = '[Plan] ADD_ITEM_ID';
export const CHANGE_PLAN_VIEW = '[Plan] CHANGE_PLAN_VIEW';
export const CHANGE_PLAN_INDEX = '[Plan] CHANGE_PLAN_INDEX';
export enum PlanSummarayActionTypes {
    GetPlanSummaryData = '[PlanUserSummaryState] GET_PLANSUMMARY',
    SetPlanSummaryData = '[PlanUserSummaryState] SET_PLANSUMMARY',
}
export class GetPlanSummaryDataAction implements Action {
    readonly type = PlanSummarayActionTypes.GetPlanSummaryData;
}
export class SetPlanSummaryDataAction implements Action {
    readonly type = PlanSummarayActionTypes.SetPlanSummaryData;
    constructor(public payload: any) { }
}

export class AddItem implements Action {
    readonly type = ADD_ITEM_ID;
    constructor(public payload: any) { }
}
export class ChangePlanIndex implements Action {
    readonly type = CHANGE_PLAN_INDEX;
    constructor(public payload: any) { }
}
export class ChangePlanView implements Action {
    readonly type = CHANGE_PLAN_VIEW;
    constructor(public payload: any) { }
}

export type All
    = AddItem
    | ChangePlanView
    | ChangePlanIndex
    | SetPlanSummaryDataAction
    | GetPlanSummaryDataAction
    ;
