import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CareersJrCommonMethodsService } from './careersJr-services/careersJr-common-methods.service';
import { StorageService } from '../../../shared/outer-services/storage.service';
@Component({
  selector: 'occ-detail-static-header',
  template: ``,
})
export class OccDetailStaticHeaderComponent {
  menuState = false;
  userName = '';
  constructor(private router: Router, private careersJrCommonService: CareersJrCommonMethodsService) {
  }

  ngOnInit() {
    this.userName = StorageService.staticSessionStorageGet('userName');
  }
  logout() {
    this.careersJrCommonService.exitApp('loggedout');
  }

  menuToggle() {
    this.menuState = !this.menuState;
  }
  menuClose() {
    this.menuState = false;
  }
}
