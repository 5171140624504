import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AssessmentHeaderComponent } from './modules/assessments/shared/assessment-header/assessment-header.component';

import { StaticHeaderComponent } from './modules/assessments/shared/header-footer/header.component';

import { LayoutConfigComponent } from './layout-config.component';

import { ReflectionComponent } from './modules/PLP/shared/shared/reflection.component';

import { SharedModule } from './shared/shared-module';
import { GridModule } from './modules/framework/grid.module';
import { FrameworkModule } from './modules/framework/framework.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { OccDetailModule } from '../app/modules/careers-details/occ-details/occ-details-module';
import { PrintResultComponent } from './shared/common-print-results.component';
// import { ProgsDetailModule } from './modules/education-details/programs-study/progs-details-module';
// import { FinancialDetailModule } from './modules/education-details/financial-aid/financial-details-module';
import { AuthInterceptor } from './shared/outer-services/authentication-interceptors';
import { AuthInterceptorModule } from './modules/auth-interceptor/auth-interceptor.module';
import { CareersJrDetailModule } from './modules/careers-details/careersJr/careersJr-details-module';
// import { MilitaryCareerModule } from './modules/careers-details/military-career/military-career-module';

@NgModule({
  imports: [
    CommonModule,
    FrameworkModule,
    GridModule,
    SharedModule,
    // HttpClientModule,
    AuthInterceptorModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    OccDetailModule,
    CareersJrDetailModule,
    // ,MilitaryCareerModule
  ],
  declarations: [
    AssessmentHeaderComponent,
    StaticHeaderComponent,
    ReflectionComponent,
    LayoutConfigComponent,
    PrintResultComponent,
  ],
  exports: [
    AssessmentHeaderComponent,
    StaticHeaderComponent,
    CommonModule,
    SharedModule,
    GridModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ReflectionComponent,
    OccDetailModule,
    LayoutConfigComponent,
    PrintResultComponent,
  ],
  // providers: [
  //     {
  //         provide: HTTP_INTERCEPTORS,
  //         useClass: AuthInterceptor,
  //         multi: true
  //     }
  // ]
})
export class AppSharedModule {
  static forRoot(): ModuleWithProviders<AppSharedModule> {
    return {
      ngModule: AppSharedModule,
      providers: [
        //services that you want to share across modules
        // SharedService,
        // SharedService2
      ],
    };
  }
}
