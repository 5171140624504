import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit {
  @Input() id: string;
  @Input() listItems: any = [];
  @Input() index: string;
  @Input() required: boolean;

  constructor() {}

  ngOnInit() {}
}
