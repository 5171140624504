import { Injectable } from "@angular/core";
@Injectable()
export class CareersJrApiCallClass {
    moduleName: String;
    data: String;
    method: String;
    sessionID: String;
    endUrl: String;
}

