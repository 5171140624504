
import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';




import { map, switchMap, catchError } from 'rxjs/operators';
import { CareersJrApiCallClass } from '../../careersJr-constants/model';
import { CareersJrApiCallService } from '../../careersJr-services/careersJr-apicalls.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { CareersJrCommonMethodsService } from '../../careersJr-services/careersJr-common-methods.service';
export function toPayload(action: Action): any {
  return (action as any).payload;
}

@Injectable()
export class OccEffects {
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;
  constructor(
    private action$: Actions,
    private apiJson: CareersJrApiCallClass,
    private careersJrApicall: CareersJrApiCallService,
    private utils: Utilities,
    private careersJrCommonService: CareersJrCommonMethodsService,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string
  ) {
    this.accountTypeJwt =
      this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
  }

  @Effect() OccEmpOutLookEffect$ = this.action$.pipe(
    ofType('GET_CAREERSJR_EMP_OUTLOOK'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_CAREERSJR_EMP_OUTLOOK',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'outlook'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts OccEmpOutLookEffect$: ${e.message}`);
            return Observable.create();
          })
        );
      }
    })
  );
  @Effect() OccWagesEffect$ = this.action$.pipe(
    ofType('GET_CAREERSJR_WAGES'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_CAREERSJR_WAGES',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'wages'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts OccWagesEffect$: ${e.message}`);
            return Observable.create();
          })
        );
      }
    })
  );
  @Effect() OccTabsEffect$ = this.action$.pipe(
    ofType('GET_CAREERSJR_SETTING_TABS_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      /* Here we have formated the path_param_json ,because of the restricted user new endpoints doesn't have the path param,
         only they have query params*/
      let path_param_json;
      if (this.restrictedAccountType) {
        path_param_json = [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ];
      } else {
        path_param_json = [
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ];
      }

      const asess_Variable = {
        input_data: path_param_json,
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      return this.careersJrApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          this.utils.hideLoading();
          return of({
            type: 'SET_CAREERSJR_SETTING_TABS_TEXT',
            payload: this.careersJrCommonService.convertSettingsTextJson(
              result[0].Result
            ),
          });
        }),
        catchError((e) => {
          this.utils.handleError(`careersJr-effects.ts OccTabsEffect$: ${e.message}`);
          return Observable.create();
        })
      );
    })
  );
  @Effect() OccIndexEffect$ = this.action$.pipe(
    ofType('GET_CAREERJR_INDEX_STORE_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      return this.careersJrApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          this.utils.hideLoading();
          return of({
            type: 'SET_CAREERJR_INDEX_STORE_TEXT',
            payload: this.careersJrCommonService.convertOCCIndexTextJson(
              result[0].Result
            ),
          });
        }),
        catchError((e) => {
          this.utils.handleError(`careersJr-effects.ts OccIndexEffect$: ${e.message}`);
          return Observable.create();
        })
      );
      //  }
    })
  );
  @Effect() occButtonsEffect$ = this.action$.pipe(
    ofType('GET_CAREERJR_BUTTONS_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      return this.careersJrApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          return of({
            type: 'SET_CAREERJR_BUTTONS_TEXT',
            payload: this.careersJrCommonService.convertTextJson(
              result[0].Result
            ),
          });
        }),
        catchError((e) => {
          this.utils.handleError(`careersJr-effects.ts occButtonsEffect$: ${e.message}`);
          return Observable.create();
        })
      );
    })
  );
  @Effect() occSectionsEffect$ = this.action$.pipe(
    ofType('GET_SECTIONS_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_SECTIONS_TEXT',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'common'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts occSectionsEffect$: ${e.message}`);
            return Observable.create();
          })
        );
      }
    })
  );
  @Effect() OccJrAssessmentFilter$ = this.action$.pipe(
    ofType('GET_OCC_JR_ASSESSMENT_FILTER'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      return this.careersJrApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          this.utils.hideLoading();
          return of({
            type: 'SET_OCC_JR_ASSESSMENT_FILTER_TEXT',
            payload: result[0].Result,
          });
        }),
        catchError((e) => {
          this.utils.handleError(`careersJr-effects.ts OccJrAssessmentFilter$: ${e.message}`);
          return Observable.create();
        })
      );
    })
  );

  /**Effects for CareersJr details page */
  //method to generate payload parameters
  private apiPayload = (payload: any) => {
    return {
      input_data: [
        {
          param_type: 'path',
          params: payload.path_params,
        },
        {
          param_type: 'query',
          params: payload.query_params,
        },
        {
          param_type: 'body',
          params: payload.body_Params,
        },
      ],
    };
  };

  //CareersJr detail page, at glance
  @Effect() careersJrDetailAtGlance$ = this.action$.pipe(
    ofType('GET_CAREERJR_ATGLANCE'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.apiPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_CAREERJR_DETAIL_ATGLANCE',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'atGlance'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts careersJrDetailAtGlance$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  //CareersJr detail page - Things to know
  @Effect() careersJrDetailThingsToKnow$ = this.action$.pipe(
    ofType('GET_CAREERJR_THINGSTOKNOW'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.apiPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_CAREERJR_DETAIL_THINGSTOKNOW',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'thingsToKnow'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts careersJrDetailThingsToKnow$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  //CareersJr detail page - Things to know
  @Effect() careersJrDetailEmployment$ = this.action$.pipe(
    ofType('GET_CAREERJR_EMPLOYMENT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.apiPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_CAREERJR_DETAIL_EMPLOYMENT',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'employment'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts careersJrDetailEmployment$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  //CareersJr detail page - Education
  @Effect() careersJrDetailEducation$ = this.action$.pipe(
    ofType('GET_CAREERJR_EDUCATION'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.apiPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_CAREERJR_DETAIL_EDUCATION',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'education'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts careersJrDetailEducation$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  //CareersJr detail page - Education
  @Effect() careersJrDetailRelatedCareers$ = this.action$.pipe(
    ofType('GET_CAREERJR_RELATEDCAREERS'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.apiPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.careersJrApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_CAREERJR_DETAIL_RELATEDCAREERS',
              payload: this.careersJrCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'relatedCareers'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`careersJr-effects.ts careersJrDetailRelatedCareers$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );
}




