import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { OccApiCallClass } from '../../occ-constants/model';
import { OccApiCallService } from '../../occ-services/occ-apicalls.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { OccCommonMethodsService } from '../../occ-services/occ-common-methods.service';
export function toPayload(action: Action): any {
  return (action as any).payload;
}

@Injectable()
export class OccEffects {
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;
  constructor(
    private action$: Actions,
    private apiJson: OccApiCallClass,
    private occApicall: OccApiCallService,
    private utils: Utilities,
    private occCommonService: OccCommonMethodsService,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string
  ) {
    this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
  }

  private careerDetailCommonPayload(payload: any) {
    return {
      input_data: [
        {
          param_type: 'path',
          params: payload.path_params,
        },
        {
          param_type: 'query',
          params: payload.query_params,
        },
        {
          param_type: 'body',
          params: payload.body_Params,
        },
      ],
    };
  }

  @Effect() OccEmpOutLookEffect$ = this.action$.pipe(
    ofType('GET_OCC_EMP_OUTLOOK'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_OCC_EMP_OUTLOOK',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'outlook'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts OccEmpOutLookEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );
  @Effect() OccWagesEffect$ = this.action$.pipe(
    ofType('GET_OCC_WAGES'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_OCC_WAGES',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'wages'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts OccWagesEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );
  @Effect() OccTabsEffect$ = this.action$.pipe(
    ofType('GET_OCC_SETTING_TABS_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      /* Here we have formated the path_param_json ,because of the restricted user new endpoints doesn't have the path param,
         only they have query params*/
      let path_param_json;
      if (this.restrictedAccountType) {
        path_param_json = [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ];
      } else {
        path_param_json = [
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ];
      }

      const asess_Variable = {
        input_data: path_param_json,
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      return this.occApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          this.utils.hideLoading();
          return of({
            type: 'SET_OCC_SETTING_TABS_TEXT',
            payload: this.occCommonService.convertSettingsTextJson(
              result[0].Result
            ),
          });
        }),
        catchError((e) => {
          this.utils.handleError(`occ-effects.ts OccTabsEffect$: ${e.message}`);
          return new Observable();
        })
      );
    })
  );
  @Effect() OccIndexEffect$ = this.action$.pipe(
    ofType('GET_OCC_INDEX_STORE_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      return this.occApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          this.utils.hideLoading();
          return of({
            type: 'SET_OCC_INDEX_STORE_TEXT',
            payload: this.occCommonService.convertOCCIndexTextJson(
              result[0].Result
            ),
          });
        }),
        catchError((e) => {
          this.utils.handleError(`occ-effects.ts OccIndexEffect$: ${e.message}`);
          return new Observable();
        })
      );
      //  }
    })
  );
  @Effect() occButtonsEffect$ = this.action$.pipe(
    ofType('GET_OCC_BUTTONS_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      return this.occApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          return of({
            type: 'SET_OCC_BUTTONS_TEXT',
            payload: this.occCommonService.convertTextJson(result[0].Result),
          });
        }),
        catchError((e) => {
          this.utils.handleError(`occ-effects.ts occButtonsEffect$: ${e.message}`);
          return new Observable();
        })
      );
    })
  );
  @Effect() occSectionsEffect$ = this.action$.pipe(
    ofType('GET_SECTIONS_TEXT'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            return of({
              type: 'SET_SECTIONS_TEXT',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'common'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts occSectionsEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );
  @Effect() OccAssessmentFilter$ = this.action$.pipe(
    ofType('GET_OCC_ASSESSMENT_FILTER'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;
      const asess_Variable = {
        input_data: [
          {
            param_type: 'path',
            params: payload.path_params,
          },
          {
            param_type: 'query',
            params: payload.query_params,
          },
          {
            param_type: 'body',
            params: payload.body_Params,
          },
        ],
      };
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      return this.occApicall.callApi([this.apiJson]).pipe(
        switchMap((result) => {
          this.utils.hideLoading();
          return of({
            type: 'SET_OCC_ASSESSMENT_FILTER_TEXT',
            payload: result[0].Result,
          });
        }),
        catchError((e) => {
          this.utils.handleError(`occ-effects.ts OccAssessmentFilter$: ${e.message}`);
          return new Observable();
        })
      );
    })
  );

  /**new career detail effects start here
   *
   * to speed up loading time of Career detail by
   * dividing one large  api into multiple calls
   */

  @Effect() DetailAtGlanceEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_ATGLANCE'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_ATGLANCE_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'atGlance'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailAtGlanceEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  @Effect() DetailJobDescriptionEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_JOBDESCRIPTION'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_JOBDESCRIPTION_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'jobDescription'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailJobDescriptionEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  @Effect() DetailWagesEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_WAGES'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_CAREERDETAIL_WAGES_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'wages'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailWagesEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  @Effect() DetailEmploymentOutlookEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_EMPLOYMENT_OUTLOOK'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);
      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_EMPLOYMENT_OUTLOOK_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'employmentOutLook'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailEmploymentOutlookEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  @Effect() DetailEducationTrainingEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_EDUCATION_TRAINING'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_EDUCATION_TRAINING_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'educationTraining'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailEducationTrainingEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );
  @Effect() DetailPersonalQualitiesEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_PERSONAL_QUALITIES'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;
      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_PERSONAL_QUALITIES_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'personalQualities'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailPersonalQualititesEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  //related careers effect - Career Details
  @Effect() DetailRelatedCareersEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_RELATED_CAREERS'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_RELATED_CAREERS_TAB',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'relatedCareers'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailRelatedCareersEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );

  //related careers effect - Career Details
  @Effect() DetailHeaderEffect$ = this.action$.pipe(
    ofType('GET_DETAIL_HEADER'),
    map(toPayload),
    switchMap((payload) => {
      this.apiJson.method = payload.methodVal;
      this.apiJson.moduleName = payload.module_Name;

      const asess_Variable = this.careerDetailCommonPayload(payload);

      const user = JSON.stringify(asess_Variable);
      this.apiJson.endUrl = payload.endUrlVal;
      this.apiJson.data = user;

      if (payload.endUrlVal == 'pages') {
        return this.occApicall.callApi([this.apiJson]).pipe(
          switchMap((result) => {
            this.utils.hideLoading();
            return of({
              type: 'SET_CAREER_DETAIL_HEADER',
              payload: this.occCommonService.convertJson(
                result[0].Result,
                'sectionName',
                'sectionResults',
                'headers'
              ),
            });
          }),
          catchError((e) => {
            this.utils.handleError(`occ-effects.ts DetailHeaderEffect$: ${e.message}`);
            return new Observable();
          })
        );
      }
    })
  );
}
