import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PracticeTestRoutingModule } from './practice-test-routing.module';

import { HomeComponent } from './home/home.component';
import { SharedModule } from '../../shared/shared-module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    PracticeTestRoutingModule,
    SharedModule,
    RouterModule
  ],
})
export class PracticeTestModule {}
