import * as PlanActions from '../action/plan.action';
import { Plan } from '../model/plan.model';
import { PlanUserSummaryState } from '../state/plan-state';
import { PlanSummarayActionTypes } from '../action/plan.action';
import { StorageService } from '../../../../../../shared/outer-services/storage.service';

export type Action = PlanActions.All;

export function planReducer(state: Plan, action: Action) {
    switch (action.type) {
        case PlanActions.ADD_ITEM_ID: {
            StorageService.staticSessionStorageSet('itemId', action.payload);
            return { ...state, itemId: action.payload };
        }
        case PlanActions.CHANGE_PLAN_INDEX: {
            StorageService.staticSessionStorageSet('planIndex', action.payload);
            return { ...state, planIndex: action.payload };
        }
        case PlanActions.CHANGE_PLAN_VIEW: {
            StorageService.staticSessionStorageSet('IsPlanIsPlanViewMode', action.payload);
            return { ...state, IsPlanViewMode: action.payload };
        }
        default: {
            return {
                ...state,
                itemId: parseInt(StorageService.staticSessionStorageGet('itemId') || '0', 10),
                planIndex: parseInt(StorageService.staticSessionStorageGet('planIndex') || '0', 10),
                IsPlanViewMode: JSON.parse(
                    StorageService.staticSessionStorageGet('IsPlanIsPlanViewMode') &&
                        StorageService.staticSessionStorageGet('IsPlanIsPlanViewMode') === 'false'
                        ? StorageService.staticSessionStorageGet('IsPlanIsPlanViewMode') : 'true'
                )
            };
        }
    }
}
export function planSummaryReducer(state: PlanUserSummaryState, action: Action) {
    switch (action.type) {
        case PlanSummarayActionTypes.SetPlanSummaryData: {
            return { ...state, planSummary: action.payload };
        }
        default: {
            return state;
        }
    }
  }
