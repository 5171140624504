import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { AccountTypeModel } from '../state/account-Type.state';


const getaccountState = createFeatureSelector<AccountTypeModel>('accountType');

export const getAccountTypeselector: MemoizedSelector<AccountTypeModel, string> = createSelector(
    getaccountState,
    state => {
        return state.accountType;
    }
);

