/**Import angular packages */
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

/**custom imports  */
// import { OCCPageState, OccTextState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { Occ_Settings_Tab_Text_State, Occ_EmpOutlook_Wages_State, Occ_Buttons_Reducer_Text_State } from '../../occ-state-management/occ-state/occ-state';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

@Component({
  selector: 'about-this-career',
  templateUrl: './abt-this.layout.html',
})

export class AboutThisComponent implements OnInit, OnDestroy {
  printview = false;
  @Input() aboutJsonValue: any = [];
  @Input('mainlogo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  @ViewChild('emergingAbout', {static: true}) emergingAbout: ElementRef;
  aboutthiscareer; //Declare for storing the OccPageText state
  aboutText; //Declare for storing the Occ_Buttons_Reducer_Text state
  settingsTextTab; //Declare for obj value of settingsText state
  settingsText; //Declare for storing the value of Occ_Settings_Tab_Text state

  subscription = new Subscription;
  occTextSettings = new Subscription;
  constructor(private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private occstore: Store<Occ_Buttons_Reducer_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occDispatch: OccDispatchService,
    private utils: Utilities,
    private storageService: StorageService
  ) {
    this.aboutthiscareer = this.OCCPageStateStore.select('Occ_EmpOutlook_Wages');
    this.aboutText = this.occstore.select('Occ_Buttons_Reducer_Text');
    this.occTextSettings = this.occSetstore.select('Occ_Settings_Tab_Text').subscribe((v) => {
      this.settingsText = v;
      if (this.settingsText.Occ_Settings_Tab_Text.tabs != null && this.settingsText.Occ_Settings_Tab_Text.tabs != undefined) {
        const ref = this;
        this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(function (obj, inx) {
          if (obj['tabId'] == 0) {
            ref.settingsTextTab = obj;
          }
        }.bind(this));

      }
    });
    this.subscription = this.occDispatch.listen().subscribe((eventPrint) => {
      if (eventPrint.type === 'emergingEvent0') {
        this.utils.showLoading();
        this.emergingAboutPrint();
      }

    });
  }
  /**ngOnInit method called when initializing the component*/
  ngOnInit() {
    const val = JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'));
    if (val && val.Occ_Buttons_Reducer_Text && val.Occ_Buttons_Reducer_Text.common != undefined) {
      this.aboutText = val;
    }

  }
  /**ngOnDestroy method called when destroying the component */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.occTextSettings.unsubscribe();
  }
  async emergingAboutPrint() {
    const data = this.emergingAbout.nativeElement;
    const opt = {
      filename: 'Emerging Career - About this Career.pdf',
      margin: [0, 0, 0.2, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }
}
