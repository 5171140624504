/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

/**Services **/
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import {
  CareersJr_EmpOutlook_Wages_State, CareersJr_Settings_Tab_Text_State,
  CareersJr_Buttons_Reducer_Text_State
} from '../../careersJr-state-management/careersJr-state/careersJr-state';
import html2pdf from 'html2pdf.js';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'at-a-glance-cluster-careersJr',
  templateUrl: './at-a-glance-cluster-careersJr.layout.html',
})

export class AtAGlanceClusterCareersJrComponent implements OnInit, OnDestroy {
  CareerJrCareerStore; /** Is a variable that is used to store data coming from reducer */
  occSettingsSub = new Subscription;
  printSubscription = new Subscription;
  showClusterBox = true; // Checks whether to show cluster box or not
  occPageSub = new Subscription;
  SortCCI = -1;
  CCIcolor = {};
  eduIcon = ['icon-education-level-1', 'icon-education-level-2', 'icon-education-level-3', 'icon-education-level-4', 'icon-education-level-5'];
  settingsText; /** Is a variable that is used to store data coming from reducer */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  clusterID = ''; /**Declare for storing ClusterID.*/
  settingsTextTab;
  occName;
  subscription: Subscription;
  atAGlancePrintSub: Subscription;
  printHidden = false;

  // printpage= true;
  @ViewChild('atAGlancePrintPage', {static: true}) atAGlancePrintPage: ElementRef;
  @Input() atAGlance: any = [];
  @Input('mainlogo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  public doughnutChartLabels = ['Completed', 'Started', 'Incomplete'];
  public doughnutChartData: number[] = [8, 52, 22, 8];
  public doughnutChartType = 'doughnut';
  optionsChart = {
    tooltips: { enabled: false },
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    // maintainAspectRatio: false
    // aspectRatio: 2.5
  };
  public doughnutDataset: Array<any> = [
    { // grey
      backgroundColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      borderColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  onLoadFirst = true;
  eventSub: Subscription;
  copyright: any;
  moreDataDailog: boolean;
  compActive = true;
  constructor(
    private router: Router,
    private careerJrSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private occDispatch: CareersJrDispatchService,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private utils: Utilities,
    private storageService: StorageService,
    private careerJrstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private CareerJrPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string,
    @Inject('LANDING_IMAGE_URL') public LandingImages: string) {
    this.atAGlancePrintSub = this.occDispatch.careerAtglanceListen().subscribe(() => {
      this.printHidden = true;
    });
    this.eventSub = occDispatch.clusterAtGlanceListen().subscribe((e) => {
      this.utils.showLoading();
      this.careerPrint();

    });
    // Get data from reducer to display buttons text
    this.occCareerText = this.careerJrstore.select('CareersJr_Buttons_Reducer_Text');
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = this.careerJrSetstore.select('CareersJr_Settings_Tab_Text').subscribe((v) => {
      this.settingsText = v;
      if (this.settingsText.CareersJr_Settings_Tab_Text.tabs != null && this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined) {
        const ref = this;
        this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(function (obj, inx) {
          if (obj['tabId'] == 0) {
            ref.settingsTextTab = obj;
          }
        }.bind(this));
        this.onLoadFirst = false;
      }
    });
    // Get data from reducer to display in

    this.occPageSub = this.CareerJrPageStateStore.select('CareersJr_EmpOutlook_Wages').subscribe((v) => {
      this.CareerJrCareerStore = v;
      if (this.CareerJrCareerStore.CCIScore && (this.CareerJrCareerStore.CCIScore.level !== -1)) {
        if (this.CareerJrCareerStore.CCIScore.level == 0) {
          this.CCIcolor['low'] = '#b0e0e6';
          this.CCIcolor['medium'] = '#bcb3b3';
          this.CCIcolor['high'] = '#cdcdcd';
        }
        if (this.CareerJrCareerStore.CCIScore.level == 1) {
          this.CCIcolor['low'] = '#bcb3b3';
          this.CCIcolor['medium'] = '#b0e0e6';
          this.CCIcolor['high'] = '#cdcdcd';
        }
        if (this.CareerJrCareerStore.CCIScore.level == 2) {
          this.CCIcolor['low'] = '#bcb3b3';
          this.CCIcolor['medium'] = '#cdcdcd';
          this.CCIcolor['high'] = ' #b0e0e6';
        }
      }
      if (this.CareerJrCareerStore.HotJob !== undefined && this.CareerJrCareerStore.STEM !== undefined) {
        if (this.CareerJrCareerStore.HotJob.rating || this.CareerJrCareerStore.STEM.rating) {
          this.showClusterBox = false;
        } else {
          this.showClusterBox = true;
        }
      }
    });
    // this.subscription = this.Frameworkstore.select('config').subscribe((res) => {
    //   if (res) {
    //     this.footervalue = res.config.Result.headerFooter.copyright;
    //     this.logo = res.config.Result.user.logo;
    //   }
    // });
  }
  ngOnInit() {
    let id;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration')).config.Result.tabItems;
    for (let i = 0; i < vl.length; i++) {
      const list = vl[i].compList;
      for (let j = 0; j < list.length; j++) {
        id = list[j].compId;
        if (id == 'sortCCIJr') {
          this.SortCCI = i;
          break;
        } else if (id == 'sortCCIAdult') {
          this.SortCCI = i;
          break;
        }
      }
    }
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
      /**
       * Defaults to 0 if no query param provided.
       * */
      this.clusterID = params['clusId'];
      this.clusterIcon = params['clusIcon'];
      // this.nameTxt = 'cls_' + this.clusterID;
      this.storageService.sessionStorageSet('cciClus_ID', JSON.stringify(params['clusId']));
      this.storageService.sessionStorageSet('cciClusIcon', JSON.stringify(params['clusIcon']));
      this.storageService.sessionStorageSet('cciclusColor', JSON.stringify(params['clusColor']));
    });
    this.copyright = this.frameWorkObj.Result.headerFooter.copyright;
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJr_Reducer_OccText'));
    if (val && val.careersJr_Buttons_Reducer_Text && val.careersJr_Buttons_Reducer_Text.common != undefined) {
      this.occCareerText = val;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.occName = params['occname'];
    });
  }
  printchange(event) {
    this.printHidden = false;
  }
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.compActive = false;
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.printSubscription.unsubscribe();
    this.atAGlancePrintSub.unsubscribe();
  this.eventSub.unsubscribe();

  }
  goToCCI(taken) {
    let navVal, navTo;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration')).config.Result.tabItems;
    for (let i = 0; i < vl.length; i++) {
      const list = vl[i].compList;
      for (let j = 0; j < list.length; j++) {
        const id = list[j].compId;
        if (id == 'sortCCIQuick') {
          navVal = i;
          this.storageService.sessionStorageSet('activeSortId', id);
          navTo = 'cciQuick';
          break;
         }
        // else {
        //   navVal = i;
        //   this.storageService.sessionStorageSet('CCIassessment', 'CCIJr');
        //   this.storageService.sessionStorageSet('activeSortId', id);
        //   navTo = 'cciJr';
        //   break;
        // }
      }
    }

    if (taken == true) {
      this.storageService.sessionStorageSet('inTab', 'cciAssess');
      this.storageService.sessionStorageSet('fromCareers', 'true');
      this.storageService.sessionStorageSet('CCIQuickResult', JSON.stringify(this.CareerJrCareerStore.CCIScore.clusters));
      const navigate = '../../' + navTo + '/result';
      this.router.navigate([navigate], { relativeTo: this.activatedRoute });
    } else if (taken == false) {
      const navigate = '../../' + 'cciQuick' + '/intro';
      this.router.navigate([navigate], { relativeTo: this.activatedRoute });
    }
  }
  //When user click on card respective events are emmited
  callPage(page) {
    if (page == 'wage') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('callWagePage', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'outlook') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('callOutlookPage', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'edu') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('calleduPage', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'job_Setting') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('job_Setting', true, true);
      this.occDispatch.dispatch(evnt);
    } else if (page == 'job_Task') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('job_Task', true, true);
      this.occDispatch.dispatch(evnt);
    }
  }
  //Redirect to cluster page
  workplaceRedirect(id, name, icon, color) {
    this.router.navigate(['../careersJrClusterHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clusId: id, clusName: name, clusIcon: icon,
        clusColor: color
      }
    });
  }
  async careerPrint() {
    const element = this.atAGlancePrintPage.nativeElement;
    const opt = {
      filename: 'Career Jr Cluster - At a Glance.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
    // this.printHidden.emit(false);
  }
  careerJrRedirect() {
    this.router.navigate(['../careerJrIndex'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        chk: 'no',
        occclusinx: 'cluster'
      }
    });
  }
}
