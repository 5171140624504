export const pieLinksColors = {
    'Conventional': '#e57373', 'Artistic': '#01b7c4',
    'Realistic': '#ff7143', 'Investigative': '#045978',
    'Social': '#b0006c', 'Enterprising': '#68c083'
};

export const wilCardlinkColors = {
    'Independence': '#669601',
    'Working Conditions': '#ab47bc',
    'Relationships': '#96015d',
    'Support': '#0c8cbd',
    'Recognition': '#0a8f72',
    'Achievement': '#510456',
};

export const clusterDetails = {
    cls_210100: {
        clusterName: 'Agriculture, Food, and Natural Resources', clusterIconValue: 'icon-clu_agriculture',
        clusterId: '210100', clusterBgColor: '#47bf2a'
    },
    cls_210200: {
        clusterName: 'Architecture and Construction', clusterIconValue: 'icon-clu_architecture',
        clusterId: '210200', clusterBgColor: '#6c777e'
    },
    cls_210300: {
        clusterName: 'Arts, Audio/Visual Technology, and Communications', clusterIconValue: 'icon-clu_arts',
        clusterId: '210300', clusterBgColor: '#ffa100'
    },
    cls_210400: {
        clusterName: 'Business Management and Administration', clusterIconValue: 'icon-clu_business',
        clusterId: '210400', clusterBgColor: '#003eb6'
    },
    cls_210500: {
        clusterName: 'Education and Training', clusterIconValue: 'icon-clu_education_and_training',
        clusterId: '210500', clusterBgColor: '#00b7c3'
    },
    cls_210600: { clusterName: 'Finance', clusterIconValue: 'icon-clu_finance', clusterId: '210600', clusterBgColor: '#ef3f42' },
    cls_210700: {
        clusterName: 'Government and Public Administration', clusterIconValue: 'icon-clu_government',
        clusterId: '210700', clusterBgColor: '#00ad83'
    },
    cls_210800: {
        clusterName: 'Health Science',
        clusterIconValue: 'icon-clu_health_science',
        clusterId: '210800', clusterBgColor: '#af006b'
    },
    cls_210900: {
        clusterName: 'Hospitality and Tourism', clusterIconValue: 'icon-clu_hospitality_and_tourism',
        clusterId: '210900', clusterBgColor: '#e0119d'
    },
    cls_211000: {
        clusterName: 'Human Services',
        clusterIconValue: 'icon-clu_human_services', clusterId: '211000', clusterBgColor: '#88cc2a'
    },
    cls_211100: {
        clusterName: 'Information Technology', clusterIconValue: 'icon-clu_information_technology',
        clusterId: '211100', clusterBgColor: '#b4d600'
    },
    cls_211200: {
        clusterName: 'Law, Public Safety, Corrections, and Security', clusterIconValue: 'icon-clu_law',
        clusterId: '211200', clusterBgColor: '#949ca1'
    },
    cls_211300: {
        clusterName: 'Manufacturing',
        clusterIconValue: 'icon-clu_manufacturing',
        clusterId: '211300', clusterBgColor: '#7700b8'
    },
    cls_211400: { clusterName: 'Marketing', clusterIconValue: 'icon-clu_marketing', clusterId: '211400', clusterBgColor: '#af00b8' },
    cls_211500: {
        clusterName: 'Science, Technology, Engineering, and Mathematics', clusterIconValue: 'icon-clu_science_technology',
        clusterId: '211500', clusterBgColor: '#fdcc2f'
    },
    cls_211600: {
        clusterName: 'Transportation, Distribution, and Logistics', clusterIconValue: 'icon-clu_transportation_distribution_and_logistics',
        clusterId: '211600', clusterBgColor: '#ff7900'
    }
};
    export const asmntImages = {
        'CCIAdult': 'cciAdult-landing.png',

        'CCIJr': 'cci-image.png',

        'CCIQuick': 'baby_CCI.png',

        'IDEAS': 'ideas-image.png',

        'MiniIP': 'interest-profiler_mini-icon.png',

        'OccSort': 'Occupation-Sort-icon.png',

        'RealityCheck': 'rc-icon.png',

        'ShortIP': 'Interest-Profiler-icon.png',

        'WIL': 'wil-image.png',

    };
