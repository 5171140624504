/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Store, select } from '@ngrx/store';
import html2pdf from 'html2pdf.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import {
  AsmntQuestionState,
  FrameworkConfigState,
} from '../../../../../state-management/state/main-state';
import {
  pieLinksColors,
  wilCardlinkColors,
} from '../../occ-constants/cluster-icons';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
/**Services **/
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import {
  Career_Detail_Personal_Qualities_State,
  Occ_EmpOutlook_Wages_State,
  Occ_Settings_Tab_Text_State,
} from '../../occ-state-management/occ-state/occ-state';
import { takeWhile } from 'rxjs/operators';

// import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from 'constants';
@Component({
  selector: 'personal-qualities',
  templateUrl: './personal-qualities-layout.html',
})
export class PersonalQualitiesComponent implements OnInit, OnDestroy {
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  pieColor; /** Get IP pie chart colors */
  wilColors; /** Get WIL pie chart colors */
  wil_Areas_Res;
  asessmentValueAttended =
    false; /** Check whether Assessment for value is taken or not, by default false */
  occSettingsSub = new Subscription();
  occPageSub = new Subscription();
  asmntParAreaSub = new Subscription();
  occName;
  subscription: Subscription;
  personalQulPrintSub: Subscription;
  asessmentInterestAttended =
    false; /** Check whether Assessment for interest is taken or not, by default false */

  public ipdoughnutChartLabels: string[] = [];
  public ipdoughnutChartData: number[];
  public ipdoughnutChartType = 'doughnut';
  ipoptions = {
    hover: { mode: null },
    tooltips: { enabled: true },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    aspectRatio: 4.5,
  };
  public iplineChartColors: Array<any> = [];

  public wildoughnutChartLabels: string[] = [];
  public wildoughnutChartData: number[];
  public wildoughnutChartType = 'doughnut';
  wiloptions = {
    tooltips: { enabled: true },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    aspectRatio: 4.5,
  };
  public willineChartColors: Array<any> = [];

  states: Array<string> = ['US', this.storageService.sessionStorageGet('stateAbr')];
  langVal: string = this.storageService.sessionStorageGet('langset')
    ? this.storageService.sessionStorageGet('langset')
    : 'en';
  occID: string = this.storageService.sessionStorageGet('occIDval')
    ? this.storageService.sessionStorageGet('occIDval')
    : '0';
  occALive: boolean = true;
  // chart; //chart variable using instance of d3pie
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  @ViewChild('personalQulitiesPrintPage', { static: true })
  personalQulitiesPrintPage: ElementRef;

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  wilInterests: any = [];
  constructor(
    private router: Router,
    private dispatchStore: Store<Action>,
    private utils: Utilities,
    private storageService: StorageService,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private occDispatch: OccDispatchService,
    private OCCPageStateStore: Store<Career_Detail_Personal_Qualities_State>,
    private AsmntQuestionStore: Store<AsmntQuestionState>,
    private activatedRoute: ActivatedRoute,
    private Frameworkstore: Store<FrameworkConfigState>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string
  ) {
    this.pieColor = pieLinksColors;
    this.wilColors = wilCardlinkColors;
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = occSetstore
      .select('Occ_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.Occ_Settings_Tab_Text.tabs != null &&
          this.settingsText.Occ_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 5) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = OCCPageStateStore.pipe(
      select('Career_Detail_Personal_Qualities'),
      takeWhile(() => this.occALive)
    ).subscribe((v) => {
      this.occCareerStore = v;
      /** Check whether IP is taken or not, by default false */
      if (this.occCareerStore.TopInterests != undefined) {
        if (this.occCareerStore.TopInterests.taken != undefined) {
          this.asessmentInterestAttended = true;
          const topInterests = this.occCareerStore.TopInterests.interests;
          const interestname = [];
          const interestscore = [];
          const chartColors = [];
          for (let i = 0; i < topInterests.length; i++) {
            interestname.push(topInterests[i].interest);
            interestscore.push(topInterests[i].score);
            chartColors.push(this.pieColor[topInterests[i].interest]);
          }
          this.ipdoughnutChartData = interestscore;
          this.ipdoughnutChartLabels = interestname;
          this.iplineChartColors.push({
            backgroundColor: chartColors,
          });
          this.utils.hideLoading();
        }
      }

      /** Check whether WIL is taken or not, by default false */
      if (this.occCareerStore.TopValues != undefined) {
        if (this.occCareerStore.TopValues.taken != undefined) {
          this.asessmentValueAttended = true;
          const topValues = this.occCareerStore.TopValues.values;
          const valuename = [];
          const vlauescore = [];
          const valuechartColors = [];
          const valueLabels = [];
          for (let j = 0; j < topValues.length; j++) {
            valuename.push(topValues[j].value);
            vlauescore.push(topValues[j].score);
            valuechartColors.push(this.wilColors[topValues[j].value]);
            valueLabels.push(topValues[j].title);
          }
          this.wildoughnutChartData = vlauescore;
          this.wildoughnutChartLabels = valueLabels;
          this.willineChartColors.push({
            backgroundColor: valuechartColors,
          });
          this.utils.hideLoading();
        }
      }
    });
    this.personalQulPrintSub = this.occDispatch
      .careerPersonalQualityListen()
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
  }
  ngOnInit() {
    this.dispatchStore.dispatch({
      type: 'GET_PARTICULAR_AREA_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'Assessment/v1/',
        path_params: [],
        query_params: { lang: this.storageService.sessionStorageGet('langset') },
        body_Params: {},
        endUrlVal: 'wil/areas',
      },
    });
    // }
    //this.pieColor = pieLinksColors;
    // this.wilColors = wilCardlinkColors;
    this.asmntParAreaSub = this.AsmntQuestionStore.select(
      'commonIntroText'
    ).subscribe((res) => {
      this.wil_Areas_Res = res;
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
    });

    try {
      this.dispatchStore.dispatch({
        type: 'GET_DETAIL_PERSONAL_QUALITIES',
        payload: {
          methodVal: 'GET',
          module_Name: 'Occ/v1/',
          path_params: ['occ', parseInt(this.occID), 'accountID'],
          query_params: {
            sections: 'TopInterests, TopValues, Interests, Values', //this is to get all tab text
            states: this.states,
            lang: this.langVal,
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    } catch (e) {
      console.log('error------>' + e.message);
    }
  }
  async careerPrint() {
    const element = this.personalQulitiesPrintPage.nativeElement;
    const opt = {
      filename: 'Career - Personal Qualities.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      margin: [0.1, 0, 0.2, 0],
      html2canvas: { scale: 2 },
      jsPDF: { compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occALive = false;
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.asmntParAreaSub.unsubscribe();
    this.personalQulPrintSub.unsubscribe();
  }
  //Takes user back to either IP or WIL based on user selection
  backAssessment(name) {
    let navVal, navTo, id;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration'))
      .config.Result.tabItems;
    for (let i = 0; i < vl.length; i++) {
      const list = vl[i].compList;
      for (let j = 0; j < list.length; j++) {
        id = list[j].compId;
        if (id == 'sortShortIP') {
          navVal = i;
          break;
        } else if (id == 'sortWIL') {
          navVal = i;
          break;
        }
      }
    }

    if (name == 'IP' && id == 'sortShortIP') {
      this.router.navigate(['../../interestProfilerSf/intro'], {
        relativeTo: this.activatedRoute,
      });
    } else if (name == 'WIL' && id == 'sortWIL') {
      this.router.navigate(['../../wilAsessment/intro'], {
        relativeTo: this.activatedRoute,
      });
    }
  }
  // Below method executes when user click on any link of top two values
  callIpResult(assessment, interest) {
    this.storageService.sessionStorageRemove('AsmntIntroText');
    /**removing the filtered items from session */
    this.storageService.sessionStorageRemove('filterOccSettings');
    let navVal, navTo;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration'))
      .config.Result.tabItems;

    this.storageService.sessionStorageSet('inTab', 'career');
    this.storageService.sessionStorageSet('personalQua', 'yes');
    // If IP link was clicked we go to IP occlist page
    if (assessment == 'IP') {
      let id;
      for (let i = 0; i < vl.length; i++) {
        const list = vl[i].compList;
        for (let j = 0; j < list.length; j++) {
          id = list[j].compId;
          if (id == 'sortShortIP') {
            navVal = i;
            this.storageService.sessionStorageSet('activeSortId', id);
            break;
          }
        }
      }
      let ipresult = [];
      const ipresultArr = [];
      ipresult = this.occCareerStore.TopInterests.interests;
      for (let i = 0; i < ipresult.length; i++) {
        ipresultArr.push({
          interest: ipresult[i].interest.toLowerCase(),
          score: ipresult[i].score,
        });
      }
      this.storageService.sessionStorageSet('ipsfInterest', interest.toLowerCase());
      this.storageService.sessionStorageSet('resultIP', JSON.stringify(ipresultArr));
      this.router.navigate(['../../interestProfilerSf/occlist'], {
        relativeTo: this.activatedRoute,
      });
    } else if (assessment == 'WIL') {
      // If WIL link was clicked we go to WIL occlist page
      const wilresultArr = [];

      for (let i = 0; i < vl.length; i++) {
        const list = vl[i].compList;
        for (let j = 0; j < list.length; j++) {
          const id = list[j].compId;
          if (id == 'sortWIL') {
            navVal = i;
            this.storageService.sessionStorageSet('activeSortId', id);
            break;
          }
        }
      }
      let wilresult = [];
      wilresult = this.occCareerStore.TopValues.values;
      for (let i = 0; i < wilresult.length; i++) {
        wilresultArr.push({
          areaAbbr: wilresult[i].value,
          title: wilresult[i].value,
          score: wilresult[i].score,
          description: '',
        });
      }
      this.storageService.sessionStorageSet('personalWil', JSON.stringify(wilresultArr));
      this.storageService.sessionStorageSet('interestwil', interest.toLowerCase());
      const twoVal = [];
      twoVal.push(this.occCareerStore.TopValues.values[0].value);
      twoVal.push(this.occCareerStore.TopValues.values[1].value);

      this.storageService.sessionStorageSet('twoTitleVal', JSON.stringify(twoVal));
      this.router.navigate(['../../wilAsessment/occlist'], {
        relativeTo: this.activatedRoute,
      });
      this.utils.hideLoading();
    }
  }
}
