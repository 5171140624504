/**Import angular core packages */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store, Action } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  debounceTime
} from 'rxjs/operators';
/**Custom imports */
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { CareersJrApiCallClass } from '../../careersJr-constants/model';
import { CareersJrApiCallService } from '../../careersJr-services/careersJr-apicalls.service';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJrSnackBar } from '../../careersJr-modal/careersJr-modal-component';
import { CareersJr_Settings_Tab_Text_State, CareersJr_Index_Reducer_Text_State, CareersJr_Buttons_Reducer_Text_State, CareersJr_EmpOutlook_Wages_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { CareersJrCommonMethodsService } from '../../careersJr-services/careersJr-common-methods.service';
import { EventDispatchService } from '../../../../../shared/outer-services/event-dispatch.service';
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';

@Component({
  selector: 'careersJr-emerg-header',
  templateUrl: './emergCareersJr-header-layout.html',
})

export class CareersJrEmergHeaderComponent implements OnInit, OnDestroy {
  accountId; // Declare for storing the value of accountid
  occName; // Declare for storing the occName value
  occEmergingStore; // Declare for assigning the value of OCCPageStatestore
  occTextStore; // Declare for assinging the value of store
  settingsText; // Declare for assigning the subscribing value of Occ_Settings_Tab_Text
  clusterIcon = ''; // Declare the params value of clusterIcon
  clusterColor = ''; //Declare the params value of clustercolor
  value = 'About this Career'; //Declare for storing the values of dropdownmenus list
  occID = ''; //Declare for storing the occid value
  showColorUp = -1; //Declare for storing the thumbs value
  langVal; //Declare for storing of sessionstorageget value of langset
  activeVal = 0; //Declare for dropdown menu lists value
  filter: any; /** boolean value toggle in mobile  */
  hideVal = 0;
  backAssessmentValue = false;
  emergingJsonValue = [];
  subscription = new Subscription;
  langChange = false;
  occclusinx = '';
  occIndexReducerSub = new Subscription;
  occTextSub = new Subscription;
  Occ_Settings_Tab_Text = new Subscription;
  outereventsubscription = new Subscription;
  public snackbar = new CareersJrSnackBar();
  frameworkObj: any;
  logo: any;
  @ViewChild('videoplayer') videoplayer: ElementRef;
  @ViewChild('occEmergingFocus', { static: true }) occEmergingFocusElement: ElementRef;
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;
  fromRoute: string;

  constructor(
    private router: Router,
    private occstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private storageService: StorageService,
    private careersJrSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private dispatchStore: Store<Action>,
    private careersJrPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private careersJrIndexstore: Store<CareersJr_Index_Reducer_Text_State>,
    private careersJrApiJson: CareersJrApiCallClass,
    private careersJrCommonService: CareersJrCommonMethodsService,
    private careersJrApiCall: CareersJrApiCallService,
    private careersJrDispatch: CareersJrDispatchService,
    private eventService: EventDispatchService, private Frameworkstore: Store<FrameworkConfigState>,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string,
    @Inject('LANDING_IMAGE_URL') public LandingImages: string,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string
  ) {
    this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
    this.storageService.sessionStorageSet('activeSortId', 'fileOcc');
    document.title = 'Careers';
    this.subscription = this.Frameworkstore.select('config').subscribe((res) => {
      if (res.config) {
        this.frameworkObj = res.config;
      }

    });
    const ref = this;
    function convertImgToDataURLviaCanvas(url, callback, outputFormat) {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        try {
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
          canvas = null;
        } catch (e) {
        } finally {
          dataURL =
            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
          callback(dataURL);
          canvas = null;
        }
      };
      img.src = url;
    }
    convertImgToDataURLviaCanvas(ref.frameworkObj.Result.user.logo, function (base64Img) {
      ref.logo = base64Img;
    }, 'base64');
    this.utils.showLoading();
    if (!this.storageService.sessionStorageGet('CareersJrIndexReducerText') || !this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')) {
      this.careersJrCommonService.OccIndexDispatch();
    }
    this.langVal = this.storageService.sessionStorageGet('langset');
    /** Below code block listens broadcasted event and
 * calls respective functionality */
    this.subscription = careersJrDispatch.listen().subscribe((occDisp) => {
      /** After event listen it will check whether user want functionality of
       * languagechanged event and relatedemergingdispatch event*/
      if (occDisp.type === 'relatedEmergingDispatch') {
        this.activeVal = 0;
        this.ngOnInit();

      } else if (occDisp.type === 'languageChanged') {
        this.langVal = this.storageService.sessionStorageGet('langset');
        const Arr = this.router.url.split('?');
        const routArr = Arr[0].split('/');
        this.hideVal = 0;
        this.langChange = true;
        if (routArr.indexOf('occEmergCareer') !== -1) {
          this.ngOnInit();
        }
      }
    });

    /**get the data from reducer related effect name**/
    let value = true;
    this.occIndexReducerSub = this.careersJrIndexstore.select('CareersJr_Index_Reducer_Text').pipe(debounceTime(100)).subscribe((resOccIndex) => {
      if (this.storageService.sessionStorageGet('parentCnt') === 'true') {
        this.savedCareerNames();
      }
      if (this.fromRoute === 'progs' && value === true && resOccIndex && resOccIndex.CareersJr_Index_Reducer_Text.occList) {
        value = false;
        this.fromProgs();
        this.ngOnInit();
      }
      if (this.fromRoute === 'mils' && value === true && resOccIndex && resOccIndex.CareersJr_Index_Reducer_Text.occList) {
        value = false;
        this.fromMils();
        this.ngOnInit();
      }
    });
    this.occEmergingStore = this.careersJrPageStateStore.select('CareersJr_EmpOutlook_Wages');
    this.occTextSub = this.occstore.select('CareersJr_Buttons_Reducer_Text').subscribe((resOccButtons) => {
      // if (this.langChange  ===  true) {
      this.occTextStore = resOccButtons;
      // }
    });

    this.Occ_Settings_Tab_Text = this.careersJrSetstore.select('CareersJr_Settings_Tab_Text').subscribe((resOccSettings) => {
      if (resOccSettings && resOccSettings.CareersJr_Settings_Tab_Text && resOccSettings.CareersJr_Settings_Tab_Text.topSections) {
        this.settingsText = resOccSettings;
        this.hideLoadingSymbol();
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.occID = params['occid'];
      this.occclusinx = params['inx'];
      this.occName = params['occname'];
      this.fromRoute = params['fromRoute'];
      if (this.fromRoute === 'progs') {
        this.fromProgs();
      } else if (this.fromRoute === 'mils') {
        this.fromMils();
      } else {
        // this.clusterIcon = params['clusIcon'];
        // this.clusterColor = params['clusColor'];
        this.utils.showLoading();
        this.ngOnInit();
      }
    });
    this.outereventsubscription = this.eventService.listenStaticContent().subscribe((e) => {
      if (this.occEmergingFocusElement) {
        this.occEmergingFocusElement.nativeElement.setAttribute('tabindex', '0');
        this.occEmergingFocusElement.nativeElement.focus();
      }
    });


  }
  fromProgs() {
    let clusterId;
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    if (val && val.CareersJr_Index_Reducer_Text.occList) {
      val.CareersJr_Index_Reducer_Text.occList.forEach((obj: any) => {
        if (obj.occID === parseInt(this.occID)) {
          clusterId = obj.clusterID;
        }

      });
      const clsVal = this.careersJrCommonService.clustDetails(clusterId);

      this.clusterIcon = clsVal.clusterIconValue;
      this.clusterColor = clsVal.clusterBgColor;
    }
  }
  fromMils() {
    let clusterId;
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    if (val && val.CareersJr_Index_Reducer_Text.occList) {
      val.CareersJr_Index_Reducer_Text.occList.forEach((obj: any) => {
        if (obj.occID === parseInt(this.occID)) {
          clusterId = obj.clusterID;
        }
      });
      const clsVal = this.careersJrCommonService.clustDetails(clusterId);

      this.clusterIcon = clsVal.clusterIconValue;
      this.clusterColor = clsVal.clusterBgColor;
    }
  }

  /**hideLoadingSymbol method is used for the hide the loading symbol */
  hideLoadingSymbol() {
    if (this.occName && this.settingsText) {
      if (this.settingsText.CareersJr_Settings_Tab_Text.topSections) {
        const ref = this;
        setTimeout(function () {
          ref.utils.hideLoading();
        }, 500);
      }
    }
  }

  /** ngOnInit method is called at the time of initialization of the component */
  ngOnInit() {
    this.savedCareerNames();
    /**this condition is for backtoassessment button when the user come from assessments or from careers */
    if (this.storageService.sessionStorageGet('inTab')) {
      if (this.storageService.sessionStorageGet('inTab') === 'Assess' || this.storageService.sessionStorageGet('inTab') === 'cciAssess'
        || this.storageService.sessionStorageGet('inTab') === 'osAssess') {
        this.backAssessmentValue = true;
      } else {
        this.backAssessmentValue = false;
      }
    }
    this.storageService.sessionStorageSet('occIDval', this.occID);
    this.emergingJsonValue['clusIcon'] = this.clusterIcon;
    this.emergingJsonValue['clusColor'] = this.clusterColor;
    this.accountId = 'accountID';

    this.dispatchStore.dispatch({
      type: 'GET_SECTIONS_TEXT', payload: {
        methodVal: 'GET', module_Name: 'OccJr/v1/',
        path_params: ['emerging', this.occID, this.accountId],
        query_params: { 'sections': 'all', 'lang': this.langVal },
        body_Params: {}, endUrlVal: 'pages', text: ''
      }
    });

    this.getText();
    this.getRating();
  }
  savedCareerNames() {
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    let value = true;
    if (val && val.CareersJr_Index_Reducer_Text.occList) {
      val.CareersJr_Index_Reducer_Text.occList.forEach((list) => {
        if ((list.occID === parseInt(this.occID)) && value === true) {
          this.occName = (list.title);
          value = false;
        }
      });
    }
  }
  videostop() {
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.pause();
  }
  /**ngOnDestroy method is called when user destroying the component */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.occIndexReducerSub.unsubscribe();
    this.occTextSub.unsubscribe();
    this.Occ_Settings_Tab_Text.unsubscribe();
    this.outereventsubscription.unsubscribe();
    this.storageService.removeSessionIndexes(true);
  }

  /**dropDownVal method is for the dropDown menu dispalyed in mobileview */
  dropDownVal(name, val) {

    this.value = name;
    this.activeVal = val;
    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent(name, true, true);
    this.careersJrDispatch.dispatch(evnt);
  }

  /**getUnFill method is for filling the thumbs icons */
  getUnFill(thumbVal) {
    this.utils.showLoading();
    if (thumbVal === 'up' && (this.showColorUp !== 1)) {
      this.showColorUp = 1;
    } else if (thumbVal === 'down' && (this.showColorUp !== 0)) {
      this.showColorUp = 0;
    } else {
      this.showColorUp = -1;
    }
    this.resultPost();
  }

  /**savePost method is for saving the result of favorites*/
  savePost() {

    this.utils.showLoading();
    this.careersJrApiJson.method = 'POST';
    this.careersJrApiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
    this.careersJrApiJson.moduleName = 'OccJr/v1/';
    const saveResult = {
      input_data: [
        {
          'param_type': 'path',
          'params': [this.accountId, 'favorites', this.occID]
        },
        {
          'param_type': 'query',
          'params': { 'lang': this.langVal }
        }

      ]
    };
    const user = JSON.stringify(saveResult);

    this.careersJrApiJson.endUrl = 'users';
    this.careersJrApiJson.data = user;
    this.careersJrApiCall.callApi([this.careersJrApiJson]).subscribe((res) => {

      if (res.Result + '' === 'true') {
        this.utils.hideLoading();
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent('PlanCompStatus', true, true);
        this.eventService.dispatch(evnt);
        this.snackbar.myFunction('Successfully added to favorites.');
      }

    }, e => this.utils.handleError(`emergCareersJr-header-component.ts savePost: ${e.message}`));

  }

  /**getRating method is for the get the rating with ratings call */
  getRating() {
    this.careersJrApiJson.method = 'GET';
    this.careersJrApiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
    this.careersJrApiJson.moduleName = 'OccJr/v1/';
    const getThumbsResult = {
      input_data: [
        {
          'param_type': 'path',
          'params': [this.accountId, 'ratings', this.occID]
        },
        {
          'param_type': 'query',
          'params': { 'lang': this.langVal }
        }

      ]
    };
    const user = JSON.stringify(getThumbsResult);
    this.careersJrApiJson.endUrl = 'users';
    this.careersJrApiJson.data = user;
    this.careersJrApiCall.callApi([this.careersJrApiJson]).subscribe((res) => {
      if (res[0].Success + '' === 'true') {

        if (res[0].Result === 1) {

          this.showColorUp = 1;
        } else if (res[0].Result === 0) {

          this.showColorUp = 0;
        } else {

          this.showColorUp = -1;
        }
      }
    }, e => this.utils.handleError(`emergCareersJr-header-component.ts getRating: ${e.message}`));

  }

  /**getText method is for dispatching the pages call text and settings call text*/
  getText() {
    // if (this.langChange  ===  false) {
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJr_Reducer_OccText'));
    if (val && val.careersJr_Buttons_Reducer_Text && val.careersJr_Buttons_Reducer_Text.common) {
      this.occTextStore = val;
    }
    // }
    const asmtEndurl = (this.restrictedAccountType) ? 'OccJr/emerging' : 'OccJr/emerging/default';

    this.dispatchStore.dispatch({
      type: 'GET_CAREERSJR_SETTING_TABS_TEXT', payload: {
        methodVal: 'GET', module_Name: 'Settings/v1/',
        path_params: (this.restrictedAccountType) ? ['accountID'] : [],
        query_params: { 'lang': this.langVal },
        body_Params: {}, endUrlVal: asmtEndurl
      }
    });

  }

  /**resultPost method is for posting the result of ratings */
  resultPost() {
    setTimeout(function () {
      this.careersJrApiJson.method = 'POST';
      this.careersJrApiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
      this.careersJrApiJson.moduleName = 'OccJr/v1/';
      const starResult = {
        input_data: [
          {
            'param_type': 'path',
            'params': [this.accountId, 'ratings', this.occID, this.showColorUp]
          },
          {
            'param_type': 'query',
            'params': { 'lang': this.langVal }
          }

        ]
      };
      const user = JSON.stringify(starResult);
      this.careersJrApiJson.endUrl = 'users';
      this.careersJrApiJson.data = user;
      this.careersJrApiCall.callApi([this.careersJrApiJson]).subscribe((res) => {
        if (res.Result + '' === 'true') {
          const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
          val.CareersJr_Index_Reducer_Text.occList.forEach(res => {
            if (res.occID === parseInt(this.occID, 10)) {
              res.rating = this.showColorUp;
            }
          });
          this.storageService.sessionStorageSet('CareersJrIndexReducerText', JSON.stringify(val));
        }
        this.utils.hideLoading();
      }, e => this.utils.handleError(`emergCareersJr-header-component.ts resultPost: ${e.message}`));
    }.bind(this));
  }

  /**backAssessment method is for the backtoassessment
   *  button clicking,when we come from the ip assessment  */
  backAssessment() {
    //when back assessment data was clicked navigate to respective page
    this.storageService.sessionStorageSet('fromCareers', 'yes');
    if (this.storageService.sessionStorageGet('inTab') !== 'cciAssess' && this.storageService.sessionStorageGet('inTab') !== 'osAssess') {
      this.router.navigate(['../../' + this.storageService.sessionStorageGet('prevRoute') + '/occlist'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../../' + this.storageService.sessionStorageGet('prevRoute') + '/result'], { relativeTo: this.activatedRoute });
    }
  }

  /**careerlist method is for careerlist button,after clicking it will go to the occIndex page */
  CareerList(check) {
    if (this.occclusinx === 'title') {
      this.storageService.sessionStorageSet('careerJrShow', '1');
    } else {
      this.storageService.sessionStorageSet('careerJrShow', '0');
    }
    this.router.navigate(['../careerJrIndex'], { relativeTo: this.activatedRoute, queryParams: { chk: check, occclusinx: this.occclusinx } });
  }
  emergingPrint() {
    this.utils.showLoading();
    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent('emergingEventPrint' + this.activeVal, true, true);
    this.careersJrDispatch.dispatch(evnt);
  }

}

