/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

/**Services **/
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { CareersJr_Settings_Tab_Text_State, CareersJr_EmpOutlook_Wages_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'education-emerging-careersJr',
  templateUrl: './education-emerging-careersJr.layout.html',
})
export class EducationEmergingCareersJrComponent implements OnDestroy, OnInit {
  expandCard = 999; /** A variable to tell which card to open first */
  occSettingsSub = new Subscription();
  occPageSub = new Subscription();
  abilityRes = []; /** Contain related program text */
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  LicenCertificate; /** it if for displaying LicenCertificate card */
  eventSub = new Subscription();
  occName;
  availProgs = false;
  educationExpand = 999;
  moreLicensingExpand = 999;
  eduTrainPrintSub: Subscription;
  printPage = false;
  @ViewChild('EducationTrainingPrintPage', { static: true })
  EducationTrainingPrintPage: ElementRef;

  @Input('mainlogo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  constructor(
    private router: Router,
    private utils: Utilities,
    private storageService: StorageService,
    private occSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private CareerJrPageStateStore: Store<CareersJr_Settings_Tab_Text_State>,
    private careerJrDispatch: CareersJrDispatchService,
    private deviceService: DeviceDetectorService,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    this.eduTrainPrintSub = this.careerJrDispatch
      .careerEducationTrainingListen()
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.eventSub = careerJrDispatch.listen().subscribe((e) => {
      if (e.type == 'Education & Training') {
        this.expandCard = 999;
      } else if (e.type === 'emergingEventPrint2') {
        this.careerPrint();
      }
    });
    this.occSettingsSub = occSetstore
      .select('CareersJr_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 4) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = CareerJrPageStateStore.select(
      'CareersJr_EmpOutlook_Wages'
    ).subscribe((v) => {
      this.occCareerStore = v;
      if (this.occCareerStore.RelatedPrograms != null) {
        this.abilityRes.push(this.occCareerStore.RelatedPrograms);
      }
      let val = [];
      val = this.occCareerStore.LicensingCert;
      if (val != undefined) {
        this.LicenCertificate = val.length;
      }
    });
  }
  ngOnInit() {
    let navVal, id;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration'))
      .config.Result.tabItems;
    for (let i = 0; i < vl.length; i++) {
      const list = vl[i].compList;
      for (let j = 0; j < list.length; j++) {
        id = list[j].compId;
        if (id == 'fileProg') {
          this.availProgs = true;
          break;
        } else if (id != 'fileProg') {
          this.availProgs = false;
        }
      }
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
    });
  }
  // Print Functionality
  async careerPrint() {
    const element = this.EducationTrainingPrintPage.nativeElement;
    const opt = {
      filename: 'Emerging Career Jr - Education.pdf',
      margin: [3, 0, 0, 0],
      image: { type: 'svg', quality: 0.98 },
      html2canvas: { scale: this.deviceDetector(), letterRendering: true },
      jsPDF: { unit: 'mm', format: 'A4', compress: true },
      pagebreak: { mode: ['avoid-all', 'css'] },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.eventSub.unsubscribe();
    this.eduTrainPrintSub.unsubscribe();
  }
  deviceDetector() {
    if (this.deviceService.getDeviceInfo().browser === 'Safari') {
      return 0.8;
    }
    return 1;
  }
}
