/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

/**Services **/
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import {
  Occ_EmpOutlook_Wages_State,
  Occ_Settings_Tab_Text_State,
} from '../../occ-state-management/occ-state/occ-state';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'edu-and-traninig',
  templateUrl: './edu-training.layout.html',
})
export class EduTrainingComponent implements OnDestroy, OnInit {
  expandCard = 999; /** A variable to tell which card to open first */
  occSettingsSub = new Subscription();
  occPageSub = new Subscription();
  abilityRes = []; /** Contain related program text */
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  LicenCertificate; /** it if for displaying LicenCertificate card */
  eventSub = new Subscription();
  occName;
  availProgs = false;
  educationExpand = 999;
  moreLicensingExpand = 999;
  eduTrainPrintSub: Subscription;
  printPage = false;
  @ViewChild('EducationTrainingPrintPage', { static: true })
  EducationTrainingPrintPage: ElementRef;

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;

  states: Array<string> = ['US', StorageService.staticSessionStorageGet('stateAbr')];
  langVal: string = StorageService.staticSessionStorageGet('langset')
    ? StorageService.staticSessionStorageGet('langset')
    : 'en';
  occID: string = StorageService.staticSessionStorageGet('occIDval')
    ? StorageService.staticSessionStorageGet('occIDval')
    : '0';

  constructor(
    private router: Router,
    private utils: Utilities,
    private storageService: StorageService,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occDispatch: OccDispatchService,
    private deviceService: DeviceDetectorService,
    private dispatchStore: Store<Action>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    this.eduTrainPrintSub = this.occDispatch
      .careerEducationTrainingListen()
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.eventSub = occDispatch.listen().subscribe((e) => {
      if (e.type == 'Education & Training') {
        this.expandCard = 999;
      }
    });
    this.occSettingsSub = occSetstore
      .select('Occ_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.Occ_Settings_Tab_Text.tabs != null &&
          this.settingsText.Occ_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 4) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = OCCPageStateStore.select(
      'Career_Detail_Education_Training'
    ).subscribe((v) => {
      this.occCareerStore = v;
      if (this.occCareerStore.RelatedPrograms != null) {
        if (this.abilityRes && this.abilityRes.length > 0) {
          this.abilityRes = [];
        }
        this.abilityRes.push(this.occCareerStore.RelatedPrograms);
      }
      let val = [];
      val = this.occCareerStore.LicensingCert;
      if (val != undefined) {
        this.LicenCertificate = val.length;
      }
    });
  }
  ngOnInit() {
    let navVal, id;
    const vl = JSON.parse(this.storageService.sessionStorageGet('loginFrameworkConfiguration'))
      .config.Result.tabItems;
    for (let i = 0; i < vl.length; i++) {
      const list = vl[i].compList;
      for (let j = 0; j < list.length; j++) {
        id = list[j].compId;
        if (id == 'fileProg') {
          this.availProgs = true;
          break;
        } else if (id != 'fileProg') {
          this.availProgs = false;
        }
      }
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
    });

    try {
      this.dispatchStore.dispatch({
        type: 'GET_DETAIL_EDUCATION_TRAINING',
        payload: {
          methodVal: 'GET',
          module_Name: 'Occ/v1/',
          path_params: ['occ', parseInt(this.occID), 'accountID'],
          query_params: {
            sections:
              'Apprenticeship,Preparation,HelpfulHSCourses,Knowledge,LicensingCert,MoreLicensing,MoreEducation,RelatedPrograms', //this is to get all tab text
            states: this.states,
            lang: this.langVal,
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    } catch (e) {
      console.log('error------>' + e.message);
    }
  }
  // Print Functionality
  async careerPrint() {
    const element = this.EducationTrainingPrintPage.nativeElement;
    const opt = {
      filename: 'Career - Education & Training.pdf',
      margin: [0, 0, 1, 0],
      image: { type: 'svg', quality: 0.98 },
      html2canvas: { scale: this.deviceDetector(), letterRendering: true },
      jsPDF: { unit: 'mm', format: 'A4', compress: true },
      pagebreak: {
        avoid: '.break-avoid',
        mode: ['auto', 'css', 'legacy', 'whiteline'],
      },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.eventSub.unsubscribe();
    this.eduTrainPrintSub.unsubscribe();
  }
  /** Called when a card is clicked to open or close */
  methodfilter(valfil, type?) {
    if (type) {
      if (type === 'MoreEducation') {
        this.educationExpand = this.educationExpand === 999 ? valfil : 999;
        this.moreLicensingExpand = 999; /*We are getting the morelicensing accordion cards in a loop. So based on the index we are showing the open accordion card and remaining all of the cards will be closed and we are assigning the variable to 999(to close)
                                       Here both indexes are comparing same value.So, We have taken another variable for moreLicesing **/
        this.expandCard = valfil;
      } else {
        this.educationExpand = 999; //We are getting the education accordion cards in a loop. So based on the index we are showing the open accordion card and remaining all of the cards will be closed and we are assigning the variable to 999(to close).
        this.moreLicensingExpand =
          this.moreLicensingExpand === 999
            ? valfil
            : this.moreLicensingExpand !== valfil
            ? valfil
            : 999; //For Morelicensing dynamic accordion cards open/close logic.
      }
    } else {
      this.educationExpand = 999;
      this.moreLicensingExpand = 999;
      if (this.expandCard != valfil) {
        this.expandCard = valfil;
      } else {
        this.expandCard = 999;
      }
    }
  }
  callProgramsCareer(id, name) {
    this.router.navigate(['../../progsdetails/progHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: { progid: id, progname: name },
    });
    this.utils.showLoading();
    setTimeout(
      function () {
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent('relatedProgamDispatch', true, true);
        this.occDispatch.dispatch(evnt);
      }.bind(this),
      100
    );
  }
  deviceDetector() {
    if (this.deviceService.getDeviceInfo().browser === 'Safari') {
      return 0.8;
    }
    return 1.5;
  }
}
