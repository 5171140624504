

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line:rxjs-proper-imports
import { Observable, forkJoin } from 'rxjs';
import { Utilities } from '../../../../shared/outer-services/utilities.service';
import { CommonExtendTime } from '../../../../Utilities/extend-time.service';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from '../../../../shared/outer-services/storage.service';

let expiredTime, eventTime;


@Injectable()
export class CareersJrApiCallService {
    constructor(
        private http: HttpClient,
        private utils: Utilities,
        private extendTimeForUser: CommonExtendTime
    ) { }
    public extractData = function (res) {
        let body = { eventTime: '', sessionID: '', result: '' };
        try {
            body = JSON.parse(res);
            if (body.result && body.result === 'jwt expired') {
                this.utils.log('careersJr-apicall.service.ts extractData jwt expired', 'debug').subscribe();
              }
        }
        catch (e) {
            this.utils.log(`careersJr-apicall.service.ts extractData parse failure: ${res}`, 'debug').subscribe();
            throw e;
        }
        if (body && body.eventTime && body.sessionID)
        {
            eventTime = body.eventTime;
            StorageService.staticSessionStorageSet('auth_key', body.sessionID);
            try {
                this.extendTimeForUser.entendTimeForUser(body);
            } catch (e) {
                console.log('exc==>' + e.message);
            }
        }

        return body || {};
    }.bind(this);


    sessionExpired = function () {
        this.occUtilities.sessionExpired();
    }.bind(this);

    // This service is called each time the client tries to hit the server api after login
    callApi(data): Observable<any> {

        if (data[0].method == 'POST') {
            try {
                return this.http.post('/api/postCall', { data: data[0] }, { responseType: 'text' })
                    .pipe(map(this.extractData),
                        catchError(e => this.utils.handleError(`careersJr-apicalls.service.ts callApi post: ${JSON.stringify(
                            e,
                            Object.getOwnPropertyNames(e)
                        )}`)));
            } catch (e) {
                this.utils.handleError(`careersJr-apicalls.service.ts callApi post: exception ${JSON.stringify(
                    e,
                    Object.getOwnPropertyNames(e)
                )}`);
            }
        } else {
            const observableBatch = [];
            try {
                data.forEach((componentarray, inx) => {

                    const str = Object.keys(componentarray).map(function (key) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(componentarray[key]);
                    }).join('&');
                    observableBatch.push(this.http.get('/api/getCall?' + str, { responseType: 'text' })
                        .pipe(map(this.extractData),
                            catchError(e => this.utils.handleError(`careersJr-apicalls.service.ts callApi get: ${JSON.stringify(
                                e,
                                Object.getOwnPropertyNames(e)
                            )} : ${str}`))));
                });
            } catch (e) {
                this.utils.handleError(`careersJr-apicalls.service.ts callApi get: exception ${JSON.stringify(
                    e,
                    Object.getOwnPropertyNames(e)
                )}`);
            }
            return forkJoin(observableBatch);
        }
    }

}
