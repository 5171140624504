import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrameworkComponent } from './modules/framework/framework.component';
import { AuthManager } from './shared/auth/authmanager';
import { DashboardModule } from './modules/framework/layouts/dashboard/dashboard.module';
import { RedirectComponent } from './modules/framework/redirect.component';

const routes: Routes = [
  {
    path: 'app',
    component: FrameworkComponent,
    children: [
      {
        path: ':tabId/tiles',
        loadChildren: () => import('./modules/framework/layouts/tiles/tiles.module').then(m => m.TilesModule)
      },
      {
        path: ':tabId/plan',
        loadChildren: () => import('./modules/framework/layouts/plans/plans.module').then(m => m.PlansModule)
      },
      {
        path: ':tabId/widget',
        loadChildren: () => import('./modules/framework/layouts/widget/widget.module').then(m => m.WidgetModule)
      },
      {
        path: ':tabId/dashboard',
         /*Here the below lazy loading type is not allowing aot so that we have changed the type of lazy loaded dashboard module */
        // loadChildren: () => DashboardModule
        loadChildren: () => import('./modules/framework/layouts/dashboard/dashboard.module').then(m => m.DashboardModule)

      },
      {
        path: 'account',
         /*Here the below lazy loading type is not allowing aot so that we have changed the type of lazy loaded dashboard module */
        // loadChildren: () => DashboardModule
        loadChildren: () => import('./modules/account-setting/account-setting.module').then(m => m.AccountSettingModule)

      },
      {
        path: 'guestAccount',
         /*Here the below lazy loading type is not allowing aot so that we have changed the type of lazy loaded dashboard module */
        // loadChildren: () => DashboardModule
        loadChildren: () => import('./modules/guest-user-account/guest-user-setting.module').then(m => m.GuestAccountSettingModule)

      },
      {
        path: 'redirect/:compId/:mainId/:subId', component: RedirectComponent

      }, {
        path: 'redirect/:compId/:mainId', component: RedirectComponent

      },
      {
        path: 'redirect/:compId', component: RedirectComponent

      }
    ]
    // canActivate: [AuthManager],
  },
  // { path: '', redirectTo: 'app', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      // enableTracing: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
