/**import angular packages*/
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import html2pdf from 'html2pdf.js';

/**custom imports */
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJr_EmpOutlook_Wages_State, CareersJr_Settings_Tab_Text_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { CareersJrCommonMethodsService } from '../../careersJr-services/careersJr-common-methods.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'related-careers-cluster',
  templateUrl: './related-cluster-careers-layout.html',
})
export class RelatedCareersJrClusterComponent implements OnInit, OnDestroy {
  @Input('relatedCareers') relatedCareers: any = {};
  @Input('mainlogo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  @ViewChild('relatedCareerPrint', { static: true }) relatedPrint: ElementRef;
  printview = false;
  relatedcareers; //Declare for storing the subscribe value for OccPageStateStore
  settingsTextTab; //Declare for storing the obj value of settingsText
  settingsText; //Declare for storing the value of settingsText
  showMilataryCareer = false; //Variable defaults false value
  reducerSub1 = new Subscription();
  eventSub = new Subscription();
  clusterIcon;
  clusterColor;
  clustersIcon = ''; /**Declare for storing the cluster icon.*/
  clusterID = ''; /**Declare for storing ClusterID.*/
  compAlive = true;
  constructor(
    private router: Router,
    private occSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private storageService: StorageService,
    private careerDispatch: CareersJrDispatchService,
    private occCommonService: CareersJrCommonMethodsService
  ) {
    this.eventSub = careerDispatch
      .clusterReatedCareerListen()
      .subscribe((e) => {
        this.utils.showLoading();
        this.relatedCareerPrint();
      });
    //get the data from reducer state OCCPageStateStore

    this.OCCPageStateStore.pipe(
      select('CareersJr_EmpOutlook_Wages'),
      takeWhile(() => this.compAlive)
    ).subscribe((v) => {
      this.relatedcareers = v;
      if (v && v.RelatedCareers && v.RelatedCareers.list) {
        const clsVal = this.occCommonService.clustDetails(
          v.RelatedCareers.lists[1].links[0].linkID
        );
        this.clusterIcon = clsVal.clusterIconValue;
        this.clusterColor = clsVal.clusterBgColor;
      }
      if (this.relatedcareers.RelatedMilitary != undefined) {
        if (this.relatedcareers.RelatedMilitary.length != 0) {
          this.showMilataryCareer = true;
        } else {
          this.showMilataryCareer = false;
        }
      }
    });
    //get the data from state store
    this.reducerSub1 = this.occSetstore
      .select('CareersJr_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 3) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
  }

  /**ngOnInit method called when initializing the component  */
  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compAlive))
      .subscribe((params) => {
        /**
         * Defaults to 0 if no query param provided.
         * */
        this.clusterID = params['clusId'];
        this.clustersIcon = params['clusIcon'];
        // this.nameTxt = 'cls_' + this.clusterID;
        this.storageService.sessionStorageSet('cciClus_ID', JSON.stringify(params['clusId']));
        this.storageService.sessionStorageSet(
          'cciClusIcon',
          JSON.stringify(params['clusIcon'])
        );
        this.storageService.sessionStorageSet(
          'cciclusColor',
          JSON.stringify(params['clusColor'])
        );
      });
  }
  async relatedCareerPrint() {
    const data = this.relatedPrint.nativeElement;
    const opt = {
      filename: 'Career Jr Cluster - Related Careers.pdf',
      margin: [0, 0, 0.2, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true },
    };
    await html2pdf(data, opt);
    this.utils.hideLoading();
  }
  /**ngOnDestroy method called when destroying the component */
  ngOnDestroy() {
    this.reducerSub1.unsubscribe();
    this.eventSub.unsubscribe();
    this.compAlive = false;
  }

  /**CallOccDetailCareer method for clicking list links in careers div */
  CallOccDetailCareer(id, name, clusterIcon, clusterColor) {
    const twoDigit = id.toString().substr(0, 2);
    if (twoDigit == 24) {
      this.router.navigate(['../careersJrEmergHeader'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          occid: id,
          occname: name,
          clusIcon: clusterIcon,
          clusColor: clusterColor,
        },
      });
      setTimeout(
        function () {
          const evnt = document.createEvent('CustomEvent');
          evnt.initEvent('relatedEmergingDispatch', true, true);
          this.careerDispatch.dispatch(evnt);
        }.bind(this),
        100
      );
    } else {
      this.router.navigate(['../careersJrHeader'], {
        relativeTo: this.activatedRoute,
        queryParams: { occid: id, occname: name },
      });
    }
  }

  /**CallOccDetailCluster method for clicking links in clusters div */
  CallOccDetailCluster(clusId, ClusName, clusterIcon, clusterColor) {
    this.utils.showLoading();
    this.router.navigate(['../careersJrClusterHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clusId: clusId,
        clusName: ClusName,
        clusIcon: clusterIcon,
        clusColor: clusterColor,
      },
    });
  }
  CallMilitaryCareer(id, name) {
    const occArray = [id, name];
    this.router.navigate(['../../military-details/militaryCareerHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: { milid: id, milname: name },
    });
    this.utils.showLoading();
    setTimeout(
      function () {
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent('relatedDispatch', true, true);
        this.careerDispatch.dispatch(evnt);
      }.bind(this),
      100
    );
  }
}
