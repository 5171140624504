export interface CareersJr_EmpOutlook_Wages_State {
    CareersJr_EmpOutlook_Wages: any;
}
export const defaultCareersJr_EmpOutlook_Wages_State: CareersJr_EmpOutlook_Wages_State = {
    CareersJr_EmpOutlook_Wages: {}
};
export interface CareersJr_Settings_Tab_Text_State {
    CareersJr_Settings_Tab_Text: any;
}
export const defaultCareersJr_Settings_Tab_Text_State: CareersJr_Settings_Tab_Text_State = {
    CareersJr_Settings_Tab_Text: {}
};
export interface CareersJr_Index_Reducer_Text_State {
    CareersJr_Index_Reducer_Text: any;
}
export const defaultCareersJr_Index_Reducer_Text_State: CareersJr_Index_Reducer_Text_State = {
    CareersJr_Index_Reducer_Text: {}
};
export interface CareersJr_Buttons_Reducer_Text_State {
    careersJr_Buttons_Reducer_Text: any;
}
export const defaultCareersJr_Buttons_Reducer_Text_State: CareersJr_Buttons_Reducer_Text_State = {
    careersJr_Buttons_Reducer_Text: {}

};
export interface Occ_Jr_Assessment_Filter_State {
    Occ_Jr_Assessment_Filter_Text: any;
}
export const defaultOcc_Jr_Assessment_Filter_State: Occ_Jr_Assessment_Filter_State = {
    Occ_Jr_Assessment_Filter_Text: {}

};

/**add extra stores */
//At Glance tab
export interface CareersJr_Detail_AtGlance_State {
  CareersJr_Detail_AtGlance: any;
}
export const defaultCareersJr_Detail_AtGlance_State: CareersJr_Detail_AtGlance_State =
  {
    CareersJr_Detail_AtGlance: {},
  };

  //Things to know tab
export interface CareersJr_Detail_ThingsToKnow_State {
  CareersJr_Detail_ThingsToKnow: any;
}
export const defaultCareersJr_Detail_ThingsToKnow_State: CareersJr_Detail_ThingsToKnow_State =
  {
    CareersJr_Detail_ThingsToKnow: {},
  };

//Wages = outlook

//Employment
export interface CareersJr_Detail_Employment_State {
  CareersJr_Detail_Employment: any;
}
export const defaultCareersJr_Detail_Employment_State: CareersJr_Detail_Employment_State =
  {
    CareersJr_Detail_Employment: {},
  };

//Education
export interface CareersJr_Detail_Education_State {
  CareersJr_Detail_Education: any;
}
export const defaultCareersJr_Detail_Education_State: CareersJr_Detail_Education_State =
  {
    CareersJr_Detail_Education: {},
  };
  
//Related Careers
export interface CareersJr_Detail_RelatedCareers_State {
  CareersJr_Detail_RelatedCareers: any;
}
export const defaultCareersJr_Detail_RelatedCareers_State: CareersJr_Detail_RelatedCareers_State =
  {
    CareersJr_Detail_RelatedCareers: {},
  };