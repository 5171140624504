import { Action } from '@ngrx/store';

export enum AccountTypes {
    AccountTypeInit = '@ngrx/store/init',
    SetAccountType = '[AccountTypeModel] SET_ACCOUNT_TYPE',
}

export class AccountTypeInitAction implements Action {
    readonly type = AccountTypes.AccountTypeInit;
}

export class SetAccountTypeAction implements Action {
    readonly type = AccountTypes.SetAccountType;
    constructor(public payload: string) { }
}

export type AccountTypeActions =
    AccountTypeInitAction |
    SetAccountTypeAction;
