import { Component, Input } from '@angular/core';
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'static-occ-footer',
  template: `<footer id="fhid" class="common-footer w-100">
    <section class="copyright">
      <div class="container w-100">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="footer-text-desktop desktop-design-display">
              <span class="d-table-cell align-middle">{{ defaultFooter }}</span>
            </div>
            <div class="pt-2">{{ defaultFooter1 }}</div>
          </div>
          <div class="mobile-design-display">
            <p>
              <span>{{ defaultFooter }}</span>
              <span class="tooltip-footer">
                <span class="footer-mobile-text">Read more...</span>
                <span class="tooltiptext"
                  >{{ defaultFooter }}<br />{{ defaultFooter1 }}
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="footer-bottom"></section>
  </footer>`,
})
export class StaticOccFooterComponent {
  subscription = new Subscription();

  defaultFooter = '';
  defaultFooter1 = '';
  constructor(private Frameworkstore: Store<FrameworkConfigState>) {
    try {
      this.subscription = this.Frameworkstore.select('config').subscribe(
        (res) => {
          if (res) {
            const frameConfig = res['config'];
            if (
              frameConfig !== undefined &&
              frameConfig !== null &&
              frameConfig !== ''
            ) {
              const foorJson = frameConfig;
              this.defaultFooter = foorJson.Result.headerFooter.copyright;
            }
          }
        }
      );
    } catch (e) {
      console.log('footer exception:' + e.message);
    }
  }
  ngOnInit() {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
