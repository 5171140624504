import { NgModule } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { PageNotFoundComponent } from './pageNotFound.component';
import { ServiceModule } from '../../shared/service-module/shared-service-module';

@NgModule({
    imports: [
        // HttpClientModule,
        CommonModule, FormsModule, ServiceModule,
        ReactiveFormsModule, RouterModule
    ],
    declarations: [
        PageNotFoundComponent
    ],
    providers: [
    ]
})

export class LoginModule { }
