import { FrameworkConfigState } from './../../../../../../state-management/state/main-state';
/**Angular Libraries **/
import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, SimpleChanges, AfterViewInit, OnChanges, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../../../../../shared/outer-services/utilities.service';

@Component({
  selector: 'at-a-glance-print-result',
  templateUrl: './at-a-glance-print-result-layout.html',
})

export class AtAGlancePrintResultComponent implements OnInit, OnChanges {
  // printpage= true;
  @ViewChild('atAGlancePrintPage', {static: true}) atAGlancePrintPage: ElementRef;
  @Output('printHidden1') printHidden1 = new EventEmitter();

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('occCareerStore') occCareerStore;
  @Input('settingsText') settingsText;
  @Input('settingsTextTab') settingsTextTab;
  @Input('showClusterBox') showClusterBox;
  @Input('printHidden') printHidden: boolean;
  frameWorkSubscription = new Subscription;
  frameworkObj;
  public doughnutChartLabels = ['Completed', 'Started', 'Incomplete'];
  public doughnutChartData: number[] = [8, 52, 22, 8];
  public doughnutChartType = 'doughnut';
  optionsChart = {
    tooltips: { enabled: false },
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    // maintainAspectRatio: false
    // aspectRatio: 2.5
  };
  public doughnutDataset: Array<any> = [
    { // grey
      backgroundColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      borderColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  constructor(private Frameworkstore: Store<FrameworkConfigState>,
    private utils: Utilities,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string) {
    this.frameWorkSubscription = this.Frameworkstore.select('config').subscribe((res) => {
      if (res.config) {
        this.frameworkObj = res.config;
      }

    });
  }
  ngOnInit() {

  }
  ngOnDestroy(){
    this.frameWorkSubscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.printHidden && changes.printHidden.currentValue && this.doughnutChartData) {
      this.utils.showLoading();
      this.careerPrint();
}
  }
  async careerPrint() {
    const element = this.atAGlancePrintPage.nativeElement;
      const opt = {
        filename: 'Career - At a Glance.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        margin : [0, 0, 0, 0],
        html2canvas: { scale: 2 },
        jsPDF: { compress: true },
      };
      // New Promise-based usage:
    await html2pdf(element, opt);
        this.utils.hideLoading();
        this.printHidden1.emit(false);
    }
  }

