export const pieLinksColors = {
    'Conventional': '#e57373', 'Artistic': '#01b7c4',
    'Realistic': '#ff7143', 'Investigative': '#045978',
    'Social': '#b0006c', 'Enterprising': '#68c083'
};

export const wilCardlinkColors = {
    'independence': '#669601',
    'workConditions': '#ab47bc',
    'relationships': '#96015d',
    'support': '#0c8cbd',
    'recognition': '#0a8f72',
    'achievement': '#510456',
};

export const clusterDetails = {
    cls_110100: {
        clusterName: 'Agriculture, Food, and Natural Resources', clusterIconValue: 'icon-clu_agriculture',
        clusterId: '110100', clusterBgColor: '#47bf2a'
    },
    cls_110200: {
        clusterName: 'Architecture and Construction', clusterIconValue: 'icon-clu_architecture',
        clusterId: '110200', clusterBgColor: '#6c777e'
    },
    cls_110300: {
        clusterName: 'Arts, Audio/Visual Technology, and Communications', clusterIconValue: 'icon-clu_arts',
        clusterId: '110300', clusterBgColor: '#ffa100'
    },
    cls_110400: {
        clusterName: 'Business Management and Administration', clusterIconValue: 'icon-clu_business',
        clusterId: '110400', clusterBgColor: '#003eb6'
    },
    cls_110500: {
        clusterName: 'Education and Training', clusterIconValue: 'icon-clu_education_and_training',
        clusterId: '110500', clusterBgColor: '#00b7c3'
    },
    cls_110600: { clusterName: 'Finance', clusterIconValue: 'icon-clu_finance', clusterId: '110600', clusterBgColor: '#ef3f42' },
    cls_110700: {
        clusterName: 'Government and Public Administration', clusterIconValue: 'icon-clu_government',
        clusterId: '110700', clusterBgColor: '#00ad83'
    },
    cls_110800: {
        clusterName: 'Health Science',
        clusterIconValue: 'icon-clu_health_science',
        clusterId: '110800', clusterBgColor: '#af006b'
    },
    cls_110900: {
        clusterName: 'Hospitality and Tourism', clusterIconValue: 'icon-clu_hospitality_and_tourism',
        clusterId: '110900', clusterBgColor: '#e0119d'
    },
    cls_111000: {
        clusterName: 'Human Services',
        clusterIconValue: 'icon-clu_human_services', clusterId: '111000', clusterBgColor: '#88cc2a'
    },
    cls_111100: {
        clusterName: 'Information Technology', clusterIconValue: 'icon-clu_information_technology',
        clusterId: '111100', clusterBgColor: '#b4d600'
    },
    cls_111200: {
        clusterName: 'Law, Public Safety, Corrections, and Security', clusterIconValue: 'icon-clu_law',
        clusterId: '111200', clusterBgColor: '#949ca1'
    },
    cls_111300: {
        clusterName: 'Manufacturing',
        clusterIconValue: 'icon-clu_manufacturing',
        clusterId: '111300', clusterBgColor: '#7700b8'
    },
    cls_111400: { clusterName: 'Marketing', clusterIconValue: 'icon-clu_marketing', clusterId: '111400', clusterBgColor: '#af00b8' },
    cls_111500: {
        clusterName: 'Science, Technology, Engineering, and Mathematics', clusterIconValue: 'icon-clu_science_technology',
        clusterId: '111500', clusterBgColor: '#fdcc2f'
    },
    cls_111600: {
        clusterName: 'Transportation, Distribution, and Logistics', clusterIconValue: 'icon-clu_transportation_distribution_and_logistics',
        clusterId: '111600', clusterBgColor: '#ff7900'
    }
};

export const asmntImages = {
    'CCIAdult': 'cciAdult-landing.png',

    'CCIJr': 'cci-image.png',

    'CCIQuick': 'baby_CCI.png',

    'IDEAS': 'ideas-image.png',

    'MiniIP': 'interest-profiler_mini-icon.png',

    'OccSort': 'Occupation-Sort-icon.png',

    'RealityCheck': 'rc-icon.png',

    'ShortIP': 'Interest-Profiler-icon.png',

    'WIL': 'wil-image.png',

};

export const CCIAsmntConst = ['CCIQuick', 'CCIQuickAdult', 'CCIJr', 'CCIAdult'];
