import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutConfigComponent } from '../../layout-config.component';
import { GridModule } from '../framework/grid.module';
import { ServiceModule } from '../../shared/service-module/shared-service-module';
const routes: Routes = [{ path: '', component: LayoutConfigComponent }];

@NgModule({
    imports: [RouterModule.forChild(routes), GridModule, ServiceModule
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: []
})
export class AssesmentsSharedModule { }
