import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AudioService } from '../outer-services/audio.service';

@Component({
  selector: 'app-audio-button',
  template: `
    <span [ngStyle]="audio_data && audio_data[0] && audio_data[1] && audio_data[0].src && audio_data[1].src?{'display':'inline-block'}:{'display':'none'}" (click)="$event.stopPropagation();">
      <i class="fa fa-volume-up pointer ps-1" #audioIcon style="color:black;opacity:.5;font-size:1rem!important;"></i>
    </span>

    <audio hidden #audioTag>
      <source
        *ngIf="audio_data && audio_data[0] && audio_data[0].src"
        src="{{ audio_data[0].src }}"
        type="{{ audio_data[0].type }}"
      />
      <source
        *ngIf="audio_data && audio_data[1] && audio_data[1].src"
        src="{{ audio_data[1].src }}"
        type="{{ audio_data[1].type }}"
      />
    </audio>
  `,
})
export class AudioButtonComponent implements OnInit {
  @ViewChild('audioIcon') audioIcon: ElementRef;
  @ViewChild('audioTag') audioTag: ElementRef;
  @Input() audio_data;

  constructor(private audioService: AudioService) {}

  ngOnInit() {
    this.audioService.closeEvent
      .subscribe(() => {
        if (this.audioIcon && this.audioTag) {
          this.audioTag.nativeElement.pause();
          this.audioIcon.nativeElement.style.opacity = '.5';
        }
      })
  }

  ngAfterViewInit() {
    let initVal = 0;
    this.audioIcon.nativeElement.addEventListener('click', () => {
      this.audioTag.nativeElement.load();
      if (initVal == 0) {
        this.audioTag.nativeElement.play();
        this.audioIcon.nativeElement.style.opacity = '1';
        initVal++;
      } else {
        this.audioTag.nativeElement.pause();
        this.audioIcon.nativeElement.style.opacity = '.5';
        initVal--;
      }
    });
    this.audioTag.nativeElement.onended = () => {
      this.audioIcon.nativeElement.style.opacity = '.5';
      initVal = 0;
    };
    document.addEventListener(
      'play',
      function (e) {
        let audios = document.getElementsByTagName('audio');
        let audioIcons = document.getElementsByClassName(
          'fa-volume-up'
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0, len = audios.length; i < len; i++) {
          if (audios[i] != e.target) {
            audios[i].pause();
            audios[i].currentTime = 0;
            audioIcons[i].style.opacity = '.5';
          }
        }
      },
      true
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.audioIcon.nativeElement.removeEventListener('click', () => {});
    document.removeEventListener('play', () => {});
  }
}
