
/** Angular imports */
import {
    Router, CanActivate,
    ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';

/** rxjs imports */



/** Custom imports */
import { StorageService } from '../outer-services/storage.service';


@Injectable()
export class ActivatingClass implements CanActivate {

    constructor(private _router: Router, private storageService: StorageService) {

    }

    canActivate(active: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            if (this.storageService.sessionStorageGet('isFrom') === 'intro') {
                return true;
            } else if (this.storageService.sessionStorageGet('isFrom') === 'result') {
                if (this.storageService.sessionStorageGet('mainPath') === 'intro') {
                    this._router.navigateByUrl(active.url + '/intro');
                } else if (this.storageService.sessionStorageGet('mainPath') === 'restore') {
                    this._router.navigateByUrl(active.url + '/restore');
                }
                return false;
            } else {
                return true;
            }


        } catch (e) {
            console.log('canDeactivate exception=>' + e.message);
        }

    }
}
