import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'hiperLinkDisplay' })
export class HiperLinkDisplay implements PipeTransform {
    transform(text: string): string {
        if (text.includes('http') || text.includes('mailto')) {
            text = this.constructText(text);
        }
        return text;
    }

    constructText(hreftext) {
        let textaray = [];
        let finalString = '';
        try {
            const hypertxt1 = [] = hreftext.split('[');
            const hypertxt2 = [] = hypertxt1[1].split(']');
            let text1;
            let hypertxt3;
            text1 = hypertxt1[0] + hypertxt2[1];
            if (hypertxt1[2]) {
                hypertxt3 = [] = hypertxt1[2].split(']');
                text1 = hypertxt1[0] + hypertxt2[1] + hypertxt3[1];
            }
            textaray = text1.split('(');
            const sendarry = [] = textaray[1].split(')');
            let sendarry2;
            if (textaray[2]) {
                sendarry2 = [] = textaray[2].split(')');
            }
            // debugger;
            // console.log('outside if hreftext, --->',hreftext);
            if (sendarry[0].includes('http://') || sendarry[0].includes('https://') || sendarry[0].includes('mailto')) {

                finalString =  sendarry[0];
                //  + hypertxt2[0] + '</a>' + sendarry[1];
                // console.log('if finalString--->'+finalString);
            }
            // if (textaray[2] && (sendarry2[0].includes('http://') || sendarry2[0].includes('https://') || sendarry2[0].includes('mailto'))) {

            //     finalString = finalString + ' <a class="anchorclass" target="_blank" href="' + sendarry2[0] + '">' +
            //         hypertxt3[0] + '</a>' + sendarry2[1];
            //     // console.log('else if finalString--->'+finalString);
            // }
            // if (textaray[2] && !(sendarry2[0].includes('http://') || sendarry2[0].includes('https://') || sendarry2[0].includes('mailto'))) {
            //     finalString = finalString + ' (' + sendarry2[0] + ') ' + sendarry2[1];
            // }
        } catch (e) {
            console.log('text custom pipe exception---->' + e.message);
        }
        return finalString;

    }

}
