/**Angular imports */
import { Injectable } from '@angular/core';

/* dialog is reference of loading model template.*/

@Injectable()
export class StorageService {

  /** This function is for logging out of the modules.
   *
   * Because this is not injectable into the main-reducer and was being instantiated there with the new command,
   * the HttpClient was not supplied and failed to build. To deal with this I have changed the functions below
   * into static functions to be used in the main-reducer.
   */
  constructor() {}
  gStorageSupported = undefined;

  /**sessionStorageRemove method for removing
   * the value implementation */
  static staticSessionStorageRemove(pKey, gStorageSupported?) {
    try {
      if (StorageService.sessionStorageSupported(gStorageSupported)) {
        try {
          window.sessionStorage.removeItem(pKey);
          return true;
        } catch (e) {
          return this.docCookiesRemoveItem('sessionstorage.' + pKey, '', '');
        }
      } else {
        return this.docCookiesRemoveItem('sessionstorage.' + pKey, '', '');
      }
    } catch (e) {
      return true;
    }
  }

  /**sessionStorageSet method for the value set
   * common method implementation */
  static staticSessionStorageSet = (pKey, pValue, gStorageSupported?) => {
    try {
      if (StorageService.sessionStorageSupported(gStorageSupported)) {
        try {
          window.sessionStorage.setItem(pKey, pValue);
        } catch (e) {
          StorageService.docCookiesSetItem(
            'sessionstorage.' + pKey,
            pValue,
            '',
            '',
            '',
            ''
          );
        }
      } else {
        StorageService.docCookiesSetItem(
          'sessionstorage.' + pKey,
          pValue,
          '',
          '',
          '',
          ''
        );
      }
    } catch (e) {
      // alert("sessionStorageSet exception:"+e.message);
    }
  };

  // This function will attempt to remove a cookie from all paths.
  static eraseCookieFromAllPaths(name) {
    const pathBits = location.pathname.split('/');
    let pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (let i = 0; i < pathBits.length; i++) {
      pathCurrent += (pathCurrent.substr(-1) !== '/' ? '/' : '') + pathBits[i];
      document.cookie =
        name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
  }
  /**sessionStorageGet function for getting the value implementation */
  static staticSessionStorageGet(pKey, gStorageSupported?) {
    try {
      if (StorageService.sessionStorageSupported(gStorageSupported)) {
        try {
          return window.sessionStorage.getItem(pKey);
        } catch (e) {
          return this.docCookiesGetItem('sessionstorage.' + pKey);
        }
      } else {
        return this.docCookiesGetItem('sessionstorage.' + pKey);
      }
    } catch (e) {
      console.log('sessionStorageGet exception:' + e.message);
    }
  }

  /**This function will attempt to get a cookie using @param skey  */
  static docCookiesGetItem(sKey) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
              encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
              '\\s*\\=\\s*([^;]*).*$)|^.*$'
          ),
          '$1'
        )
      ) || null
    );
  }

  /**This function will attempt to get a cookie */
  static docCookiesSetItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
              : '; max-age=' + vEnd;
          break;
        case String:
          sExpires = '; expires=' + vEnd;
          break;
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString();
          break;
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=' +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '');
    return true;
  }

  /**This function will attempt to remove a cookie */
  static docCookiesRemoveItem(sKey, sPath, sDomain) {
    if (!sKey || !this.docCookiesHasItem(sKey)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '');
    return true;
  }

  /**This function checking the item using sKey */
  static docCookiesHasItem(sKey) {
    return new RegExp(
      '(?:^|;\\s*)' +
        encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
        '\\s*\\='
    ).test(document.cookie);
  }
  /* optional method: you can safely remove it! */
  static docCookiesKeys() {
    const aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:\=[^;]*)?;\s*/);
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  }

  static sessionStorageSupported(gStorageSupported?) {
    // global to cache value

    const testKey = 'test',
      storage = window.sessionStorage;
    if (gStorageSupported === undefined) {
      try {
        storage.setItem('testKey', '1');

        gStorageSupported = true;
      } catch (error) {
        gStorageSupported = false;
      }
    }
    return gStorageSupported;
  }

  // The angular framework uses and instantiated version of this class and would not reference the static functions.
  sessionStorageSet(pKey, pValue?) {
    return StorageService.staticSessionStorageSet(
      pKey,
      pValue,
      this.gStorageSupported
    );
  }
  sessionStorageGet(pKey) {
    return StorageService.staticSessionStorageGet(pKey, this.gStorageSupported);
  }
  sessionStorageRemove(pKey) {
    return StorageService.staticSessionStorageRemove(
      pKey,
      this.gStorageSupported
    );
  }
  sessionStorageClear(gStorageSupported?) {
    if (StorageService.sessionStorageSupported(gStorageSupported)) {
        window.sessionStorage.clear();
    }
  }

  removeSessionIndexes(removeLangStore:boolean = false) {
    this.sessionStorageRemove('AidIndexReducerText');
    this.sessionStorageRemove('SchoolIndexReducerText');
    this.sessionStorageRemove('inStateActive');
    this.sessionStorageRemove('majorSchools');
    this.sessionStorageRemove('Reducer_MajorsText');
    this.sessionStorageRemove('ProgIndexReducerText');
    this.sessionStorageRemove('CareersJrIndexReducerText');
    this.sessionStorageRemove('Reducer_OccText');
    this.sessionStorageRemove('OccIndexReducerText');
    this.sessionStorageRemove('MilitaryIndexReducerText');
    this.sessionStorageRemove('milparentCnt');
    this.sessionStorageRemove('parentCnt');
    this.sessionStorageRemove('schoolparentCnt');
    this.sessionStorageRemove('aidparentCnt');
    this.sessionStorageRemove('progparentCnt');
    this.sessionStorageRemove('rcOccList');
    const langVal = this.sessionStorageGet('langset');
    if (!removeLangStore) return;
    let dataStore = JSON.parse(this.sessionStorageGet('langDataStore'));
    dataStore[langVal] = {};
    this.sessionStorageSet('langDataStore', JSON.stringify(dataStore));
  }
}
