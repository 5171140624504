/**Angular imports. */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Event as NavigationEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
/**Third party Libraries */
import { Action, select, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, take, takeWhile } from 'rxjs/operators';
import { routeObj, HideRestrictedComponents } from '../../shared/constants/app.constants';
import { ServerApi } from '../../shared/outer-services/app.apicall.service';
import { DashboardApiCallService } from '../../shared/outer-services/dashBoard-api-calls.service';
/**Custom imports.*/
import { EventDispatchService } from '../../shared/outer-services/event-dispatch.service';
import { StorageService } from '../../shared/outer-services/storage.service';
import { Utilities } from '../../shared/outer-services/utilities.service';
import { GetDashboardPersonalInfoAction } from '../../state-management/actions/dashboard.action';
import { dashboardPersonalInfoSelector } from '../../state-management/reducers/dashboard.reducer';
import { getAccountTypeselector } from '../../state-management/selectors/account-Type.selector';
import { getltilocationselector } from '../../state-management/selectors/lti-location.selector';
import { StoreService } from '../../state-management/services/store-service';
import { AccountTypeModel } from '../../state-management/state/account-Type.state';
import { dashboardPersonalInfoState } from '../../state-management/state/dashboard.state';
import { LtiLocationModel } from '../../state-management/state/lti-location.state';
import { FrameworkConfigState } from '../../state-management/state/main-state';
import { SetPlanSummaryDataAction } from './layouts/plans/state-management/action/plan.action';
import { Plan } from './layouts/plans/state-management/model/plan.model';
import { PlanUserSummaryState } from './layouts/plans/state-management/state/plan-state';
import { RouteService } from '../../shared/outer-services/route.service';

@Component({
  selector: 'app-accont-setting',
  templateUrl: './framework.layout.html',
})
export class FrameworkComponent implements OnInit, OnDestroy {
  // initPLPContent = 'personalInfo';
  initAssessmentContent = 'tiles';
  initOccContent = 'occIndex';
  menuItems = [];
  accountId = ''; /**Declare for storing the account id.*/
  appTitle = ''; /**Declare for storing the app title. */
  hideFilter = true; /**Declare for storing the boolean value.*/
  clickBtn = false; /**Declare for storing the boolean value.*/
  changedLang = '';
  userName = ''; /**Declare for storing the username.*/
  userObj; /**Declare for storing the language list. */
  headerFooterObj;
  subscription =
    new Subscription(); /** Declare to listen if any change occured.*/
  logo;
  stateName;
  compActive = true;
  plansData;
  personalData;
  accountTypeStore;
  indvlocationmodule;
  FrameworkObj: any;
  urlArr: Array<string>;
  frameConfigDetails: any;
  frameworkTabItems;
  combineAllcomp = [];
  ltiReturnUrl: string = '';
  enableGotoEnterprise: boolean = false;
  constructor(
    private Frameworkstore: Store<FrameworkConfigState>,
    private commonlang: StoreService,
    private utils: Utilities,
    private plansummaryStore: Store<PlanUserSummaryState>,
    private router: Router,
    private storageService: StorageService,
    private dispatchStore: Store<Action>,
    private activatedRoute: ActivatedRoute,
    private eventService: EventDispatchService,
    private accountStore: Store<AccountTypeModel>,
    private serviceApi: ServerApi,
    public personalInfoStore: Store<dashboardPersonalInfoState>,
    private locatonStore: Store<LtiLocationModel>,
    public serviceDashboard: DashboardApiCallService,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    private modalService: NgbModal,
    private routeService: RouteService
  ) {
    // The below router events track the path on browser front/back navigation and set the tabid accordingly.
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        }),
        takeWhile(() => this.compActive)
      )
      .subscribe((event: NavigationStart) => {
        //As on browser back, the value of the tabId(of the list of names in Navbar) to be viewed is not set and the icons related to that particular tab are not appearing. Now, splitting the URL and extracting the tabId from Url and setting in the sessions makes sure that the tab that is in the view of its data can be displayed without any issues. In General, the click of each tab in the navbar sets the tab id in the sessions. Now, by taking the help of the router events 'NavigationStart' URL property, irrespective of clicking the tabs or browser back the tabId is set in the Sessions.
        this.storageService.sessionStorageSet(
          'tabId',
          JSON.stringify(parseInt(event.url.split('/')[2]))
        );
      });
    /*Dispatching action*/

    this.subscription = this.eventService.listen().subscribe((e) => {
      if (e.type.indexOf('&PLPSectionName') != -1) {
        const name = e.type.split('&');
        this.userName = name[0];
      }
    });
    this.locatonStore
      .pipe(select(getltilocationselector), take(1))
      .subscribe((locresponse) => {
        if (locresponse) {
          this.indvlocationmodule = locresponse;
        }
      });
    // this.indvlocationmodule = this.storageService.sessionStorageGet('startLocation');
    this.eventService
      .menucloselisten()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (e.type == 'hideBar') {
          this.hideFil('bar');
        }
      });
    this.accountStore
      .select(
        getAccountTypeselector,
        takeWhile(() => this.compActive)
      )
      .subscribe((accountresponse) => {
        if (accountresponse) {
          this.accountTypeStore = accountresponse;
          //   console.log('accountType from framework-->', accountresponse);
        }
      });
    const currentDate = new Date();
    const currentSeconds = currentDate.getTime();
    this.storageService.sessionStorageSet('currentSec', currentSeconds + '');
  }
  async summerycall() {
    await this.serviceApi.getUserSummery().then((respUserData) => {
      this.plansData = respUserData['Result'];
      this.plansummaryStore.dispatch(
        new SetPlanSummaryDataAction(respUserData)
      );
    });
  }
  async ngOnInit() {
    let first = '';
    this.accountId = this.utils.getAccountId();
    if (
      this.storageService.sessionStorageGet('LogoutURL') !== 'undefined' &&
      this.storageService.sessionStorageGet('LogoutURL') !== null &&
      this.storageService.sessionStorageGet('stateAbr') !== 'UT' &&
      this.storageService.sessionStorageGet('stateAbr') !== 'AK'
    ) {
      this.ltiReturnUrl = this.storageService.sessionStorageGet('LogoutURL');
    }
    await this.summerycall();

    // this.plansummaryStore.dispatch(new GetPlanSummaryDataAction());
    this.personalInfoStore.dispatch(new GetDashboardPersonalInfoAction());
    /* Here we must use take(1) , because the subscription is calling multiple times
    which is causing redirection to lti startLocation module */
    this.Frameworkstore.select('config')
      .pipe(take(1))
      .subscribe((v) => {
        const frameConfig = v['config']['Result'];
        this.frameConfigDetails = frameConfig;

        if (frameConfig !== undefined && frameConfig !== null) {
          const res = frameConfig;
          this.frameworkTabItems = frameConfig;
          this.menuItems = [];
          this.headerFooterObj = res.headerFooter;
          if (document.title.length < 1) {
            const matchedId = this.headerFooterObj.tabs.find(
              (x) => x.tabId == this.storageService.sessionStorageGet('tabId')
            );
            if (matchedId) {
              document.title = matchedId.tabName
                ? matchedId.tabName + ' - CIS360 Home'
                : 'cis360 Home';
            } else {
              document.title = 'Career Information System';
            }
          }
          this.appTitle = this.headerFooterObj.appName;
          this.userObj = res.user;

          if (this.userObj && this.userObj.accountType) {
            if (
              this.userObj.accountType == 'Site Staff' ||
              this.userObj.accountType == 'Site Administrator'
            ) {
              this.enableGotoEnterprise = true;
            }
          }
          this.logo = res.user.logo;
          this.setFavicon(res.user.favicon);
          let langArr = [];
          langArr = JSON.parse(
            this.storageService.sessionStorageGet('langAssArr')
          );
          const langSet = this.storageService.sessionStorageGet('langset');
          if (langSet == null || langSet + '' == 'undefined') {
            const tmpObj = this.userObj.langList.find((val, inx) => {
              return val.lang == res.user.prefLang;
            });
            {
              this.changedLang = tmpObj.name;
              this.storageService.sessionStorageSet('langset', tmpObj.lang);
            }
          } else {
            const ref = this;
            this.userObj.langList.forEach(function (index, val) {
              if (index.lang == langSet) {
                ref.changedLang = index.name;
              }
            });
          }
          let moduleLoadObj;
          res.tabItems
            .filter((obj, inx) => {
              const checkingRestrictedComp = obj;
              if (this.accountTypeStore !== 'Restricted') {
                return checkingRestrictedComp;
              } else if (
                this.accountTypeStore === 'Restricted' &&
                !(
                  HideRestrictedComponents.includes(obj.layout) ||
                  obj.compList.find((val) =>
                    HideRestrictedComponents.includes(val.compId)
                  )
                )
              ) {
                return checkingRestrictedComp;
              } else if (obj.header === 'Employment') {
                return checkingRestrictedComp;
              }
            })
            .forEach(
              function (obj, inx) {
                const tmpobj = {};
                let individualModule;
                if (obj.tabId != undefined) {
                  if (this.indvlocationmodule) {
                    const startLoc = this.indvlocationmodule.split('|');

                    if (parseInt(startLoc[0]) === inx + 1) {
                      individualModule = obj;
                    } else if (
                      startLoc[0] === 'plan' &&
                      obj.layout === 'plan'
                    ) {
                      individualModule = obj;
                    } else if (obj.layout !== 'dashboard') {
                      individualModule = obj.compList.find(
                        (val) => val.compId == startLoc[0]
                      );
                    }
                  }
                  if (
                    this.headerFooterObj.tabs.find(
                      (val) => val.tabId === obj.tabId
                    )
                  ) {
                    tmpobj['title'] = this.headerFooterObj.tabs.find(
                      (val) => val.tabId === obj.tabId
                    ).tabName;
                  }
                  if (obj.layout == 'tiles' && obj.defaultComp == 'landing') {
                    tmpobj['subroute'] = 'tiles';
                  } else if (
                    obj.layout == 'plan' &&
                    obj.defaultComp == 'landing'
                  ) {
                    tmpobj['subroute'] = 'plan';
                  } else if (
                    obj.layout == 'dashboard' &&
                    obj.defaultComp == 'landing'
                  ) {
                    tmpobj['subroute'] = 'dashboard';
                  } else if (obj.defaultComp != '') {
                    tmpobj['subroute'] =
                      routeObj[obj.defaultComp].itemConfig.url;
                  } else {
                    tmpobj['subroute'] =
                      routeObj[obj.compList[0].moduleId].itemConfig.url;
                  }

                  tmpobj['root'] = obj.tabId;

                  /**hide tab(s) if this tab does not contain tiles */
                  if (
                    obj.layout !== 'tiles' ||
                    (obj.compList && obj.compList.length > 0)
                  ) {
                    this.menuItems.push(tmpobj);
                  }

                  /*If the accountType is not 'Guest' then, all the modules will be loaded onto the Browser,
                     which means that the user is already registered and has privelege to access different
                     modules in the application except the GuestAccount module*/
                  if (individualModule) {
                    first = 'done';
                    // this.loadModules(tmpobj);
                    moduleLoadObj = tmpobj;
                    // this.router.navigate(['./' + obj.tabId + '/' + obj.layout], { relativeTo: this.activatedRoute });
                    this.storageService.sessionStorageSet('tabId', obj.tabId);
                  } else if (
                    !this.storageService.sessionStorageGet('tabId') &&
                    this.userObj.accountType !== 'Guest'
                  ) {
                    if (
                      (!first && tmpobj['subroute'] !== 'plan') ||
                      (!first &&
                        (obj.compList.length > 0 ||
                          (this.plansData && this.plansData.length > 0)) &&
                        tmpobj['subroute'] === 'plan')
                    ) {
                      first = 'done';

                      moduleLoadObj = tmpobj;

                      this.storageService.sessionStorageSet('tabId', obj.tabId);
                    }
                  }
                }
              }.bind(this)
            );

          if (moduleLoadObj) {
            this.loadModules(moduleLoadObj);
          }
          this.utils.appendDynamicColors(res.colors);
          this.utils.appendDynamicIconStyle();
        }
        if (this.plansData && this.plansData.length === 0) {
          const removeIndex = this.menuItems
            .map(function (item) {
              return item.subroute;
            })
            .indexOf('plan');
          //Without this logic the education tab will not display for restricted users.
          if (removeIndex >= 0) {
            this.menuItems.splice(removeIndex, 1);
          }
        }
      });
    this.frameworkTabItems.tabItems.map((obj) => {
      if (obj.layout == 'tiles') {
        obj.compList.map((obj1) => {
          if (obj1.compId.includes('sort') || obj1.compId.includes('file')) {
            this.combineAllcomp.push(obj1);
          }
        });
      }
    });
    //Checking event on browser refresh
    if (this.router.navigated) {
      const urlArr = this.router.url.split('/');
      // this.setDocumentTitle(urlArr);
    }
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        const urlArr = event.url.split('/');
        // this.setDocumentTitle(urlArr);
        const lastItem = urlArr[urlArr.length - 1];
        if (lastItem === 'app' || lastItem === 'loading') {
          this.routeService.mainLogOut();
        }
        // To dismiss all the modal-ups when we do browser back navigation(eg, from assessment to Dashboard)
        this.modalService.dismissAll();
      }
    });

    /* Getting personal information data from api in framework component*/
    this.personalInfoStore
      .pipe(
        select(dashboardPersonalInfoSelector),
        takeWhile(() => this.compActive)
      )
      .subscribe((personalInfoResponse) => {
        // console.log('dashboard personalInfoResponse', personalInfoResponse);
        this.personalData = personalInfoResponse;
      });
  }
  accountSetting() {
    this.router.navigate(['../app/account'], {
      relativeTo: this.activatedRoute,
    });
  }
  /**
   * This method is for loading the modules.
   * @param item declare for storing that which module is getting loaded.
   */

  loadModules(item) {
    this.utils.showLoading();
    this.storageService.sessionStorageRemove('planIndex');
    this.storageService.sessionStorageRemove('IsPlanIsPlanViewMode');
    this.storageService.sessionStorageRemove('planId');
    this.storageService.sessionStorageRemove('itemId');
    this.storageService.sessionStorageRemove('CustomAttr');
    const evnt = document.createEvent('CustomEvent');
    evnt.initCustomEvent('FooterDispatch', true, true, '');
    this.eventService.dispatch(evnt);
    /*Due to this code, the background page is navigating to plp homepage without any user responce.
      In order to avoid this error, I have removed below code.
      Now, the navigation will be done as per user responce for warning model popup.*/
    /*if (item.subroute == 'plan') {
        this.store.dispatch(new AddItem(0));
        this.store.dispatch(new ChangePlanView(true))
    }*/
    if (
      item.subroute == 'plan' &&
      !this.storageService.sessionStorageGet('loginPlansConfiguration')
    ) {
      // console.log('plan dispatched');
      this.storageService.sessionStorageSet('tabId', item.root);
      // document.title = this.appTitle;
      const url = '../app/' + item.root + '/' + item.subroute;
      this.storageService.sessionStorageSet('planPath', url);
      // const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('PlanLoad', true, true);
      this.eventService.dispatch(evnt);
    } else {
      // document.title = this.appTitle;
      this.router
        .navigate(['../app/' + item.root + '/' + item.subroute], {
          relativeTo: this.activatedRoute,
        })
        .then((val) => {
          // if user clicked yes for Unsaved changes will be lost(modal pop up) then we are setting manu tab id in session storage
          if (val) {
            this.storageService.sessionStorageSet('tabId', item.root);
          }
        });
    }
    this.utils.hideLoading();
  }

  @HostListener('window:mousedown', ['$event'])
  onmousedown(event) {
    const currentDate = new Date();
    const currentSeconds = currentDate.getTime();
    this.storageService.sessionStorageSet('currentSec', currentSeconds + '');
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    this.onmousedown(event);
  }

  hideFil(val) {
    this.storageService.sessionStorageSet('careerShow', '0');
    if (window.innerWidth <= 991) {
      this.clickBtn = true;
      if (val == 'btn') {
        if (this.hideFilter == true) {
          this.hideFilter = false;
          this.clickBtn = true;
          const evnt = document.createEvent('CustomEvent');
          evnt.initEvent('menuBar', true, true);
          this.eventService.menucloseDispatch(evnt);
        } else if (this.hideFilter == false) {
          this.hideFilter = true;
          this.clickBtn = false;
        }
      } else if (val == 'bar') {
        if (this.hideFilter == false) {
          this.hideFilter = true;
          this.clickBtn = false;
        } else {
          this.clickBtn = false;
        }
      }
    }
  }

  /**
   * This method gets executed when we click on logout.
   */
  logout() {
    this.routeService.mainLogOut();
  }

  isActive(instruction: any[]): boolean {
    const v1 = this.router.isActive(
      this.router.createUrlTree(['app/' + instruction]),
      true
    );
    const v2 = this.router.isActive(
      this.router.createUrlTree(['app/' + instruction]),
      false
    );
    return v2;
  }

  /**
   * This method is for selecting the language in the menu.
   * @param langObj declare for storing the language values.
   */
  selectLang(langObj) {
    const ref = this;
    if (ref.changedLang != langObj.name) {
      this.utils.showLoading();
      // The changed location from after the event dispatch to before because don't want to delay the session setting of lang in sessions as it plays a crucial role in individual assessment modules. Even before the event is dispatched, it should be available wherever needed in that particular assessment.
      ref.storageService.sessionStorageSet('langset', langObj.lang);
      setTimeout(
        function () {
          const evnt = document.createEvent('CustomEvent');
          evnt.initEvent('languageChanged', true, true);
          ref.eventService.dispatch(evnt);
        }.bind(this),
        100
      );

      ref.changedLang = langObj.name;
      this.storageService.sessionStorageSet('langChanged', 'true');
      // const payloajson = {
      //     type: 'OCC_INDEX_STORE_TEXT', payload: {
      //         methodVal: 'GET', module_Name: 'Occ/v1/',
      //         path_params: ['index', this.utils.getAccountId()], query_params: { 'lang': langObj.lang },
      //         body_Params: {}, endUrlVal: 'pages'
      //     }
      // };
      //this.commonlang.commonLanguageChange(langObj.lang, 'OCC_index_list', payloajson);

      // this.dispatchStore.dispatch({
      //     type: 'OCC_TEXT', payload: {
      //         methodVal: 'GET', module_Name: 'Occ/v1/',
      //         path_params: ['text'], query_params: { 'lang': langObj.lang },
      //         body_Params: {}, endUrlVal: 'pages'
      //     }
      // });

      const accountType = 'Restricted';
      const accountTypeValue =
        this.accountTypeStore === accountType ? 'siteId' : 'accountID';
      const endurlpath =
        this.accountTypeStore === accountType ? 'framework/site' : 'framework';
      this.dispatchStore.dispatch({
        type: 'GET_HEADERFOOTER_TEXT',
        payload: {
          methodVal: 'GET',
          module_Name: 'Settings/v1/',
          path_params: ['headerFooter', accountTypeValue],
          query_params: { lang: langObj.lang },
          body_Params: {},
          endUrlVal: endurlpath,
        },
      });

      this.dispatchStore.dispatch({
        type: 'GET_TABITEMS_TEXT',
        payload: {
          methodVal: 'GET',
          module_Name: 'Settings/v1/',
          path_params: ['tabItems', accountTypeValue],
          query_params: { lang: langObj.lang },
          body_Params: {},
          endUrlVal: endurlpath,
        },
      });
      let factorArr = {};
      factorArr = JSON.parse(this.storageService.sessionStorageGet('testArr'));
      this.utils.hideLoading();
    }
  }
  disableLanguageChanged() {
    return this.storageService.sessionStorageGet('languageDisabled')
      ? 'true'
      : null;
  }
  getLanguageTitle() {
    return this.storageService.sessionStorageGet('languageDisabled');
  }
  // Chrome allows you to simply tweak the HREF of the LINK tag.
  // Firefox appears to require that you remove it and readd it.
  setFavicon(url) {
    this.removeFavicon();
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  getFavicon() {
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute('rel') === 'icon') {
        return links[i];
      }
    }
    return undefined;
  }
  removeFavicon() {
    const links = document.getElementsByTagName('link');
    const head = document.getElementsByTagName('head')[0];
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute('rel') === 'icon') {
        head.removeChild(links[i]);
      }
    }
  }
  navigateEnterprise() {
    this.utils.showLoading();
    const a = document.createElement('a');
    this.serviceDashboard
      .getEnterprise()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (
          this.deviceService.getDeviceInfo().browser === 'Safari' &&
          parseInt(
            this.deviceService.getDeviceInfo().browser_version.split('.')[0]
          ) >= 14
        ) {
          response.text().then((text) => {
            const blob = new Blob([text], { type: 'text/html' });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.click();
          });
        } else {
          const url = window.URL.createObjectURL(response);
          a.href = url;
          a.click();
        }
        this.utils.hideLoading();
      });
  }

  // A Function for setting document title on every component action and on browser refresh
  setDocumentTitle(urlArr) {
    const lastItem = urlArr[urlArr.length - 1];
    if (
      lastItem === 'tiles' ||
      lastItem === 'dashboard' ||
      lastItem === 'plan'
    ) {
      document.title = this.appTitle;
    } else {
      const asmntHdr = this.storageService.sessionStorageGet('activeSortId');
      if (asmntHdr) {
        const asmntObj = this.combineAllcomp.find((x) => x.compId === asmntHdr);
        if (asmntObj) {
          document.title = asmntObj.compName;
        } else {
          this.frameConfigDetails.tabItems.map((x) => {
            const compIdObj = x.compList.find((x) => x.compId === asmntHdr);
            if (compIdObj) {
              document.title = compIdObj.compName;
            }
          });
        }
      }
    }
  }
  /**
   * This method is used for unsubscribing the event.
   */

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.compActive = false;
  }
  /**title service */
  public setDocTitle(title: string) {
    this.titleService.setTitle(title + ' - CIS360 Home');
  }
}
