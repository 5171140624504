import {
  defaultOcc_EmpOutlook_Wages_State,
  defaultOcc_Buttons_Reducer_Text_State,
  defaultOcc_Assessment_Filter_State,
  defaultOcc_Details_AtGlance_State,
  defaultOcc_Detail_JobDescription_State,
  defaultOcc_Detail_Wages_State,
  defaultOcc_Detail_Employment_Outlook_State,
  initOcc_Detail_Education_Training_State,
  initCareer_Details_Personal_Qualities_State,
  Career_Detail_Related_Careers_State,
  initCareer_Detail_Related_Careers_State,
  initCareer_Detail_Header_State,
} from '../occ-state/occ-state';
import { defaultOcc_Settings_Tab_Text_State } from '../occ-state/occ-state';
import { defaultOcc_Index_Reducer_Text_State } from '../occ-state/occ-state';
import { Action } from '../occ-action/occ-action';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

export function Occ_EmpOutlook_Wages_Reducer(
  state = defaultOcc_EmpOutlook_Wages_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_SECTIONS_TEXT': {
      state = action.payload;
      return state;
    }
    case 'SET_OCC_EMP_OUTLOOK': {
      state['MajorEmployers'] = action.payload.MajorEmployers;
      state['OutlookInfo'] = action.payload.OutlookInfo;
      state['OutlookRatings'] = action.payload.OutlookRatings;
      return state;
    }
    case 'SET_OCC_WAGES': {
      state['WageLevels'] = action.payload.WageLevels;
      return state;
    }
    case 'RESET_OCC_PAGE': {
      state = { Occ_EmpOutlook_Wages: '' };
    }
    default: {
      return state;
    }
  }
}
export function Occ_Settings_Tab_Text_Reducer(
  state = defaultOcc_Settings_Tab_Text_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_OCC_SETTING_TABS_TEXT': {
      state = { Occ_Settings_Tab_Text: action.payload };
      return state;
    }
    case 'RESET_OCC_SETTING_TABS_TEXT': {
      state = { Occ_Settings_Tab_Text: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
export function Occ_Index_Reducer_Text_Reducer(
  state = defaultOcc_Index_Reducer_Text_State,
  action: Action
) {
  try {
    const checknull = StorageService.staticSessionStorageGet('OccIndexReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('Occ_Index_Reducer_Text reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_OCC_INDEX_STORE_TEXT': {
      state = { Occ_Index_Reducer_Text: action.payload };

      StorageService.staticSessionStorageSet(
        'OccIndexReducerText',
        JSON.stringify(state)
      );
      return state;
    }
    default: {
      return state;
    }
  }
}
export function Occ_Buttons_Reducer_Text(
  state = defaultOcc_Buttons_Reducer_Text_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_OCC_BUTTONS_TEXT': {
      state = { Occ_Buttons_Reducer_Text: action.payload };
      StorageService.staticSessionStorageSet('Reducer_OccText', JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}
export function Occ_Assessment_Filter_Reducer(
  state = defaultOcc_Assessment_Filter_State,
  action: Action
) {
  try {
    const checknull = StorageService.staticSessionStorageGet('OccAssessmentReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);
    }
  } catch (e) {
    console.log('Occ_Assessment_Filter_Reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_OCC_ASSESSMENT_FILTER_TEXT': {
      state = { Occ_Assessment_Filter_Text: action.payload };
      StorageService.staticSessionStorageSet(
        'OccAssessmentReducerText',
        JSON.stringify(state)
      );
      return state;
    }
    default: {
      return state;
    }
  }
}

/**new reducerd for tab state stoes of career details  */
export function Career_Detail_AtGlance_Reducer(
  state = defaultOcc_Details_AtGlance_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_ATGLANCE_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_ATGLANCE_TAB': {
      state = { Occ_Details_At_Glance: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Job Description tab reducer, Career Detail view
export function Career_Detail_JobDescription_Reducer(
  state = defaultOcc_Detail_JobDescription_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_JOBDESCRIPTION_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_JOBDESCRIPTION_TAB': {
      state = { Occ_Detail_Job_Description: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
//Wages tab reducer, Career Detail view
export function Career_Detail_Wages_Reducer(
  state = defaultOcc_Detail_Wages_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREERDETAIL_WAGES_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREERDETAIL_WAGES_TAB': {
      state = { Occ_Detail_Wages: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Employment & Outlook tab reducer, Career Detail view
export function Career_Detail_EmploymentOutlook_Reducer(
  state = defaultOcc_Detail_Employment_Outlook_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_EMPLOYMENT_OUTLOOK_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_EMPLOYMENT_OUTLOOK_TAB': {
      state = { Occ_Detail_Employment_Outlook: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
//Education & Training tab reducer, Career Detail view
export function Career_Detail_EducationTraining_Reducer(
  state = initOcc_Detail_Education_Training_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_EDUCATION_TRAINING_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_EDUCATION_TRAINING_TAB': {
      state = { Occ_Detail_Education_Training: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Personal Qualities tab reducer, Career Detail view
export function Career_Detail_PersonalQualities_Reducer(
  state = initCareer_Details_Personal_Qualities_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_PERSONAL_QUALITIES_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_PERSONAL_QUALITIES_TAB': {
      state = { Career_Detail_Personal_Qualities: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Related Careers tab reducer, Career Detail view
export function Career_Detail_RelatedCareers_Reducer(
  state = initCareer_Detail_Related_Careers_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_RELATED_CAREERS_TAB': {
      state = action.payload;
      return state;
    }
    case 'RESET_RELATED_CAREERS_TAB': {
      state = { Career_Detail_Related_Careers: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

//Detail Header reducer, Career Detail view
export function Career_Detail_Header_Reducer(
  state = initCareer_Detail_Header_State,
  action: Action
) {
  switch (action.type) {
    case 'SET_CAREER_DETAIL_HEADER': {
      state = action.payload;
      return state;
    }
    case 'RESET_CAREER_DETAIL_HEADER': {
      state = { Career_Detail_Header: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
