
/** Angular imports */
import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

/** Custom imports */
import { StorageService } from '../outer-services/storage.service';
import { NgbdModalContent } from '../modal/shared-modal-component';



@Injectable()
export class UnSavedChangesCanActive implements CanDeactivate<any> {

    commonModelText;
    options: NgbModalOptions = {
        backdrop: 'static',
    };
    constructor(private store: Store<any>, private router: Router, private storageService: StorageService, private modalService: NgbModal) {

    }
    canDeactivate(component: any): any {
        const sessExp = this.storageService.sessionStorageGet('sessionExpired');
        if (component.saveChanges() && sessExp != 'true') {
            return this.confirm();
        }
        this.storageService.sessionStorageRemove('sessionExpired');
        return true;
    }

    confirm() {
        const modalRef = this.modalService.open(NgbdModalContent, this.options);
        if (this.storageService.sessionStorageGet('savePartial') == 'yes') {
            modalRef.componentInstance.commonButtons = { unsavedTxt: 'quitMsg', headsection: 'alert', yesbtn: 'yes', nobtn: 'no' };
        } else {
            modalRef.componentInstance.commonButtons = { unsavedTxt: 'startOverMsg', headsection: 'alert', yesbtn: 'yes', nobtn: 'no' };
        }
        modalRef.componentInstance.valueofunchange = 'unsavedcheck';
        return modalRef.result;
    }

}
