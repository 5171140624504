/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

/**Services **/
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJr_Settings_Tab_Text_State, CareersJr_EmpOutlook_Wages_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';

@Component({
  selector: 'things-to-know-emerging-careersJr',
  templateUrl: './things-to-know-emerging.layout.html',
})
export class ThingsToKnowEmergingCareersJrComponent
  implements OnInit, OnDestroy
{
  expandCard = 0; /** A variable to tell which card to open first */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  careerJrCareerStore; /** Is a variable that is used to store data coming from reducer */
  eventSub = new Subscription();
  occSettingsSub = new Subscription();
  settingsTextTab;
  descriptionExpand = 999; //T show/hide the MoreDescription accordion cards
  occName;
  jobDescPrintSub: Subscription;
  printPage = false;

  @ViewChild('atJobdescPrintPage', { static: true })
  atJobdescPrintPage: ElementRef;

  @Input('mainlogo') mainlogo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  occPageSub = new Subscription();

  constructor(
    private router: Router,
    private careerJrDispatch: CareersJrDispatchService,
    private activatedRoute: ActivatedRoute,
    private careerJrSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private utils: Utilities,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    // Subsribing an event, when job task or setting was clicked in at-a-glance
    this.jobDescPrintSub = this.careerJrDispatch
      .careerJobDescriptionListen()
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
    this.eventSub = careerJrDispatch.listen().subscribe((e) => {
      if (e.type == 'job_Setting') {
        this.expandCard = 2;
      } else if (e.type == 'Job Description') {
        this.expandCard = 0;
      } else if (e.type === 'emergingEventPrint1') {
        this.careerPrint();
      }
    });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = careerJrSetstore
      .select('CareersJr_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 1) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = this.OCCPageStateStore.select(
      'CareersJr_EmpOutlook_Wages'
    ).subscribe((v) => {
      this.careerJrCareerStore = v;
    });
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
    });
  }
  async careerPrint() {
    const element = this.atJobdescPrintPage.nativeElement;
    const opt = {
      filename: 'Emerging Career Jr - Things to Know.pdf',
      image: { type: 'svg', quality: 0.98 },
      margin: [3, 0, 5, 0],
      html2canvas: { scale: 1.5 },
      jsPDF: { format: 'A4', compress: true },
      pagebreak: {
        avoid: '.break-avoid',
        mode: ['auto', 'css', 'legacy', 'whiteline'],
      },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** Called when a card is clicked to open or close */
  methodfilter(valfil) {
    if (this.expandCard != valfil) {
      this.expandCard = valfil;
      this.descriptionExpand = valfil; //We are getting the description accordion cards in a loop. So based on the index we are showing the open accordion card and remaining all of the cards will be closed and we are assigning the variable to 999(to close).
    } else {
      this.descriptionExpand = 999;
      this.expandCard = 999;
    }
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.eventSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.jobDescPrintSub.unsubscribe();
  }
}
