import { Injectable, OnDestroy } from '@angular/core';
import { questionsArr } from '../../../../shared/constants/app.constants';
import { EndUrlArr } from '../../../../shared/constants/app.constants';
import { returnUrl } from '../../../../shared/constants/app.constants';
import { tableNoData } from '../../../../shared/constants/app.constants';
import { successMessageArr } from '../../../../shared/constants/app.constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContent } from '../../../../shared/modal/shared-modal-component';
import { EventDispatchService } from '../../../../shared/outer-services/event-dispatch.service';

/** Importing route config */
import {
  routeObj,
  sectionsArr,
} from '../../../../shared/constants/app.constants';
@Injectable()
export class PLPSharedService implements OnDestroy {
  sectionsList;
  questionsList;
  endUrlObj;
  returnUrl;
  noDataList;
  messages;
  successMessageList;

  /*This constructor initializes values*/
  constructor(
    private modalService: NgbModal,
    private eventService: EventDispatchService
  ) {
    this.sectionsList = sectionsArr;
    this.questionsList = questionsArr;
    this.endUrlObj = EndUrlArr;
    this.returnUrl = returnUrl;
    this.noDataList = tableNoData;
    this.successMessageList = successMessageArr;
  }

  ngOnDestroy() {}

  open(text) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = 'World';
    modalRef.componentInstance.transVal = text;
  }

  /*This function returns the list of all section objects
   */
  getServiceList() {
    return this.sectionsList;
  }

  /*This function returns the list of specific section objects
   */
  getSectionObject(section) {
    try {
      this.sectionsList = sectionsArr;
      let sectionObject;
      if (this.sectionsList.length > 0) {
        this.sectionsList.forEach((obj, key) => {
          if (
            obj.section + '' == section + '' ||
            obj.routerLink == section + ''
          ) {
            obj.totalCount = this.sectionsList.length;
            obj.currentCount = key + 1;

            if (key != 0) {
              obj.previousSec = this.sectionsList[key - 1].section;
              obj.previousSecLink = this.sectionsList[key - 1].routerLink;
            }

            if (key != this.sectionsList.length - 1) {
              obj.nextSec = this.sectionsList[key + 1].section;
              obj.nextSecLink = this.sectionsList[key + 1].routerLink;
            }
            sectionObject = obj;
          }
        });
      }
      return sectionObject;
    } catch (e) {
      console.log('exception in getSectionObject function' + e.message);
    }
  }

  /****this is for plans module */
  getPlanSectionObject(itemID) {
    try {
      this.sectionsList = sectionsArr;
      let sectionObject;
      if (this.sectionsList.length > 0) {
        this.sectionsList.forEach((obj, key) => {
          //  console.log('getPlanSectionObject:', obj);
          if (obj.ItemID && obj.ItemID == itemID) {
            obj.totalCount = this.sectionsList.length;
            obj.currentCount = key + 1;

            if (key != 0) {
              //  console.log('this.sectionsList[key - 1]:', this.sectionsList[key - 1]);
              obj.previousSec = this.sectionsList[key - 1].section;
              obj.prevItemID = this.sectionsList[key - 1].ItemID;
              obj.previousSecLink = this.sectionsList[key - 1].routerLink;
            }

            if (key != this.sectionsList.length - 1) {
              obj.nextSec = this.sectionsList[key + 1].section;
              obj.nextItemID = this.sectionsList[key + 1].ItemID;
              obj.nextSecLink = this.sectionsList[key + 1].routerLink;
            }
            sectionObject = obj;
          }
        });
      }
      // console.log('sectionObject--->', sectionsArr);
      return sectionObject;
    } catch (e) {
      console.log('exception in getPlanSectionObject function' + e.message);
    }
  }
  /*This function returns the list of specific section objects
   */
  getQuestion(section) {
    let questionObject;
    if (this.questionsList.length > 0) {
      this.questionsList.forEach((obj, key) => {
        if (obj.section + '' == section + '') {
          questionObject = obj;
        }
      });
    }

    return questionObject;
  }

  // This function returns entire endUrlObj based on section name.
  getUrlObject(section) {
    let urlObj;
    if (this.endUrlObj.length > 0) {
      this.endUrlObj.forEach((obj, key) => {
        if (obj.section + '' == section + '') {
          urlObj = obj;
        }
      });
    }
    return urlObj;
  }

  // This function is used to show the no data information in the tables
  getTableNoData(section) {
    let noData;
    if (this.noDataList.length > 0) {
      this.noDataList.forEach((objF, key) => {
        objF.section.forEach((obj, key1) => {
          if (obj + '' == section + '') {
            noData = objF.text;
          }
        });
      });
    }

    return noData;
  }

  // the below function is to display the success data success message
  getSuccessMessage(section) {
    let message;
    if (this.successMessageList.length > 0) {
      this.successMessageList.forEach((objF, key) => {
        objF.section.forEach((obj, key1) => {
          if (obj + '' == section + '') {
            message = objF;
          }
        });
      });
    }
    return message;
  }

  isJsonChanged(previous, latest) {
    if (JSON.stringify(previous) === JSON.stringify(latest)) {
      return false;
    } else {
      return true;
    }
  }
}
export function plpSectionFunction(plpObject) {
  if (sectionsArr.length != 0) {
    sectionsArr.length = 0;
  }

  plpObject.compList.forEach(function (objinr, inxinr) {
    if (
      routeObj[objinr.compId] !== undefined &&
      routeObj[objinr.compId].itemConfig !== undefined
    ) {
      const sectionObj = {
        routerLink: routeObj[objinr.compId].routeConfig.path,
        section: routeObj[objinr.compId].itemConfig.section,
        SectionCode: routeObj[objinr.compId].itemConfig.apiName,
        title: objinr.displayName,
        icon: routeObj[objinr.compId].itemConfig.icon,
        ItemID: 0,
      };
      if (objinr.ItemID) {
        // sectionObj.section = sectionObj.section + objinr.ItemID;
        sectionObj.ItemID = objinr.ItemID;

        // routeObj[objinr.compId].itemConfig.section = routeObj[objinr.compId].itemConfig.section + objinr.ItemID;
      }
      // console.log('plpSectionFunction:', sectionObj, ':objinr:', objinr);
      sectionsArr.push(sectionObj);
    }
  });
}
