/**Angular Libraries **/
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  Inject,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Action, Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

/**Services **/
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import {
  Occ_Settings_Tab_Text_State,
  Occ_EmpOutlook_Wages_State,
  Occ_Details_AtGlance_State,
  Occ_Detail_JobDescription_State,
} from '../../occ-state-management/occ-state/occ-state';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

@Component({
  selector: 'job-description',
  templateUrl: './job-desc-layout.html',
})
export class JobDescriptionComponent implements OnInit, OnDestroy {
  expandCard = 0; /** A variable to tell which card to open first */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  eventSub = new Subscription();
  occSettingsSub = new Subscription();
  settingsTextTab;
  descriptionExpand = 999; //T show/hide the MoreDescription accordion cards
  occName;
  subscription: Subscription;
  jobDescPrintSub: Subscription;
  printPage = false;
  occAlive: boolean = true;
  @ViewChild('atJobdescPrintPage', { static: true })
  atJobdescPrintPage: ElementRef;

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;

  states: Array<string> = ['US', StorageService.staticSessionStorageGet('stateAbr')];
  langVal: string = StorageService.staticSessionStorageGet('langset')
    ? StorageService.staticSessionStorageGet('langset')
    : 'en';
  occID: string = StorageService.staticSessionStorageGet('occIDval')
    ? StorageService.staticSessionStorageGet('occIDval')
    : '0';

  constructor(
    private router: Router,
    private occDispatch: OccDispatchService,
    private activatedRoute: ActivatedRoute,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private utils: Utilities,
    private storageService: StorageService,
    private dispatchStore: Store<Action>,
    private CareerDetailJobDescriptionStore: Store<Occ_Detail_JobDescription_State>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string
  ) {
    // Subsribing an event, when job task or setting was clicked in at-a-glance
    this.jobDescPrintSub = this.occDispatch
      .careerJobDescriptionListen()
      .subscribe(() => {
        this.utils.showLoading();
        this.careerPrint();
      });
    this.eventSub = occDispatch.listen().subscribe((e) => {
      if (e.type == 'job_Setting') {
        this.expandCard = 2;
      } else if (e.type == 'job_Task') {
        this.expandCard = 5;
      } else if (e.type == 'Job Description') {
        this.expandCard = 0;
      }
    });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = occSetstore
      .select('Occ_Settings_Tab_Text')
      .subscribe((v) => {
        this.settingsText = v;
        if (
          this.settingsText.Occ_Settings_Tab_Text.tabs != null &&
          this.settingsText.Occ_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 1) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });

    /**get job description text */
    this.occCareerStore = this.CareerDetailJobDescriptionStore.pipe(
      select('Career_Detail_Job_Description')
    );
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
    });

    try {
      this.dispatchStore.dispatch({
        type: 'GET_DETAIL_JOBDESCRIPTION',
        payload: {
          methodVal: 'GET',
          module_Name: 'Occ/v1/',
          path_params: ['occ', this.occID, 'accountID'],
          query_params: {
            sections:
              'OverView,TaskList,SkillsAbilities,WorkingConditions,PhysicalDemands,MoreDescription', //this is to get all tab text
            states: this.states,
            lang: this.langVal,
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    } catch (e) {
      console.log('error------>' + e.message);
    }
  }
  async careerPrint() {
    const element = this.atJobdescPrintPage.nativeElement;
    const opt = {
      filename: 'Career - Job Description.pdf',
      image: { type: 'svg', quality: 0.98 },
      margin: [0.1, 0, 0.2, 0],
      html2canvas: { scale: 1.5 },
      jsPDF: { format: 'A4', compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** Called when a card is clicked to open or close */
  methodfilter(valfil) {
    if (this.expandCard != valfil) {
      this.expandCard = valfil;
      this.descriptionExpand = valfil; //We are getting the description accordion cards in a loop. So based on the index we are showing the open accordion card and remaining all of the cards will be closed and we are assigning the variable to 999(to close).
    } else {
      this.descriptionExpand = 999;
      this.expandCard = 999;
    }
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occAlive = false;
    this.occSettingsSub.unsubscribe();
    this.eventSub.unsubscribe();
    this.jobDescPrintSub.unsubscribe();
  }
}
