import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'home/0', pathMatch: 'prefix' },
      {
        path: 'home/0',
        data: { testType: 0 },
        component: HomeComponent,
      },
      {
        path: 'home/1',
        data: { testType: 1 },
        component: HomeComponent,
      },
      {
        path: 'home/2',
        data: { testType: 2 },
        component: HomeComponent,
      },
      {
        path: 'home/3',
        data: { testType: 3 },
        component: HomeComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PracticeTestRoutingModule { }
