import { Component } from '@angular/core';
import { OccCommonMethodsService } from './occ-services/occ-common-methods.service';
import { StorageService } from '../../../shared/outer-services/storage.service';
@Component({
  selector: 'occ-detail-static-header',
  template: ``,
})
export class OccDetailStaticHeaderComponent {
  menuState = false;
  userName = '';
  constructor(private occCommonService: OccCommonMethodsService,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.userName = this.storageService.sessionStorageGet('userName');
  }
  logout() {
    this.occCommonService.exitApp('loggedout');
  }

  menuToggle() {
    this.menuState = !this.menuState;
  }
  menuClose() {
    this.menuState = false;
  }
}
