import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { OCCPageState, OccSettingsTextState } from '../../../../../state-management/state/main-state';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Occ_Settings_Tab_Text_State, Occ_EmpOutlook_Wages_State } from '../../occ-state-management/occ-state/occ-state';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import html2pdf from 'html2pdf.js';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'related-careers-cluster',
  templateUrl: './related-careers-layout.html',
})

export class RelatedCareerClusterComponent implements OnInit, OnDestroy {
  occClusterStore; /**Declare for storing the cluster text from store.*/
  settingsTextTab; /**Declare for storing the tab values from store.*/
  settingsText; /**Declare for storing the section names from store.*/
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  printview = false;
  compActive = true;
  @Input('relatedcareersData') relatedcareersData: any = {};
  @ViewChild('clusRelatedCareers', {static: true}) clusRelatedCareers: ElementRef;

  @Input('logo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  constructor(
    private activatedRoute: ActivatedRoute,
    private occDispatch: OccDispatchService,
    private router: Router,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>, private utils: Utilities
  ) {
    this.relatedcareersData['RelatedCareers'] = { 'lists': [] };
    this.OCCPageStateStore.pipe(select('Occ_EmpOutlook_Wages'), takeWhile(() => this.compActive)).subscribe((resp) => {
      this.occClusterStore = resp;
    });
    this.occSetstore.pipe(select('Occ_Settings_Tab_Text'), takeWhile(() => this.compActive)).subscribe((v) => {
      this.settingsText = v;

     if (this.settingsText.Occ_Settings_Tab_Text.tabs) {
       this.settingsTextTab = this.settingsText.Occ_Settings_Tab_Text.tabs.find((obj) => (obj['tabId'] === 4));
     }
   });
     this.occDispatch.clusterReatedCareerListen().pipe(takeWhile(() => this.compActive)).subscribe(() => {
      this.utils.showLoading();
      this.clusterPrint();
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {
      /**
       * Defaults to 0 if no query param provided.
       * */
      this.clusterIcon = params['clusIcon'];
    });

  }

  /**
   * This method is for navigating from cluster to the career.
   * @param id is for storing the careerID.
   * @param name is for storing the cluster name.
   */
  CallOccDetailCareer(id, name) {
    const twoDigit = id.toString().substr(0, 2);
    if (twoDigit == 14) {
      this.router.navigate(['../occEmergCareer'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          occid: id, occname: name, clusIcon: this.relatedcareersData['clusterIcon'],
          clusColor: this.relatedcareersData['clusterColor']
        }
      });
    } else {
      this.router.navigate(['../occCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    }
  }
  async clusterPrint() {
    const data = this.clusRelatedCareers.nativeElement;
    const opt = {
      filename: 'Career Cluster - Related Careers.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      margin: [0.1, 0, 0.2, 0],
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }

  /**
     * This ngOnDestroy() function is call after Component destory.
     */
  ngOnDestroy() {
    this.compActive = false;
  }
}


