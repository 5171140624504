export const headerButtons = {
    intro: ['restore'],
    restore: ['assessName'],
    assessment: ['startover', 'partialsave'],
    result: ['startover', 'resultsave', 'Report', 'Back_Career'],
    occlist: ['startover', 'Back_Result', 'Back_Career'],
    chooseCareer: ['startover', 'restore'],
    factors: ['startover'],
    comparison: ['startover'],
    grade: ['partialsave']
};

export const pieColors = {
    'conventional': '#e57373', 'artistic': '#01b7c4',
    'realistic': '#ff7143', 'investigative': '#045978',
    'social': '#b0006c', 'enterprising': '#68c083'
};
export const pieLinksColors = {
    'Conventional': '#e57373', 'Artistic': '#01b7c4',
    'Realistic': '#ff7143', 'Investigative': '#045978',
    'Social': '#b0006c', 'Enterprising': '#68c083'
};
export const rcIcons = {
    'House': 'icon-home', 'Food': 'icon-plate-fork-and-knife', 'Loan': 'icon-gradution-cap', 'Util': 'icon-light',
    'Misc': 'icon-sitting-dog', 'Save': 'icon-pig', 'Hlth': 'icon-first-aid-kit', 'Entr': 'icon-tv',
    'Clth': 'icon-t-shirt-black-silhouette', 'Pers': 'icon-bath-tub', 'Trans': 'icon-sports-car', 'Family': 'icon-baby'
};
export const rccolors = {
    'House': '#96c300', 'Food': '#024892', 'Loan': '#1180c4', 'Util': '#2fa41b',
    'Misc': '#ff7f00', 'Save': '#f8df00', 'Hlth': '#90009b', 'Entr': '#e0119d',
    'Clth': '#55009b', 'Pers': '#e7292b', 'Trans': '#002898', 'Family': '#38425a'
};
export const wilCardColors = {
    'independence': '#669601',
    'workConditions': '#ab47bc',
    'relationships': '#96015d',
    'support': '#0c8cbd',
    'recognition': '#0a8f72',
    'achievement': '#510456',
    'working Conditions': '#ab47bc',
};

export const wilCardlinkColors = {
    'Independence': '#669601',
    'Working Conditions': '#ab47bc',
    'Relationships': '#96015d',
    'Support': '#0c8cbd',
    'Recognition': '#0a8f72',
    'Achievement': '#510456',
};
export const wesIcons = {
    'communication': '/assets/images/plp-4-1.png',
    'collaboration': '/assets/images/plp-4-2.png',
    'thinking': '/assets/images/plp-4-3.png',
    'professional': '/assets/images/plp-4-4.png',
    'initiative': '/assets/images/plp-4-6.png',
    'intergenerational': '/assets/images/plp-4-7.png',
    'organizational': '/assets/images/plp-4-8.png',
    'legal': '/assets/images/plp-4-9.png',
    'financial': '/assets/images/plp-4-5.png'
};
export const aeIcons = {
    'Artistic/Design': '/assets/images/artistic.png',
    'Clerical': '/assets/images/keybord.png',
    'Interpersonal': '/assets/images/has-family-support.png',
    'Language': '/assets/images/pen.png',
    'Leadership': '/assets/images/mick.png',
    'Manual': '/assets/images/tools.png',
    'Musical/Dramatic': '/assets/images/music.png',
    'Numerical/Mathematical': '/assets/images/tv.png',
    'Organizational': '/assets/images/orgnization.png',
    'Persuasive': '/assets/images/enterprise.png',
    'Scientific': '/assets/images/testtube.png',
    'Social': '/assets/images/social.png',
    'Technical/Mechanical': '/assets/images/conventional.png'

};

export const prog_cluster = {
    cls_110000: {
        clusterName: 'Agriculture', clusterIconValue: 'icon-clu_agriculture',
        clusterId: '110000', clusterBgColor: '#47bf2a'
    },
    cls_120000: {
        clusterName: 'Architecture and Community Planning', clusterIconValue: 'icon-clus_aidforstudyinArchitectureandCommunityPlanning',
        clusterId: '120000', clusterBgColor: '#6c777e'
    },
    cls_130000: {
        clusterName: 'Arts', clusterIconValue: 'icon-clu_arts',
        clusterId: '130000', clusterBgColor: '#ffa100'
    },
    cls_140000: {
        clusterName: 'Biological Sciences', clusterIconValue: 'icon-clu_science_technology',
        clusterId: '140000', clusterBgColor: '#fdcc2f'
    },
    cls_150000: {
        clusterName: 'Biomedical Sciences', clusterIconValue: 'icon-clus_aidforstudyinBiomedicalSciences',
        clusterId: '150000', clusterBgColor: '#fdcc2f'
    },
    cls_160000: {
        clusterName: 'Business, Management, and Administrative Services', clusterIconValue: 'icon-clu_business',
        clusterId: '160000', clusterBgColor: '#003eb6'
    },
    cls_170000: {
        clusterName: 'Communications Technologies', clusterIconValue: 'icon-clus_CommunicationsTechnologies', clusterId: '170000', clusterBgColor: '#00ad83'
    },
    cls_180000: {
        clusterName: 'Computer Science and Information Technologies', clusterIconValue: 'icon-clus_aidforstudyinComputerScienceandInformationTechnologies', clusterId: '180000', clusterBgColor: '#e0119d'
    },
    cls_190000: {
        clusterName: 'Construction', clusterIconValue: 'icon-clu_architecture', clusterId: '190000', clusterBgColor: '#6c777e'
    },

    cls_200000: {
        clusterName: 'Counseling and Human Services', clusterIconValue: 'icon-clu_human_services',
        clusterId: '200000', clusterBgColor: '#88cc2a'
    },
    cls_210000: {
        clusterName: 'Cultural and Ethnic Studies', clusterIconValue: 'icon-clus_aidforstudyinCulturalandEthnicStudies',
        clusterId: '210000', clusterBgColor: '#949ca1'
    },
    cls_220000: {
        clusterName: 'Education', clusterIconValue: 'icon-clu_education_and_training', clusterId: '220000', clusterBgColor: '#00b7c3'
    },
    cls_230000: {
        clusterName: 'Engineering', clusterIconValue: 'icon-clus_aidforstudyinEngineering', clusterId: '230000', clusterBgColor: '#af00b8'
    },
    cls_240000: {
        clusterName: 'Engineering Technologies', clusterIconValue: 'icon-clus_aidforstudyinEngineeringTechnologies',
        clusterId: '240000', clusterBgColor: '#fdcc2f'
    },
    cls_250000: {
        clusterName: 'Family and Consumer Sciences', clusterIconValue: 'icon-clus_aidforstudyinFamilyandConsumerSciences',
        clusterId: '250000', clusterBgColor: '#ff7900'
    },
    cls_260000: {
        clusterName: 'Healthcare Administration', clusterIconValue: 'icon-clus_aidforstudyinHealthcareAdministration',
        clusterId: '260000', clusterBgColor: '#ff7900'
    },
    cls_270000: {
        clusterName: 'Healthcare Assisting and Technology', clusterIconValue: 'icon-clu_health_science',
        clusterId: '270000', clusterBgColor: '#af006b'
    },
    cls_280000: {
        clusterName: 'Hospitality and Recreation Studies', clusterIconValue: 'icon-clu_hospitality_and_tourism',
        clusterId: '280000', clusterBgColor: '#e0119d'
    },
    cls_290000: {
        clusterName: 'Interdisciplinary and General Studies', clusterIconValue: 'icon-clus_aidforstudyinInterdisciplinaryandGeneralStudies',
        clusterId: '290000', clusterBgColor: '#ff7900'
    },
    cls_300000: {
        clusterName: 'Journalism and Communications', clusterIconValue: 'icon-clu_marketing',
        clusterId: '300000', clusterBgColor: '#af00b8'
    },
    cls_310000: {
        clusterName: 'Languages, Literature, and Linguistics', clusterIconValue: 'icon-clus_aidforstudyinLanguagesLiteratureandLinguistics',
        clusterId: '310000', clusterBgColor: '#af00b8'
    },
    cls_320000: {
        clusterName: 'Law and Legal Studies', clusterIconValue: 'icon-clu_law',
        clusterId: '320000', clusterBgColor: '#949ca1'
    },
    cls_330000: {
        clusterName: 'Library and Museum Studies', clusterIconValue: 'icon-clu_government',
        clusterId: '330000', clusterBgColor: '#00ad83'
    },
    cls_340000: {
        clusterName: 'Mathematics and Statistics', clusterIconValue: 'icon-clus_AidforstudyinMathematicsandStatistics',
        clusterId: '340000', clusterBgColor: '#af00b8'
    },
    cls_350000: {
        clusterName: 'Mechanic and Repair Technologies', clusterIconValue: 'icon-clu_manufacturing',
        clusterId: '350000', clusterBgColor: '#7700b8'
    },
    cls_500000: {
        clusterName: 'Medical Testing and Treatment Technology', clusterIconValue: 'icon-clus_aidforstudyinMedicalTestingandLaboratoryTechnology',
        clusterId: '500000', clusterBgColor: '#7700b8'
    },
    cls_360000: {
        clusterName: 'Medicine, Dentistry, Pharmacy, and Veterinary', clusterIconValue: 'icon-clus_aidforstudyinMedicineDentistryPharmacyandVeterinary',
        clusterId: '360000', clusterBgColor: '#7700b8'
    },
    cls_370000: {
        clusterName: 'Natural Resources', clusterIconValue: 'icon-clus_aidforstudyinNaturalResources',
        clusterId: '370000', clusterBgColor: '#7700b8'
    },
    cls_380000: {
        clusterName: 'Nursing', clusterIconValue: 'icon-clus_aidforstudyinNursing',
        clusterId: '380000', clusterBgColor: '#7700b8'
    },
    cls_390000: {
        clusterName: 'Personal Services', clusterIconValue: 'icon-clus_aidforstudyinPersonalServices',
        clusterId: '390000', clusterBgColor: '#7700b8'
    },
    cls_400000: {
        clusterName: 'Physical Sciences', clusterIconValue: 'icon-clus_aidforstudyinPhysicalSciences',
        clusterId: '400000', clusterBgColor: '#7700b8'
    },
    cls_410000: {
        clusterName: 'Precision Production', clusterIconValue: 'icon-clus_aidforstudyinPrecisionProduction',
        clusterId: '410000', clusterBgColor: '#7700b8'
    },
    cls_420000: {
        clusterName: 'Protective Services', clusterIconValue: 'icon-clus_aidforstudyinProtectiveServices',
        clusterId: '420000', clusterBgColor: '#7700b8'
    },
    cls_430000: {
        clusterName: 'Psychology', clusterIconValue: 'icon-clus_aidforstudyinPsychology',
        clusterId: '430000', clusterBgColor: '#7700b8'
    },
    cls_440000: {
        clusterName: 'Secretarial Studies and Office Management', clusterIconValue: 'icon-clus_aidforstudyinSecretarialStudiesandOfficeManagement',
        clusterId: '440000', clusterBgColor: '#7700b8'
    },
    cls_450000: {
        clusterName: 'Social Sciences', clusterIconValue: 'icon-clus_aidforstudyinSocialSciences',
        clusterId: '450000', clusterBgColor: '#7700b8'
    },
    cls_460000: {
        clusterName: 'Special Programs', clusterIconValue: 'icon-clus_aidforstudyinSocialSciences',
        clusterId: '460000', clusterBgColor: '#7700b8'
    },
    cls_480000: {
        clusterName: 'Theology, Religion, and Philosophy', clusterIconValue: 'icon-clus_aidforstudyinTheologyReligionandPhilosophy',
        clusterId: '480000', clusterBgColor: '#7700b8'
    },
    cls_510000: {
        clusterName: 'Therapy and Rehabilitation Services', clusterIconValue: 'icon-clus_aidforstudyinTherapyandRehabilitationServices',
        clusterId: '510000', clusterBgColor: '#7700b8'
    },
    cls_490000: {
        clusterName: 'Transportation', clusterIconValue: 'icon-clu_transportation_distribution_and_logistics',
        clusterId: '490000', clusterBgColor: '#ff7900'
    }
};

export const pieIpIcons = {
    'conventional': '/assets/images/keyboard.png', 'artistic': '/assets/images/artistic.png',
    'realistic': '/assets/images/realistic.png', 'investigative': '/assets/images/investigative.png',
    'social': '/assets/images/social.png', 'enterprising': '/assets/images/enterprise.png'
};
export const wilResultIcons = {
    'independence': '/assets/images/independent.png', 'support': '/assets/images/support.png',
    'workConditions': '/assets/images/working-conditions.png', 'relationships': '/assets/images/relationships.png',
    'recognition': '/assets/images/recognition.png', 'achievement': '/assets/images/has-a-need-to-achieve.png'
};
export const clusterDetails = {
    cls_110100: {
        clusterName: 'Agriculture, Food, and Natural Resources', clusterIconValue: 'icon-clu_agriculture',
        clusterId: '110100', clusterBgColor: '#47bf2a'
    },
    cls_110200: {
        clusterName: 'Architecture and Construction', clusterIconValue: 'icon-clu_architecture',
        clusterId: '110200', clusterBgColor: '#6c777e'
    },
    cls_110300: {
        clusterName: 'Arts, Audio/Visual Technology, and Communications', clusterIconValue: 'icon-clu_arts',
        clusterId: '110300', clusterBgColor: '#ffa100'
    },
    cls_110400: {
        clusterName: 'Business Management and Administration', clusterIconValue: 'icon-clu_business',
        clusterId: '110400', clusterBgColor: '#003eb6'
    },
    cls_110500: {
        clusterName: 'Education and Training', clusterIconValue: 'icon-clu_education_and_training',
        clusterId: '110500', clusterBgColor: '#00b7c3'
    },
    cls_110600: { clusterName: 'Finance', clusterIconValue: 'icon-clu_finance', clusterId: '110600', clusterBgColor: '#ef3f42' },
    cls_110700: {
        clusterName: 'Government and Public Administration', clusterIconValue: 'icon-clu_government',
        clusterId: '110700', clusterBgColor: '#00ad83'
    },
    cls_110800: { clusterName: 'Health Science', clusterIconValue: 'icon-clu_health_science', clusterId: '110800', clusterBgColor: '#af006b' },
    cls_110900: {
        clusterName: 'Hospitality and Tourism', clusterIconValue: 'icon-clu_hospitality_and_tourism',
        clusterId: '110900', clusterBgColor: '#e0119d'
    },
    cls_111000: { clusterName: 'Human Services', clusterIconValue: 'icon-clu_human_services', clusterId: '111000', clusterBgColor: '#88cc2a' },
    cls_111100: {
        clusterName: 'Information Technology', clusterIconValue: 'icon-clu_information_technology',
        clusterId: '111100', clusterBgColor: '#b4d600'
    },
    cls_111200: {
        clusterName: 'Law, Public Safety, Corrections, and Security', clusterIconValue: 'icon-clu_law',
        clusterId: '111200', clusterBgColor: '#949ca1'
    },
    cls_111300: { clusterName: 'Manufacturing', clusterIconValue: 'icon-clu_manufacturing', clusterId: '111300', clusterBgColor: '#7700b8' },
    cls_111400: { clusterName: 'Marketing', clusterIconValue: 'icon-clu_marketing', clusterId: '111400', clusterBgColor: '#af00b8' },
    cls_111500: {
        clusterName: 'Science, Technology, Engineering, and Mathematics', clusterIconValue: 'icon-clu_science_technology',
        clusterId: '111500', clusterBgColor: '#fdcc2f'
    },
    cls_111600: {
        clusterName: 'Transportation, Distribution, and Logistics', clusterIconValue: 'icon-clu_transportation_distribution_and_logistics',
        clusterId: '111600', clusterBgColor: '#ff7900'
    }
};
export const aid_cluter = {
    cls_799003: {
        clusterName: 'Aid Based on Academics', clusterIconValue: 'icon-progs_preperation',
        clusterId: '799003', clusterBgColor: '#47bf2a'
    },
    cls_799070: {
        clusterName: 'Aid Based on Ethnic or National Background', clusterIconValue: 'icon-clus_aidBasedonEthnicorNationalBackground',
        clusterId: '799070', clusterBgColor: '#6c777e'
    },
    cls_799068: {
        clusterName: 'Aid Based on Financial Need', clusterIconValue: 'icon-clu_finance',
        clusterId: '799068', clusterBgColor: '#ef3f42'
    },
    cls_799001: {
        clusterName: 'Aid Based on Hobbies, Talents, and Interests', clusterIconValue: 'icon-clus_aidBasedonHobbiesTalentsandInterests',
        clusterId: '799001', clusterBgColor: '#fdcc2f'
    },
    cls_799004: {
        clusterName: 'Aid Based on Leadership', clusterIconValue: 'icon-clus_aidBasedonLeadership',
        clusterId: '799004', clusterBgColor: '#00b7c3'
    },
    cls_799071: {
        clusterName: 'Aid Based on Racial Background', clusterIconValue: 'icon-clus_aidBasedonRacialBackground',
        clusterId: '799071', clusterBgColor: '#00b7c3'
    },
    cls_799002: {
        clusterName: 'Aid Based on Work or Volunteer Experience (Parent or Student)', clusterIconValue: 'icon-clus_aidBasedonWorkorVolunteerExperience',
        clusterId: '799002', clusterBgColor: '#00b7c3'
    },
    cls_799073: {
        clusterName: 'Aid for Individuals with Disabilities', clusterIconValue: 'icon-clus_aidforIndividualswithDisabilities',
        clusterId: '799073', clusterBgColor: '#00b7c3'
    },
    cls_799005: {
        clusterName: 'Aid for Men', clusterIconValue: 'icon-clus_aidforMen',
        clusterId: '799005', clusterBgColor: '#00b7c3'
    },
    cls_799077: {
        clusterName: 'Aid for Those in the Military', clusterIconValue: 'icon-clus_aidforThoseintheMilitary',
        clusterId: '799077', clusterBgColor: '#00b7c3'
    },
    cls_799072: {
        clusterName: 'Aid for Women', clusterIconValue: 'icon-clus_aidforWomen',
        clusterId: '799072', clusterBgColor: '#00b7c3'
    },
    cls_799076: {
        clusterName: 'Aid Requiring Affiliation with a Business (Parent or Student)', clusterIconValue: 'icon-clus_aidRequiringAffiliationwithaBusiness',
        clusterId: '799076', clusterBgColor: '#00b7c3'
    },
    cls_799079: {
        clusterName: 'Aid Requiring Affiliation with a Club, Civic Association, or Union', clusterIconValue: 'icon-clus_aidRequiringAffiliationwithaClubCivicAssoc',
        clusterId: '799079', clusterBgColor: '#00b7c3'
    },
    cls_799074: {
        clusterName: 'Aid Requiring Religious Affiliation', clusterIconValue: '',
        clusterId: '799074', clusterBgColor: '#00b7c3'
    },
    cls_799011: {
        clusterName: 'Aid for study in Agriculture', clusterIconValue: 'icon-clu_agriculture',
        clusterId: '799011', clusterBgColor: '#47bf2a'
    },
    cls_799012: {
        clusterName: 'Aid for study in Architecture and Community Planning', clusterIconValue: 'icon-clus_aidforstudyinArchitectureandCommunityPlanning',
        clusterId: '799012', clusterBgColor: '#00b7c3'
    },
    cls_799013: {
        clusterName: 'Aid for study in Arts', clusterIconValue: 'icon-clu_arts',
        clusterId: '799013', clusterBgColor: '#ffa100'
    },
    cls_799014: {
        clusterName: 'Aid for study in Biological Sciences', clusterIconValue: 'icon-clu_science_technology',
        clusterId: '799014', clusterBgColor: '#fdcc2f'
    },
    cls_799015: {
        clusterName: 'Aid for study in Biomedical Sciences', clusterIconValue: 'icon-clus_aidforstudyinBiomedicalSciences',
        clusterId: '799015', clusterBgColor: '#fdcc2f'
    },
    cls_799016: {
        clusterName: 'Aid for study in Business, Management, and Administrative Services', clusterIconValue: 'icon-clu_business',
        clusterId: '799016', clusterBgColor: '#003eb6'
    },
    cls_799017: {
        clusterName: 'Aid for study in Communications Technologies', clusterIconValue: 'icon-clus_CommunicationsTechnologies',
        clusterId: '799017', clusterBgColor: '#003eb6'
    },
    cls_799018: {
        clusterName: 'Aid for study in Computer Science and Information Technologies', clusterIconValue: 'icon-clus_aidforstudyinComputerScienceandInformationTechnologies',
        clusterId: '799018', clusterBgColor: '#003eb6'
    }, cls_799019: {
        clusterName: 'Aid for study in Construction', clusterIconValue: 'icon-clu_architecture',
        clusterId: '799019', clusterBgColor: '#003eb6'
    },
    cls_799020: {
        clusterName: 'Aid for study in Counseling and Human Services', clusterIconValue: 'icon-clu_human_services',
        clusterId: '799020', clusterBgColor: '#88cc2a'
    },
    cls_799021: {
        clusterName: 'Aid for study in Cultural and Ethnic Studies', clusterIconValue: 'icon-clus_aidforstudyinCulturalandEthnicStudies',
        clusterId: '799021', clusterBgColor: '#003eb6'
    },
    cls_799022: {
        clusterName: 'Aid for study in Education', clusterIconValue: 'icon-clu_education_and_training',
        clusterId: '799022', clusterBgColor: '#00b7c3'
    },
    cls_799023: {
        clusterName: 'Aid for study in Engineering', clusterIconValue: 'icon-clus_aidforstudyinEngineering',
        clusterId: '799023', clusterBgColor: '#00b7c3'
    },
    cls_799024: {
        clusterName: 'Aid for study in Engineering Technologies', clusterIconValue: 'icon-clus_aidforstudyinEngineeringTechnologies',
        clusterId: '799024', clusterBgColor: '#00b7c3'
    },
    cls_799025: {
        clusterName: 'Aid for study in Family and Consumer Sciences', clusterIconValue: 'icon-clus_aidforstudyinFamilyandConsumerSciences',
        clusterId: '799025', clusterBgColor: '#00b7c3'
    },
    cls_799026: {
        clusterName: 'Aid for study in Healthcare Administration', clusterIconValue: 'icon-clus_aidforstudyinHealthcareAdministration',
        clusterId: '799026', clusterBgColor: '#00b7c3'
    },
    cls_799027: {
        clusterName: 'Aid for study in Healthcare Assisting and Technology', clusterIconValue: 'icon-clu_health_science',
        clusterId: '799027', clusterBgColor: '#af006b'
    },
    cls_799028: {
        clusterName: 'Aid for study in Hospitality and Recreation Studies', clusterIconValue: 'icon-clu_hospitality_and_tourism',
        clusterId: '799028', clusterBgColor: '#e0119d'
    },
    cls_799029: {
        clusterName: 'Aid for study in Interdisciplinary and General Studies', clusterIconValue: 'icon-clus_aidforstudyinInterdisciplinaryandGeneralStudies',
        clusterId: '799029', clusterBgColor: '#00b7c3'
    },
    cls_799030: {
        clusterName: 'Aid for study in Journalism and Communications', clusterIconValue: 'icon-clu_marketing',
        clusterId: '799030', clusterBgColor: '#af00b8'
    },
    cls_799031: {
        clusterName: 'Aid for study in Languages, Literature, and Linguistics', clusterIconValue: 'icon-clus_aidforstudyinLanguagesLiteratureandLinguistics',
        clusterId: '799031', clusterBgColor: '#00b7c3'
    },
    cls_799032: {
        clusterName: 'Aid for study in Law and Legal Studies', clusterIconValue: 'icon-clu_law',
        clusterId: '799032', clusterBgColor: '#00b7c3'
    },
    cls_799033: {
        clusterName: 'Aid for study in Library and Museum Studies', clusterIconValue: 'icon-clu_government',
        clusterId: '799033', clusterBgColor: '#00ad83'
    },
    cls_799034: {
        clusterName: 'Aid for study in Mathematics and Statistics', clusterIconValue: 'icon-clus_AidforstudyinMathematicsandStatistics',
        clusterId: '799034', clusterBgColor: '#00b7c3'
    },
    cls_799035: {
        clusterName: 'Aid for study in Mechanic and Repair Technologies', clusterIconValue: 'icon-clu_manufacturing',
        clusterId: '799035', clusterBgColor: '#7700b8'
    },
    cls_799050: {
        clusterName: 'Aid for study in Medical Testing and Laboratory Technology', clusterIconValue: 'icon-clus_aidforstudyinMedicalTestingandLaboratoryTechnology',
        clusterId: '799050', clusterBgColor: '#00b7c3'
    },
    cls_799036: {
        clusterName: 'Aid for study in Medicine, Dentistry, Pharmacy, and Veterinary', clusterIconValue: 'icon-clus_aidforstudyinMedicineDentistryPharmacyandVeterinary',
        clusterId: '799036', clusterBgColor: '#00b7c3'
    },
    cls_799037: {
        clusterName: 'Aid for study in Natural Resources', clusterIconValue: 'icon-clus_aidforstudyinNaturalResources',
        clusterId: '799037', clusterBgColor: '#00b7c3'
    },
    cls_799038: {
        clusterName: 'Aid for study in Nursing', clusterIconValue: 'icon-clus_aidforstudyinNursing',
        clusterId: '799038', clusterBgColor: '#00b7c3'
    }, cls_799039: {
        clusterName: 'Aid for study in Personal Services', clusterIconValue: 'icon-clus_aidforstudyinPersonalServices',
        clusterId: '799039', clusterBgColor: '#00b7c3'
    }, cls_799040: {
        clusterName: 'Aid for study in Physical Sciences', clusterIconValue: 'icon-clus_aidforstudyinPhysicalSciences',
        clusterId: '799040', clusterBgColor: '#00b7c3'
    }, cls_799041: {
        clusterName: 'Aid for study in Precision Production', clusterIconValue: 'icon-clus_aidforstudyinPrecisionProduction',
        clusterId: '799041', clusterBgColor: '#00b7c3'
    }, cls_799042: {
        clusterName: 'Aid for study in Protective Services', clusterIconValue: 'icon-clus_aidforstudyinProtectiveServices',
        clusterId: '799042', clusterBgColor: '#00b7c3'
    }, cls_799043: {
        clusterName: 'Aid for study in Psychology', clusterIconValue: 'icon-clus_aidforstudyinPsychology',
        clusterId: '799043', clusterBgColor: '#00b7c3'
    }, cls_799044: {
        clusterName: 'Aid for study in Secretarial Studies and Office Management', clusterIconValue: 'icon-clus_aidforstudyinSecretarialStudiesandOfficeManagement',
        clusterId: '799044', clusterBgColor: '#00b7c3'
    }, cls_799045: {
        clusterName: 'Aid for study in Social Sciences', clusterIconValue: 'icon-clus_aidforstudyinSocialSciences',
        clusterId: '799045', clusterBgColor: '#00b7c3'
    }, cls_799048: {
        clusterName: 'Aid for study in Theology, Religion, and Philosophy', clusterIconValue: 'icon-clus_aidforstudyinTheologyReligionandPhilosophy',
        clusterId: '799048', clusterBgColor: '#00b7c3'
    }, cls_799051: {
        clusterName: 'Aid for study in Therapy and Rehabilitation Services', clusterIconValue: 'icon-clus_aidforstudyinTherapyandRehabilitationServices',
        clusterId: '799051', clusterBgColor: '#00b7c3'
    }, cls_799049: {
        clusterName: 'Aid for study in Transportation', clusterIconValue: 'icon-clu_transportation_distribution_and_logistics',
        clusterId: '799049', clusterBgColor: '#ff7900'
    }, cls_799006: {
        clusterName: 'Miscellaneous Awards', clusterIconValue: 'icon-clus_MiscellaneousAwards',
        clusterId: '799006', clusterBgColor: '#00b7c3'
    }, cls_800000: {
        clusterName: 'Aid and Awards for Idaho Residents', clusterIconValue: 'icon-location',
        clusterId: '800000', clusterBgColor: '#00b7c3'
    },
    cls_799069: {
        clusterName: 'Awards not otherwise clustered', clusterIconValue: 'icon-location',
        clusterId: '799069', clusterBgColor: '#00b7c3'
    }
};

export let ideasColors = {
    'high': '#510556', 'average': '#10b24e', 'low': '#0E90A0'
};
export let abilityColors = {
    'high': '#ff7143', 'medium': '#4cb050', 'low': '#00bcd5'
};
export let ideasIcons = {
    'Mechanical/Fixing': '/assets/images/tools.png', 'Protective Services': '/assets/images/service.png',
    'Nature/Outdoors': '/assets/images/sun.png', 'Mathematics': '/assets/images/tv.png',
    'Science': '/assets/images/testtube.png', 'Medical': '/assets/images/doct.png', 'Creative Arts': '/assets/images/music.png',
    'Writing': '/assets/images/pen.png',
    'Community Service': '/assets/images/heart.png',
    'Educating': '/assets/images/apple.png',
    'Child Care': '/assets/images/child-care.png',
    'Public Speaking': '/assets/images/mick.png',
    'Business': '/assets/images/brefcase.png',
    'Sales': '/assets/images/enterprise.png',
    'Office Practices': '/assets/images/keyboard.png',
    'Food Service': '/assets/images/food.png'
};
export const cardEqIcons = {
    'Works Hard': '/assets/images/works-hard.png',
    'Has Family Support': '/assets/images/has-family-support.png',
    'Takes Risks': '/assets/images/takes-risks.png',
    'Sacrifices Employment Benefits': '/assets/images/sacrifices-employment.png',
    'Is Independent': '/assets/images/independent.png',
    'Wants Financial Success': '/assets/images/Wants-Financial-Success.png',
    'Is Energetic': '/assets/images/Is-Energetic.png',
    'Has an Internal "Locus of Control"': '/assets/images/has-an-internal-locus-of-control.png',
    'Has a Need to Achieve': '/assets/images/has-a-need-to-achieve.png',
    'Has Business Experience': '/assets/images/enterprise.png',
    'Has a Self-Employed Parent as a Role Model': '/assets/images/has-a-self-employed-parent-as-a-role-model.png',
    'Has Self-Confidence': '/assets/images/has-self-confidence.png',
    'Has Integrity': '/assets/images/has-integrity.png',
    'Has Determination': '/assets/images/has-determination.png',
    'Adapts to Change': '/assets/images/adapts-to-change.png',
    'Has a Good Network of Professionals': '/assets/images/has-a-good-network.png'
};
export const localJson = [
    {
        'title': 'Investigative', 'childId': [6, 12, 18],
        'childAreas': ['SCI', 'MED', 'MTH'],
        'height': '150', 'id': '0', 'range': '60', 'margin': '81px 292px 0px 0px', 'rotate': 'rotate(304deg)', 'transformer': '0', 'width': '15'
    },

    {
        'title': 'Artistic', 'childId': [7, 13],
        'childAreas': ['WRI', 'ART'], 'height': '110', 'id': '1', 'range': '-60', 'margin': '0 241px 5px 115px', 'rotate': 'rotate(350deg)', 'transformer': '-180', 'width': '10'
    },
    {
        'title': 'Social', 'childId': [8, 14, 19],
        'childAreas': ['EDU', 'COM', 'CHI'], 'height': '80', 'id': '2', 'range': '-60', 'margin': '0 56px 10px -112px', 'rotate': 'rotate(347deg)', 'transformer': '-180', 'width': '10'
    },

    {
        'title': 'Enterprising', 'childId': [9, 15, 20],
        'childAreas': ['PUB', 'SAL', 'BUS'], 'height': '150', 'id': '3', 'range': '-60', 'margin': '0px 287px 12px 164px', 'rotate': 'rotate(357deg)', 'transformer': '-180', 'width': '10'
    },
    {
        'title': 'Conventional', 'childId': [10, 16],
        'childAreas': ['OFF', 'FOO'], 'height': '180', 'id': '4', 'range': '-60', 'margin': '0px 149px 4px 47px', 'rotate': 'rotate(353deg)', 'transformer': '-180', 'width': '10'
    },
    {
        'title': 'Realistic', 'childId': [11, 17, 21],
        'childAreas': ['PRO', 'MEC', 'NAT'], 'height': '120', 'id': '5', 'range': '60', 'margin': '82px 301px 0px 0px', 'rotate': 'rotate(302deg)', 'transformer': '0', 'width': '10'
    },

];
export const defaultgraph = [
    { 'title': '', 'areaAbbr': '', 'height': '100', 'id': '6', 'range': '40', 'margin': '58px 239px 0 17px', 'rotate': 'rotate(315deg)', 'transformer': '0', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '100', 'id': '7', 'range': '-50', 'margin': '0 118px -10px 0', 'rotate': 'rotate(347deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '100', 'id': '8', 'range': '-55', 'margin': '26px 125px 0 0', 'rotate': 'rotate(345deg)', 'transformer': '-180', 'width': '18' },

    { 'title': '', 'areaAbbr': '', 'height': '100', 'id': '9', 'range': '-55', 'margin': '23px 103px 7px 0px', 'rotate': 'rotate(353deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '100', 'id': '10', 'range': '-55', 'margin': '25px 113px 6px 0px', 'rotate': 'rotate(347deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '100', 'id': '11', 'range': '55', 'margin': '66px 290px 0 58px', 'rotate': 'rotate(305deg)', 'transformer': '5', 'width': '15' },

    { 'title': '', 'areaAbbr': '', 'height': '60', 'id': '12', 'range': '60', 'margin': '45px 160px 0px -7px', 'rotate': 'rotate(306deg)', 'transformer': '0', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '60', 'id': '13', 'range': '-50', 'margin': '23px 104px -1px 0px', 'rotate': 'rotate(338deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '80', 'id': '14', 'range': '-45', 'margin': '0 93px -3px 0px', 'rotate': 'rotate(343deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '60', 'id': '15', 'range': '-60', 'margin': '0px 91px -2px 0px', 'rotate': 'rotate(349deg)', 'transformer': '180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '60', 'id': '16', 'range': '-60', 'margin': '0px 90px -3px 0px', 'rotate': 'rotate(350deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '60', 'id': '17', 'range': '60', 'margin': '47px 169px 0px 0px', 'rotate': 'rotate(307deg)', 'transformer': '0', 'width': '14' },

    { 'title': '', 'areaAbbr': '', 'height': '50', 'id': '18', 'range': '60', 'margin': '48px 134px 0px 29px', 'rotate': 'rotate(304deg)', 'transformer': '0', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '50', 'id': '19', 'range': '-65', 'margin': '27px 42px 4px 0px', 'rotate': 'rotate(345deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '50', 'id': '20', 'range': '-60', 'margin': '31px 80px 6px 34px', 'rotate': 'rotate(344deg)', 'transformer': '-180', 'width': '10' },

    { 'title': '', 'areaAbbr': '', 'height': '50', 'id': '21', 'range': '60', 'margin': '49px 114px 2px 5px', 'rotate': 'rotate(305deg)', 'transformer': '0', 'width': '14' },
];
export const langData = {


};
