//Angular imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Custom imports
import { AppSharedModule } from '../../app.sharedmodule';
import { PlpComponent } from './PLP-sections/PLP-sections.component';
import { PLPSharedService } from './shared/shared/PLP-shared.service';
import { ServiceModule } from '../../shared/service-module/shared-service-module';

const routes: Routes = [{ path: '', component: PlpComponent }];

@NgModule({
  imports: [
    RouterModule.forChild(routes), ServiceModule,
    AppSharedModule.forRoot()
  ],
  declarations: [
    PlpComponent
  ],
  exports: [
    PlpComponent,
  ],
  providers: [PLPSharedService]
})

export class PLPSectionsModule {
  constructor() {
  }
}
