import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import { BrowserDomAdapter } from '@angular/platform-browser/src/browser/browser_adapter';
import { Store, select } from '@ngrx/store';
// import { OCCPageState, OccSettingsTextState } from '../../../../../state-management/state/main-state';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Occ_Settings_Tab_Text_State, Occ_EmpOutlook_Wages_State } from '../../occ-state-management/occ-state/occ-state';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import html2pdf from 'html2pdf.js';
import { takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pathways',
  templateUrl: './pathways-layout.html',
})

export class PathwaysComponent implements OnInit, OnDestroy {
  filter; /**Declare for storing the index value */
  highlightedDiv: number; /**Declare for storing the number value. */
  occClusterStore; /**Declare for storing the cluster text from store.*/
  showId = false; /**Declare for storing the booleanvalue */
  settingsTextTab;  /**Declare for storing the tab values from store.*/
  settingsText;  /**Declare for storing the section names from store.*/
  eventSub = new Subscription;
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  printview = false;
  compActive = true;
  // It contains all information about the machine's browser(Internet Explorer) which doesn't allow page break
  NotIE = navigator.userAgent.match(/Trident.*rv\:11\./);
  @Input('pathwaysData') pathwaysData = { 'Pathways': [] };
  @ViewChild('clusPathway', { static: true }) clusPathway: ElementRef;

  @Input('logo') logo;
  @Input('clusterTitle') clusterTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  // dom: BrowserDomAdapter;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occDispatch: OccDispatchService, private utils: Utilities) {
     occDispatch.listen().pipe(takeWhile(() => this.compActive)).subscribe((e) => {
      if (e.type == 'Pathways') {

        this.filter = 999;

      }
    });




    this.OCCPageStateStore.pipe(select('Occ_EmpOutlook_Wages'), takeWhile(() => this.compActive)).subscribe((resp) => {
      this.occClusterStore = resp;
    });
    this.occSetstore.pipe(select('Occ_Settings_Tab_Text'), takeWhile(() => this.compActive)).subscribe((v) => {
      this.settingsText = v;

     if (this.settingsText.Occ_Settings_Tab_Text.tabs) {
       this.settingsTextTab = this.settingsText.Occ_Settings_Tab_Text.tabs.find((obj) => (obj['tabId'] === 3));
     }
   });
      this.occDispatch.clusterPaywayListen().pipe(takeWhile(() => this.compActive)).subscribe(() => {
      this.utils.showLoading();
      this.clusterPrint();
    });
  }
  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.compActive)).subscribe(params => {

      /**
       * Defaults to 0 if no query param provided.
       * */
      this.clusterIcon = params['clusIcon'];
    });
  }

  /**
   *
   * @param valfil is for storing the value.
   * @param indexval is for storing the index value.
   * @param newValue isfor storing the number value.
   */
  methodfilter(valfil, indexval, newValue: number) {
    this.filter = valfil;
    this.showId = indexval;
    if (this.highlightedDiv === newValue) {
      this.highlightedDiv = 0;
    } else {
      this.highlightedDiv = newValue;
    }
  }

  /**
   * This method is for navigating from cluster to the career.
   * @param id is for storing the occcareer id.
   * @param nameis for storing the occcareer name.
   */
  CallOccDetailCareer(id, name) {
    const twoDigit = id.toString().substr(0, 2);
    if (twoDigit == 14) {
      this.router.navigate(['../occEmergCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    } else {
      this.router.navigate(['../occCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    }
  }
  //  This function is for displaying the pop-up when we ckick on print button /
  async clusterPrint() {
    const data = this.clusPathway.nativeElement;
    const opt = {
      filename: 'Career Cluster - Pathways.pdf',
      margin: [0.1, 0, 0, 0],
      image: { type: 'svg', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }

    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }

  /**
    * This ngOnDestroy() function is call after Component destory.
    */
  ngOnDestroy() {
    this.compActive = false;
  }
}
