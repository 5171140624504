/**Angular imports. */
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { RouterModule, Router } from '@angular/router';


/**Custome imports. */
import { EventDispatchService } from '../../../../shared/outer-services/event-dispatch.service';
import { StorageService } from '../../../../shared/outer-services/storage.service';
import { TilesDynamicComponent } from '../tiles/tiles.component';
import { Utilities } from '../../../../shared/outer-services/utilities.service';

@Component({
  selector: 'tile-component',
  template: `
    <dynamic-layout-tag
      [componentTypes]="componentType"
      [FrameworkObj]="FrameworkObj"
    ></dynamic-layout-tag>
  `,
})
export class TileDesignComponent implements OnInit, OnDestroy {
  @Input('FrameworkObj') FrameworkObj = [];
  componentType = TilesDynamicComponent;
  constructor(
    private route: Router,
    private eventDispatcher: EventDispatchService,
    private storageService: StorageService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
