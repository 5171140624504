/**Angular Libraries **/
import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Action } from '@ngrx/store';
import { Subscription } from 'rxjs';
/**Services **/
// import { OCCPageState, OccSettingsTextState } from "../../../../../state-management/state/main-state";
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';
import html2pdf from 'html2pdf.js';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { CareersJr_EmpOutlook_Wages_State, CareersJr_Settings_Tab_Text_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJrCommonMethodsService } from '../../careersJr-services/careersJr-common-methods.service';

@Component({
  selector: 'related-careersJr',
  templateUrl: './related-careersJr-layout.html',
})
export class RelatedCareersJrComponent implements OnInit, OnDestroy {
  @Output() changeViewRelate = new EventEmitter();
  showMilataryCareer = false; /** Show and hide milatary career */
  occCareerStore: any; /** Is a variable that is used to store data coming from reducer */
  occSettingsSub = new Subscription();
  occPageSub = new Subscription();
  settingsTextTab: any;
  occName: string;
  relCareerPrintsub: Subscription;
  printPage = false;
  settingsText: any; /** Is a variable that is used to store data coming from reducer */
  occID: number = 0;
  states: Array<string> = ['US', StorageService.staticSessionStorageGet('stateAbr')];
  langVal: string = StorageService.staticSessionStorageGet('langset')
    ? StorageService.staticSessionStorageGet('langset')
    : 'en';

  @ViewChild('RelatedCarees', { static: true }) RelatedCarees: ElementRef;

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  constructor(
    private router: Router,
    private utils: Utilities,
    private activatedRoute: ActivatedRoute,
    private dispatchStore: Store<Action>,
    private occSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private occDispatch: CareersJrDispatchService,
    private occCommonService: CareersJrCommonMethodsService,
    private Frameworkstore: Store<FrameworkConfigState>
  ) {
    this.relCareerPrintsub = this.occDispatch
      .careerRelatedListen()
      .subscribe(() => {
        // console.log('AGA called ---');
        this.utils.showLoading();
        this.careerPrint();
      });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSettingsSub = this.occSetstore
      .select('CareersJr_Settings_Tab_Text')
      .subscribe((occSettings) => {
        this.settingsText = occSettings;
        if (this.settingsText.CareersJr_Settings_Tab_Text.tabs) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] === 6) {
                ref.settingsTextTab = obj;
              }
            }.bind(this)
          );
        }
      });
    // Get data from reducer to display in cards
    this.occPageSub = this.OCCPageStateStore.select(
      'CareersJr_Detail_RelatedCareers_Selector'
    ).subscribe((occWages) => {
      this.occCareerStore = occWages;

      if (this.occCareerStore.RelatedMilitary) {
        if (this.occCareerStore.RelatedMilitary.length !== 0) {
          this.showMilataryCareer = true;
        } else {
          this.showMilataryCareer = false;
        }
      }
    });
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.occName = params['occname'];
      this.occID = Number(params['occid']);
    });

    try {
      this.dispatchStore.dispatch({
        type: 'GET_CAREERJR_RELATEDCAREERS',
        payload: {
          methodVal: 'GET',
          module_Name: 'OccJr/v1/',
          path_params: ['OccJr', this.occID, 'accountID'],
          query_params: {
            sections: 'RelatedCareers,ParentCluster',
            states: this.states,
            lang: this.langVal,
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    } catch (e) {
      console.log('error------>' + e.message);
    }
  }
  async careerPrint() {
    const element = this.RelatedCarees.nativeElement;
    const opt = {
      filename: 'Career Jr - Related Careers.pdf',
      margin: [0.1, 0, 0.2, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { compress: true },
    };

    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.occSettingsSub.unsubscribe();
    this.occPageSub.unsubscribe();
    this.relCareerPrintsub.unsubscribe();
  }
  //Redirects to respective career page
  CallOccDetailCareer(id, name) {
    let clusterId;
    let clusterIcon;
    let backGroundClr;
    const val = JSON.parse(StorageService.staticSessionStorageGet('CareersJrIndexReducerText'));
    val.CareersJr_Index_Reducer_Text.occList.forEach(function (obj, inx) {
      if (obj.occID === id) {
        clusterId = obj.clusterID;
      }
    });
    const clsVal = this.occCommonService.clustDetails(clusterId);
    clusterIcon = clsVal.clusterIconValue;
    backGroundClr = clsVal.clusterBgColor;

    const twoDigit = id.toString().substr(0, 2);
    if (parseInt(twoDigit, 10) === 24) {
      this.router.navigate(['../careersJrEmergHeader'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          occid: id,
          occname: name,
          clusIcon: clusterIcon,
          clusColor: backGroundClr,
        },
      });
    } else {
      this.dispatchStore.dispatch({ type: 'RESET_OCC_SETTING_TABS_TEXT' });
      this.dispatchStore.dispatch({ type: 'RESET_OCC_PAGE' });
      const occArray = [id, name];
      this.router.navigate(['../careersJrHeader'], {
        relativeTo: this.activatedRoute,
        queryParams: { occid: id, occname: name },
      });
      this.utils.showLoading();
      setTimeout(
        function () {
          const evnt = document.createEvent('CustomEvent');
          evnt.initEvent('relatedDispatch', true, true);
          this.occDispatch.dispatch(evnt);
        }.bind(this),
        100
      );
    }
  }

  //Redirects to respective cluster page
  CallOccDetailCluster(clusId, ClusName) {
    const clusterIcon = this.occCareerStore.ParentCluster.parentClusterIcon;
    this.router.navigate(['../careersJrClusterHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clusId: clusId,
        clusName: ClusName,
        clusIcon: clusterIcon,
        clusColor: this.occCareerStore.ParentCluster.parentClusterColor,
      },
    });
  }
}
