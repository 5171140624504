/**import angular packages*/
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import html2pdf from 'html2pdf.js';

/**custom imports */
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { Occ_EmpOutlook_Wages_State, Occ_Settings_Tab_Text_State } from '../../occ-state-management/occ-state/occ-state';
import { OccCommonMethodsService } from '../../occ-services/occ-common-methods.service';

@Component({
  selector: 'related-careers-emerg',
  templateUrl: './related-careers-layout.html',
})

export class RelatedCareersEmergComponent implements OnInit, OnDestroy {
  @Input('relatedCareers') relatedCareers: any = {};
  @Input('mainlogo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  @ViewChild('relatedCareerPrint', {static: true}) relatedPrint: ElementRef;
  printview = false;
  relatedcareers; //Declare for storing the subscribe value for OccPageStateStore
  settingsTextTab; //Declare for storing the obj value of settingsText
  settingsText; //Declare for storing the value of settingsText
  showMilataryCareer = false; //Variable defaults false value
  reducerSub2 = new Subscription;
  reducerSub1 = new Subscription;
  eventSub = new Subscription;
  clusterIcon;
  clusterColor;
  constructor(
    private router: Router,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private occDispatch: OccDispatchService, private occCommonService: OccCommonMethodsService) {
    this.eventSub = occDispatch.listen().subscribe((e) => {
      if (e.type === 'emergingEvent2') {
        this.relatedCareerPrint();
      }
    });
    //get the data from reducer state OCCPageStateStore
    this.reducerSub2 = this.OCCPageStateStore.select('Occ_EmpOutlook_Wages').subscribe((v) => {
      this.relatedcareers = v;
      if (v.RelatedCareers) {
        const clsVal = this.occCommonService.clustDetails(v.RelatedCareers.lists[1].links[0].linkID);
        this.clusterIcon = clsVal.clusterIconValue;
        this.clusterColor = clsVal.clusterBgColor;
      }
      if (this.relatedcareers.RelatedMilitary != undefined) {
        if (this.relatedcareers.RelatedMilitary.length != 0) {
          this.showMilataryCareer = true;
        } else {
          this.showMilataryCareer = false;

        }
      }
    });

    //get the data from state store
    this.reducerSub1 = this.occSetstore.select('Occ_Settings_Tab_Text').subscribe((v) => {
      this.settingsText = v;
      if (this.settingsText.Occ_Settings_Tab_Text.tabs != null && this.settingsText.Occ_Settings_Tab_Text.tabs != undefined) {
        const ref = this;
        this.settingsText.Occ_Settings_Tab_Text.tabs.forEach(function (obj, inx) {
          if (obj['tabId'] == 2) {
            ref.settingsTextTab = obj;
          }
        }.bind(this));

      }

    });
  }

  /**ngOnInit method called when initializing the component  */
  ngOnInit() {

  }
  async relatedCareerPrint() {
    const data = this.relatedPrint.nativeElement;
    const opt = {
      filename: 'Emerging Career - Related Careers.pdf',
      margin: [0, 0, 0.2, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'portrait', compress: true }
    };
    await html2pdf(data, opt);
      this.utils.hideLoading();
  }
  /**ngOnDestroy method called when destroying the component */
  ngOnDestroy() {
    this.reducerSub2.unsubscribe();
    this.reducerSub1.unsubscribe();
    this.eventSub.unsubscribe();
  }

  /**CallOccDetailCareer method for clicking list links in careers div */
  CallOccDetailCareer(id, name, clusterIcon, clusterColor) {
    const twoDigit = id.toString().substr(0, 2);
    if (twoDigit == 14) {
      this.router.navigate(['../occEmergCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name, clusIcon: clusterIcon, clusColor: clusterColor } });
      setTimeout(function () {
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent('relatedEmergingDispatch', true, true);
        this.occDispatch.dispatch(evnt);
      }.bind(this), 100);

    } else {
      this.router.navigate(['../occCareer'], { relativeTo: this.activatedRoute, queryParams: { occid: id, occname: name } });
    }
  }

  /**CallOccDetailCluster method for clicking links in clusters div */
  CallOccDetailCluster(clusId, ClusName, clusterIcon, clusterColor) {
    this.utils.showLoading();
    this.router.navigate(['../occCluster'], {
      relativeTo: this.activatedRoute,
      queryParams: { clusId: clusId, clusName: ClusName, clusIcon: clusterIcon, clusColor: clusterColor }
    });
  }
  CallMilitaryCareer(id, name) {
    const occArray = [id, name];
    this.router.navigate(['../../military-details/militaryCareerHeader'], { relativeTo: this.activatedRoute, queryParams: { milid: id, milname: name } });
    this.utils.showLoading();
    setTimeout(function () {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('relatedDispatch', true, true);
      this.occDispatch.dispatch(evnt);
    }.bind(this), 100);


  }
}
