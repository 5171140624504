import { StorageService } from '../../shared/outer-services/storage.service';
import * as LtiLocationAllAction from '../actions/lti-module.action';
import { LtiLocationModel } from '../state/lti-location.state';

export type LtiLocationAction = LtiLocationAllAction.LtiLocationActions;

const defaulltiLocationState: LtiLocationModel = {
    componentId: ''
};

export function ltiLocationReducer(state: LtiLocationModel = defaulltiLocationState, action: LtiLocationAction): LtiLocationModel {
    switch (action.type) {
        case LtiLocationAllAction.LtiLocationActionTypes.SetLtiLocation: {
            const result = { ...state, componentId: action.payload };
            StorageService.staticSessionStorageSet('locationModule', JSON.stringify(result));
            return result;
        }
        case LtiLocationAllAction.LtiLocationActionTypes.LtiLocationInit: {
            const result = { ...state, ...JSON.parse(StorageService.staticSessionStorageGet('locationModule')) || '{}' };
            return result;
        }
        case LtiLocationAllAction.LtiLocationActionTypes.RemoveLtiLocation: {
            // StorageService.staticSessionStorageRemove('locationModule');
            delete state.componentId;
            const result = { ...state };
            return result;
        }
        default: {
            return state;
        }
    }
}
