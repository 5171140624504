
export interface FrameworkConfigState {
  config: any;
}
export const defaultFrameworkConfig: FrameworkConfigState = {
  config: {}
};

export interface PlanConfigState {
  plan: any;
}
export const defaultplanConfig: PlanConfigState = {
  plan: {}
};

export interface AsmntCommonTextState {
  AsmntCommonText: any;
}
// export interface OccIndexCommonState {
//   commonText: any
// }
export const defaultAsmntCommonTextState: AsmntCommonTextState = {
  AsmntCommonText: {}
};

export interface AsmntLocationState {
  commonLocationText: any;
}
export const defaultLocationText: AsmntLocationState = {
  commonLocationText: {}
};

export interface AsmntQuestionState {
  commonIntroText: any;
}
export const defaultQuestionText: AsmntQuestionState = {
  commonIntroText: {}
};


export interface AsmntResponseState {
  commonResponseText: any;
}
export const defaultResponseText: AsmntResponseState = {
  commonResponseText: {}
};
export interface OCCPageState {
  occPage: any;
}
export const defaultOccPage: OCCPageState = {
  occPage: [{}]
};


export interface SectionsFillStatusState {
  statusValues: any;
}

export const defaultSectionsStatus: SectionsFillStatusState = {
  statusValues: [{}]
};









export interface OccTextState {
  OccText: any;
}
export const defaultOccTextState: OccTextState = {
  OccText: {}
};

export interface OccSettingsTextState {
  OccSettingsText: any;
}
export const defaultOccSettingsTextState: OccSettingsTextState = {
  OccSettingsText: {}
};

export interface AsmntIntroTextState {
  AsmntIntroText: any;
}
export const defaultAsmntIntroTextState: AsmntIntroTextState = {
  AsmntIntroText: {}
};

export interface OccIndexReducerTextState {
  OccIndexReducerText: any;
}
export const defaultOccIndexReducerTextState: OccIndexReducerTextState = {
  OccIndexReducerText: {}
};

export interface GetAllOccListState {
  GetAllOccList: any;
}
export const defaultGetAllOccListState: GetAllOccListState = {
  GetAllOccList: {}
};
export interface ProgIndexReducerTextState {
  ProgIndexReducerText: any;
}
export const defaultProgIndexReducerTextState: ProgIndexReducerTextState = {
  ProgIndexReducerText: {}
};
export interface ProgTextState {
  ProgText: any;
}
export const defaultProgTextState: ProgTextState = {
  ProgText: {}
};
export interface AidIndexReducerTextState {
  AidIndexReducerText: any;
}
export const defaultAidIndexReducerTextState: AidIndexReducerTextState = {
  AidIndexReducerText: {}
};
export interface AidTextState {
  AidText: any;
}
export const defaultAidTextState: AidTextState = {
  AidText: {}
};
export interface SchoolIndexReducerTextState {
  SchoolIndexReducerText: any;
}
export const defaultSchoolIndexReducerTextState: SchoolIndexReducerTextState = {
  SchoolIndexReducerText: {}
};
export interface SchoolTextState {
  SchoolText: any;
}
export const defaultSchoolTextState: SchoolTextState = {
  SchoolText: {}
};
export interface MilitaryIndexReducerTextState {
  MilitaryIndexReducerText: any;
}
export const defaultMilitaryIndexReducerTextState: MilitaryIndexReducerTextState = {
  MilitaryIndexReducerText: {}
};
export interface MilitaryTextState {
  MilitaryText: any;
}
export const defaultMilitaryTextState: MilitaryTextState = {
  MilitaryText: {}
};
