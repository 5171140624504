import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.css']
})
export class DatetimeComponent implements OnInit {

  @Input() id: string;
  @Input() value: string;
  @Input() index: string;
  @Input() required: boolean;

  dateTimeValue: any;

  constructor() { }

  ngOnInit() {
    let date;
    if (this.value != '') {
      let d = new Date(this.value);
      if (Object.prototype.toString.call(d) === '[object Date]') {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
          // date is not valid
          date = '';
        } else {
          // date is valid
          date = d;
        }
      } else {
        // not a date
        date = '';
      }
    }

    this.dateTimeValue = date;
  }

}
