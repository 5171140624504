import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PLPSharedService } from '../shared/shared/PLP-shared.service';
import { ApiCallClass } from '../../../shared/modal/apicall.modal';
import { StorageService } from '../../../shared/outer-services/storage.service';
import { Utilities } from '../../../shared/outer-services/utilities.service';
import { ServerApi } from '../../../shared/outer-services/app.apicall.service';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventDispatchService } from '../../../shared/outer-services/event-dispatch.service';



const formFillIndexVal = 0;


@Component({
  selector: 'plp-set',
  templateUrl: './PLP-sections.layout.html',
})
export class PlpComponent implements OnInit, OnDestroy {
  prevObjname;
  sectionsList;
  menuState = false;
  viewMode;
  oldViewMode;
  report;
  username;
  compleSectionsList = [];
  mouseup = new EventEmitter();
  mousedown = new EventEmitter();
  mousemove = new EventEmitter();
  @Input('report-status') reportState = '';

  subscription = new Subscription();
  componentType;
  sectionObject;
  itemsList = {};
  tileView = false;
  defaultPage = '';
  footerval;
  footervalip;
  footerPostion = 0;
  viewValue = 0;

  constructor(
    private router: Router,
    private eventService: EventDispatchService,
    private apiJson: ApiCallClass,
    private utils: Utilities,
    private storageService: StorageService,
    private apicall: ServerApi,
    private plpShared: PLPSharedService,
    private activatedRoute: ActivatedRoute
  ) {
    try {
      this.subscription = this.eventService.listen().subscribe((e) => {
        try {
          const name = e.type;
          const plpname = name.split('&');
          if (plpname[1] == 'PLPSections') {
            this.changeViewHeader(plpname[0]);
          }
        } catch (e) {
          alert('PLP-section constructor inside exception:' + e.message);
        }
      });
    } catch (e) {
      alert('PLP-section constructor exception:' + e.message);
    }

    this.routerEventmethod();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routerEventmethod();
      }
    });

    this.footervalip = '';
    this.footerval = 'footer_eq';
  }

  routerEventmethod() {
    let urlArr;
    let ref = this,
      activeRouteUrl = '';
    activeRouteUrl = this.router.url;
    urlArr = activeRouteUrl.split('/');

    if (urlArr[urlArr.length - 1] === 'tiles' || urlArr.length === 3) {
      this.tileView = true;
    } else {
      this.tileView = false;
    }
  }

  resultsArr = [];
  responseArr = [];
  // setTitle($event) {

  // }
  ngOnInit() {
    try {
      this.sectionObject = this.plpShared.getSectionObject('PersonalInfo');
    } catch (e) {
      console.log('Exception in PlpComponent oninit' + e.message);
    }
    this.storageService.sessionStorageSet(
      'itemsList',
      JSON.stringify(this.itemsList)
    );
    const frame = this.utils.getFrameworkComponent('modPLP'); //ListDynamicComponent;
    const items = this.utils.getItemsList(frame.compList, true);
    this.itemsList = items;

    this.componentType = frame.component;
    if (frame.layout == 'tiles') {
      this.router.navigate(['tiles'], { relativeTo: this.activatedRoute });
    } else {
    }
    const userdata = {
      accountID: this.utils.getAccountId(),
    };
    this.apiJson.moduleName = 'PLP';
    this.apiJson.method = 'GET';
    this.apiJson.endUrl = 'CompletionStatus';
    this.apiJson.sessionID = this.utils.getAuthKey();
    const user = JSON.stringify(userdata);
    this.apiJson.data = user;
    this.apicall.callApi([this.apiJson]).subscribe(
      (response) => {
        this.responseArr = response[0].Result;
        const reference = this;
        const ref = this;
        ref.itemsList['menuItems'].forEach(function (obj, inx) {
          ref.responseArr.forEach(function (resObj, resInx) {
            if (obj.apiName == ref.responseArr[resInx].SectionCode) {
              obj['fillStatus'] = ref.responseArr[resInx].SectionComplete;
            }
          });
        });
      },
      (e) =>
        this.utils.handleError(
          `PLP-sections-component.ts ngOnInit: ${e.message}`
        )
    );

    this.utils.showLoading();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  changeViewHeader(evnt) {
    try {
      const name = evnt;
      this.sectionObject = this.plpShared.getSectionObject(name);
    } catch (e) {
      console.log('Exception in PlpComponent oninit' + e.message);
    }
  }
  menuToggle() {
    this.menuState = !this.menuState;
  }
  menuClose() {
    this.menuState = false;
  }

  navView(evnt) {}
  changeFilledStatus(section) {}
}

