import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthInterceptorModule } from './auth-interceptor.module';
import { Utilities } from '../../shared/outer-services/utilities.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private utils: Utilities) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.utils.getAuthKey();
    // console.log("session token--->"+token);
    /*Usecase : Here the token was setting in headers*/
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }
 /*Usecase : Here this can taken as defalut if 'Content-Type' does not exist */
    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        const message = err.message ? err.message : err.toString();
        // console.log('message error---->' + err + '-----error name----' + err.name + '---' + err.status);
        try {
          if (err.name === 'HttpErrorResponse') {
            // console.log('message error----2>');
            // token expires condition
            // redirect to the login route
            // this.sessionExpired();
            // this.dialogPopUp.sessionExpire('Unauthorized Token');
            // window.location.href = '/';
          }
        } catch (e) {
          console.log('Handle error exception:' + e.message);
        }
      }
    }));
  }
}
