import { Action } from '@ngrx/store';

export enum LtiLocationActionTypes {
    LtiLocationInit = '@ngrx/store/init',
    GetLtiLocation = '[LtiLocationModel] GET_LTI_LOCATION',
    SetLtiLocation = '[LtiLocationModel] SET_LTI_LOCATION',
    RemoveLtiLocation= '[LtiLocationModel] REMOVE_LTI_LOCATION'
}

export class LtiLocationInitAction implements Action {
    readonly type = LtiLocationActionTypes.LtiLocationInit;
}

export class GetLtiLocationAction implements Action {
    readonly type = LtiLocationActionTypes.GetLtiLocation;
}
export class RemoveLtiLocationAction implements Action {
    readonly type = LtiLocationActionTypes.RemoveLtiLocation;
}

export class SetLtiLocationAction implements Action {
    readonly type = LtiLocationActionTypes.SetLtiLocation;
    constructor(public payload: string) { }
}

export type LtiLocationActions =
    LtiLocationInitAction |
    GetLtiLocationAction |
    SetLtiLocationAction |
    RemoveLtiLocationAction;
