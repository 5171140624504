/**Angular core packages */
// import { TilesDynamicComponent } from '../../modules/framework/layouts/tiles.component';
// import { WidgetDynamicComponent } from '../../modules/framework/layouts/widget.component';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import { Http, Response } from '@angular/http';
/**Import third party libraries */
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { ListDynamicComponent } from '../../modules/framework/layouts/list.component';
import { WidgetDynamicComponent } from '../../modules/framework/layouts/widget/widget.component';
/**Custom Imports */
import {
  AsmntCommonTextState,
  FrameworkConfigState,
} from '../../state-management/state/main-state';
import { returnUrl, routeObj } from '../constants/app.constants';
import { messages } from '../constants/messages';
import {
  NgbdModalContent,
  NgbdModalLoaderContent,
} from '../modal/shared-modal-component';
import { StorageService } from '../outer-services/storage.service';
import { EventDispatchService } from './event-dispatch.service';
import { catchError, map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

let errCall; // Variable for errCall assigning the setTimeOut method

/* dialog is reference of loading model template.*/
@Injectable()
export class Utilities implements OnDestroy {
  osFromDashboard = false;
  options: NgbModalOptions = {
    backdrop: 'static',
  }; // options variable passing as a parameter to the open method of modalService
  timeoutVariable; // Variable for assigning the setTimeOut method
  modalRef1; // Variable for assigning the modalService open method
  commonModelText; // Variable for assigning the AsmntCommonText
  frameworkConfig; // Variable for assigning the subscribe value of FrameworkConfigReducer
  /** This function is for logging out of the modules. */
  handleError = function (error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    this.hideLoading();
    this.storageService.sessionStorageRemove('error');
    clearTimeout(errCall);
    // In this function checking the conditions for messages for TokenExpiredError, 401 Error
    errCall = setTimeout(
      function () {
        try {
          if (error.name === 'TokenExpiredError') {
            this.activeModel.close(true);
            this.sessionExpired();
          } else {
            this.log(`${error} ${this.getClientInfo()}`, 'error').subscribe(() => {
              this.hideLoading();
            });
            this.callErrorPopup();
          }
        } catch (e) {
          console.log('Handle error exception:' + e.message);
        }
      }.bind(this),
      0
    );
  }.bind(this);
  constructor(
    private Frameworkstore: Store<FrameworkConfigState>,
    private asmntCommonstore: Store<AsmntCommonTextState>,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private activeModel: NgbActiveModal,
    private eventService: EventDispatchService,
    private loaderContent: NgbdModalLoaderContent,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private deviceService: DeviceDetectorService
  ) {
    this.commonModelText = asmntCommonstore.select('AsmntCommonText');
    this.Frameworkstore.select('config').subscribe((v) => {
      this.frameworkConfig = v;
    });
  }
  getFrameObjbyId() {
    const frameworkResult = this.frameworkConfig;
    const moduleName = this.storageService.sessionStorageGet('tabId');
    const framework = frameworkResult['config'].Result.tabItems.find(
      (obj, inx) => {
        return moduleName == obj.tabId;
      }
    );
    try {
      if (framework + '' != 'undefined') {
        framework.compList.forEach(
          function (obj, inx) {
            if (
              routeObj[obj.compId] !== undefined ||
              obj.compId.includes('url')
            ) {
              let result1;
              if (obj.compId.startsWith('url')) {
                routeObj['url'].itemConfig.image = obj.compIconUrl;
                routeObj['url'].itemConfig.icon = obj.compIconUrl;
                routeObj['url'].itemConfig.url = obj.compUrl;
                routeObj['url'].itemConfig.apiName = obj.compName;
                routeObj['url'].itemConfig.section = obj.compName;
                routeObj['url'].itemConfig.color = obj.compColor;
                result1 = Object.assign({}, obj, routeObj['url'].itemConfig);
              } else {
                result1 = Object.assign(
                  {},
                  routeObj[obj.compId].itemConfig,
                  obj
                );
              }
              framework.compList[inx] = result1;
            } else {
              framework.compList.splice(inx, 1);
            }
          }.bind(this)
        );
        framework['menuHighlightStatus'] = false;
        return framework;
      }
    } catch (e) {
      console.log('assessment entry exception:' + e.message);
    }
  }
  ngOnDestroy() {}

  // In this function checking the conditions for widget,tiles and dashboard layouts
  getFrameworkComponent(moduleName) {
    let frame;
    this.frameworkConfig.config.Result.tabItems.forEach(function (obj, inx) {
      if (obj.tabId + '' === moduleName + '') {
        frame = obj;
        if (obj.layout === 'widget') {
          frame['component'] = WidgetDynamicComponent;
        } else if (obj.layout === 'tiles') {
        } else if (obj.layout === 'dashboard') {
          frame['component'] = ListDynamicComponent;
        }
      }
    });
    return frame;
  }

  // In this method passing name as an argument of sectionname and dispatch an event
  dispatchSectionLoad(name) {
    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent(name, true, true);
    this.eventService.dispatch(evnt);
  }

  /**getItemsList method @param frameworkObj ,@param status passing parameter  frameworkObj
   * contains compList array has assessments and careers
   * status contains true or false
   */
  getItemsList(frameworkObj, status) {
    // let list = [], result = {};

    frameworkObj.compList.forEach(function (obj, inx) {
      if (routeObj[obj.compId] !== undefined) {
        const result = Object.assign({}, routeObj[obj.compId].itemConfig, obj);
        frameworkObj.compList[inx] = result;
      }
    });

    frameworkObj['menuHighlightStatus'] = status;

    return frameworkObj;
  }

  /** For showing loading symbol */
  showLoading() {
    this.loaderContent.showLoading();
  }

  /** For hiding loading symbol */
  hideLoading() {
    this.loaderContent.hideLoading();
  }
  // username pattern validator
  userNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      //username length is 5 - 50 in length, excludding space," #:'\
      const regexp = new RegExp('^[^#:<\\\\>/\'"/` ]{5,50}$');

      return regexp.test(control.value) ? null : { invalidUsername: true };
    };
  }
  // imageUrl pattern validator
  imgUrlPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('https?://.+.(?:png|jpe?g|jpg|svg|gif|bmp)');
      const valid = regex.test(control.value);
      return valid ? null : { invalidImageUrl: true };
    };
  }
  // Method to validate the new password and re-type password:
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  // password pattern validator
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  // hides the modal
  hideModal() {}

  // Call error modalpopup
  callErrorPopup() {
    const modalRef = this.modalService.open(NgbdModalContent, this.options);
    modalRef.componentInstance.trans_error = 'Error';
    modalRef.componentInstance.close = 'Close';
    modalRef.componentInstance.err_occ = 'Sorry an error occured';
    modalRef.componentInstance.Closebtnvalue = '1';
  }
  // call testScore 'are you sure to delete' modalpopup
  callAlertSure(inx, testarray) {
    const modalRef = this.modalService.open(NgbdModalContent, this.options);
    modalRef.componentInstance.deletevalue = inx;
    modalRef.componentInstance.arrayValue = testarray;
    modalRef.componentInstance.commonButtons = {
      headsection: 'alert',
      yesbtn: 'yes',
      nobtn: 'no',
    };
    modalRef.componentInstance.err_occ = 'Are you sure you want to delete?';
  }

  /**setClearTime function for modal closing at the time of sessionExpiration  */
  setClearTime() {
    clearTimeout(this.timeoutVariable);
    this.timeoutVariable = setTimeout(
      function () {
        this.modalRef1.close();
        this.sessionExpired();
      }.bind(this),
      2 * 60 * 1000
    );
  }

  /**clearTimeoutVariable function for calling the clearTimeout method by passing timeoutVariable */
  clearTimeoutVariable() {
    clearTimeout(this.timeoutVariable);
  }

  /**sessionExpired function called at the time of session Expiration and exit the application  */
  sessionExpired() {
    this.storageService.sessionStorageSet('sessionExpired', 'true');
    this.exitApp('expired');
  }

  /**getFramework method for the getting the http response from frameworkJSON JSON file */
  getFramework() {
    this.http.get('/assets/frameworkJSON.json').subscribe(
      (data) => {
        this.storageService.sessionStorageSet(
          'loginFrameworkConfiguration',
          JSON.stringify(data)
        );
      },
      (err) => console.error(err)
    );
  }

  /**setAccountId method for setting the accountID to the sessionStorageSet method */
  setAccountId(id) {
    return this.storageService.sessionStorageSet('accountID', id);
  }

  /**getAccountId method for getting the accountID to the sessionStorageGet method */
  getAccountId() {
    return 'accountID';
  }

  /**setAuthKey method for setting the auth_key using sessionStorageSet method */
  setAuthKey(key) {
    window.localStorage.setItem('formioToken', key);
    return this.storageService.sessionStorageSet('auth_key', key);
  }

  /**getAuthKey method for setting the auth_key using sessionStorageGet method */
  getAuthKey() {
    return this.storageService.sessionStorageGet('auth_key');
  }

  /**getReturnUrl method returning the url */
  getReturnUrl() {
    return returnUrl.url;
  }

  /** getMessages method for returning the messages */
  getMessages() {
    return messages;
  }
  initialSessionSettings(item) {
    /* Here the below code is copied from tiles component ,Because storing some data in sessions which are
       related to career and education tabs*/
    this.storageService.sessionStorageSet('careerShow', '0');
    this.storageService.sessionStorageSet('activeSortId', item.compId);
    this.storageService.sessionStorageSet('progschool', 'false');
    this.storageService.sessionStorageSet('showHeaderText', '0');
    this.storageService.sessionStorageRemove('filterSettings');
    this.storageService.sessionStorageRemove('prevRoute');
    this.storageService.sessionStorageSet('savedValId', '');
    if (item.compId == 'sortCCIJr') {
      this.storageService.sessionStorageSet('CCIassessment', 'CCIJr');
    } else {
      this.storageService.sessionStorageSet('CCIassessment', 'CCIAdult');
    }
  }
  tabChange(tabItems, resultData) {
    tabItems.map((tabItem) => {
      const tab = tabItem.compList.find(
        (compItem) => resultData.compId === compItem.compId
      );
      if (tab) {
        resultData['newTabid'] = tabItem.tabId;
      }
    });
    return resultData;
  }
  appendDynamicColors(colorObj) {
    if (
      colorObj &&
      colorObj.primary &&
      colorObj.secondary &&
      colorObj.planHeader
    ) {
      document.documentElement.style.setProperty(
        '--primary-color',
        colorObj.primary
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        colorObj.secondary
      );
      document.documentElement.style.setProperty(
        '--plan-header-color',
        colorObj.planHeader
      );
    }
  }
  appendDynamicIconStyle() {
    if (this.storageService.sessionStorageGet('isJunior')) {
      document.documentElement.style.setProperty('--icon-size', '100%');
    } else {
      document.documentElement.style.setProperty('--icon-size', '75%');
    }
  }
  log(error: any, level: string): Observable<any> {
    try {
      let errObj = {};
      if (typeof error === 'object') {
        errObj = {
          error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
          level: level,
        };
      } else {
        errObj = { error: error, level: level };
      }
      return this.http.post('/logError', errObj);
    } catch (e) {
      alert('post error exception:' + e.message);
    }
  }
  getDecodedAccessToken() {
    const authVal = this.storageService.sessionStorageGet('auth_key');
    const tokenInfo = jwt_decode(authVal);
    return tokenInfo.payload;
  }
  deepIsEqual(first, second) {
    // If first and second are the same type and have the same value
    // Useful if strings or other primitive types are compared
    if (first === second) {
      return true;
    }
    // Try a quick compare by seeing if the length of properties are the same
    const firstProps = Object.getOwnPropertyNames(first);
    const secondProps = Object.getOwnPropertyNames(second);
    // Check different amount of properties
    if (firstProps.length !== secondProps.length) {
      return false;
    }
    // Go through properties of first object
    for (let i = 0; i < firstProps.length; i++) {
      const prop = firstProps[i];
      // Check the type of property to perform different comparisons
      switch (typeof first[prop]) {
        // If it is an object, decend for deep compare
        case 'object':
          if (!this.deepIsEqual(first[prop], second[prop])) {
            return false;
          }
          break;
        case 'number':
          // with JavaScript NaN != NaN so we need a special check
          if (isNaN(first[prop]) && isNaN(second[prop])) {
            break;
          }
        // tslint:disable-next-line: no-switch-case-fall-through
        default:
          if (first[prop] !== second[prop]) {
            return false;
          }
      }
    }
    return true;
  }
  public getClientInfo() {
    return `client=${JSON.stringify(this.deviceService.getDeviceInfo())} browser=${this.getBrowserName()}`
  }
	public getBrowserName() {
		const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
		  case agent.indexOf('edge') > -1:
			return 'edge';
		  case agent.indexOf('opr') > -1 && !!(<any>window).opr:
			return 'opera';
		  case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
			return 'chrome';
		  case agent.indexOf('trident') > -1:
			return 'ie';
		  case agent.indexOf('firefox') > -1:
			return 'firefox';
		  case agent.indexOf('safari') > -1:
			return 'safari';
		  default:
			return 'other';
		}
	}
  /**This function has exitApp implementation
   * @param option conatins loggedout value
   */
  exitApp(option) {
    try {
      return this.http
        .post('/logout/log', {})
        .pipe(
          // catch error is required incase a 401 is returned from trying to use an expired JWT
          catchError((err) => (window.location.href = '/logout'))
        )
        .subscribe(
          (response: any) => (window.location.href = response.logoutURL)
        );
    } catch (e) {
      console.log('exitApp exception:' + e.message);
    }
  }
}
