/**Angular2 Libraries **/
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

/**Third Party Libraries.*/
import { Store } from '@ngrx/store';

/** Custom imports.*/
import { FrameworkConfigState, AsmntCommonTextState } from '../../../../state-management/state/main-state';
import { AssessmentsService } from '../services/assessments.service';
import { EventDispatchService } from '../../../../shared/outer-services/event-dispatch.service';
import { headerButtons } from '../constants/assessments-constants';
import { StorageService } from '../../../../shared/outer-services/storage.service';
import { Utilities } from '../../../../shared/outer-services/utilities.service';
import { RouteService } from '../../../../shared/outer-services/route.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'assessment-header',
  templateUrl: './assessment-header.layout.html',
})
export class AssessmentHeaderComponent implements OnInit, OnDestroy {
  assessmentheadextra; /**variable for storing the extraname value.*/
  headerSubscription; /**variable for storing the subscription value of FreameworkConfigReducer.*/
  _hash = '!';
  constantNames = [];
  routeSub = new Subscription(); /** Declare to listen if any change occured.*/
  subscription =
    new Subscription(); /** Declare to listen if any change occured.*/
  commonTxtSubscription =
    new Subscription(); /** Declare to listen if any change occured.*/
  asmntname = ''; /**variable for stroing the assessment value*/
  tooltipArray = {};
  endurl = ''; /**variable for storing the endurl*/
  occID; /**variable for storing the occID value*/
  headerid; /**variable for storing the assessment id name*/
  headerName; /**variable for storing the assessment header name*/
  headerImage;
  backCareerValue = false; /**variable for storing the boolean value false*/
  path;
  HeaderExtra = 'false'; /**Declare for storing the boolean values.*/
  reducerSub =
    new Subscription(); /** Declare to listen if any change occured.*/
  returnedVal; /**variable for assessment common text*/
  btnValue = false; /**variable for boolean value defaults false*/

  constructor(
    private trackEvnt: AssessmentsService,
    private route: Router,
    private Frameworkstore: Store<FrameworkConfigState>,
    private asmntCommonstore: Store<AsmntCommonTextState>,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private storageService: StorageService,
    private routeService: RouteService,
    private eventService: EventDispatchService
  ) {
    this.subscription = eventService.listen().subscribe((e) => {
      if (e.type === 'restore_btn_true') {
        this.HeaderExtra = 'true';
      } else if (e.type === 'restore_btn_false') {
        this.HeaderExtra = 'false';
      }
    });
    try {
      this.trackEvnt.callCommonText();

      this.commonTxtSubscription = asmntCommonstore
        .select('AsmntCommonText')
        .subscribe((v) => {
          this.returnedVal = v;

          if (
            this.returnedVal.commonText != null ||
            this.returnedVal.commonText != undefined
          ) {
            if (
              this.returnedVal.commonText.restore != null ||
              this.returnedVal.commonText.restore != undefined
            ) {
              this.tooltipArray['restore'] =
                this.returnedVal.commonText.restore;
            }
            if (
              this.returnedVal.commonText.results != null ||
              this.returnedVal.commonText.results != undefined
            ) {
              this.tooltipArray['Back_Result'] =
                this.returnedVal.commonText.results;
            }
            if (
              this.returnedVal.commonText.startOver != null ||
              this.returnedVal.commonText.startOver != undefined
            ) {
              this.tooltipArray['startover'] =
                this.returnedVal.commonText.startOver;
            }
            if (
              this.returnedVal.commonText.save != null ||
              this.returnedVal.commonText.save != undefined
            ) {
              this.tooltipArray['partialsave'] =
                this.returnedVal.commonText.save;
              this.tooltipArray['resultsave'] =
                this.returnedVal.commonText.save;
            }
            if (
              this.returnedVal.commonText.print != null ||
              this.returnedVal.commonText.print != undefined
            ) {
              this.tooltipArray['print'] = this.returnedVal.commonText.print;
            }
          }
        });
      this.routeEvents();
      this.routeSub = route.events
        .pipe(debounceTime(300))
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.routeEvents();
          }
        });
    } catch (e) {
      console.log('assessment header constructor exception:' + e.message);
    }
  }
  routeEvents() {
    this.path = this.route.url.split('/');
    if (this.path.length > 1) {
      this.asmntname = this.path[this.path.length - 2];
    }
    if (this.path.length > 2) {
      if (this.path[this.path.length - 1].indexOf('?') == -1) {
        this.endurl = this.path[this.path.length - 1];
      } else {
        const val = this.path[this.path.length - 1].split('?');
        this.endurl = val[0];
      }
    }
    if (
      this.endurl.indexOf('occCareer') == -1 &&
      this.endurl.indexOf('occCluster') == -1 &&
      this.endurl.indexOf('occEmergCareer') == -1 &&
      this.endurl.indexOf('occIndex') == -1 &&
      this.endurl.indexOf('compare') == -1
    ) {
      this.constantNames = headerButtons[this.endurl];
    }
    setTimeout(
      function () {
        const TextSub = JSON.parse(
          this.storageService.sessionStorageGet('AsmntIntroText')
        );
        if (TextSub != null) {
          if (this.endurl.indexOf('comparison') != -1) {
            this.assessmentheadextra =
              TextSub.AsmntIntroText.pageText.comparePage.pageTitleExtra;
          } else if (this.endurl.indexOf('occlist') != -1) {
            this.assessmentheadextra =
              TextSub.AsmntIntroText.pageText.listPage.pageTitleExtra;
          } else if (this.endurl.indexOf('result') != -1) {
            this.assessmentheadextra =
              TextSub.AsmntIntroText.pageText.resultsPage.pageTitleExtra;
          } else if (this.endurl.indexOf('restore') != -1) {
            const CommonSub = JSON.parse(
              this.storageService.sessionStorageGet('AsmntCommonTextStorage')
            );
            this.assessmentheadextra = ': ' + CommonSub.commonText.restore;
          } else if (this.endurl.indexOf('factors') != -1) {
            this.occFactorHeader();
          } else {
            this.assessmentheadextra = '';
          }
        }
      }.bind(this),
      2300
    );

    const rtArr = window.location.pathname.split('/');

    for (let i = 0; i < rtArr.length; i++) {
      if (
        this.storageService.sessionStorageGet('inTab') != undefined ||
        this.storageService.sessionStorageGet('inTab') != null
      ) {
        if (
          rtArr[i] == 'occlist' &&
          this.storageService.sessionStorageGet('inTab') == 'career'
        ) {
          this.storageService.sessionStorageSet('goTo', 'career');
          this.backCareerValue = true;
          break;
        } else if (
          this.storageService.sessionStorageGet('inTab') == 'cciCareer'
        ) {
          this.storageService.sessionStorageSet('goTo', 'cluster');
          this.backCareerValue = true;
          break;
        } else {
          this.storageService.sessionStorageSet('goTo', '');
          this.backCareerValue = false;
        }
      }
    }
  }
  /**ngOnInit method called when  initialization of the component */
  ngOnInit() {
    this.headerSubscription = this.Frameworkstore.select('config').subscribe(
      (id) => {
        const ref = this;
        ref.storageService.sessionStorageSet('logoutClicked', '');
        ref.headerid = ref.storageService.sessionStorageGet('activeSortId');
        id['config']['Result']['tabItems'].forEach(function (obj, inx) {
          for (let i = 0; i < obj.compList.length; i++) {
            if (ref.headerid === obj.compList[i].compId) {
              ref.headerName = obj.compList[i].compName;
              if (obj.compList[i].image) {
                ref.headerImage = obj.compList[i].image;
              }
              ref.storageService.sessionStorageSet(
                'assessName',
                obj.compList[i].compName
              );
              break;
            }
          }
        });
      }
    );
  }

  /**
   * This method is for calling the realted functionalities in mobile view.
   * @param call stores the related items clicked in the mobile view.
   */
  callFunction(call) {
    if (call == 'restore') {
      this.restoreAnswerSets();
    } else if (call == 'assessName') {
      this.launchIntroPage();
    } else if (call == 'startover') {
      this.startOver();
    } else if (call == 'partialsave') {
      this.saveParitalAssesment(this.headerName);
    } else if (call == 'resultsave') {
      this.saveResultAssessment(this.headerName);
    } else if (call == 'print' || call == 'Report') {
      this.printResult();
    } else if (call == 'Back_Result') {
      this.backToResult();
    } else if (call == 'Back_Career') {
      this.backToCareer(this.headerName);
    }
  }

  /**occFactorHeader method for the Occupation Sort Factors headers changing dynamically in factors page*/
  occFactorHeader(): Observable<Event> {
    const TextSub = JSON.parse(
      this.storageService.sessionStorageGet('AsmntIntroText')
    );

    if (this.storageService.sessionStorageGet('occFactorHeader') == 'true') {
      this.assessmentheadextra =
        TextSub.AsmntIntroText.pageText.rankPage.pageTitleExtra;
    } else {
      this.assessmentheadextra =
        TextSub.AsmntIntroText.pageText.selectPage.pageTitleExtra;
    }
    return;
  }
  /**This method for saving the partial assessment */
  saveParitalAssesment(assessmentName) {
    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent('save_Partial', true, true);
    this.eventService.dispatch(evnt);
  }

  /**This function is for restoring the answer sets. */
  restoreAnswerSets() {
    this.route.navigate(['./restore'], { relativeTo: this.activatedRoute });
  }

  /**This function is for saving the assessement. */
  saveResultAssessment(assessmentName) {
    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent('save_Complete', true, true);
    this.eventService.dispatch(evnt);
  }

  /**In restore page of assessments, this method for the button navigate to the assessment intro page */
  launchIntroPage() {
    this.storageService.sessionStorageSet('save_Par_UserNotes', '');
    this.storageService.sessionStorageSet('save_Com_UserNotes', '');
    this.route.navigate(['./intro'], { relativeTo: this.activatedRoute });
  }

  /**This function is for showing the pop-up and for starting the assessement.  */
  startOver() {
    this.storageService.sessionStorageSet('save_Par_UserNotes', '');
    this.storageService.sessionStorageSet('save_Com_UserNotes', '');
    if (this.path.indexOf('assessment') != -1) {
      this.trackEvnt.showStartOverDialog('/intro');
    } else {
      this.storageService.sessionStorageRemove('logID');
      const rtArr = this.route.url.split('/');
      const rtVal = rtArr.slice(0, rtArr.length - 1).join('/');
      this.route.navigate([rtVal + '/intro'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**This function is for printing the result screen in Interest-profiler and Entrepreneur-quiz. */
  printResult() {
    const rtArr = this.route.url.split('/');
    const rtVal = rtArr.slice(rtArr.length - 2, rtArr.length - 1).join('/');
    const evnt = document.createEvent('CustomEvent');
    const name = 'print_' + rtVal;
    evnt.initEvent(name, true, true);
    this.eventService.dispatch(evnt);
  }

  /*This function is to display the browser pop-up on page refresh*/

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander($event: any) {
    const savePart = this.storageService.sessionStorageGet('savePartial');
    this.storageService.sessionStorageSet('isAssessment', this.endurl);

    if (
      this.path.indexOf('assessment') != -1 &&
      savePart != 'yes' &&
      this.storageService.sessionStorageGet('logoutClicked') == ''
    ) {
      $event.returnValue = 'Your data will be lost!';
    }
  }

  @HostListener('window:hashchange', ['$event'])
  hashChangeEvent($event: any) {
    if (window.location.hash !== this._hash) {
      window.location.hash = this._hash;
    }
  }

  /**This function is for logging out of the modules. */
  logOut() {
    this.storageService.sessionStorageSet('logoutClicked', 'yes');
    if (this.path.indexOf('assessment') != -1) {
      this.trackEvnt.showStartOverDialog('logout');
    } else {
      this.routeService.mainLogOut();
    }
  }

  /**BackToResult method for the button in the occlist pages navigates to the result page */
  backToResult() {
    this.route.navigate(['./result'], { relativeTo: this.activatedRoute });
  }

  /**BackToCareer method for the button navigates to the career page when click on the link in personal qualities page*/
  backToCareer(assessmentName) {
    const rtArr = window.location.pathname.split('/');
    try {
      let navVal, navTo, id;
      const vl = JSON.parse(
        this.storageService.sessionStorageGet('loginFrameworkConfiguration')
      ).config.Result.tabItems;
      for (let i = 0; i < vl.length; i++) {
        const list = vl[i].compList;
        for (let j = 0; j < list.length; j++) {
          id = list[j].compId;
          if (id == 'fileOcc') {
            navVal = i;
          }
        }
      }
      for (let i = 0; i < rtArr.length; i++) {
        if (this.storageService.sessionStorageGet('goTo') == 'career') {
          this.occID = this.storageService.sessionStorageGet('occIDval');
          this.route.navigate(
            ['../../' + navVal + '/careers-details/occCareer'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {
                occid: this.occID,
                occname: '',
              },
            }
          );
        } else if (this.storageService.sessionStorageGet('goTo') == 'cluster') {
          const clusId = this.storageService.sessionStorageGet('occIDval');
          this.route.navigate(
            ['../../' + navVal + '/careers-details/occCluster'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {
                clusId: this.storageService.sessionStorageGet('cciClus_ID'),
                clusIcon: this.storageService.sessionStorageGet('cciClusIcon'),
                clusColor:
                  this.storageService.sessionStorageGet('cciclusColor'),
              },
            }
          );
        }
      }
    } catch (e) {
      console.log('backToCareer exception:' + e.message);
    }
  }

  /** This ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routeSub.unsubscribe();
    this.headerSubscription.unsubscribe();
    this.commonTxtSubscription.unsubscribe();
  }
}
