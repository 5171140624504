import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
enableProdMode();
// if (environment.production) {
//   enableProdMode();
// }
platformBrowserDynamic().bootstrapModule(AppModule);
