/**Angular imports */
import { Component, Input, Inject, Renderer2 } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

/**Third party libraries */
import { Action, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// import { ISlimScrollOptions } from 'ng2-slimscroll';
import {
    debounceTime
} from 'rxjs/operators';
/**Custom imports */
import { EventDispatchService } from '../../../../shared/outer-services/event-dispatch.service';
import { FrameworkConfigState } from '../../../../state-management/state/main-state';
import { langData } from '../../../../shared/constants/app.constants';
import { PLPSharedService } from '../../../PLP/shared/shared/PLP-shared.service';
import { routeObj } from '../../../../shared/constants/app.constants';
import { sectionsArr } from '../../../../shared/constants/app.constants';
import { StoreService } from '../../../../state-management/services/store-service';
import { Utilities } from '../../../../shared/outer-services/utilities.service';

@Component({
    selector: 'widget-dynamic-component',
    template: `


        <div class="container content-box-shadow tiles-top-spacing"><!--  -->

          <div class="d-block">

          <PLP-nav-header class="w-100"  [header]="sectionObject" [FrameworkObj]='FrameworkObj' (changeInrView)="changeViewHeader($event)" [report]="''"></PLP-nav-header>


        <div class="side-menu">

           <a href="#" class="widget-side-menu" (click)="$event.preventDefault();menuToggle()"  >
                <div class="icon-none-m" data-bs-toggle="collapse" data-bs-target=".nav-collapse.in">
                <i class="fa-micon-menu fa icon" [class.fa-close]="menuState" [class.fa-bars]="!menuState"></i>
                </div>
                <div class="side-menu-text"> <span>Personal Learning Plan</span> </div>
            </a>

  <div class="scrollbar"   (click)="menuClose()">
    <nav class="main-menu sidemenu-t" [class.expanded]="menuState">

      <div  class="side-menu-scroll-bar" >
        <div class="scroll-innter">
          <ul>
            <li *ngFor="let item of FrameworkObj?.compList;let inx=index"
            [ngClass]="(filledStatusVariable[inx])? 'menu-filled':''"
            [class.menu-active]="(viewMode == item.url)"

                       class="white-color"  > <a [routerLink]="item.url">
                        <span  [ngClass]="(filledStatusVariable[inx])? 'menu-span-bg-box':'menu-not-filled'"> <i class="fa-micon {{item.icon}} fa"></i> </span>

                        <span class="nav-text">{{item.compName}}</span> <i *ngIf = "(filledStatusVariable[inx])" class="fa-micon fa fa-check"></i> </a>
                         </li>
          </ul>
        </div>
      </div>

    </nav>
  </div>

</div>
<div (click)="menuClose()"  >
  <router-outlet></router-outlet>
</div>
</div>
</div>

    `,
})


export class WidgetDynamicComponent {
    @Input('FrameworkObj') FrameworkObj;
    viewMode; /**Declare for storing the url. */
    menuState = false; /**Declare for storing the boolean value. */
    opts; /**Declare for storing the css values. */
    sectionObject;
    filledStatusVariable = [];
    subscription = new Subscription; /** Declare to listen if any change occured.*/
    subscription1 = new Subscription; /** Declare to listen if any change occured.*/

    constructor(
        private common: StoreService,
        private renderer: Renderer2,
        private router: Router,
        private plpShared: PLPSharedService,
        private eventService: EventDispatchService,
        private store: Store<any>) {
        this.subscription = this.eventService.listen().subscribe((e) => {
            try {
                if (e.type.indexOf('&Filled') != -1 || e.type.indexOf('&Empty') != -1) {
                    const val = './' + e.type.split('&')[0];
                    this.FrameworkObj.compList.forEach((obj, inx) => {
                        if (obj.url == val) {
                            if (e.type.indexOf('&Filled') != -1) {
                                obj.SectionComplete = true;
                                this.filledStatusVariable[inx] = true;
                            } else if (e.type.indexOf('&Empty') != -1) {
                                this.filledStatusVariable[inx] = false;
                                obj.SectionComplete = false;
                            }
                        }
                    });

                }
            } catch (e) {
                alert('widget constructor inside exception:' + e.message);
            }
        });

        this.opts = {
            position: 'right',
            barBackground: '#555',
            gridBackground: '#016269',
            barWidth: '6',
            gridWidth: '0',
            barBorderRadius: '2'
        };

    }
    ngOnInit() {
        this.sectionObject = this.plpShared.getSectionObject('PersonalInfo');
        const payloadjson = {
            type: 'GET_SECTIONS_STATES', payload: {
                methodVal: 'GET', module_Name: 'PLP/api',
                path_params: ['CompletionStatus'], query_params: {},
                body_Params: {}, endUrlVal: ''
            }
        };
        this.common.commonLanguageChange('en', 'sectionsstatus', payloadjson);
        const ref = this;
        this.subscription1 = this.store.select('SectionsStatusValues').subscribe((sectionsResult) => {
            this.FrameworkObj.compList.forEach(function (obj, inx) {
                sectionsResult['statusValues'].find((inrObj) => {
                    if (obj.apiName == inrObj.SectionCode) {
                        obj['SectionComplete'] = inrObj.SectionComplete;
                        ref.filledStatusVariable[inx] = inrObj.SectionComplete;
                        return true;
                    }

                });
                obj.SectionComplete;

            });
        });

        const urlArr = window.location.pathname.split('/');
        this.viewMode = './' + urlArr[urlArr.length - 1];
        this.subscription = this.router.events.pipe(debounceTime(100)).subscribe(event => {
            const urlArr = window.location.pathname.split('/');
            this.viewMode = './' + urlArr[urlArr.length - 1];
        });
    }
    menuToggle() {
        this.menuState = !this.menuState;
    }

    /**This method gets executed while closing the menu. */
    menuClose() {
        this.menuState = false;
    }

    /**
     * This method is for changing the view of the header.
     * @param evnt declare for event occuring.
     */
    changeViewHeader(evnt) {
        try {
            const name = evnt;
            this.sectionObject = this.plpShared.getSectionObject(name);
        } catch (e) {
            console.log('Exception in PlpComponent oninit' + e.message);
        }
    }

    /**
   * This method is used for unsubscribing the event.
   */
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscription1.unsubscribe();
    }
}
