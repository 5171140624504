// Angular imports
import { Injectable, OnDestroy } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';

// Third party imports
import { Subscription } from 'rxjs';

// Custom Imports
import { AssessmentsService } from '../../modules/assessments/shared/services/assessments.service';
import { ServerApi } from './app.apicall.service';
import { StorageService } from './storage.service';
import { StoreService } from '../../state-management/services/store-service';
import { Utilities } from './utilities.service';
import { clusterDetails } from '../../modules/assessments/shared/constants/assessments-constants';
import { ApiCallClass } from '../modal/apicall.modal';
import { getRouteConfig } from '../../route.config';
import { EventDispatchService } from './event-dispatch.service';
import { PlanConfigState, FrameworkConfigState } from '../../state-management/state/main-state';
import { routeObj } from '../../shared/constants/app.constants';
import { take, takeWhile } from 'rxjs/operators';
import { getAccountTypeselector } from '../../state-management/selectors/account-Type.selector';
import { AccountTypeModel } from '../../state-management/state/account-Type.state';



@Injectable()
export class StoreSharedService implements OnDestroy {
  /*This constructor initializes values*/

  framework;
  subscription4 = new Subscription();
  subscription: Subscription;
  DynamicPLPData = [];
  PlanSettings: Subscription;
  keys = [];
  methodresult = 0;
  compActive = true;
  // DynamicPLPData: any = [];
  /**
   *
   * @param trackEvnt is to call CommonText in Assessment service,
   * @param commonlang is to call commonLanguageChange function that stores all occ data,
   * @param apiJson is a JSON obj that contains parameters to call API,
   * @param loginauth is to call API,
   * @param assessStoreService is to call getLangChangefunction when ever the lang is changed,
   * @param router is an angular router,
   * @param dispatchStore is to dispatch events to effect or reducer,
   * @param utils is a service,
   * @param storageService is a service to utilies session storage methods
   */
  constructor(
    private trackEvnt: AssessmentsService,
    private commonlang: StoreService,
    private apiJson: ApiCallClass,
    private loginauth: ServerApi,
    private assessStoreService: StoreService,
    private router: Router,
    private dispatchStore: Store<Action>,
    private utils: Utilities,
    private eventDispatcher: EventDispatchService,
    private planstore: Store<PlanConfigState>,
    public activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private Frameworkstore: Store<FrameworkConfigState>,
    private accountStore: Store<AccountTypeModel>
  ) {
    this.subscription = this.eventDispatcher.listen().subscribe((e) => {
      try {
        if (e.type == 'PlanChange') {
          const index = this.storageService.sessionStorageGet('planIndex');
          this.planLoading(index);
        }
        if (e.type == 'PlanLoad') {
          this.PlansDataGetCall();
        }

        if (e.type.includes('statusChange')) {
          this.statusChange(
            e.type.split('&')[1],
            e.type.split('&')[2],
            e.type.split('&')[3]
          );
        }
      } catch (e) {
        console.log('Error in eventDispatch--->' + e.message);
      }
    });
  }

  // To call effects
  callEffect() {
    const routerVal = this.router.url.split('&');
    const occID = routerVal[0].split('=');
    const outlook = this.storageService.sessionStorageGet('outlookStates');
    const wages = this.storageService.sessionStorageGet('wagesStates');
    if (!occID || occID.length === 0) {
      this.utils.log('store-shared callEffect EMP_OUTLOOK_TEXT', 'debug').subscribe();
    }
    if (outlook != null) {
      this.dispatchStore.dispatch({
        type: 'EMP_OUTLOOK_TEXT',
        payload: {
          methodVal: 'GET',
          module_Name: 'Occ/v1/',
          path_params: [
            'occ',
            parseInt(occID[1], 10),
            this.utils.getAccountId(),
          ],
          query_params: {
            sections:
              'MajorEmployers,OutlookInfo,OutlookRatings,TopOpeningLocations',
            states: outlook,
            lang: this.storageService.sessionStorageGet('langset'),
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    }
    if (wages != null) {
      this.dispatchStore.dispatch({
        type: 'WAGES_TEXT',
        payload: {
          methodVal: 'GET',
          module_Name: 'Occ/v1/',
          path_params: [
            'occ',
            parseInt(occID[1], 10),
            this.utils.getAccountId(),
          ],
          query_params: {
            sections: 'WageLevels',
            states: wages,
            lang: this.storageService.sessionStorageGet('langset'),
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    }
  }
  /**
   *
   * @param payload contains result data
   * @param val using this we load entire occlist or only list for particular page
   */
  filterProgsListData(payload, val) {
    let Total_List;
    if (val === 'prog_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('ProgIndexReducerText')
      ).ProgIndexReducerText;
    } else {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('getListForAll')
      ).GetAllOccList;
    }
    const list = [];
    const CluList = [];
    const ClusterIdList = [];

    let totalListValues = [];
    totalListValues = Total_List.progList.filter(function (obj, inx) {
      let filterchekcondition = true;
      if (payload.text !== '') {
        const regExp = new RegExp(payload.text, 'gi');
        filterchekcondition = regExp.test(obj.title);
      }
      if (payload.rating.length > 0) {
        if (!(payload.rating.indexOf(obj.rating) > -1)) {
          filterchekcondition = false;
        }
      }

      return filterchekcondition;
    });

    Total_List.clusList.forEach(
      function (obj, inx) {
        totalListValues.forEach(
          function (obj1, inx1) {
            if (obj.clusterID === obj1.clusterID) {
              if (ClusterIdList.indexOf(obj.clusterID) === -1) {
                ClusterIdList.push(obj.clusterID);
                CluList.push(obj);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    const temp1 = {
      clusList: [],
      progList: [],
    };
    temp1.clusList = CluList;
    temp1.progList = totalListValues;
    if (val === 'prog_index') {
      return { ProgIndexReducerText: temp1 };
    } else {
      return { GetAllOccList: temp1 };
    }
  }
  /**
   *
   * @param array contains total data
   * @param KeyString contains name for the key
   * @param ValueString contains values for respective keys
   * @param name used to call effect
   */
  convertJson(array, KeyString, ValueString, name) {
    const temp = {};
    const ref = this;
    array.forEach(
      function (obj, inx) {
        if (obj[KeyString] === 'ParentCluster') {
          const id = obj[ValueString].linkID;
          const val = clusterDetails['cls_' + id];
          const iconValue = clusterDetails['cls_' + id].clusterIconValue;
          const bgValue = clusterDetails['cls_' + id].clusterBgColor;
          temp[obj[KeyString]] = obj[ValueString];
          temp[obj[KeyString]].parentClusterIcon = iconValue;
          temp[obj[KeyString]].parentClusterColor = bgValue;
        } else if (obj[KeyString] === 'WageLevels') {
          const headers = {};
          obj[ValueString].headers.forEach(function (obj1, inx1) {
            headers[obj1['type']] = obj1['header'];
          });
          temp[obj[KeyString]] = {
            headers: headers,
            periods: obj[ValueString].periods,
            notes: obj[ValueString].notes,
          };
        } else {
          temp[obj[KeyString]] = obj[ValueString];
        }
      }.bind(this)
    );
    if (name === 'common') {
      this.callEffect();
    }
    return temp;
  }

  /**
   *
   * @param payload contains result data
   * @param val using this we load entire occlist or only list for particular page
   */
  filterListData(payload, val) {
    let Total_List;
    if (val === 'index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('OccIndexReducerText')
      ).OccIndexReducerText;
    } else if (val == 'rc_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('rcOccList')
      ).OccIndexReducerText;
    } else {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('getListForAll')
      ).GetAllOccList;
    }
    const list = [];
    const CluList = [];
    const ClusterIdList = [];

    let totalListValues = [];
    totalListValues = Total_List.occList.filter(function (obj, inx) {
      let filterchekcondition = true;
      if (payload.text !== '') {
        const regExp = new RegExp(payload.text, 'gi');
        filterchekcondition = regExp.test(obj.title);
      }
      if (payload.rating.length > 0) {
        if (!(payload.rating.indexOf(obj.rating) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.edu.length > 0) {
        if (!(payload.edu.indexOf(obj.edLevelID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.wages.length > 0) {
        if (!(payload.wages.indexOf(obj.wageID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.hotjob.length > 0) {
        if (!(payload.hotjob.indexOf(obj.hotJob) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.stem.length > 0) {
        if (!(payload.stem.indexOf(obj.stem) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.math.length > 0) {
        if (!(payload.math.indexOf(obj.math) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.locating.length > 0) {
        if (!(payload.locating.indexOf(obj.locating) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.reading.length > 0) {
        if (!(payload.reading.indexOf(obj.reading) > -1)) {
          filterchekcondition = false;
        }
      }
      return filterchekcondition;
    });

    Total_List.clusList.forEach(
      function (obj, inx) {
        totalListValues.forEach(
          function (obj1, inx1) {
            if (obj.clusterID === obj1.clusterID) {
              if (ClusterIdList.indexOf(obj.clusterID) === -1) {
                ClusterIdList.push(obj.clusterID);
                CluList.push(obj);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    const temp1 = {
      clusList: [],
      occList: [],
    };
    temp1.clusList = CluList;
    temp1.occList = totalListValues;
    if (val == 'index') {
      return { OccIndexReducerText: temp1 };
    } else if (val == 'rc_index') {
      return { OccIndexReducerText: temp1 };
    } else {
      return { GetAllOccList: temp1 };
    }
  }

  /**
   *
   * @param array contains total data
   */
  convertOCCFilterTextJson(array) {
    const filtemp = {
      headers: {},
      buttons: {},
      filters: [],
    };
    array.headers.forEach(function (obj, inx) {
      filtemp.headers[obj.type] = obj.header;
    });
    array.buttons.forEach(function (obj, inx) {
      filtemp.buttons[obj.button] = obj.title;
    });
    filtemp.filters = array.filters;
    this.utils.showLoading();
    return this.assessStoreService.getLangChange(
      this.storageService.sessionStorageGet('langset'),
      'occFilter',
      filtemp
    );
  }

  /**
   *
   * @param array contains total data
   */
  convertOCCIndexTextJson(array) {
    const temp = [];
    const rctemp = [];
    const rctemp1 = {
      OccIndexReducerText: {
        clusList: [],
        occList: [],
      },
    };
    const temp1 = {
      clusList: [],
      occList: [],
    };
    for (let j = 0; j < array.occs.length; j++) {
      temp.push(array.occs[j]);
      rctemp.push(array.occs[j]);
    }
    for (let j = 0; j < array.emerging.length; j++) {
      temp.push(array.emerging[j]);
    }
    for (let i = 0; i < temp.length; i++) {
      for (let j = i + 1; j < temp.length; j++) {
        if (temp[i].title > temp[j].title) {
          const a = temp[i];
          temp[i] = temp[j];
          temp[j] = a;
        }
      }
      const str = temp[i].occID + '';
      if (str.substring(0, 2) === '14') {
        temp[i].check = false;
      } else {
        temp[i].check = true;
      }
    }
    for (let i = 0; i < rctemp.length; i++) {
      for (let j = i + 1; j < rctemp.length; j++) {
        if (rctemp[i].title > rctemp[j].title) {
          const a = rctemp[i];
          rctemp[i] = rctemp[j];
          rctemp[j] = a;
        }
      }
      const str = rctemp[i].occID + '';
      if (str.substring(0, 2) === '14') {
        rctemp[i].check = false;
      } else {
        rctemp[i].check = true;
      }
    }
    rctemp1.OccIndexReducerText.occList = rctemp;
    for (let j = 0; j < array.clusters.length; j++) {
      temp1.clusList.push(array.clusters[j]);
      rctemp1.OccIndexReducerText.clusList.push(array.clusters[j]);
    }
    temp1.occList = temp;
    this.storageService.sessionStorageSet('rcOccList', JSON.stringify(rctemp1));

    if (temp1.occList.length !== 0) {
      this.storageService.sessionStorageSet('parentCnt', 'true');
    } else if (temp1.occList.length === 0) {
      this.storageService.sessionStorageSet('parentCnt', 'false');
    }
    const setVal = this.assessStoreService.getLangChange(
      this.storageService.sessionStorageGet('langset'),
      'OCC_index_list',
      temp1
    );
    return temp1;
  }
  /**
   *
   * @param array contains progs total data
   */
  convertProgIndexTextJson(array) {
    const temp = [];
    const temp1 = {
      clusList: [],
      progList: [],
    };
    for (let j = 0; j < array.progs.length; j++) {
      temp.push(array.progs[j]);
    }
    for (let i = 0; i < temp.length; i++) {
      for (let j = i + 1; j < temp.length; j++) {
        if (temp[i].title > temp[j].title) {
          const a = temp[i];
          temp[i] = temp[j];
          temp[j] = a;
        }
      }
    }

    for (let j = 0; j < array.clusters.length; j++) {
      temp1.clusList.push(array.clusters[j]);
    }
    temp1.progList = temp;

    if (temp1.progList.length !== 0) {
      this.storageService.sessionStorageSet('progparentCnt', 'true');
    } else if (temp1.progList.length === 0) {
      this.storageService.sessionStorageSet('progparentCnt', 'false');
    }
    const setVal = this.assessStoreService.getLangChange(
      this.storageService.sessionStorageGet('langset'),
      'Prog_index_list',
      temp1
    );
    return temp1;
  }
  /**
   *
   * @param array contains total data
   */
  convertProgTextJson(array) {
    const temp = {
      common: {},
      filters: {},
    };
    temp.common = array.common;
    array.filters.forEach(function (obj, inx) {
      temp.filters[obj['type']] = obj;
    });
    return temp;
  }
  convertAidIndexTextJson(array) {
    const temp = [];
    const temp1 = {
      clusList: [],
      aidList: [],
    };
    for (let j = 0; j < array.aid.length; j++) {
      temp.push(array.aid[j]);
    }
    for (let i = 0; i < temp.length; i++) {
      for (let j = i + 1; j < temp.length; j++) {
        if (temp[i].title > temp[j].title) {
          const a = temp[i];
          temp[i] = temp[j];
          temp[j] = a;
        }
      }
    }

    for (let j = 0; j < array.clusters.length; j++) {
      temp1.clusList.push(array.clusters[j]);
    }
    temp1.aidList = temp;

    if (temp1.aidList.length !== 0) {
      this.storageService.sessionStorageSet('aidparentCnt', 'true');
    } else if (temp1.aidList.length === 0) {
      this.storageService.sessionStorageSet('aidparentCnt', 'false');
    }
    const setVal = this.assessStoreService.getLangChange(
      this.storageService.sessionStorageGet('langset'),
      'aid_index_list',
      temp1
    );
    return temp1;
  }

  convertAidTextJson(array) {
    const temp = {
      common: {},
      filters: {},
      aidIndexFromProgPage: {},
    };
    temp.common = array.common;
    temp.aidIndexFromProgPage = array.aidIndexFromProgPage;
    array.filters.forEach(function (obj, inx) {
      temp.filters[obj['type']] = obj;
    });
    return temp;
  }

  convertSchoolIndexTextJson(array) {
    const temp = [];
    const temp1 = {
      clusList: [],
      schoolList: [],
    };
    for (let j = 0; j < array.schools.length; j++) {
      temp.push(array.schools[j]);
    }
    for (let i = 0; i < temp.length; i++) {
      for (let j = i + 1; j < temp.length; j++) {
        if (temp[i].title > temp[j].title) {
          const a = temp[i];
          temp[i] = temp[j];
          temp[j] = a;
        }
      }
    }

    for (let j = 0; j < array.clusters.length; j++) {
      temp1.clusList.push(array.clusters[j]);
    }
    temp1.schoolList = temp;

    if (temp1.schoolList.length !== 0) {
      this.storageService.sessionStorageSet('schoolparentCnt', 'true');
    } else if (temp1.schoolList.length === 0) {
      this.storageService.sessionStorageSet('schoolparentCnt', 'false');
    }
    const setVal = this.assessStoreService.getLangChange(
      this.storageService.sessionStorageGet('langset'),
      'school_index_list',
      temp1
    );
    return temp1;
  }
  convertSchoolTextJson(array) {
    const temp = {
      common: {},
      filters: {},
      pageText: {},
      schoolIndexFromProgPage: {},
    };
    temp.common = array.common;
    temp.pageText = array.pageText;
    temp.schoolIndexFromProgPage = array.schoolIndexFromProgPage;
    array.filters.forEach(function (obj, inx) {
      temp.filters[obj['type']] = obj;
    });
    return temp;
  }
  convertMilitaryIndexTextJson(array) {
    const temp = [];
    const temp1 = {
      clusList: [],
      milList: [],
    };
    for (let j = 0; j < array.mil.length; j++) {
      temp.push(array.mil[j]);
    }
    for (let i = 0; i < temp.length; i++) {
      for (let j = i + 1; j < temp.length; j++) {
        if (temp[i].title > temp[j].title) {
          const a = temp[i];
          temp[i] = temp[j];
          temp[j] = a;
        }
      }
    }

    for (let j = 0; j < array.clusters.length; j++) {
      temp1.clusList.push(array.clusters[j]);
    }
    temp1.milList = temp;

    if (temp1.milList.length !== 0) {
      this.storageService.sessionStorageSet('milparentCnt', 'true');
    } else if (temp1.milList.length === 0) {
      this.storageService.sessionStorageSet('milparentCnt', 'false');
    }
    const setVal = this.assessStoreService.getLangChange(
      this.storageService.sessionStorageGet('langset'),
      'military_index_list',
      temp1
    );
    return temp1;
  }
  convertMilitaryTextJson(array) {
    const temp = {
      common: {},
      filters: {},
      icons: {},
    };
    temp.common = array.common;

    array.filters.forEach(function (obj, inx) {
      temp.filters[obj['type']] = obj;
    });
    array.icons.forEach(function (obj1, inx1) {
      temp.icons[obj1['text']] = obj1;
    });
    return temp;
  }

  filterAidListData(payload, val) {
    const temp1 = {
      clusList: [],
      aidList: [],
    };
    let Total_List;
    if (val === 'aid_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('AidIndexReducerText')
      ).AidIndexReducerText;
    } else if (val == 'aid_scholarship_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('scholarshipData')
      ).AidIndexReducerText;
    } else if (val == 'scholarship_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('ProgscholarshipData')
      ).AidScholarshipText;
    } else {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('getListForAll')
      ).GetAllOccList;
    }
    const ClusterIdList = [];

    let totalListValues = [];
    totalListValues = Total_List.aidList.filter(function (obj, inx) {
      let filterchekcondition = true;
      if (payload.text !== '') {
        const regExp = new RegExp(payload.text, 'gi');
        filterchekcondition = regExp.test(obj.title);
      }
      if (payload.rating.length > 0) {
        if (!(payload.rating.indexOf(obj.rating) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.closeDate.length > 0) {
        if (!(payload.closeDate.indexOf(obj.closeDateID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.need.length > 0) {
        if (!(payload.need.indexOf(obj.need) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.gpa.length > 0) {
        if (!(payload.gpa.indexOf(obj.gpaID) > -1)) {
          filterchekcondition = false;
        }
      }
      return filterchekcondition;
    });
    const totalClusterListValues = [];

    Total_List.clusList.forEach(
      function (obj, inx) {
        totalListValues.forEach(
          function (obj1, inx1) {
            if (obj1.clusterID.indexOf(obj.clusterID) != -1) {
              if (ClusterIdList.indexOf(obj.clusterID) === -1) {
                ClusterIdList.push(obj.clusterID);
                totalClusterListValues.push(obj);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    if (val == 'aid_index' || val == 'aid_scholarship_index') {
      temp1.clusList = totalClusterListValues;
      temp1.aidList = totalListValues;
      return { AidIndexReducerText: temp1 };
    } else if (val == 'scholarship_index') {
      temp1.clusList = totalClusterListValues;
      temp1.aidList = totalListValues;
      return { AidScholarshipText: temp1 };
    } else {
      return { GetAllOccList: temp1 };
    }
  }
  filterSchoolListData(payload, val) {
    let Total_List;
    if (val === 'school_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('SchoolIndexReducerText')
      ).SchoolIndexReducerText;
    } else if (val === 'fromprog_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('FromSchoolIndexReducerText')
      ).SchoolIndexReducerText;
    } else if (val === 'inState_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('inStateActive')
      ).SchoolIndexReducerText;
    } else {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('getListForAll')
      ).GetAllOccList;
    }
    const CluList = [];
    const ClusterIdList = [];

    let totalListValues = [];
    totalListValues = Total_List.schoolList.filter(function (obj, inx) {
      let filterchekcondition = true;
      if (payload.text !== '') {
        const regExp = new RegExp(payload.text, 'gi');
        filterchekcondition = regExp.test(obj.title);
      }
      if (payload.rating.length > 0) {
        if (!(payload.rating.indexOf(obj.rating) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.type.length > 0) {
        if (!(payload.type.indexOf(obj.typeID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.ugLevel.length > 0) {
        if (!(payload.ugLevel.indexOf(obj.levelID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.location.length > 0) {
        if (!(payload.location.indexOf(obj.clusterID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.difficulty.length > 0) {
        if (!(payload.difficulty.indexOf(obj.difficultyID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.size.length > 0) {
        if (!(payload.size.indexOf(obj.sizeID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.aid.length > 0) {
        if (!(payload.aid.indexOf(obj.aidID) > -1)) {
          filterchekcondition = false;
        }
      }
      return filterchekcondition;
    });

    Total_List.clusList.forEach(
      function (obj, inx) {
        totalListValues.forEach(
          function (obj1, inx1) {
            if (obj.clusterID === obj1.clusterID) {
              if (ClusterIdList.indexOf(obj.clusterID) === -1) {
                ClusterIdList.push(obj.clusterID);
                CluList.push(obj);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    const temp1 = {
      clusList: [],
      schoolList: [],
    };
    temp1.clusList = CluList;
    temp1.schoolList = totalListValues;
    if (
      val === 'school_index' ||
      val === 'fromprog_index' ||
      val === 'inState_index'
    ) {
      return { SchoolIndexReducerText: temp1 };
    } else {
      return { GetAllOccList: temp1 };
    }
  }

  filterMilitaryListData(payload, val) {
    let Total_List;
    if (val === 'mil_index') {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('MilitaryIndexReducerText')
      ).MilitaryIndexReducerText;
    } else {
      Total_List = JSON.parse(
        this.storageService.sessionStorageGet('getListForAll')
      ).GetAllOccList;
    }
    const list = [];
    const CluList = [];
    const ClusterIdList = [];

    let totalListValues = [];
    totalListValues = Total_List.milList.filter(function (obj, inx) {
      let filterchekcondition = true;
      if (payload.text !== '') {
        const regExp = new RegExp(payload.text, 'gi');
        filterchekcondition = regExp.test(obj.title);
      }
      if (payload.rating.length > 0) {
        if (!(payload.rating.indexOf(obj.rating) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.type.length > 0) {
        if (!(payload.type.indexOf(obj.typeID) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.airForce.length > 0) {
        if (!(payload.airForce.indexOf(obj.airForce) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.army.length > 0) {
        if (!(payload.army.indexOf(obj.army) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.coastGuard.length > 0) {
        if (!(payload.coastGuard.indexOf(obj.coastGuard) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.marines.length > 0) {
        if (!(payload.marines.indexOf(obj.marines) > -1)) {
          filterchekcondition = false;
        }
      }
      if (payload.navy.length > 0) {
        if (!(payload.navy.indexOf(obj.navy) > -1)) {
          filterchekcondition = false;
        }
      }
      return filterchekcondition;
    });
    Total_List.clusList.forEach(
      function (obj, inx) {
        totalListValues.forEach(
          function (obj1, inx1) {
            if (obj.clusterID === obj1.clusterID) {
              if (ClusterIdList.indexOf(obj.clusterID) === -1) {
                ClusterIdList.push(obj.clusterID);
                CluList.push(obj);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    const temp1 = {
      clusList: [],
      milList: [],
    };
    temp1.clusList = CluList;
    temp1.milList = totalListValues;
    if (val === 'mil_index') {
      return { MilitaryIndexReducerText: temp1 };
    } else {
      return { GetAllOccList: temp1 };
    }
  }
  /**
   *
   * @param array contains total data
   */
  oSNotOnMyList(array) {
    try {
      const temp = [];

      const temp1 = {
        clusList: [],
        occList: [],
      };
      const resnotData = JSON.parse(
        this.storageService.sessionStorageGet('NotOccList')
      );
      if (resnotData != null && resnotData.length !== 0) {
        for (let j = 0; j < array.occList.length; j++) {
          for (let i = 0; i < resnotData.length; i++) {
            if (resnotData[i].occID === array.occList[j].occID) {
              temp.push(array.occList[j]);
            }
          }
        }
        const ClusterIdList = [];
        for (let j = 0; j < array.clusList.length; j++) {
          for (let i = 0; i < temp.length; i++) {
            if (ClusterIdList.indexOf(array.clusList[j].clusterID) === -1) {
              if (temp[i].clusterID === array.clusList[j].clusterID) {
                temp1.clusList.push(array.clusList[j]);
                ClusterIdList.push(array.clusList[j].clusterID);
              }
            }
          }
        }
        temp1.occList = temp;
      }
      if (temp1.occList.length !== 0) {
        this.storageService.sessionStorageSet('parentCnt', 'true');
      } else if (temp1.occList.length === 0) {
        this.storageService.sessionStorageSet('parentCnt', 'false');
      }
      const state = { GetAllOccList: temp1 };
      this.storageService.sessionStorageSet(
        'OSNotOnMyList',
        JSON.stringify(state)
      );
      this.utils.hideLoading();
    } catch (e) {
      console.log(
        'convertOCCListTextJson OSNotOnMyList exception:' + e.message
      );
    }
  }

  /**
   *
   * @param array contains total data
   * @param resData contains list for particular Area
   * @param module contains which module to load
   */
  convertOCCListTextJson(array, resData, module) {
    if (module === 'OSOnMyList') {
      this.oSNotOnMyList(array);
    }
    try {
      const temp = [];

      const temp1 = {
        clusList: [],
        occList: [],
      };

      if (resData.hasOwnProperty('occs')) {
        if (resData != null && resData.occs.length !== 0) {
          for (let j = 0; j < array.occList.length; j++) {
            for (let i = 0; i < resData.occs.length; i++) {
              if (resData.occs[i] === array.occList[j].occID) {
                temp.push(array.occList[j]);
              }
            }
          }
          const ClusterIdList = [];
          for (let j = 0; j < array.clusList.length; j++) {
            for (let i = 0; i < temp.length; i++) {
              if (ClusterIdList.indexOf(array.clusList[j].clusterID) === -1) {
                if (temp[i].clusterID === array.clusList[j].clusterID) {
                  temp1.clusList.push(array.clusList[j]);
                  ClusterIdList.push(array.clusList[j].clusterID);
                }
              }
            }
          }
          temp1.occList = temp;
        }
      } else {
        if (resData != null && resData.length !== 0) {
          for (let j = 0; j < array.occList.length; j++) {
            for (let i = 0; i < resData.length; i++) {
              if (resData[i].occID === array.occList[j].occID) {
                temp.push(array.occList[j]);
              }
            }
          }
          const ClusterIdList = [];
          for (let j = 0; j < array.clusList.length; j++) {
            for (let i = 0; i < temp.length; i++) {
              if (ClusterIdList.indexOf(array.clusList[j].clusterID) === -1) {
                if (temp[i].clusterID === array.clusList[j].clusterID) {
                  temp1.clusList.push(array.clusList[j]);
                  ClusterIdList.push(array.clusList[j].clusterID);
                }
              }
            }
          }
          temp1.occList = temp;
        }
      }

      if (temp1.occList.length !== 0) {
        this.storageService.sessionStorageSet('parentCnt', 'true');
      } else if (temp1.occList.length === 0) {
        this.storageService.sessionStorageSet('parentCnt', 'false');
      }

      if (module !== 'OSOnMyList') {
        this.dispatchStore.dispatch({
          type: 'OCC_LIST_STORE_EFFECT',
          payload: temp1,
        });
      } else if (module === 'OSOnMyList') {
        this.dispatchStore.dispatch({
          type: 'OCC_LIST_WHY_EFFECT',
          payload: temp1,
        });
      }
    } catch (e) {
      console.log('convertOCCListTextJson exception:' + e.message);
    }
  }

  /**
   *
   * @param array contains total data
   */
  convertSettingsTextJson(array) {
    const temp = {
      topSections: [],
      tabs: {},
      allSections: {},
    };
    array.tabs.forEach(function (obj, inx) {
      const num: string = obj['tabId'] + '';
      temp.tabs['tab' + num] = obj;
    });
    temp.topSections = array.topSections;
    // temp.tabs = array.tabs;
    array.allSections.forEach(function (obj, inx) {
      temp.allSections[obj['section']] = obj['title'];
    });
    temp.tabs = array.tabs;
    return temp;
  }
  /**
   *
   * @param array contains total data
   */
  convertTextJson(array) {
    const temp = {
      common: {},
      filters: {},
      select: {
        intro: '',
        states: [],
      },
    };
    temp.common = array.common;
    array.filters.forEach(function (obj, inx) {
      temp.filters[obj['type']] = obj;
    });
    temp.select.intro = array.select.intro;
    temp.select.states = array.select.states;
    return temp;
  }

  getRCStatecode() {
    const rcIntro1 = {
      input_data: [
        {
          param_type: 'path',
          params: [this.utils.getAccountId()],
        },
        {
          param_type: 'query',
          params: { isForCis360: true, lang: 'en' },
        },
        {
          param_type: 'body',
          params: {},
        },
      ],
    };
    this.apiJson.method = 'GET';
    this.apiJson.sessionID = this.utils.getAuthKey();
    this.apiJson.moduleName = 'Assessment/v1/';
    this.apiJson.endUrl = 'Users/realityCheck/StateCode';
    this.apiJson.data = JSON.stringify(rcIntro1);
    // debugger;
    this.loginauth.callApi([this.apiJson]).subscribe(
      (res) => {
        if (res[0] && res[0].Success) {
          StorageService.staticSessionStorageSet('RC_stateCode', res[0].Result);
        }
      },
      (e) =>
        this.utils.handleError(
          `store-shared.service.ts getRCStatecode: ${e.message}`
        )
    );
  }

  /**
   *
   * @param resp contains cookies like account id
   */
  pugLogin() {
    const isZipLogin =
      StorageService.staticSessionStorageGet('isZipLogin') === 'true'
        ? true
        : false;

    try {
      this.loginauth.getStateAbbr().subscribe((res) => {
        StorageService.staticSessionStorageSet('stateAbr', res.result);
      });
      let accountTypeStore;
      const accountType = 'Restricted';
      this.accountStore
        .pipe(
          select(getAccountTypeselector),
          takeWhile(() => this.compActive)
        )
        .subscribe((accountresponse) => {
          if (accountresponse) {
            accountTypeStore = accountresponse;
          }
        });
      const accountTypeValue =
        accountTypeStore === accountType ? 'siteId' : 'accountID';
      const endurlpath =
        accountTypeStore === accountType ? 'framework/site' : 'framework';
      const apiJson1 = new ApiCallClass();
      apiJson1.method = 'GET';
      apiJson1.endUrl = endurlpath;
      apiJson1.moduleName = 'Settings/v1/';
      const data = {
        input_data: [
          {
            param_type: 'path',
            params: [accountTypeValue],
          },
        ],
      };
      apiJson1.method = 'GET';
      apiJson1.data = JSON.stringify(data);
      // This could subscribe to the dispatchStore and wait for the completion this.framework being set instead of using a timeout here.
      // What's the workflow here?  Why are we waiting until the data has arrive to navigate, vs navigating and then watching for the data
      // needed for the app page.

      // Instead of using callAPI here, this could be the first effect / thunk kicked off by the SET_FRAMEWORK_CONFIG action.
      this.loginauth
        .callApi([apiJson1])
        .toPromise()
        .then((respSetting) => {
          this.getRCStatecode();
          StorageService.staticSessionStorageSet('savedValId', '');
          const langData = {};
          StorageService.staticSessionStorageSet(
            'langDataStore',
            JSON.stringify(langData)
          );
          this.framework = respSetting[0];
          /*Here the usecase is the careers and Edcucation component List are copying into dashboard componentList */
          let dashboardIndex = -1,
            dashboardCompList = [];
          this.framework.Result.tabItems.forEach((outerobj, inx1) => {
            if (outerobj.layout == 'dashboard') {
              dashboardIndex = inx1;
            }
            outerobj.compList.forEach(
              function (obj, inx) {
                if (routeObj[obj.compId] !== undefined) {
                  const result1 = Object.assign(
                    {},
                    routeObj[obj.compId].itemConfig,
                    obj
                  );
                  outerobj.compList[inx] = result1;
                  if (
                    obj.compId.includes('file') ||
                    obj.compId.includes('sort')
                  ) {
                    dashboardCompList.push(outerobj.compList[inx]);
                  }
                }
              }.bind(this)
            );
          });
          /* Here the dashboardIndex have the -1 value when we login TestDynamic .So we changed the ternary to if condition now. */
          if (dashboardIndex !== -1) {
            this.framework.Result.tabItems[dashboardIndex].compList =
              dashboardCompList;
          }
          let isJunior = false;
          this.framework.Result.tabItems.forEach(function (obj, inx) {
            obj.compList.forEach(function (obj1, inx1) {
              if (obj1.compId === 'fileOccJr') {
                StorageService.staticSessionStorageSet('occsJr', obj1.compId);
                isJunior = true;
              }
              if (obj1.compId.includes('fileOcc')) {
                StorageService.staticSessionStorageSet('fileOcc', 'true');
              }
            });
          });
          if (isJunior) {
            StorageService.staticSessionStorageSet('isJunior', 'true');
          }
          this.dispatchStore.dispatch({
            type: 'SET_FRAMEWORK_CONFIG',
            payload: this.framework,
          });
          /* If the accountType is 'Guest', then the account is only given access to a form(CreateAccountSetting) to signUp,
         thus restricting the other modules from loading into the browser. Which means,
          till he registers(as portfolio user) he is not given access to any of the other modules in the applicaton */
          if (
            isZipLogin &&
            this.framework.Result.user.accountType === 'Guest'
          ) {
            this.router.navigate(['../app/guestAccount'], {
              relativeTo: this.activatedRoute,
            });
          } else {
            if (this.framework.Result.user.accountType === 'Guest') {
              this.router.navigate(['../app/guestAccount'], {
                relativeTo: this.activatedRoute,
              });
            } else {
              this.router.navigateByUrl('app');
            }
          }
          // This is an effect that should be kicked off by the set_framework_config action.
          this.trackEvnt.callCommonText();
        });
    } catch (e) {
      console.log('Login success routeLogin exception:' + e.message);
    }
  }
  async PlansDataGetCall() {
    const ref = this;
    this.framework = JSON.parse(
      StorageService.staticSessionStorageGet('loginFrameworkConfiguration')
    )
      ? JSON.parse(
          StorageService.staticSessionStorageGet('loginFrameworkConfiguration')
        ).config
      : // this is an observable turned into a promise
        // This sovles a race condition that is not yet well known where the this.Frameworkstore.select('config').subscribe((v)
        // in the framework.component.ts on init is called before the session storage loginFrameworkConfigutaion is set.
        // Can be recreated by logging in, using logout route to logout, logging back in.
        await this.Frameworkstore.select('config')
          .pipe(take(1))
          .toPromise()
          .then((config) => {
            return config.config;
          });
    this.framework.Result.tabItems.forEach(
      function (obj, inx) {
        if (obj.layout == 'plan') {
          const apiJson2 = new ApiCallClass();
          apiJson2.method = 'GET';
          apiJson2.endUrl =
            '/Dynamicicap/UserPlans/' +
            this.utils.getAccountId() +
            '?isForCis360=true&stateAbbr=stateAbbr&lang=en';
          apiJson2.moduleName = 'dynamicicap/';
          apiJson2.method = 'GET';
          ref.subscription4 = ref.loginauth
            .callDynamicPlpApi([apiJson2])
            .subscribe((respSetting2) => {
              ref.DynamicPLPData = respSetting2[0].Result;
              ref.storageService.sessionStorageSet(
                'loginPlansConfiguration',
                JSON.stringify({ plan: ref.DynamicPLPData })
              );
              const URLData = StorageService.staticSessionStorageGet('planPath');
              /* if not in plan navigate to url else navigate to parent path.
          This is for navigating to the parent route instead of reloading the entire route.
          This will also deactivate the inner router-outlet. */
              if (!ref.router.url.includes('plan')) {
                ref.router.navigate([URLData], {
                  relativeTo: ref.activatedRoute,
                });
              } else {
                // '.' represents the parent path.
                ref.router.navigate(['.'], { relativeTo: ref.activatedRoute });
              }
            });
        }
      }.bind(this)
    );
  }
  planLoading(index) {
    const ref = this;
    const data = JSON.parse(
      StorageService.staticSessionStorageGet('loginFrameworkConfiguration')
    );
    this.framework = data.config;
    const planData = JSON.parse(
      StorageService.staticSessionStorageGet('loginPlansConfiguration')
    );
    this.DynamicPLPData = planData.plan;

    const frameworkdata = this.DynamicPLPData;
    const apiJson3 = new ApiCallClass();
    apiJson3.method = 'GET';
    apiJson3.endUrl =
      '/Dynamicicap/UserPlan/' +
      this.utils.getAccountId() +
      '/' +
      frameworkdata[index].PlanID +
      '?isForCis360=true&stateAbbr=stateAbbr&lang=en';
    apiJson3.moduleName = 'dynamicicap';
    apiJson3.method = 'GET';
    ref.PlanSettings = ref.loginauth
      .callDynamicPlpApi([apiJson3])
      .subscribe((PlanSettings) => {
        frameworkdata[index] = PlanSettings[0].Result;
        ref.storageService.sessionStorageSet(
          'loginPlansConfiguration',
          JSON.stringify({ plan: ref.DynamicPLPData })
        );
        this.framework.Result.tabItems.forEach(
          function (obj, inx) {
            if (obj.layout == 'plan') {
              ref.framework.Result.tabItems[inx].compList = [];
              frameworkdata[index].ItemsList.forEach(function (obj1, inx1) {
                ref.framework.Result.tabItems[inx].compList.push({
                  ...obj1,
                  compId: obj1.ItemType.toLowerCase() + obj1.ModuleID,
                  compName: obj1.DisplayName,
                  description: obj1.Description,
                  ItemID: obj1.ItemID,
                  CompletionStatus: obj1.CompletionStatus,
                });
              });
            }
          }.bind(this)
        );
        StorageService.staticSessionStorageSet(
          'loginFrameworkConfiguration',
          JSON.stringify({ config: this.framework })
        );
        // });
        this.dispatchStore.dispatch({
          type: 'SET_FRAMEWORK_CONFIG',
          payload: this.framework,
        });
        this.utils.hideLoading();
      });
  }
  statusChange(planInx, ItemId, status) {
    const planData = JSON.parse(
      StorageService.staticSessionStorageGet('loginPlansConfiguration')
    );
    for (let inx = 0; inx < planData.plan.length; inx++) {
      for (let i = 0; i < planData.plan[inx].ItemsList.length; i++) {
        if (planData.plan[inx].ItemsList[i].ItemID == ItemId) {
          planData.plan[inx].ItemsList[i].CompletionStatus = status;
        }
      }
    }
    StorageService.staticSessionStorageSet(
      'loginPlansConfiguration',
      JSON.stringify(planData)
    );
    this.planLoading(planInx);
  }

  applyingMethodFilter(list, sectionslist) {
    this.methodresult = 0;
    for (let k = 0; k < sectionslist.sections.length; k++) {
      if (list[sectionslist.sections[k]].length > 0) {
        this.methodresult++;
        if (this.methodresult == 1) {
          StorageService.staticSessionStorageSet('applyingCard', sectionslist.sections[k]);
        } else {
          StorageService.staticSessionStorageSet('applyingCard', '');
        }
      }
    }

    return this.methodresult;
  }

  commonMethodFilter(list, sectionslist) {
    this.methodresult = 0;
    for (let k = 0; k < sectionslist.length; k++) {
      if (list[sectionslist[k]] && list[sectionslist[k]].length > 0) {
        this.methodresult++;
        if (this.methodresult == 1) {
          StorageService.staticSessionStorageSet('availableCard', sectionslist[k]);
        } else {
          StorageService.staticSessionStorageSet('availableCard', '');
        }
      }
    }

    return this.methodresult;
  }

  ngOnDestroy() {
    this.compActive = false;
    this.subscription.unsubscribe();
    this.PlanSettings.unsubscribe();
    this.subscription4.unsubscribe();
  }
}
