// Angular imports
import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Action } from '@ngrx/store';
import { clusterDetails } from '../occ-constants/cluster-icons';
import { StorageService } from '../../../../shared/outer-services/storage.service';
import { Utilities } from '../../../../shared/outer-services/utilities.service';

@Injectable()
export class OccCommonMethodsService {
    clusterView; // Variable used to store boolean value to switch from title view
    titleView; // Variable used to store boolean value to switch from cluster view
    Total_List_Cluster_View; // Variable used for the cluster View and to store the 'array' consisting all the cluster career details
    Total_List_Title_View; // Variable used for the cluster View and to store the 'array' consisting all the cluster career details
    constructor(
        private router: Router,
        private dispatchStore: Store<Action>,
        private storageService: StorageService,
        private utils: Utilities
    ) {

    }
    OccIndexDispatch() {
        const payloadjson = {
            type: 'GET_OCC_INDEX_STORE_TEXT', payload: {
                methodVal: 'GET', module_Name: 'Occ/v1/',
                path_params:
                    ['index', 'accountID'],
                query_params: { 'lang': this.storageService.sessionStorageGet('langset') },
                body_Params: {}, endUrlVal: 'pages'
            }
        };
        this.commonLanguageChange(this.storageService.sessionStorageGet('langset'), 'OCC_index_list', payloadjson);
        this.dispatchStore.dispatch({
            type: 'GET_OCC_BUTTONS_TEXT', payload: {
                methodVal: 'GET', module_Name: 'Occ/v1/',
                path_params: ['text'], query_params: { 'lang': this.storageService.sessionStorageGet('langset') },
                body_Params: {}, endUrlVal: 'pages'
            }
        });
        this.dispatchStore.dispatch({
            type: 'GET_OCC_ASSESSMENT_FILTER', payload: {
                methodVal: 'GET', module_Name: 'Assessment/v1/',
                path_params: ['accountID'], query_params: { 'lang': this.storageService.sessionStorageGet('langset') },
                body_Params: {}, endUrlVal: 'Users/SortIDs'
            }
        });
    }
    clustDetails(value) {
        return clusterDetails['cls_' + value];
    }
    eraseCookieFromAllPaths(name) {
        const pathBits = location.pathname.split('/');
        let pathCurrent = ' path=';

        // do a simple pathless delete first.
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

        for (let i = 0; i < pathBits.length; i++) {
            pathCurrent += ((pathCurrent.substr(-1) !== '/') ? '/' : '') + pathBits[i];
            document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
        }
    }
    exitApp(option) {
        try {
            // @todo hit logout route here with jwt?  If the server redirects then pass logoutURL too
            this.eraseCookieFromAllPaths('LTILogin2');
            const logoutURL = this.storageService.sessionStorageGet('LogoutURL');
            this.storageService.sessionStorageClear();
            if (logoutURL !== '' && logoutURL !== null && logoutURL !== undefined) {
                window.location.href = logoutURL;
            } else {
                window.location.href = 'login';
            }

        } catch (e) {
            console.log('exitApp exception:' + e.message);
        }
    }

    /**
     *
     * @param array contains total data
     * @param KeyString contains name for the key
     * @param ValueString contains values for respective keys
     * @param name used to call effect
     */
    convertJson(array, KeyString, ValueString, name) {
        const temp = {};
        const ref = this;
        array.forEach(function (obj, inx) {
            if (obj[KeyString] === 'ParentCluster' && obj[ValueString] && obj[ValueString].linkID) {
                const id = obj[ValueString].linkID;
                const val = clusterDetails['cls_' + id];
                const iconValue = clusterDetails['cls_' + id].clusterIconValue;
                const bgValue = clusterDetails['cls_' + id].clusterBgColor;
                temp[obj[KeyString]] = obj[ValueString];
                temp[obj[KeyString]].parentClusterIcon = iconValue;
                temp[obj[KeyString]].parentClusterColor = bgValue;
            } else if (obj[KeyString] === 'WageLevels') {
                const headers = {};
                obj[ValueString].headers.forEach(function (obj1, inx1) {
                    headers[obj1['type']] = obj1['header'];
                });
                temp[obj[KeyString]] = { 'headers': headers, 'periods': obj[ValueString].periods, 'notes': obj[ValueString].notes };

            } else {
                temp[obj[KeyString]] = obj[ValueString];
            }
        }.bind(this));
        if (name === 'common') {
            this.callEffect();
        }
        return temp;
    }
    /**
   *
   * @param array contains total data
   */
    convertOCCIndexTextJson(array) {
        // this.Total_List_Cluster_View = array.occs;
        // this.Total_List_Title_View = array.occs;
        // const temp = [];
        // const rctemp = [];
        const rctemp1 = {
            OccIndexReducerText: {
                'clusList': [],
                'occList': []
            }
        };
        const temp1 = {
            'clusList': [],
            'occList': []
        };
        //Without this LOGIC i:e Inserting the ClusterName in occs objects, while in clusterView it is not possible to filter based on the clusterNames.
        let temp = array.occs.map((occ) => {
            array.clusters.reduce((acc, cluster) => {
                if (occ.clusterID === cluster.clusterID) {
                    occ.clusTitle = cluster.title;
                }
            });
            return occ;
        });
        const rctemp = temp.concat([]);
        //In case of emerging careers we are trying to insert the title on comparision with id's. Otherwise, the filter based on clusterview will not happen;
        let emergingObj;
        emergingObj = temp.concat(array.emerging.map((obj) => {
            array.clusters.reduce((acc, cluster) => {
                if (obj.clusterID === cluster.clusterID) {
                    obj.clusTitle = cluster.title;
                }
            });
            return obj;
        }));
        temp = emergingObj;
        for (let i = 0; i < temp.length; i++) {
            for (let j = i + 1; j < temp.length; j++) {
                if (temp[i].title > temp[j].title) {
                    const a = temp[i];
                    temp[i] = temp[j];
                    temp[j] = a;
                }
            }
            const str = temp[i].occID + '';
            if (str.substring(0, 2) === '14') {
                temp[i].check = false;
            } else {
                temp[i].check = true;
            }
        }
        for (let i = 0; i < rctemp.length; i++) {
            for (let j = i + 1; j < rctemp.length; j++) {
                if (rctemp[i].title > rctemp[j].title) {
                    const a = rctemp[i];
                    rctemp[i] = rctemp[j];
                    rctemp[j] = a;
                }
            }
            const str = rctemp[i].occID + '';
            if (str.substring(0, 2) === '14') {
                rctemp[i].check = false;
            } else {
                rctemp[i].check = true;
            }
        }
        rctemp1.OccIndexReducerText.occList = rctemp;
        for (let j = 0; j < array.clusters.length; j++) {

            temp1.clusList.push(array.clusters[j]);
            rctemp1.OccIndexReducerText.clusList.push(array.clusters[j]);
        }
        temp1.occList = temp;
        this.storageService.sessionStorageSet('rcOccList', JSON.stringify(rctemp1));


        if ((temp1.occList).length !== 0) {
            this.storageService.sessionStorageSet('parentCnt', 'true');
        } else if ((temp1.occList).length === 0) {
            this.storageService.sessionStorageSet('parentCnt', 'false');
        }
        const setVal = this.getLangChange(this.storageService.sessionStorageGet('langset'), 'OCC_index_list', temp1);
        return temp1;

    }

    /**
     *
     * @param payload contains result data
     * @param val using this we load entire occlist or only list for particular page
     */
    filterListData(payload, val) {
        const temp1 = {
            'clusList': [],
            'occList': []
        };
        let Total_List;
        if (val === 'index') {
            const index = this.storageService.sessionStorageGet('OccIndexReducerText');
            if (index && index !== 'undefined') {
                Total_List = JSON.parse(index).Occ_Index_Reducer_Text;
            }
        } else if (val === 'rc_index') {
            const rc_index = this.storageService.sessionStorageGet('rcOccList');
            if(rc_index && rc_index !== 'undefined') {
                Total_List = JSON.parse(rc_index).OccIndexReducerText;
            }
        } else {
            const index = this.storageService.sessionStorageGet('getListForAll');
            if (index && index != 'undefined') {
                Total_List = JSON.parse(index).GetAllOccList;
            }
        }
        if (Total_List) {
            this.Total_List_Cluster_View = Total_List.occList;
            this.Total_List_Title_View = Total_List.occList;
        }
        const list = [];
        const CluList = [];
        const ClusterIdList = [];
        let totalListValues = [];
        if (!Total_List || !Total_List.occList) {
            return { Occ_Index_Reducer_Text: temp1 };
        }
        if (payload.text === '') {
            totalListValues = Total_List.occList.filter(function (obj, inx) {
                let filterchekcondition = true;
                if (payload.apprenticeship.length > 0){
                    if (!(payload.apprenticeship.indexOf(obj.apprenticeship) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.rating.length > 0) {
                    if (!(payload.rating.indexOf(obj.rating) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.edu.length > 0) {
                    if (!(payload.edu.indexOf(obj.edLevelID) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.wages.length > 0) {
                    if (!(payload.wages.indexOf(obj.wageID) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.hotjob.length > 0) {
                    if (!(payload.hotjob.indexOf(obj.hotJob) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.stem.length > 0) {
                    if (!(payload.stem.indexOf(obj.stem) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.selfemploy.length > 0) {
                    if (!(payload.selfemploy.indexOf(obj.selfEmploy) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.math && payload.math.length > 0) {
                    if (!(payload.math.indexOf(obj.math) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.locating && payload.locating.length > 0) {
                    if (!(payload.locating.indexOf(obj.locating) > -1)) {
                        filterchekcondition = false;
                    }
                }
                if (payload.reading && payload.reading.length > 0) {
                    if (!(payload.reading.indexOf(obj.reading) > -1)) {
                        filterchekcondition = false;
                    }
                }
                return filterchekcondition;

            });
        } else {
            let Total_Array;
            Total_Array = this.Total_List_Title_View;
            let count = 0; // Variable that tracks number of times the match between clusterName and careerName.
            let counting;
            if (this.titleView) {
                this.clusterView = false;
                totalListValues = Total_Array.filter(function (obj, inx) {
                    let filterchekcondition = false; // Variable to check for match with the title
                    const filterchekcondition1 = false; // Variable to check for match with the clustitle
                    const filterchekcondition2 = false; // Variable to check for match with both title and clustitle
                    if (payload.text !== '') {
                        const altTitles = obj.altTitles.split(',');
                        const word = payload.text.toLowerCase();
                        filterchekcondition = altTitles.find(title => title.startsWith(word));
                    }
                    if (payload.apprenticeship.length > 0) {
                        if (!(payload.apprenticeship.indexOf(obj.apprenticeship) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.rating.length > 0) {
                        if (!(payload.rating.indexOf(obj.rating) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.edu.length > 0) {
                        if (!(payload.edu.indexOf(obj.edLevelID) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.wages.length > 0) {
                        if (!(payload.wages.indexOf(obj.wageID) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.hotjob.length > 0) {
                        if (!(payload.hotjob.indexOf(obj.hotJob) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.stem.length > 0) {
                        if (!(payload.stem.indexOf(obj.stem) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.math && payload.math.length > 0) {
                        if (!(payload.math.indexOf(obj.math) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.locating && payload.locating.length > 0) {
                        if (!(payload.locating.indexOf(obj.locating) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.reading && payload.reading.length > 0) {
                        if (!(payload.reading.indexOf(obj.reading) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    return filterchekcondition;
                });
            }
            // Filtering based on the search text in clusterView.
            if (this.clusterView) {
                this.titleView = false;
                totalListValues = Total_Array.filter(function (obj) {
                    let filterchekcondition = false; // Variable to check for match with the title
                    let filterchekcondition1 = false; // Variable to check for match with the clustitle
                    let filterchekcondition2 = false; // Variable to check for match with both title and clustitle
                    if (payload.text !== '') {
                        const altTitles = obj.altTitles.split(',');
                        const word = payload.text.toLowerCase();
                        filterchekcondition = altTitles.find(title => title.startsWith(word));
                        if (obj.clusTitle) {
                            filterchekcondition1 = obj.clusTitle.toLowerCase().startsWith(word);
                        }

                        if (filterchekcondition && filterchekcondition1) {
                            filterchekcondition2 = true;
                        }
                    }
                    if (payload.apprenticeship.length > 0) {
                        if (!(payload.apprenticeship.indexOf(obj.apprenticeship) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.rating.length > 0) {
                        if (!(payload.rating.indexOf(obj.rating) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.edu.length > 0) {
                        if (!(payload.edu.indexOf(obj.edLevelID) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.wages.length > 0) {
                        if (!(payload.wages.indexOf(obj.wageID) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.hotjob.length > 0) {
                        if (!(payload.hotjob.indexOf(obj.hotJob) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.stem.length > 0) {
                        if (!(payload.stem.indexOf(obj.stem) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.math && payload.math.length > 0) {
                        if (!(payload.math.indexOf(obj.math) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.locating && payload.locating.length > 0) {
                        if (!(payload.locating.indexOf(obj.locating) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (payload.reading && payload.reading.length > 0) {
                        if (!(payload.reading.indexOf(obj.reading) > -1)) {
                            filterchekcondition = false;
                        }
                    }
                    if (filterchekcondition && !filterchekcondition1 && !filterchekcondition2) {
                        obj.delete = 'title'; // Random property Name inserted when there is a match to title name;
                        return filterchekcondition;
                    }
                    if (filterchekcondition1 && !filterchekcondition && !filterchekcondition2) {
                        obj.delete = 'clusTitle'; // Random property Name inserted when there is a match to ClusterTitle;
                        return filterchekcondition1;
                    }
                    if (filterchekcondition1 && filterchekcondition) {
                        obj.delete = 'no'; // Random property Name to differentiate when there is a match between clusTitle and Title;
                        counting = count++;
                        return filterchekcondition2;
                    }
                });
                // Below condition for pushing into a new array once found out that there is a match between the ClusterName and Title:
                if (count < counting + 2) {
                    const arrayToStoreWhenClusTitleAndTitleMatch = [];
                    totalListValues.map((obj) => {
                        if (obj.delete === 'no' || obj.delete === 'title') {
                            arrayToStoreWhenClusTitleAndTitleMatch.push(obj);
                        }
                    });
                    if (arrayToStoreWhenClusTitleAndTitleMatch) {
                        totalListValues = arrayToStoreWhenClusTitleAndTitleMatch;
                    }
                }
            }
        }
        // Here below the filtered occlist will be set to an array.
        Total_List.clusList.forEach(function (obj, inx) {
            totalListValues.forEach(function (obj1, inx1) {
                if (obj.clusterID === obj1.clusterID) {
                    if ((ClusterIdList.indexOf(obj.clusterID) === -1)) {
                        ClusterIdList.push(obj.clusterID);
                        CluList.push(obj);
                    }
                }
            }.bind(this));
        }.bind(this));
        temp1.clusList = CluList;
        temp1.occList = totalListValues;
        if (val == 'index') {
            return { Occ_Index_Reducer_Text: temp1 };
        } else if (val == 'rc_index') {
            return { Occ_Index_Reducer_Text: temp1 };
        } else {
            return { GetAllOccList: temp1 };
        }
    }
    /**
   *
   * @param array contains total data
   */
    convertSettingsTextJson(array) {
        const temp = {
            'topSections': [],
            'tabs': {},
            'allSections': {}

        };
        array.tabs.forEach(function (obj, inx) {
            const num: string = obj['tabId'] + '';
            temp.tabs['tab' + num] = obj;
        });
        temp.topSections = array.topSections;
        // temp.tabs = array.tabs;
        array.allSections.forEach(function (obj, inx) {
            temp.allSections[obj['section']] = obj['title'];
        });
        temp.tabs = array.tabs;
        return temp;
    }
    /**
       *
       * @param array contains total data
       */
    convertTextJson(array) {
        const temp = {
            'common': {},
            'filters': {},
            'select': {
                'intro': '',
                'states': []
            }
        };
        temp.common = array.common;
        array.filters.forEach(function (obj, inx) {
            temp.filters[obj['type']] = obj;
        });
        temp.select.intro = array.select.intro;
        temp.select.states = array.select.states;
        return temp;
    }
    getLangChange(langVal, key, result) {
        const val = JSON.parse(this.storageService.sessionStorageGet('langDataStore'));
        if (Object.keys(val[langVal]).indexOf(key) != -1) {
            return result;
        } else {
            val[langVal][key] = result;
            this.storageService.sessionStorageSet('langDataStore', JSON.stringify(val));
            return result;
        }
    }

    commonLanguageChange(langVal, key, payload) {
        try {
            const val = JSON.parse(this.storageService.sessionStorageGet('langDataStore'));
            if (Object.keys(val).indexOf(langVal) == -1) {
                val[langVal] = {};
                this.storageService.sessionStorageSet('langDataStore', JSON.stringify(val));
                this.dispatchStore.dispatch(payload);


            } else {
                if (Object.keys(val[langVal]).indexOf(key) == -1) {
                    this.dispatchStore.dispatch(payload);
                } else {
                    if (key == 'OCC_index_list') {
                        this.storageService.sessionStorageSet('OccIndexReducerText', JSON.stringify({ Occ_Index_Reducer_Text: val[langVal][key] }));
                    } else if (key == 'Prog_index_list') {
                        this.storageService.sessionStorageSet('ProgIndexReducerText', JSON.stringify({ ProgIndexReducerText: val[langVal][key] }));
                    } else if (key == 'aid_index_list') {
                        this.storageService.sessionStorageSet('AidIndexReducerText', JSON.stringify({ AidIndexReducerText: val[langVal][key] }));
                    } else if (key == 'school_index_list') {
                        this.storageService.sessionStorageSet('SchoolIndexReducerText', JSON.stringify({ SchoolIndexReducerText: val[langVal][key] }));
                    } else if (key == 'military_index_list') {
                        this.storageService.sessionStorageSet('MilitaryIndexReducerText', JSON.stringify({ MilitaryIndexReducerText: val[langVal][key] }));
                    }
                }
            }

        } catch (e) {
            console.log('exception in commonLanguageChange' + e.message);
        }
    }
    // To call effects
    callEffect() {
        const routerVal = this.router.url.split('&');
        const occID = routerVal[0].split('=');
        if (!occID || occID.length === 0) {
            this.utils.log('cci-store-shared.services.ts callEffect EMP_OUTLOOK_TEXT', 'debug').subscribe();
          }
        const outlook = this.storageService.sessionStorageGet('outlookStates');
        const wages = this.storageService.sessionStorageGet('wagesStates');
        if (outlook != null) {
            this.dispatchStore.dispatch({
                type: 'GET_OCC_EMP_OUTLOOK', payload: {
                    methodVal: 'GET', module_Name: 'Occ/v1/',
                    path_params: ['occ', parseInt(occID[1], 10), 'accountID'],
                    query_params: {
                        'sections': 'MajorEmployers,OutlookInfo,OutlookRatings,TopOpeningLocations',
                        'states': outlook, 'lang': this.storageService.sessionStorageGet('langset')
                    },
                    body_Params: {}, endUrlVal: 'pages'
                }
            });
        }
        if (wages != null) {
            this.dispatchStore.dispatch({
                type: 'GET_OCC_WAGES', payload: {
                    methodVal: 'GET', module_Name: 'Occ/v1/',
                    path_params: ['occ', parseInt(occID[1], 10), 'accountID'],
                    query_params: { 'sections': 'WageLevels', 'states': wages, 'lang': this.storageService.sessionStorageGet('langset') },
                    body_Params: {}, endUrlVal: 'pages'
                }
            });
        }
    }
}
